import { apiSlice } from "../api/apliSlice";

export const homeApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllProperty: builder.query({
      query: () => ({
        url: `/hotels`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["hotels"],
    }),
    getProperty: builder.query({
      query: ({ token, id }) => ({
        url: `/hotels/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["hotel"],
    }),

    getCities: builder.query({
      query: () => ({
        url: `/hotels/cities`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["cities"],
    }),

    getGuestReviews: builder.query({
      query: () => ({
        url: `/reviews`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["Reviews"],
    }),

 getRecentlyView: builder.query({
  query: (token) => {
    if (!token) return null; 
    return {
      url: `/auth/recently-viewed`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      fetchOptions: {
        mode: "cors",
      },
    };
  },
  providesTags: ["recently-viewed"],

}),

  }),
});

export const {
  useGetAllPropertyQuery,
  useGetPropertyQuery,
  useGetCitiesQuery,
  useGetGuestReviewsQuery,
  useGetRecentlyViewQuery,
} = homeApi;
