import React, { useEffect } from "react";
import Banner from "../../components/LuxuryProperties/Banner";
import UkiyoExlusiveVillas from "../../components/LuxuryProperties/ExclusiveLuxury";
import Exquisite from "../../components/LuxuryProperties/Exquisite";
import MostTrending from "../../components/LuxuryProperties/MostTrending";
import Container from "../../components/ui/Container";
import ChatSupportPopup from "../ChatSupportPopup/ChatSupportPopup";
const LuxuryProperties = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Container>
        <div className="lg:px-6">
          {/* Banner Section */}
          <Banner />
          {/* Most Trending Property  */}
          <div className="px-6 md:px-0 ">
            <MostTrending />
          </div>
          {/* exquisite card section */}
          <Exquisite />

          {/* Ukiyo Exclusive Luxury Villas */}
          <UkiyoExlusiveVillas />
        </div>
      </Container>
      <ChatSupportPopup />
    </>
  );
};

export default LuxuryProperties;
