import { useState } from "react";

const BlogsOptions = ({ setOptionSelected, OptionSelected }) => {
  const Options = [
    "All",
    "Locations",
    "Restaurants & Nightlife",
    "Travel Guides",
    "Things to do",
    "Places to go",
    "Wellness",
    "Events & Experiences",
    "Design",
    "Travel Inspiration",
    "Stays",
    "Luxury travel",
    "Travel",
  ];
  const [hoveredOption, setHoveredOption] = useState(0);
  return (
    <div className="mt-[88px] mb-[129px] mx-8">
      <div className=" md:flex-wrap md:justify-center md:items-center gap-8 flex  max-md:overflow-x-scroll hide_scrollbar">
        {Options?.map((item, index) => (
          <div
            role="button"
            key={index}
            className=""
            onMouseOver={() => setHoveredOption(index)}
            onMouseOut={() => setHoveredOption(OptionSelected)}
            onClick={() => setOptionSelected(item)}
          >
            <div className={`${OptionSelected===item && "max-md:bg-black max-md:text-white"}  2xl:text-xl max-md:w-[100px] py-[7px] px-[16px] max-md:h-[38px] flex flex-col items-center font-semibold  max-md:border border-black p-2 rounded-md`}>
              {item}
              <div
                className={`${
                  hoveredOption === index || OptionSelected === item
                    ? "opacity-100"
                    : "opacity-0"
                } lg:w-full lg:h-[3px] transform duration-500 bg-zinc-950 rounded-[200px]`}
              />
            </div>
            {/* <div className="w-3 h-3 bg-neutral-200 rounded-full max-md:hidden" /> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsOptions;
