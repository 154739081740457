import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import messageIcon from "../../assets/svg/message.webp";
import Banner from "../../components/Banner";
import ChatModalContent from "../../components/FutureGetaways/ChatModal/ChatModalContent";
import BookingDiscount from "../../components/LuxuryVillas/BookingDiscount";
import MainLuxuryVillas from "../../components/LuxuryVillas/MainLuxuryVillas";
import ChatModal from "../../components/modals/ChatModal";
import ChatSupportPopup from "../ChatSupportPopup/ChatSupportPopup";
const AllProperties = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const location = useLocation();
  //  const searchBar = location?.state?.searchData;
  // const searchBar = location?.state?.data;

  const searchBar = location?.state?.searchData ? (location?.state?.searchData) : (location?.state?.data);
  // console.log(
  //   "👉🏻 > file: index.jsx:19 > AllProperties > searchBar:",
  //   searchBar
  // );

  const city = location?.state?.location || location?.state?.data?.data?.availableHotels[0]?.city;
  console.log("th city is here :", location?.state?.data?.data?.availableHotels[0]?.city)
  const checkIn = location?.state?.checkIn;
  const checkOut = location?.state?.checkOut;
  const isProp = location?.state?.isProp;
  const SearchTags = location?.state?.SearchTag;

  const [token, setToken] = useState("");

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);
  const [chatModal, setChatModal] = useState(false);
  const openChatModal = () => {
    setChatModal(true);
  };
  const CloseChatModal = () => {
    setChatModal(false);
  };
  useEffect(() => { }, [isProp]);





  return (
    <>
      <div>
        <BookingDiscount />
      </div>
      <div>
        <Banner city={city} isHomeScreen={false} />
      </div>
      <MainLuxuryVillas
        searchData={searchBar}
        token={token}
        city={city}
        checkIn={checkIn}
        checkOut={checkOut}
        SearchTag={SearchTags}
      />
      {chatModal && (
        <div>
          <ChatModal CloseChatModal={CloseChatModal}>
            <ChatModalContent
              CloseChatModal={CloseChatModal}
              openChatModal={openChatModal}
            />
          </ChatModal>{" "}
        </div>
      )}
      {!chatModal && (
        <div className=" m-2  mt-10 flex justify-end items-end  bottom-2 lg:bottom-4 right-2 lg:right-4">
          <div
            className=" bg-black w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex rounded-full justify-center items-center relative "
            onClick={openChatModal}
          >
            <img src={messageIcon} width="23" height="23" alt="" />
          </div>
        </div>
      )}
      <ChatSupportPopup />
    </>
  );
};

export default AllProperties;
