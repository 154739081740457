import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import phone_call from "../../assets/images/PropertyBooking/phone.webp";
import rightImg from "../../assets/images/PropertyBooking/step1.webp";
import stepTwo from "../../assets/images/PropertyBooking/step2.webp";
import stepThree from "../../assets/images/PropertyBooking/step3.webp";
import leftArrow from "../../assets/svg/leftArrow.webp";
import shareIcon from "../../assets/svg/share.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";

import { loginModalContext } from "../../components/Layout";

import BookingOfferseModal from "../../components/modals/BookingOfferseModal";
import HelpModal from "../../components/modals/HelpModal";

import ProfileDropDown from "../../components/Navbar/ProfileDropDown";

import Container from "../../components/ui/Container";

import AddOns from "../../components/PropertyBookingCoupon/AddOns";

import BookingGuests from "../../components/PropertyBookingCoupon/BookingGuests";
import BookingInfo from "../../components/PropertyBookingCoupon/BookingInfo";
import BookingMeals from "../../components/PropertyBookingCoupon/BookingMeals";
import CancellationPolicy from "../../components/PropertyBookingCoupon/CancellationPolicy";
import HelpModalContent from "../../components/PropertyBookingCoupon/HelpModalContent";
import OfferseModalContent from "../../components/PropertyBookingCoupon/OfferseModalContent";
import BookingDate from "./BookingDate";
import BookingDetails from "./BookingDetails";

const BookingPropertyAddons = () => {
  const params = useParams();
  console.log("paramaID :::", params.id);
  const token = useTokenFromLocalStorage();
  const { showModal, setShowModal } = useContext(loginModalContext);
  const navigate = useNavigate();
  const location = useLocation();
  // const [token, setToken] = useState("");
  const [helpModal, setHelpModal] = useState(false);
  const [offers, setOffers] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [InDate, setInDate] = useState("");
  const [OutDate, setOutDate] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [adultsCount, setAdultsCount] = useState(0);
  const [chidrenCount, setChidrenCount] = useState(0);
  const [hotelData, setHotelData] = useState({});
  const [rooms, setRooms] = useState(null);
  useEffect(() => {
    fetch(`https://api.ukiyostays.com/api/bookings?bookingId=${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setHotelData(data?.data?.booking?.hotelDetails);
        setInDate(data?.data?.booking?.checkIn);
        setOutDate(data?.data?.booking?.checkOut);
        setAdultsCount(data?.data?.booking?.noOfAdults);
        setChidrenCount(data?.data?.booking?.noOfChildren);
        setRooms(data?.data?.booking?.noOfRooms);
      });
  }, []);
  const [payment, setPayment] = useState({
    rental: "1,38,000",
    discount: "10,000",
    gst: "23,040",
    security: "0",
    totalPayable: "1,61,000",
  });

  const [selectedCoupons, setSelectedCoupons] = useState();

  const pay = location?.state?.payment;
  console.log("PaY : ", pay);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  useEffect(() => {
    const locationObj = location?.state;
    console.log(locationObj);
    // getTokenFromLocalStorage();
    setInDate(locationObj?.checkIn);
    setOutDate(locationObj?.checkOut);
    setAdultsCount(locationObj?.adults);
    setChidrenCount(locationObj?.children);
    console.log(
      locationObj?.checkInDate,
      "  datesss  ",
      locationObj?.checkOutDate
    );
    setBookingData({
      hotelId: locationObj?.hotelId,
      checkInDate: locationObj?.checkInDate,
      checkOutDate: locationObj?.checkOutDate,
      payment: payment,
      adults: locationObj?.adults,
      children: locationObj?.children,
      rooms: locationObj?.rooms,
    });
  }, []);

  useEffect(() => {
    const discountPercent = selectedCoupons && selectedCoupons.discount;
    const discountedAmount = selectedCoupons
      ? (discountPercent * pay) / 100
      : "";
    const totalPayable = selectedCoupons ? pay - discountedAmount : pay;
    setPayment((prev) => {
      return {
        ...prev,
        rental: pay,
        totalPayable: totalPayable,
        discount: discountedAmount,
      };
    });
  }, [pay, selectedCoupons]);

  const navigateTo = (payId) => {
    navigate("/paymentSuccess", { state: { payId: payId } });
  };

  // STEP 3
  const createBooking = async (paymentId, paymentStatus, amount) => {
    try {
      const body = {
        hotelId: bookingData?.hotelId,
        checkIn: bookingData?.checkInDate,
        checkOut: bookingData?.checkOutDate,
        noOfAdults: bookingData?.adults,
        noOfChildren: bookingData?.children,
        noOfRooms: bookingData?.rooms,
        paymentId: paymentId,
        bookingStatus: paymentStatus,
        totalAmount: amount,
        specialRequests: "Early check-in requested",
      };

      const response = await fetch(
        `https://api.ukiyostays.com/api/bookings/create`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const responseData = await response.json();
      const success = responseData?.success;

      console.log("response of create booking::: ", responseData);
      if (success) {
        const payId = responseData.data.booking._id;
        navigateTo(payId);
        console.log("payId : ", payId);
        // navigateTo("/paymentSuccess");
      }
    } catch (error) {
      console.log("error isss : ", error);
    }
  };

  // STEP 2
  const paymentVerify = async (payId, orderId, signature) => {
    const body = {
      razorpay_payment_id: payId,
      razorpay_order_id: orderId,
      razorpay_signature: signature,
    };
    try {
      const response = await fetch(
        `https://api.ukiyostays.com/api/payments/verify`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const responseData = await response.json();
      const success = responseData?.success;
      const payment = responseData.data.payment;
      const paymentId = payment._id;
      const paymentStatus = payment.paymentStatus;
      const amount = responseData?.data?.order?.amount / 100;

      if (success) createBooking(paymentId, paymentStatus, amount);

      console.log("res data iss : ", responseData);
    } catch (error) {
      console.log(error);
    }
  };

  // STEP 1
  const makePayment = async () => {
    const body = {
      amount: payment?.totalPayable,
      currency: "INR",
    };
    const responseJson = await fetch(
      `https://api.ukiyostays.com/api/payments/pay`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    console.log(selectedCoupons);
    const responseData = await responseJson.json();
    const order = responseData?.data?.order;
    const amount = order?.amount;
    console.log(
      "👉🏻 > file: PropertyBooking.jsx:200 > makePayment > amount:",
      amount
    );
    const order_id = order?.id;

    const options = {
      key: "rzp_live_cS4pveHYkI5L8K",
      amount: amount,
      currency: "INR",
      name: "Ukiyo",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order_id,

      handler: function (response) {
        paymentVerify(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },

      prefill: {
        name: "Gaurav Kumar", //your customer's name
        email: "gaurav.kumar@example.com",
        contact: "9000090000", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const isOpenOfferspModal = () => {
    setOffers(true);
  };
  const isCloseOffersModal = () => {
    setOffers(false);
  };
  const isOpenHelpModal = () => {
    setHelpModal(true);
  };
  const isCloseHelpModal = () => {
    setHelpModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [GoogleValue, setGoogleValue] = useState("");
  const [showShareToolTip, setShareToolTip] = useState(false);
  // const paymentSuccessfully=()=>{navigate()}
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const bookingForm = (
    <>
      <div className="w-full ">
        <div className="w-full h-fit border p-4 max-xl:p-0 max-xl:border-none  rounded-md  py-6 ">
          <h1 className="text-[#000000]  my-4 max-md:text-lg text-3xl  font-[600] ">
            Price Details
          </h1>
          <hr />
          <div>
            <div className="flex justify-between mt-4 leading-8">
              <p className="text-[#000000] max-md:text-base text-xl font-medium ">
                Rental
              </p>
              <p className="text-[#000000]  text-xl max-md:text-base font-medium ">
                ₹{payment.rental}
              </p>
            </div>
            <p className="text-[#6D747A] mb-4 text-[14px] max-md:font-normal max-md:text-sm font-[400] leading-6">
              For 2 Guests, 2 Nights
            </p>
          </div>
          <div>
            <div className="flex justify-between mt-4 ">
              <p className="text-[#039855]  text-lg  font-[Lato] font-medium ">
                {selectedCoupons
                  ? ` Discount: Coupon (${selectedCoupons.code}) `
                  : ""}
              </p>
              <p className="text-[#039855]  text-lg  font-[Lato] font-medium ">
                {selectedCoupons && `(-)₹${payment.discount}`}
              </p>
            </div>
          </div>
          <div>
            <div className="flex justify-between mt-4 leading-8">
              <p className="text-[#000000]  text-xl max-md:text-base font-medium ">
                GST
              </p>
              <p className="text-[#000000]  text-[16px]  max-md:text-base font-[400]  ">
                ₹{payment.gst}
              </p>
            </div>
            <p className="text-[#6D747A] mb-4 text-[14px] max-md:font-normal max-md:text-sm font-[400] leading-6">
              As per government guidelines
            </p>
          </div>
          <div>
            <div className="flex justify-between mt-4 leading-8">
              <p className="text-[#000000]  text-xl max-md:text-base font-medium ">
                Security Deposit
              </p>
              <p className="text-[#000000]  text-[16px]  max-md:text-base font-[400]  ">
                ₹{payment.security}
              </p>
            </div>
            <p className="text-[#6D747A] mb-4 text-[14px] max-md:font-normal max-md:text-sm font-[400] leading-6">
              Refundable
            </p>
          </div>
          <hr />
          <div className="flex justify-between mt-4 leading-8">
            <p
              className="text-[#08090A
] max-md:text-lg text-[24px]  font-medium "
            >
              Total Payable{" "}
            </p>
            <p className="text-[#08090A] max-md:text-lg text-[20px] font-medium ">
              ₹{payment.totalPayable}
            </p>
          </div>
          <div
            className="flex justify-between items-center bg-[#ECF4FE] w-full h-[48px] mb-4 p-2 rounded-md   outline-dashed border-dashed mt-6 outline-[#0F6DDC] hover:cursor-pointer"
            onClick={isOpenOfferspModal}
          >
            <p className="text-[#6D747A] max-md:text-base font-[400]">
              Have a discount coupon?
            </p>
            <p className="text-[#0F6DDC] max-md:text-sm max-md:font-medium font-normal">
              VIEW OFFERS
            </p>
          </div>
          <hr className="max-xl:hidden" />
          <div className=" text-white font-bold items-center rounded-md flex gap-2 my-6">
            <input
              type="checkbox"
              name=""
              id=""
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
              className="custom-checkbox w-8 h-8 max-lg:w-7 max-lg:h-7 outline-none bg-black"
            />

            <span className="text-[#434141] max-md:text-sm text-[15px] font-[400]">
              I have read and accept the Terms & Conditions, Privacy Policies,
              and Cancellation Policy
            </span>
          </div>
          <hr className="md:hidden" />
          <button
            onClick={() => {
              if (!token) return setShowModal(true);
              if (isChecked) return makePayment() || setShowModal(true);
            }}
            type="submit"
            className="bg-[#08090A] text-white
                  text-base
                  font-bold
                max-lg:hidden
                  leading-none w-full py-4 h-[48px]   rounded-md"
          >
            ₹{payment.totalPayable} - Make Payment
          </button>
          <div className="my-6 max-lg:hidden">
            <div className="w-full h-10 py-2 justify-center items-center gap-6 inline-flex">
              <div className="w-full h-[0px] border border-slate-200"></div>
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Or{" "}
              </div>
              <div className="w-full h-[0px] border border-slate-200"></div>
            </div>
          </div>
          <button
            type="submit"
            onClick={isOpenHelpModal}
            className=" w-full -mb-1 h-[48px] border-black bg-white font-bold border border-1  rounded-md"
          >
            <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
              Make partial payment
            </div>
          </button>
          {/* smaller than lg device payment button */}
          <div
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
            }}
            className="w-full lg:hidden fixed bottom-0 left-0 h-fit px-6 pt-2 pb-3 bg-white shadow flex-col justify-start items-start gap-2 inline-flex"
          >
            <div className="flex-col justify-start items-start gap-2 flex">
              <div className="justify-start items-start gap-1 inline-flex">
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="w-[205px] text-emerald-600 text-xs font-normal  leading-[18px] tracking-tight">
                    Add Coupon code to get discounts
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => isChecked && makePayment()}
              type="submit"
              className="bg-[#08090A] text-white
                  text-base
                  font-bold
                
                  leading-none w-full py-4 h-[48px]   rounded-md"
            >
              ₹{payment.totalPayable} - Make Payment
            </button>
            <div className="my-1 w-full">
              <div className="w-full h-6 justify-center items-center gap-6 inline-flex">
                <div className="w-full h-[0px] border border-slate-200"></div>
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Or{" "}
                </div>
                <div className="w-full h-[0px] border border-slate-200"></div>
              </div>
            </div>
            <button
              type="submit"
              onClick={isOpenHelpModal}
              className=" w-full -mb-1 h-[48px] border-black bg-white font-bold border border-1  rounded-md"
            >
              <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
                Make partial payment
              </div>
            </button>
          </div>
        </div>
        <button
          type="submit"
          onClick={isOpenHelpModal}
          className=" w-full mt-4 mb-2 h-[48px] border-black bg-white font-bold border border-1  rounded-md"
        >
          Need Help ?
        </button>
        <div className="flex text-lg my-4 max-md:text-base justify-center items-center gap-1 mb-6 text-[#505356]  ">
          <p className="max-md:w-4 max-md:h-4 ">
            <img
              src={phone_call}
              alt="phone_call"
              className="text-[#6D747A] font-[500]"
            />
          </p>
          <p className="max-2xl:text-[16px] max-md:text-sm text-[#6D747A] font-[500]  ">
            For Booking Assistance, Call{" "}
          </p>
          <p className="font-medium max-2xl:text-[16px] max-md:text-sm">
            +91 8976767650
          </p>
        </div>
      </div>
    </>
  );
  return (
    <>
      <hr className="w-full border max-md:hidden" />
      <Container>
        <div className="px-6 max-md:px-0 2xl:px-0">
          <div className="py-3  lg:hidden px-6 flex justify-between items-center">
            <div className="flex-auto flex items-center gap-x-4 max-md:text-lg font-butler text-4xl tracking-wide">
              <button onClick={() => navigate(-1)} className="w-7 lg:hidden ">
                <img src={leftArrow} alt="" />
              </button>{" "}
              Booking Details
            </div>
            {token?.length > 0 && !GoogleValue ? <ProfileDropDown /> : ""}
          </div>
          <hr className="w-full border xl:hidden" />
          <BookingStepnner />
          <div className="grid grid-cols-12  gap-8">
            <div className="col-span-8 max-xl:col-span-12 ">
              <div className="py-3.5 w-full  max-xl:px-6 flex justify-between items-center">
                <div className="flex-auto flex items-center gap-x-4 max-md:text-lg font-butler font-[500] text-4xl tracking-wide">
                  Booking Details
                </div>{" "}
                <button
                  onMouseOver={() => {
                    setShareToolTip(true);
                  }}
                  onMouseLeave={() => {
                    setShareToolTip(false);
                  }}
                  className="border lg:hidden border-[#6D747A] rounded-full cursor-pointer"
                >
                  <img
                    className="md:p-[10px] max-md:p-1.5 max-md:w-[24px]"
                    src={shareIcon}
                    alt="icon"
                  />
                </button>
              </div>
              <div className="w-full max-xl:w-full">
                <BookingDetails
                  hotelData={hotelData}
                  adultsCount={adultsCount}
                  noOfRooms={rooms}
                />
                <BookingDate checkInDate={InDate} checkOutDate={OutDate} />
                <BookingGuests
                  adultsCount={adultsCount}
                  chidrenCount={chidrenCount}
                />
                <AddOns />
                <div className="hidden max-xl:block">
                  <BookingInfo />
                  <div className="col-span-4 max-xl:px-6">{bookingForm}</div>
                </div>
                <BookingMeals />
                <div className="max-xl:hidden">
                  <BookingInfo />
                </div>
                {/* <SpecialRequests /> */}
                <CancellationPolicy />

                {/* <PaymentSuccess /> */}
              </div>
            </div>
            <div className="col-span-4 max-xl:hidden max-xl:px-6 ">
              {bookingForm}
            </div>
          </div>
        </div>
      </Container>
      {helpModal && (
        <HelpModal
          children={<HelpModalContent hotelId={bookingData?.hotelId} />}
          isCloseHelpModal={isCloseHelpModal}
          helpModal={helpModal}
        />
      )}
      {offers && (
        <div className="">
          <BookingOfferseModal
            children={
              <OfferseModalContent
                setSelectedCoupon={setSelectedCoupons}
                token={token}
                setOffers={setOffers}
              />
            }
            isCloseOffersModal={isCloseOffersModal}
          />
        </div>
      )}
    </>
  );
};

export default BookingPropertyAddons;

export const BookingStepnner = () => {
  return (
    <ol className="xl:max-w-[1296px] w-[90%] mx-auto h-[49px] px-[20px] max-sm:pt-0 pt-3 max-sm:w-[342px] max-sm:shadow-none  pb-[13px] bg-white shadow-lg justify-center items-center flex max-lg:mb-4 mb-[55px] mt-[16px] max-sm:gap-9 shadow-neutral-700/10">
      <li className="flex w-full items-center space-x-2.5 rtl:space-x-reverse relative max-sm:flex-col">
        <img src={rightImg} alt="" className="max-md:w-4 max-md:h-4" />

        <span>
          <h3
            className="text-[#434141] max-sm:text-xs text-nowrap font-[500] 
text-base

leading-normal
tracking-tight"
          >
            Select your villa
          </h3>
        </span>

        <div className="border flex flex-grow w-full  border-b-2 border-[#EAEFF2] max-md:w-[120%] max-sm:left-12 top-1.5 max-w-[389px] left-0 max-md:absolute static"></div>
      </li>
      <li className="flex w-full items-center space-x-2.5 rtl:space-x-reverse relative max-sm:flex-col">
        <img src={stepTwo} alt="" className="max-md:w-4 ms-1 max-md:h-4" />
        <span>
          <h3
            className="text-[#434141] max-sm:text-xs text-nowrap font-[500] 
            text-base
            
            leading-normal
            tracking-tight"
          >
            Confirm Booking
          </h3>
        </span>
        <div className="border flex flex-grow w-full  border-b-2 border-[#EAEFF2] max-md:w-[100%] static left-0 max-sm:left-[52px] top-1.5 max-w-[379px] max-md:absolute"></div>
      </li>
      <li className="flex  items-center max-sm:flex-col space-x-2.5 rtl:space-x-reverse">
        <img src={stepThree} alt="" className="max-md:w-4 max-md:h-4" />
        <span>
          <h3
            className="text-[#434141] max-sm:text-xs text-nowrap font-[500] mr-4
           text-base
           
           leading-normal
           tracking-tight"
          >
            Payment
          </h3>
        </span>
      </li>
    </ol>
  );
};
