import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import whiteHeart from "../../assets/svg/whiteHeart.webp";
import AddOnsModal from "./AddOnsModal";

const AddOns = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const params = useParams();
  console.log("paramas id : ", params.id);
  const AddOnsImg = [
    "https://tb-static.uber.com/prod/image-proc/processed_images/3171f7bb6e2694e04ca5daf47ea0372a/3ac2b39ad528f8c8c5dc77c59abb683d.jpeg",
  ];
  const dummyData = [
    {
      _id: "1",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 1",
      price: 100,
    },
    {
      _id: "2",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 2",
      price: 200,
    },
    {
      _id: "3",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 3",
      price: 300,
    },
    {
      _id: "4",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 4",
      price: 200,
    },
    {
      _id: "5",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 5",
      price: 300,
    },
    {
      _id: "6",
      images: AddOnsImg,
      mealCombo: "Dummy Meal 6",
      price: 300,
    },
  ];

  //const reviews = [1, 2, 3, 4, 5];
  const [addOnsData, setAddOnsData] = useState([]);
  const [showAddOns, setShowAddOns] = useState(false);
  useEffect(() => {
    if (!params.id) {
      // Use dummy data if params.id is undefined
      setAddOnsData(dummyData);
    } else {
      fetch(`https://api.ukiyostays.com/api/payments/${params.id}/add-ons`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("addons data", data);
          setAddOnsData(data);
        });
    }
  }, [params.id]);

  const [addedAddOnsData, setAddedAddOnsData] = useState([]);
  return (
    <div
      className={`max-xl:px-5 mt-6 hidden md:block ${
        Array.isArray(addOnsData) && addOnsData.length > 0 ? "block" : "block"
      } `}
    >
      <div className="w-full mb-12 h-9 justify-between items-center flex">
        <div className="justify-center items-center gap-4 flex">
          <div className="text-zinc-950 text-2xl font-normal font-butler leading-9 tracking-tight">
            Add On’s
          </div>
        </div>
        <button
          onClick={() => setShowAddOns(!showAddOns)}
          className="bg-white justify-start items-start gap-2.5 flex"
        >
          <div className="text-blue-600 text-lg font-bold font-Lato uppercase leading-[27px]">
            view all
          </div>
        </button>
      </div>
      <div className="w-full h-10 flex-col justify-center items-start gap-[0.25px] mb-[29px] inline-flex">
        <div className="justify-start overflow-x-auto max-sm:max-w-[370px] w-full items-start gap-12 flex">
          <div className="flex-col text-nowrap justify-start items-start gap-1.5 flex">
            <div className="text-zinc-950 text-xl font-medium font-butler leading-[30px]">
              All Add on’s
            </div>
            <div className="self-stretch h-[3px] bg-zinc-950 rounded-[202px]" />
          </div>
          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
            <div className="text-neutral-700 text-xl font-medium font-butler leading-[30px] text-nowrap tracking-tight">
              F&B Offers
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
            <div className="text-neutral-700 text-xl font-medium font-butler leading-[30px]  text-nowrap tracking-tight">
              Services
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
            <div className="text-neutral-700 text-xl font-medium font-butler leading-[30px]  text-nowrap tracking-tight">
              Experiences
            </div>
          </div>
        </div>
      </div>
      {/* slider */}
      <div className="lg:block hidden ">
        <Swiper
          ref={swiperRef}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            handleSlideChange();
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
            630: {
              slidesPerView: 1,
            },
            368: {
              slidesPerView: 1,
            },
          }}
          spaceBetween={170}
          onSlideChange={handleSlideChange}
          className="w-full "
        >
          {Array.isArray(addOnsData) &&
            addOnsData?.map((data, index) => (
              <SwiperSlide className="me-5 py-3 " key={index}>
                <div
                  className="w-[306px] h-[450px] relative bg-white rounded 
                border border-slate-200 cursor-pointer"
                >
                  <div onClick={() => setShowAddOns(!showAddOns)}>
                    <img
                      className="w-[306px] h-[253px] left-0 top-0 absolute"
                      src={data?.images[0]}
                      alt="images"
                    />
                    <div className="h-[111px] px-4 py-3 left-0 top-[253px] absolute w-full flex-col justify-center items-start gap-3 inline-flex">
                      <div className="flex-col justify-center items-start gap-2 flex">
                        <div className="flex-col justify-center items-start gap-1.5 flex">
                          <div className="flex-col justify-center items-start gap-px flex">
                            <div className="text-zinc-950 text-lg font-normal font-butler leading-[27px] tracking-tight">
                              {data?.mealCombo}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-col justify-start items-start flex">
                        <div className="w-[203px] text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                          Starting from
                        </div>
                        <div className="justify-center items-center gap-0.5 inline-flex">
                          <div className="text-zinc-950 text-lg font-semibold font-Lato leading-[27px] tracking-tight">
                            ₹ {data?.price}
                          </div>
                          <div className="text-neutral-700 text-base font-normal font-['Roboto'] leading-normal tracking-tight">
                            {" "}
                            + Taxes
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 py-1 left-[16px] top-[15px] absolute bg-white rounded justify-start items-center gap-1 inline-flex">
                      <div className="text-neutral-700 text-xs font-semibold font-Lato leading-[18px] tracking-tight">
                        Most selling
                      </div>
                    </div>
                    <div className="w-6 h-6 left-[264px] top-[17px] absolute">
                      <img src={whiteHeart} alt="" />
                    </div>
                  </div>
                  {addedAddOnsData?.find((item) => item._id === data?._id) ? (
                    <div
                      role="button"
                      onClick={() => {
                        console.log(addOnsData);
                        const filtered = addedAddOnsData?.filter(
                          (item) => item._id !== data?._id
                        );
                        if (!filtered) {
                          return;
                        }
                        setAddedAddOnsData(filtered);
                      }}
                      className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                    >
                      <div
                        role="button"
                        className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                      >
                        <div className="h-12 w-full py-4 bg-white border border-black text-black rounded flex-col justify-center items-center gap-2.5 flex">
                          <div className=" text-base font-bold font-Lato leading-none">
                            Remove Item
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      role="button"
                      onClick={() => {
                        console.log(addOnsData);
                        const filtered = addedAddOnsData?.find(
                          (item) => item._id === data?._id
                        );
                        if (filtered) {
                          return;
                        }
                        setAddedAddOnsData([
                          ...addedAddOnsData,
                          {
                            _id: data?._id,
                          },
                        ]);
                      }}
                      className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                    >
                      <div
                        role="button"
                        className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                      >
                        <div className="h-12 w-full py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 flex">
                          <div className="text-white text-base font-bold font-Lato leading-none">
                            Add item
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div
        className="flex max-lg:mb-6 lg:hidden flex-wrap gap-x-5 justify-center items-center w-full
      "
      >
        {Array.isArray(addOnsData) &&
          addOnsData?.map((data, i) => (
            <SwiperSlide className="me-5 py-3 " key={i}>
              <div className="w-[306px] h-[450px] relative bg-white rounded border border-slate-200">
                <img
                  className="w-[306px] h-[253px] left-0 top-0 absolute"
                  src={data?.images[0]}
                  alt="images"
                />
                <div className="h-[111px] px-4 py-3 left-0 top-[253px] absolute w-full flex-col justify-center items-start gap-3 inline-flex">
                  <div className="flex-col justify-center items-start gap-2 flex">
                    <div className="flex-col justify-center items-start gap-1.5 flex">
                      <div className="flex-col justify-center items-start gap-px flex">
                        <div className="text-zinc-950 text-lg font-normal font-butler leading-[27px] tracking-tight">
                          {data?.mealCombo}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start flex">
                    <div className="w-[203px] text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                      Starting from
                    </div>
                    <div className="justify-center items-center gap-0.5 inline-flex">
                      <div className="text-zinc-950 text-lg font-semibold font-Lato leading-[27px] tracking-tight">
                        ₹ {data?.price}
                      </div>
                      <div className="text-neutral-700 text-base font-normal font-['Roboto'] leading-normal tracking-tight">
                        {" "}
                        + Taxes
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-2 py-1 left-[16px] top-[15px] absolute bg-white rounded justify-start items-center gap-1 inline-flex">
                  <div className="text-neutral-700 text-xs font-semibold font-Lato leading-[18px] tracking-tight">
                    Most selling
                  </div>
                </div>
                <div className="w-6 h-6 left-[264px] top-[17px] absolute">
                  <img src={whiteHeart} alt="" />
                </div>
                {addedAddOnsData.find((item) => item._id === data?._id) ? (
                  <div
                    role="button"
                    onClick={() => {
                      console.log(addOnsData);
                      const filtered = addedAddOnsData?.filter(
                        (item) => item._id !== data?._id
                      );
                      if (!filtered) {
                        return;
                      }
                      setAddedAddOnsData(filtered);
                    }}
                    className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                  >
                    <div
                      role="button"
                      className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                    >
                      <div className="h-12 w-full py-4 bg-white border border-black text-black rounded flex-col justify-center items-center gap-2.5 flex">
                        <div className=" text-base font-bold font-Lato leading-none">
                          Remove Item
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    role="button"
                    onClick={() => {
                      console.log(addOnsData);
                      const filtered = addedAddOnsData?.find(
                        (item) => item._id === data?._id
                      );
                      if (filtered) {
                        return;
                      }
                      setAddedAddOnsData([
                        ...addedAddOnsData,
                        {
                          _id: data?._id,
                        },
                      ]);
                    }}
                    className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                  >
                    <div
                      role="button"
                      className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                    >
                      <div className="h-12 w-full py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 flex">
                        <div className="text-white text-base font-bold font-Lato leading-none">
                          Add item
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        <div className="w-[164px] h-12 px-6 py-6 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
            Load More
          </div>
        </div>
      </div>
      <div className=" md:flex-row   max-w-[1296px]    justify-end lg:flex hidden  md:mx-6  xl:mx-2  items-center  gap-x-4">
        <button onClick={swipePrev} className="cursor-pointer">
          <img src={isBeginning ? scrollLeft : scrollLeftActive} alt="scroll" />
        </button>
        <button onClick={swipeNext} className="cursor-pointer">
          <img src={scrollRight} alt="scroll" />
        </button>
      </div>
      <div className="mx-auto max-lg:hidden max-w-[416px] max-lg:mb-10 h-12 justify-center items-start mt-5 flex">
        <div
          role="button"
          // onClick={() => {}}
          onClick={() => setShowAddOns(!showAddOns)}
          className={`${
            addedAddOnsData.length > 0 ? "bg-black" : "bg-zinc-950/30"
          } w-[416px] z-50 h-12 flex-col justify-center items-center mx-auto rounded  flex font-Lato text-white text-base font-bold `}
        >
          Proceed
          {addedAddOnsData.length > 0 && `${addedAddOnsData.length} Add Ons`}
        </div>
      </div>

      {showAddOns && (
        <AddOnsModal
          addOnsData={addOnsData}
          addedAddOnsData={addedAddOnsData}
          setAddedAddOnsData={setAddedAddOnsData}
          setShowAddOns={setShowAddOns}
        />
      )}
    </div>
  );
};

export default AddOns;
