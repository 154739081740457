import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import { InputField } from "../inputField/InputField";
import SpecialRequests from "./SpecialRequests";

const BookingInfo = () => {
  const token = useTokenFromLocalStorage();
  const { data: userDetails } = useAuthUserQuery(token);

  const userPrefiled = userDetails?.user;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    residentialCity: "",
    phoneNumber: "",
    registratedCompanyAddress: "",
    registratedCompanyName: "",
    registrationNumber: "",
    gstDetails: false,
  });

  useEffect(() => {
    if (userPrefiled) {
      setFormData({
        firstName: userPrefiled.firstName || "",
        lastName: userPrefiled.lastName || "",
        email: userPrefiled.email || "",
        residentialCity: userPrefiled.city || "",
        phoneNumber: userPrefiled.phoneNumber || "",
        gstDetails: false,
      });
    }
  }, [userPrefiled]);

  const [errorFields, setErrorFields] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    let errorMessage = "";

    switch (name) {
      case "firstName":
      case "lastName":
        if (!/^[A-Za-z]+$/.test(value)) {
          errorMessage = "Name should contain only letters.";
        }
        break;
      case "email":
        if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = "Please enter a valid email address.";
        }
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorFields({
      ...errorFields,
      [name]: errorMessage,
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData({
      ...formData,
      gstDetails: checked,
    });
  };

  const handlePhoneInputChange = (value) => {
    setErrorFields({
      ...errorFields,
      phoneNumber: "",
    });

    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // window.scrollTo({
    //   top: 210,
    //   behavior: "smooth", // Optional smooth scrolling behavior
    // });
    const emptyFieldsArray = Object.keys(formData).filter(
      (key) => formData[key] === "" && key !== "gstDetails"
    );
    if (emptyFieldsArray.length > 0) {
      const errorFieldsCopy = { ...errorFields };
      emptyFieldsArray.forEach((field) => {
        errorFieldsCopy[field] = "Please enter this field.";
      });
      setErrorFields(errorFieldsCopy);
      console.log("return 1");
      return;
    }
    if (Object.values(errorFields).some((error) => error !== "")) {
      console.log("return 2");
      return;
    }

    // Display the form data in the console
    console.log("Form Data:", formData);
  };

  return (
    <div className="max-xl:px-6 ">
      <div className="text-[#08090A] font-[Lato] max-lg:text-lg text-2xl leading-8 mb-5 flex justify-between">
        Enter Your Details
        <span  className="text-black cursor-pointer
                  text-lg
                  font-[600]
                  max-md:text-sm
                  underline-offset-2 uppercase
                  underline
                  leading-[27px]
                  tracking-tight">                
             Edit
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex mb-4 gap-5 ">
          <div className="mr-2 max-lg:mr-0 w-1/2">
            <InputField
              type="text"
              placeholder="First Name"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              id="firstName"
              label="First Name"
              className={`mt-1 p-2 w-full border rounded-md flex-1 pl-32 ${errorFields["firstName"] ? "border-red-500" : ""
                }`}
            />
            {errorFields["firstName"] && (
              <p className="text-red-500">{errorFields["firstName"]}</p>
            )}
          </div>
          <div className="w-1/2">
            <InputField
              type="text"
              placeholder="Last Name"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              id="lastName"
              label="Last Name"
              className={`mt-1 p-2 w-full border rounded-md flex-1 pl-32 ${errorFields["lastName"] ? "border-red-500" : ""
                }`}
            />
            {errorFields["lastName"] && (
              <p className="text-red-500">{errorFields["lastName"]}</p>
            )}
          </div>
        </div>
        <div className="flex w-full max-lg:flex-col gap-5 max-xl:gap-4">
          <div className="mb-4 max-xl:mb-0 mr-2 max-xl:w-full w-1/2">
            <label
              htmlFor="phoneNumber"
              className="font-semibold max-xl:mb-1 flex justify-start"
            >
              Phone Number
            </label>
            <div className="flex items-center w-full gap-2 relative ">
              <PhoneInput
                id="phoneNumber"
                country={"in"}
                name="phoneNumber"
                placeholder="Enter your phone number"
                className={"phone-input-wrappe "}
                value={formData.phoneNumber}
                onChange={handlePhoneInputChange}
                inputStyle={{ width: "85%", height: "48px", outline: "none" }}
              />
            </div>
            {errorFields["phoneNumber"] && (
              <p className="text-red-500">{errorFields["phoneNumber"]}</p>
            )}
          </div>

          <div className="w-1/2 max-xl:w-full">
            <InputField
              type="email"
              placeholder="exampleperson@gmail.com"
              name="email"
              onChange={handleChange}
              value={formData.email}
              id="email"
              label="Email address"
              className={`mt-1 p-2 w-full border rounded-md flex-1 pl-32 ${errorFields["email"] ? "border-red-500" : ""
                }`}
            />
            {errorFields["email"] && (
              <p className="text-red-500">{errorFields["email"]}</p>
            )}
          </div>
        </div>
        <div className="mb-4 max-xl:my-4 w-[48%] max-xl:w-full">
          <InputField
            type="text"
            placeholder="City"
            name="residentialCity"
            onChange={handleChange}
            value={formData.residentialCity}
            id="residentialCity"
            label="Residential City"
            className={`mt-1 p-2  w-full border rounded-md flex-1 pl-32 ${errorFields["residentialCity"] ? "border-red-500" : ""
              }`}
          />
          {errorFields["residentialCity"] && (
            <p className="text-red-500">{errorFields["residentialCity"]}</p>
          )}
        </div>
        <div className="text-white mb-2 font-bold py-2 items-center rounded-md flex gap-2">
          <input
            type="checkbox"
            name="gstDetails"
            id="gstDetails"
            className="w-5 h-5"
            onChange={handleCheckboxChange}
            checked={formData.gstDetails}
          />
          <span className="text-black  font-[500] text-[18px]">
            Enter GST Details{" "}
            <span className="text-gray-500 font-[400] text-[14px]">
              (Optional)
            </span>
          </span>
          {errorFields["gstDetails"] && (
            <p className="text-red-500">{errorFields["gstDetails"]}</p>
          )}
        </div>
        {formData.gstDetails && (
          <div className="flex flex-col sm:flex-row gap-4 mb-5 w-full">
            <div className="flex-1">
              <InputField
                type="tel"
                placeholder="Enter Registration Number"
                name="registrationNumber"
                onChange={handleChange}
                value={formData.registrationNumber}
                id="registrationNumber"
                label="Registration Number"
              />
            </div>
            <div className="flex-1">
              <InputField
                type="text"
                placeholder="Enter Company Name"
                name="registratedCompanyName"
                onChange={handleChange}
                value={formData.registratedCompanyName}
                id="registratedCompanyName"
                label="Registered Company Name"
              />
            </div>
            <div className="flex-1">
              <InputField
                type="text"
                placeholder="Enter Company Address"
                name="registratedCompanyAddress"
                onChange={handleChange}
                value={formData.registratedCompanyAddress}
                id="registratedCompanyAddress"
                label="Registered Company Address"
              />
            </div>
          </div>

        )}
        <div className="border max-xl:hidden border-b-1 border-[#CED4DA] max-xl:w-full w-[856px] mt-10 mb-5"></div>
        <div className="hidden xl:block">
          <SpecialRequests />
        </div>
        <button
          type="submit"
          className="bg-[#08090A]  text-white py-2 rounded-md w-[106px] h-12 px-6 justify-center align-items-center gap-10 mb-5"
        >
          Submit
        </button>
      </form>
      <div className="border max-xl:hidden border-b-1 border-[#CED4DA] max-xl:w-full w-[856px] mt-10 mb-5"></div>
    </div>
  );
};

export default BookingInfo;
