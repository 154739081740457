import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import closeIcon from "../../../assets/images/review/crossIcon.webp";
import heartIconChange from "../../../assets/svg/heart-red.webp";
import hertIcon from "../../../assets/svg/heart.webp";
import propertyDetailsSide1 from "../../../assets/svg/photoGallery/image1.webp";
import propertyDetailsSide2 from "../../../assets/svg/photoGallery/image2.webp";
import propertyDetailsSide3 from "../../../assets/svg/photoGallery/image3.webp";
import propertyDetailsSide4 from "../../../assets/svg/photoGallery/image4.webp";
import propertyDetailsSide5 from "../../../assets/svg/photoGallery/image5.webp";
import propertyDetailsSide6 from "../../../assets/svg/photoGallery/image6.webp";
import propertyDetailsSide7 from "../../../assets/svg/photoGallery/image7.webp";

import shereIcon from "../../../assets/images/shereIcon.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../../redux/features/userDashboard/userDashboardApi";

const AllPhotoGallery = () => {
  const location = useLocation();
  console.log(location?.state?.from);
  const photoData = location?.state?.from;
  const token = useTokenFromLocalStorage();
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.error("Error sharing:", err);
        });
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };
  const { id } = useParams();

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const photo = [
    { src: propertyDetailsSide1, width: "700px" },
    { src: propertyDetailsSide2, width: "600px" },
    { src: propertyDetailsSide3, width: "800px" },
    { src: propertyDetailsSide4, width: "500px" },
    { src: propertyDetailsSide5, width: "750px" },
    { src: propertyDetailsSide6, width: "650px" },
    { src: propertyDetailsSide7, width: "850px" },
    { src: propertyDetailsSide2, width: "300px" },
    { src: propertyDetailsSide3, width: "600px" },
  ];
  const photo1 = [
    { src: propertyDetailsSide1, width: "70px", height: "60px" },
    { src: propertyDetailsSide2, width: "46px", height: "54px" },
    { src: propertyDetailsSide3, width: "80px", height: "40px" },
    { src: propertyDetailsSide4, width: "40px", height: "33px" },
    { src: propertyDetailsSide5, width: "55px", height: "54px" },
    { src: propertyDetailsSide6, width: "90px", height: "20px" },
    { src: propertyDetailsSide7, width: "34px", height: "28px" },
    { src: propertyDetailsSide3, width: "70px", height: "40px" },
  ];
  const [addWishlist, { isSuccess }] = useAddWishlistMutation();
  const handleAddWishlist = (token, id) => {
    addWishlist({ token, id });
    // console.log(isSuccess);
  };
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      delay: 1,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-black py-4 px-6">
      <div className="h-[32px] flex justify-between items-center py-6 px-12 text-white">
        <div>
          <Link to={`/propertydetails/${id}`} className="flex items-center gap-4">
            <h1>
              <img
                src={closeIcon}
                alt="closeIcon"
                className="w-8 h-8 text-white"
              />
            </h1>
            <h1 className="text-xl">Close</h1>
          </Link>
        </div>
        <div className="flex gap-4 items-center justify-between">
          <h1 onClick={handleShare}>
            <img src={shereIcon} alt="shereIcon" className="w-8 h-8" />
          </h1>
          <h1 onClick={() => handleAddWishlist(token, id)}>
            <img
              className="md:p-[10px] md:w-12 md:h-12 max-md:w-[24px] z-50 cursor-pointer"
              src={isSuccess ? heartIconChange : hertIcon}
              alt="icon"
            />
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-8 mt-24 mb-10 relative">
          {photoData?.map((item, i) => (
            <div
              key={i}
              className="flex justify-center"
              style={{ left: `${i * 25}%` }}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
              data-aos={i !== 0 ? "fade-down-left" : ""}
            >
              <img
                src={item}
                alt="item"
                className={`w-[${item.width}] h-[600px] ml-10`}
              />
            </div>
          ))}
        </div>

        <div className="col-span-12 lg:col-span-4 fixed left-[1100px] top-52">
          {photoData?.map((item, i) => (
            <div
              key={i}
              style={{ left: `${i * 250}%` }}
              className={
                i === hoveredIndex
                  ? `flex justify-center items-center  opacity-30 `
                  : null
              }
              data-aos={i === hoveredIndex ? "zoom-in " : null}
            >
              <img
                src={item}
                alt="item"
                className={`w-[150px] h-[100px] ml-10 `}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllPhotoGallery;
