import React, { useRef } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import client_avatar from "../../assets/svg/property-d-review-a-1.webp";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";

const Reviews = ({ hotelsReviews }) => {
  const swiperRef = useRef(null);

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  return (
    <div className="mt-[60px] mb-[60px] px-5 lg:px-5 2xl:px-0" id="Reviews">
      <div>
        <h1 className="text-[36px] max-sm:text-2xl font-[500] font-[Butler]">
          Reviews
        </h1>
        <div className="w-full border border-[#CED4DA]"></div>
      </div>
      {hotelsReviews?.length > 0 ? (
        <div className="mt-[24px] max-sm:block max-xl:w-full w-[856px]">
          <Swiper
            ref={swiperRef}
            spaceBetween={10}
            pagination={{ clickable: true }}
            breakpoints={{
              1280: { slidesPerView: 3 },
              1024: { slidesPerView: 3 },
              630: { slidesPerView: 2 },
              368: { slidesPerView: 1 },
            }}
          >
            {hotelsReviews.map((review) => (
              <SwiperSlide key={review._id}>
                <div className="space-y-4">
                  <div className="flex gap-6 items-center">
                    <img
                      src={review?.author?.profilePhoto || client_avatar}
                      alt="Avatar"
                      className="w-24"
                    />
                    <div className="">
                      <p className="font-semibold text-2xl">{review?.author?.firstName} {review?.author?.lastName}</p>
                      <p className="text-lg">{month[Number(review?.createdAt?.slice(5, 7)) - 1]}{' '}{review?.createdAt?.slice(0, 4)}</p>
                    </div>
                  </div>
                  <p className="text-2xl font-normal">{review?.text?.substring(0, 20)}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <p className="my-3 mb-5">No reviews available for this property.</p>
      )}
      {/* <div className="flex items-center gap-2 mt-6">
        <button onClick={swipePrev}>
          <img src={scrollLeft} alt="Scroll left" />
        </button>
        <button onClick={swipeNext}>
          <img src={scrollRight} alt="Scroll right" />
        </button>
      </div> */}
    </div>
  );
};

export default Reviews;
