import { useRef, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import user from "../../assets/svg/addOnsUser.webp";
import dollar from "../../assets/svg/dollar.webp";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import whiteHeart from "../../assets/svg/whiteHeart.webp";
import AddOnsGallery from "./AddOnsGallery";
import CheckOutModal from "./CheckOutModal";

const AddOnsModal = ({
  setShowAddOns,
  addOnsData,
  addedAddOnsData,
  setAddedAddOnsData,
}) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const reviews = [1, 2, 3, 4, 5];

  const [mealDetailsModal, setMealDetailsModal] = useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [addOnsDetailsData, setaddOnsDetailsData] = useState({});
  return (
    <div className="fixed overflow-scroll example top-0 bottom-0 left-0 right-0 w-full h-full bg-black/30 z-50"
    >
      {mealDetailsModal === false && !showCheckOutModal && (
        <div className="w-[1174px] my-10 pb-10 mx-auto h-[90%] overflow-y-scroll relative bg-white rounded-lg" >
          <div className="h-9 left-[49px] top-[41px] mt-10 mx-10 mb-8 flex-col justify-start items-start gap-12 inline-flex">
            <div className="w-[1076px] opacity-70 justify-start items-center gap-[465px] inline-flex">
              <div className="w-6 h-6 justify-center items-center flex">
                <div className="w-6 h-6 relative flex-col justify-start items-start flex"
                onClick={() => {
                  setMealDetailsModal(false);
                  setShowAddOns(false);
                }}>
                  <FaAngleLeft className="text-xl cursor-pointer" />
                </div>
              </div>
              <div className="text-zinc-950 text-2xl font-bold font-Lato leading-9">
                Add On’s
              </div>
              <div
                role="button"
                onClick={() => {
                  setMealDetailsModal(false);
                  setShowAddOns(false);
                }}
                className="w-6 h-6 relative"
              >
                <RxCross2 className="text-xl" />
              </div>
            </div>
          </div>
          <div className="w-[856px] h-10 pr-80 left-[49px] top-[109px] mx-10 justify-start items-center inline-flex">
            <div className="justify-start items-start gap-12 inline-flex">
              <div className="flex-col cursor-pointer justify-start items-start gap-1.5 inline-flex">
                <div className="text-zinc-950 text-xl font-medium font-['Butler'] leading-[30px]">
                  All Add on’s
                </div>
                <div className="self-stretch h-[3px] bg-zinc-950 rounded-[202px]" />
              </div>
              <div className="flex-col cursor-pointer justify-start items-start gap-1.5 inline-flex">
                <div className="text-neutral-700 text-xl font-medium font-['Butler'] leading-[30px] tracking-tight">
                  F&B Offers
                </div>
              </div>
              <div className="flex-col cursor-pointer justify-start items-start gap-1.5 inline-flex">
                <div className="text-neutral-700 text-xl font-medium font-['Butler'] leading-[30px] tracking-tight">
                  Services
                </div>
              </div>
              <div className="flex-col cursor-pointer justify-start items-start gap-1.5 inline-flex">
                <div className="text-neutral-700 text-xl font-medium font-['Butler'] leading-[30px] tracking-tight">
                  Experiences
                </div>
              </div>
            </div>
          </div>

          <div className="mx-10 mt-8">
            <Swiper
              ref={swiperRef}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                },
                630: {
                  slidesPerView: 1,
                },
                368: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={210}
              onSlideChange={handleSlideChange}
              className="w-full "
            >
              {Array.isArray(addOnsData) &&
                addOnsData?.map((data, index) => (
                  <SwiperSlide className=" py-3 " key={index}>
                    <div className="w-[306px] h-[450px] relative bg-white 
                    cursor-pointer rounded border border-slate-200">
                      <div
                        onClick={() => {
                          setaddOnsDetailsData(data);
                          setMealDetailsModal(true);
                        }}
                        className=" w-[306px] h-[450px] relative  "
                      >
                        <img
                          className="w-[306px] h-[253px] left-0 top-0 absolute"
                          src={data?.images[0]}
                          alt="images"
                        />
                        <div className="h-[111px] px-4 py-3 left-0 top-[253px] absolute w-full flex-col justify-center items-start gap-3 inline-flex">
                          <div className="flex-col justify-center items-start gap-2 flex">
                            <div className="flex-col justify-center items-start gap-1.5 flex">
                              <div className="flex-col justify-center items-start gap-px flex">
                                <div className="text-zinc-950 text-lg font-normal font-butler leading-[27px] tracking-tight">
                                  {data?.mealCombo}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex-col justify-start items-start flex">
                            <div className="w-[203px] text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                              Starting from
                            </div>
                            <div className="justify-center items-center gap-0.5 inline-flex">
                              <div className="text-zinc-950 text-lg font-semibold font-Lato leading-[27px] tracking-tight">
                                ₹ {data?.price}
                              </div>
                              <div className="text-neutral-700 text-base font-normal font-['Roboto'] leading-normal tracking-tight">
                                {" "}
                                + Taxes
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-2 py-1 left-[16px] top-[15px] absolute bg-white rounded justify-start items-center gap-1 inline-flex">
                          <div className="text-neutral-700 text-xs font-semibold font-Lato leading-[18px] tracking-tight">
                            Most selling
                          </div>
                        </div>
                        <div className="w-6 h-6 left-[264px] top-[17px] absolute">
                          <img src={whiteHeart} alt="" />
                        </div>
                      </div>
                      {addedAddOnsData.find(
                        (item) => item._id === data?._id
                      ) ? (
                        <div
                          role="button"
                          onClick={() => {
                            console.log(addOnsData);
                            const filtered = addedAddOnsData?.filter(
                              (item) => item._id !== data?._id
                            );
                            if (!filtered) {
                              return;
                            }
                            setAddedAddOnsData(filtered);
                          }}
                          className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                        >
                          <div
                            role="button"
                            className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                          >
                            <div className="h-12 w-full py-4 bg-white border border-black text-black rounded flex-col justify-center items-center gap-2.5 flex">
                              <div className=" text-base font-bold font-Lato leading-none">
                                Remove Item
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          role="button"
                          onClick={() => {
                            console.log(addOnsData);
                            const filtered = addedAddOnsData?.find(
                              (item) => item._id === data?._id
                            );
                            if (filtered) {
                              return;
                            }
                            setAddedAddOnsData([
                              ...addedAddOnsData,
                              {
                                _id: data?._id,
                              },
                            ]);
                          }}
                          className="w-full left-0 top-[380px] absolute z-40 justify-center items-center gap-4 inline-flex"
                        >
                          <div
                            role="button"
                            className="flex-col w-full px-[21px] justify-start items-start inline-flex"
                          >
                            <div className="h-12 w-full py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 flex">
                              <div className="text-white text-base font-bold font-Lato leading-none">
                                Add item
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className=" mx-10 md:flex-row   max-w-[1296px]    justify-end lg:flex hidden  mt-8  items-center  gap-x-4">
            <button onClick={swipePrev} disabled={isBeginning} className="cursor-pointer">
              <img
                src={isBeginning ? scrollLeft : scrollLeftActive}
                alt="scroll"
              />
            </button>
            <button onClick={swipeNext} disabled={isEnd} className="cursor-pointer">
              <img src={scrollRight} alt="scroll" />
            </button>
          </div>
          <div
            role="button"
            onClick={() => {
              if (addedAddOnsData.length > 0) {
                setShowCheckOutModal(true);
              }
            }}
            className={`${
              addedAddOnsData.length > 0 ? "bg-black" : "bg-zinc-950/30"
            } w-[416px] z-50 h-12 flex-col justify-center items-center mx-auto rounded  flex font-Lato text-white text-base font-bold `}
          >
            Proceed{" "}
            {addedAddOnsData.length > 0 && `${addedAddOnsData.length} Add Ons`}
          </div>
        </div>
      )}
      {mealDetailsModal && (
        <div className="w-[1174px] my-10 pb-10 overflow-y-scroll mx-auto  relative bg-white rounded-lg h-[90%]" >
          <div className="h-9 left-[49px] top-[41px] mt-10 mx-10 mb-8 flex-col justify-start items-start gap-12 inline-flex" >
            <div className="w-[1076px] opacity-70 justify-start items-center gap-[465px] inline-flex">
              <div
                role="button"
                onClick={() => setMealDetailsModal(false)}
                className="w-6 h-6 justify-center items-center flex"
              >
                <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                  <FaAngleLeft className="text-xl" />
                </div>
              </div>
              <div className="text-zinc-950 text-2xl font-bold font-Lato leading-9">
                Add On’s
              </div>
              <div
                role="button"
                onClick={() => {
                  setMealDetailsModal(false);
                  setShowAddOns(false);
                }}
                className="w-6 h-6 relative"
              >
                <RxCross2 className="text-xl" />
              </div>
            </div>
          </div>
          <div className="mx-10 flex gap-5">
            <div className="w-[746px] h-full flex flex-col justify-start items-start ">
              <AddOnsGallery />
              <p className="w-full text-black text-base font-normal font-Lato mt-[15px] leading-normal tracking-tight">
                {addOnsDetailsData?.description}
              </p>
              <button className="text-black text-lg font-normal font-Lato mt-2 underline leading-[27px] tracking-tight">
                READ MORE
              </button>
              <div className="flex items-center gap-x-[18px] mb-12">
                <div className="w-[364px] h-[86px] px-6 py-5 bg-white mt-5 rounded-lg border border-gray-300 justify-between items-center inline-flex">
                  <div className="grow shrink basis-0 p-2 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="w-[60px] h-[60px] relative flex justify-center items-center">
                        <img src={dollar} alt="" />
                      </div>
                      <div className="flex-col justify-start items-start gap-1 inline-flex">
                        <div className="text-zinc-950 text-xl font-medium font-Lato leading-[30px]">
                          Value for Money
                        </div>
                        <div className="text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                          4.5 rating for pricing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[364px] h-[86px] px-6 py-5 bg-white mt-5 rounded-lg border border-gray-300 justify-between items-center inline-flex">
                  <div className="grow shrink basis-0 p-2 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="w-[60px] h-[60px] relative flex justify-center items-center">
                        <img
                          className="w-full p-1 object-contain h-full"
                          src={user}
                          alt=""
                        />
                      </div>
                      <div className="flex-col justify-start items-start gap-1 inline-flex">
                        <div className="text-zinc-950 text-xl font-medium font-Lato leading-[30px]">
                          Value for Money
                        </div>
                        <div className="text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                          4.5 rating for pricing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full h-[355px] pl-[19px] pr-[18px] pt-[31px] pb-8 bg-white rounded border border-slate-200 flex-col justify-center items-center inline-flex">
                <div className="self-stretch flex-col justify-start w-full items-start gap-6 inline-flex">
                  <div className="flex-col w-full justify-start items-start gap-6 flex">
                    <div className="flex-col justify-start items-start gap-[12.16px] flex">
                      <div className="flex-col justify-start items-start flex">
                        <div className="w-[205px] text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                          Starting from
                        </div>
                        <div className="justify-center items-center gap-0.5 inline-flex">
                          <div className="text-zinc-950 text-2xl font-semibold font-Lato leading-9 tracking-tight">
                            ₹ {addOnsDetailsData?.price}
                          </div>
                          <div className="text-neutral-700 text-lg font-normal font-Lato leading-[27px] tracking-tight">
                            / night + Taxes
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-20 w-full flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch h-20 flex-col justify-start w-full items-start gap-2 flex">
                        <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                          Guests
                        </div>
                        <div className="self-stretch h-12 flex-col justify-start w-full items-end gap-1 flex">
                          <div className="self-stretch h-12 px-4 py-3 rounded w-full border border-gray-300 justify-between items-center inline-flex">
                            <div className="text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight">
                              2 Adults
                            </div>
                            <div className="w-6 h-6 relative flex justify-center items-center -rotate-90">
                              <FaAngleLeft />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[271px] h-8 flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch h-8 px-4 py-[13px] bg-indigo-50 rounded border border-blue-600 justify-start items-center gap-20 inline-flex">
                      <div className="h-[18px] justify-start items-center gap-6 flex">
                        <div className="w-[136px] text-zinc-500 text-xs font-normal font-Lato leading-[18px] tracking-tight">
                          Have a discount coupon?
                        </div>
                        <div className="text-blue-600 text-xs font-medium font-Lato leading-[18px] tracking-tight">
                          VIEW OFFERS
                        </div>
                      </div>
                    </div>
                  </div>
                  {addedAddOnsData.find(
                    (item) => item._id === addOnsDetailsData?._id
                  ) ? (
                    <div
                      role="button"
                      onClick={() => {
                        console.log(addOnsData);
                        const filtered = addedAddOnsData?.filter(
                          (item) => item._id !== addOnsDetailsData?._id
                        );
                        if (!filtered) {
                          return;
                        }
                        setAddedAddOnsData(filtered);
                      }}
                      className="w-full left-0 top-[380px]  z-40 justify-center items-center gap-4 inline-flex"
                    >
                      <div
                        role="button"
                        className="flex-col w-full  justify-start items-start inline-flex"
                      >
                        <div className="h-12 w-full py-4 bg-white border border-black text-black rounded flex-col justify-center items-center gap-2.5 flex">
                          <div className=" text-base font-bold font-Lato leading-none">
                            Remove Item
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      role="button"
                      onClick={() => {
                        console.log(addOnsData);
                        const filtered = addedAddOnsData?.find(
                          (item) => item._id === addOnsDetailsData?._id
                        );
                        if (filtered) {
                          return;
                        }
                        setAddedAddOnsData([
                          ...addedAddOnsData,
                          {
                            _id: addOnsDetailsData?._id,
                          },
                        ]);
                      }}
                      className="w-full left-0 top-[380px]  z-40 justify-center items-center gap-4 inline-flex"
                    >
                      <div
                        role="button"
                        className="flex-col w-full  justify-start items-start inline-flex"
                      >
                        <div className="h-12 w-full py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 flex">
                          <div className="text-white text-base font-bold font-Lato leading-none">
                            Add item
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            role="button"
            onClick={() => {
              if (addedAddOnsData.length > 0) {
                setShowCheckOutModal(true);
              }
            }}
            className={`${
              addedAddOnsData.length > 0 ? "bg-black" : "bg-zinc-950/30"
            } w-[416px] z-50 h-12 flex-col justify-center items-center mx-auto rounded  flex font-Lato text-white text-base font-bold `}
          >
            Proceed{" "}
            {addedAddOnsData.length > 0 && `${addedAddOnsData.length} Add Ons`}
          </div>
        </div>
      )}
      {showCheckOutModal && (
        <CheckOutModal
          setShowCheckOutModal={setShowCheckOutModal}
          addOnsData={addOnsData}
        />
      )}
    </div>
  );
};

export default AddOnsModal;
