import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import no_schedule from "../../assets/icon/noschedule.webp";
import chevron_right from "../../assets/icon/rightIcon.webp";
import gift from "../../assets/svg/gift.webp";
import heart from "../../assets/svg/heart-black-border.webp";
import inbox from "../../assets/svg/inbox.webp";
import luggage from "../../assets/svg/luggage.webp";

import { getAuth, signOut } from "firebase/auth";
import sign_out_svgrepo_com from "../../assets/icon/signOut.webp";
import user_svgrepo_com from "../../assets/icon/user_svgrepo_com (2) 1.webp";
import chevronDown from "../../assets/svg/chevronup.webp";
import phoneBlack from "../../assets/svg/PhoneBooking.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { app } from "../../pages/auth/config";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";

const authApp = getAuth(app);
const ProfileDropDown = () => {
  const [isHovered3, setIsHovered3] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const token = useTokenFromLocalStorage();
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;

  // REMOVE TOKEN FROM LOCALSTORAGE
  const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("token");
  };

  // HANDLE LOG OUT
  const handleLogOut = () => {
    setLogOut(true);
    window.location.reload();
    localStorage.removeItem("token");
  };

  if (logOut) {
    removeTokenFromLocalStorage();
  }

  const handleClick3 = () => {
    setIsHovered3(!isHovered3);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  // Dropdown list
  const dropdownData3 = [
    {
      logo: user_svgrepo_com,
      text: "My Profile",
      link: "/userdashboard/profileDetails",
    },
    // this is my trips/booking
    {
      logo: luggage,
      text: "My Trips",
      link: "/userdashboard/myBooking",
    },
    {
      logo: heart,
      text: "Wishlist",
      link: "/userdashboard/wishlist",
    },
    {
      logo: gift,
      text: "Rewards",
      link: "/userdashboard/rewards",
    },
    {
      logo: inbox,
      text: "Inbox",
      link: "/connectWithHost",
      hasUnread: true, // Indicate that Inbox has unread messages
    },
    // cancellations route
    {
      logo: no_schedule,
      text: "Cancellations",
      link: "/userdashboard/cancellations",
    },
  ];
  const count = 1;
  //to fetch the count of unread messages in inbox

  // profile drop down for the booking page
  const [showProfile, setshowProfile] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (
      location?.pathname === "/bookingproperty" ||
      location?.pathname.includes("propertyDetails")
    ) {
      setshowProfile(true);
      console.log("hi");
    } else {
      setshowProfile(false);
      console.log("hello");
    }
  }, [location]);
  return (
    <>
      <div className="relative  inline-block text-center z-50 font-Lato">
        <button
          id="dropdownHoverButton"
          data-dropdown-toggle="dropdownHover"
          data-dropdown-trigger="hover"
          className={`${
            showProfile
              ? "w-full border-none max-lg:p-0 max-lg:mb-0 max-lg:h-full"
              : "w-[133px] "
          } lg:w-[140px] w-[133px] bg-white border lg:h-14 h-[53.2px]   rounded- border-gray-300 flex justify-center items-center  font-medium rounded-lg text-lg px-2  py-2  mb-2 gap-2 leading-10`}
          type="button"
          onClick={handleClick3}
          // onclcik={handleMouseEnter3}
          //onMouseLeave={handleMouseLeave3}
        >
          <div
            className={`${
              showProfile ? "max-lg:flex hidden" : "hidden"
            } flex items-center justify-center border me-2 border-black bg-white w-[32px] h-[32px] rounded-full`}
          >
            <img src={phoneBlack} alt="" />
          </div>
          <div className="flex items-center justify-center  bg-[#E3E3E3] w-[36px] h-[36px] rounded-full">
            <span className="text-[18px] font-[500] text-[#08090A] uppercase">
              {profileDetails?.firstName?.slice(0, 1)}
            </span>
            <span className="text-[18px] font-[500] text-[#08090A] uppercase">
              {profileDetails?.lastName?.slice(0, 1)}
            </span>
          </div>
          <div
            className={`flex ${
              showProfile ? "max-lg:hidden block" : "block"
            } items-center gap-2`}
          >
            <span className="">Profile</span>
            <img src={chevronDown} width="14" height="8" alt="" />
          </div>
        </button>

        {isHovered3 && (
          <div
            id="dropdownHover" style={{width:"330px",marginLeft:"-50px"}}
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
            className={`data z-10 mr-8 absolute font-Lato top-full lg:left-1/3 transform  -translate-x-1/2  divide-y divide-gray-100 rounded-lg shadow w-[193px] dark:bg-white ${
              showProfile ? "block bg-red-500" : "hidden left-1/3 lg:block"
            } ${isHovered3 ? "block" : "hidden"}`}
          >
            <ul
              className="py-2  text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownHoverButton"
            >
              {dropdownData3?.map((item, i) => (
                <li className="" key={i}>
                  <Link
                    onClick={handleMouseEnter3}
                    to={item?.link}
                    className="flex justify-between px-4 py-2 items-center  dark:hover:text-white"
                  >
                    {/* <div className="flex items-center gap-x-1">
                      <span className="w-6">
                        <img src={item.logo} alt="mapLogo" />
                      </span>
                      <span className="text-[#08090A] font-Lato font-[400] text-[16px]">
                        {item.text}
                      </span>
                    </div> */}
                    <div className="flex gap-x-1 w-full" >
                    <span className="w-6">
                      <img src={item.logo} alt="mapLogo" />
                    </span>
                    <span className="text-[#08090A] font-Lato font-[400] text-[16px] flex w-full justify-between" 
                    >
                      {item.text.includes("Inbox") ? (
                        <>
                          Inbox {item.hasUnread && (
                            <span className="text-gray-400 px-3"  onClick={handleMouseEnter3}>{count} unread</span>
                          )}
                        </>
                      ) : (
                        item.text
                      )}
                    </span>
                    </div>
                    <p className="flex text-end ">
                      <img className="w-6 h-6" src={chevron_right} alt="" />
                    </p>
                  </Link>
                </li>
              ))}
              <div className="px-6 py-3">
                <hr />
              </div>
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  signOut(authApp);
                  handleLogOut();
                }}
                className="text-[#DE2929] font-Lato   font-[400] text-[16px] px-4 py-1 cursor-pointer"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  className="w-6 h-6"
                  src={sign_out_svgrepo_com}
                  alt="sign_out_svgrepo_com"
                />
                Sign Out
              </button>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileDropDown;
