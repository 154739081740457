import React, { useState } from "react";
import TopFilters from "./TopFilters";
import PriceRange from "./PriceRange";
import GuestsFilter from "./GuestsFilter";
import TypesFilter from "./TypesFilter";
import SearchCard from "./SearchCard";

const SearchSubSec = (props) => {
  const [selectedFilters, setSelectedFilters] = useState([]); 
  const [showAll, setShowAll] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(props.guests); // Added state for rooms

  console.log("SearchSubSec prop datas :", props);

  // Update selected filters when a filter is applied
  const handleFilterChange = (filter) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter)
        : [...prevFilters, filter]
    );
  };

  // Update selected rooms when changed in GuestsFilter
  const handleRoomsChange = (newRoomCount) => {
    setSelectedRooms(newRoomCount);
  };

  // Price range handler
  const handlePriceRangeApply = (minPrice, maxPrice) => {
    console.log(`Applied price range: ₹${minPrice} - ₹${maxPrice}`);
    // Perform actions based on price range (e.g., filtering properties)
  };

  const handleShowMore = () => {
    setShowAll(true); 
  };

  let allAmenities = [];
  if (Array.isArray(props.data[0])) {
    props.data.map((subArray) => {
      subArray.map((item) => {
        allAmenities = allAmenities.concat(item.amenities);
      });
    });
  } else {
    allAmenities = props.data.map((item) => item.amenities).flat();
  }
  const uniqueAmenities = [...new Set(allAmenities)];

  // Filter data based on selected filters and selected rooms
  const filteredCards = selectedFilters.length
    ? props.data.filter((item) =>
        selectedFilters.every((filter) => item.amenities.includes(filter))
      )
    : props.data;

  // Filter further based on rooms (noOfBedrooms + noOfRooms)
  const filteredByRooms = filteredCards.filter((item) => 
    item.noOfBedrooms + item.noOfRooms >= selectedRooms
  );

  const cardsToDisplay = showAll ? filteredByRooms : filteredByRooms.slice(0, 3);

  return (
    <div className="mt-[190px] gap-6 grid grid-cols-[1fr,3fr] max-w-[1298px] w-[90%] mx-auto">
      {/* Filters Section */}
      <div className="border-r-[1px] pr-4 border-r-[#6D747A]">
        <TopFilters 
          amenities={uniqueAmenities}  
          selectedFilters={selectedFilters} 
          onFilterChange={handleFilterChange} 
        />
        <PriceRange onApply={handlePriceRangeApply} />
        <GuestsFilter 
          guests={selectedRooms} 
          func={handleRoomsChange}  // Pass the handler for rooms
        />
        <TypesFilter func={props.filters[2]} />
      </div>

      {/* Cards Display Section */}
      <div>
        {/* Header with Total Count */}
        <div className="flex mb-10 justify-between mt-[60px] items-center">
          <h2 className="font-butler text-[32px] leading-[48px] tracking-[0.32px]">
            Explore venues in {props?.city},{" "}
            <span className="text-[#434141] text-2xl leading-[150%] tracking-[0.24px]">
              {filteredByRooms.length} Properties
            </span>
          </h2>
          <select
            className="p-2 rounded outline-none border-[#CED4DA] border-[1px] text-[#08090A] font-semibold leading-6 tracking-[0.16px]"
          >
            <option value="">Sort By : Recommended</option>
          </select>
        </div>

        {/* Cards Display */}
        <div className="flex flex-col gap-12">
          {cardsToDisplay?.map((item, index) => (
            <SearchCard data={item} key={index} />
          ))}
        </div>

        {/* Show More Button */}
        {!showAll && filteredByRooms.length > 3 && (
          <button
            className="mt-6 px-4 py-3 text-dark rounded w-full text-xl"
            onClick={handleShowMore}
            style={{ border: "black solid 1px" }}
          >
            SHOW MORE RESULTS
          </button>
        )}
      </div>
    </div>
  );
};


export default SearchSubSec;
