import React, { useState } from "react";
import dubbleBedIcon from "../../assets/svg/bed-double.webp";
import calendarCheckIcon from "../../assets/svg/calendar-check.webp";
import coffeeIcon from "../../assets/svg/coffee.webp";
import homeIcon from "../../assets/svg/home.webp";
import petPrintIcon from "../../assets/svg/paw-print.webp";
import poolIcon from "../../assets/svg/swimmer.webp";
import usersIcon from "../../assets/svg/users.webp";
import wifiIcon from "../../assets/svg/wifi.webp";
import downTick from "../../assets/images/chevron-down.png";

const VillaDetails = () => {
  const [villaDetails1] = useState([
    { offer: "Entire Villa", icon: homeIcon },
    { offer: "Upto 8 Guests", icon: usersIcon },
    { offer: "4 Bedrooms", icon: dubbleBedIcon },
    { offer: "Pet Friendly", icon: petPrintIcon },
  ]);

  const [villaDetails2] = useState([
    { offer: "Check In - 2 PM I Check Out - 11 AM ", icon: calendarCheckIcon },
    { offer: "Unlimited WiFi", icon: wifiIcon },
    { offer: "Complimentary Breakfast", icon: coffeeIcon },
    { offer: "Private Pool", icon: poolIcon },
  ]);

  const [bedOption, setShowBedOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState("4 Bedrooms");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowBedOption(false); // Close the dropdown after selection
  };

  return (
    <div className="mt-[24px] px-5 lg:px-5 2xl:px-0">
      {/* villa details 1 */}
      <div className="flex max-sm:flex-wrap max-sm:gap-2.5 items-center gap-[24px]">
        {villaDetails1?.map((item, index) => (
          <div
            key={index + 1}
            className="flex cursor-default items-center gap-[8px] py-[8px] px-[12px] border border-[#CED4DA] rounded-[4px]"
          >
            <img src={item.icon} alt="icon" />
            {/* Display the selected option or fallback */}
            <p className="text-[#08090A] text-[16px]">
              {item.offer === "4 Bedrooms" ? selectedOption : item.offer}
            </p>
            {/* Always render chevron for "4 Bedrooms" */}
            {item.offer === "4 Bedrooms" && (
              <img
                onClick={() => setShowBedOption(!bedOption)}
                src={downTick}
                alt="dropdown icon"
                className="cursor-pointer"
              />
            )}
          </div>
        ))}
      </div>
      {/* Dropdown menu */}
      {bedOption && (
        <div className="w-[171px] bg-white rounded shadow flex-col cursor-pointer absolute lg:left-[445px] z-10">
          {["Min. 1 Room", "2 Rooms", "3 Rooms", "4 (All Rooms)"].map(
            (option, index) => (
              <div
                key={index}
                onClick={() => handleOptionSelect(option)}
                className={`self-stretch h-12 px-6 py-3 bg-white rounded flex justify-start items-center hover:bg-gray-100 ${
                  selectedOption === option ? "font-bold" : ""
                }`}
              >
                <p className="text-zinc-950 text-base font-['Lato'] leading-normal">
                  {option}
                </p>
              </div>
            )
          )}
        </div>
      )}
      {/* divider */}
      <div className="w-full border border-[#CED4DA] mt-[32px]"></div>
      {/* villa details 2 */}
      <div className="grid md:grid-cols-2 gap-x-10 gap-y-6 mt-[32px]">
        {villaDetails2?.map((item, index) => (
          <div
            key={index + 1}
            className="flex items-center gap-2 max-xl:gap-2"
          >
            <img src={item.icon} alt="icon" />
            <p className="text-gray-900 max-xl:text-base text-lg">
              {item.offer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VillaDetails;
