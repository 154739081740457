import React, { useState } from "react";
import Banner from "../../components/EventsAndExperiences/Banner";
import ExplorePopular from "../../components/EventsAndExperiences/ExplorePopular";
import { useSendSearchMutation } from "../../redux/features/eventsAndExp/eventsSearch";
import BaseSubSec from "../../components/EventsAndExperiences/BaseSubSec";
import SearchSubSec from "../../components/EventsAndExperiences/SearchSubSec";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetOnlyCityQuery } from "../../redux/features/search/getOnlyCity.js";

const EventsAndExp = () => {
  const token = useTokenFromLocalStorage();
  const [search, { data, isLoading, isSuccess }] = useSendSearchMutation();
  const CitiesOnlyData = useGetOnlyCityQuery();
  // console.log("CitiesOnlyData data:", CitiesOnlyData);

  // Core search variables
  const [dest, setDest] = useState();
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [toe, setToe] = useState();
  const [guests, setGuests] = useState(0);

  // Optional search filters
  const [tag, setTag] = useState();
  const [tos, setTos] = useState();
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [noOfEventRooms, setNoOfEventRooms] = useState();

  // Search function code
  const initSearch = (e) => {
    e.preventDefault();
    const searchData = {
      destination: dest,
      checkIn: checkin,
      checkOut: checkout,
      typeOfEvent: toe,
      guest: guests,
      minPrice: minPrice,
      maxPrice: maxPrice,
      noOfEventRooms: noOfEventRooms,
    };

    // Checking if tags are available
    if (tag) {
      searchData.tags = tag;
    }

    // Checking if type of events are available
    if (tos) {
      searchData.typeOfStay = tos;
    }

    search(searchData);
    console.log(searchData);
  };

  console.log("CitiesOnlyData object:", CitiesOnlyData.data);
  return (
    <>
      <Banner
        city={CitiesOnlyData?.data}
        func={initSearch}
        formVal={[dest, toe, guests, minPrice, maxPrice, noOfEventRooms]}
        form={[setDest, setCheckin, setCheckout, setToe, setGuests, setTag, setTos, setMinPrice, setMaxPrice, setNoOfEventRooms]}
      />
      {/* Base experience sub section */}
      {data ? (
        <SearchSubSec
          city={data.data[0] ? data.data[0].city : dest}
          num={data?.data.length}
          data={data?.data}
          guests={guests}
          filters={[setTag, setGuests, setTos]}
        />
      ) : (
        <BaseSubSec />
      )}
      <ExplorePopular />
    </>
  );
};

export default EventsAndExp;
