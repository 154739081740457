import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cancelHome from "../../assets/images/canccelhomeimg.webp";
import calendar1 from "../../assets/images/PropertyBooking/cal.webp";
import user from "../../assets/images/usercancelPage.webp";

const CancellationModalContent = ({ setIsCancelBooking, booking }) => {
  const navigate = useNavigate();
  const [reason, setReason] = useState("Changed my mind"); // State to track selected reason
  const [otherReason, setOtherReason] = useState(""); // State to store other reason
  const [textareaDisabled, setTextareaDisabled] = useState(true); // State to control textarea disabled state
  const [validationError, setValidationError] = useState(""); // State to track validation error

  console.log("bookingsssssss: ", booking);
  const handleRadioChange = (event) => {
    const selectedReason = event.target.value;
    setReason(selectedReason);

    // Enable/disable textarea based on selected reason
    if (selectedReason === "Others") {
      setTextareaDisabled(false);
    } else {
      setTextareaDisabled(true);
      setOtherReason(""); // Clear other reason if not selected
    }
  };

  const handleTextareaChange = (event) => {
    setOtherReason(event.target.value);
  };

  const navigateTo = () => {
    navigate("/cancellation", {
      state: {
        reason: textareaDisabled ? reason : otherReason,
        bookingId: booking._id,
      },
    });
  };
  const formatDates = (date1, date2) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return `${months[date.getMonth()]}${date.getDate()}`;
    };

    const formattedDate1 = formatDate(date1);
    const formattedDate2 = formatDate(date2);

    const year = new Date(date1).getFullYear();

    return `${formattedDate1} - ${formattedDate2}, ${year}`;
  };

  const handleSubmit = () => {
    if (reason === "Others" && otherReason.trim() === "") {
      setValidationError("Please provide a reason for cancellation.");
    } else {
      setIsCancelBooking(false);
      setValidationError("");
      navigateTo();
    }
  };

  return (
    <div className="w-[424px] py-2">
      <div className="flex gap-3 mb-3">
        <div>
          <img src={cancelHome} alt="Rectangle" className="w-[60px] h-[60px]" />
        </div>
        <div className="">
          <h6 className="text-[#08090A] font-[Butler] font-medium text-xl font-[400] pl-2">
            {booking[0]?.hotel?.name + "  " + booking[0]?.hotel?.city}
          </h6>
          <div className="flex mb-1 mt-1 font-medium text-[#434141] gap-1 font-[500]">
            <img src={calendar1} alt="map" className="w-6 h-6" />
            <span>{formatDates(booking[0]?.checkIn, booking[0]?.checkOut)}</span>
            <img src={user} alt="map" className="w-6 h-6 ml-8" />
            <span>{booking[0]?.noOfAdults + booking[0]?.noOfChildren}</span>
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-100 w-[400px]"></div>
      <div className="mt-6">
        <p className="text-[#08090A] font-[400] text-lg">
          Tell us the reason you want to cancel
        </p>
        <div className="mt-4">
          <div className="flex gap-2">
            <input
              name="type"
              type="radio"
              className="w-[20px] h-[20px]"
              value="Changed my mind"
              checked={reason === "Changed my mind"}
              onChange={handleRadioChange}
            />
            <label
              className="text-[#08090A] font-[400] font-medium cursor-pointer select"
              htmlFor="react"
            >
              Changed my mind
            </label>
          </div>
          <div className="flex gap-2 mt-3">
            <input
              name="type"
              type="radio"
              className="w-[20px] h-[20px]"
              value="Others"
              checked={reason === "Others"}
              onChange={handleRadioChange}
            />
            <label
              className="text-[#08090A] font-[400] font-medium cursor-pointer select-none"
              htmlFor="react"
            >
              Others
            </label>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div>
          <textarea
            className="border border-[#CED4DA] w-[376px] h-[145px] outline-none rounded-md placeholder-[#6D747A] resize-none px-2 pt-2"
            placeholder="Tell us more (optional)"
            disabled={textareaDisabled}
            value={otherReason}
            onChange={handleTextareaChange}
          />
        </div>
        {validationError && <p className="text-red-500">{validationError}</p>}
      </div>
      <div className="mt-6 text-[#08090A] w-[376px] h-[72px]">
        <p>
          Please refer to our cancellation policy for information regarding
          cancellation fees and penalties.{" "}
          <span className="text-sky-600 text-lg">Cancellation Policy.</span>
        </p>
      </div>
      <div className="mt-10 mb-4">
        <button
          className="w-[376px] h-[48px] text-lg rounded-md text-white bg-black "
          onClick={handleSubmit}
        >
          Cancel Booking
        </button>
      </div>
    </div>
  );
};

export default CancellationModalContent;
