import React from "react";
import { useNavigate } from "react-router-dom";
import chatIcon from "../../assets/svg/Chat Bubble.webp";
import callIcon from "../../assets/svg/phone-call-black.webp";

const ContactButton = ({ isBookNowClicked ,tell}) => {
  const navigate = useNavigate();
  console.log(tell, "testttt");
  const handleCallUs = () => {
    window.location.href = "tel:+918976767650";
  };

  return (
    <div
      className={`mb-10 max-lg:px-0 px-5 lg:px-4 ${
        tell == true && " sm: hidden"
      }`}
    >
      {console.log(tell, "testttt")}
      <button
        onClick={handleCallUs}
        className="w-full py-[14px] px-[24px] border border-[#08090A] rounded-[4px] mt-[32px] flex items-center gap-[10px] text-[16px] justify-center font-[500]"
      >
        <img src={callIcon} alt="Call Icon" /> Call Us on{" "}
        <span className="font-[600]">+91 8976767650</span>
      </button>
      <button
        onClick={() => navigate("/connectWithHost")}
        className="w-full py-[14px] px-[24px] border border-[#08090A] rounded-[4px] mt-[16px] flex items-center gap-[10px] text-[16px] justify-center font-[500]"
      >
        <img src={chatIcon} alt="Chat Icon" />
        <span className="font-[600]">Connect with Host</span>
      </button>
    </div>
  );
};

export default ContactButton;
