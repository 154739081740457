import React, { useState, useEffect } from "react";
import { useGetUkiyoExlusivePropertyQuery } from "../../../redux/features/luxuryProperties/luxuryPropertiesAPI";
import Card from "../../PropertyCardReusable/Card";

const UkiyoExlusiveVillas = () => {
  const [showAll, setShowAll] = useState(false);
  const { data } = useGetUkiyoExlusivePropertyQuery();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(()=>console.log("Exclusive data",{data}))

  const handleShowAll = () => {
    setShowAll(true);
  };

  // Update mobile state on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine the number of properties to show based on screen size
  const initialCount = isMobile ? 7 : 17;
  const shouldShowMoreButton = data?.data?.availableHotels?.length > initialCount;

  return (
    <div className="mt-[136px]">
      <p className="md:text-[32px] text-2xl text-[#000000] font-butler font-[400] max-lg:px-6 ">
        <span className="hidden md:inline">Ukiyo Exclusive Luxury Villas</span>
        <span className="inline md:hidden">Ukiyo Recommended Properties</span>
      </p>

      {/* Render Most Trending Property Card */}
      <div className="mt-[32px] mb-[144px]">
      {/* <div className="mt-[32px] mb-[144px]">
        <div className="grid max-sm:flex max-sm:flex-col
         max-sm:items-center max-sm:px-0 max-lg:px-6 max-lg:grid-cols-2 
         grid-cols-4 max-xl:grid-cols-3 items-center justify-center  
         gap-x-[20px] gap-y-[24px] lg:gap-y-[64px] xl:gap-x-3"> */}
        <div className="flex flex-wrap gap-3">
          {showAll
            ? data?.data?.availableHotels?.map((property) => (
                <Card key={property?.id} propertyData={property} />
              ))
            : data?.data?.availableHotels
                ?.slice(0, initialCount)
                ?.map((property) => (
                  <Card key={property?.id} propertyData={property} />
                ))}
        </div>
        {!showAll && shouldShowMoreButton && (
          <div className="mt-[56px] mb-5 flex items-center justify-center">
            <button
              onClick={handleShowAll}
              className="w-[342px] px-[24px] py-[16px] text-[#08090A] border border-[#08090A] font-[600] text-[16px] rounded-[4px]"
            >
              {isMobile ? "Load More" : "Show More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UkiyoExlusiveVillas;
