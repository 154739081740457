import { useNavigate } from "react-router-dom";
import HowItWorkImg1 from "../../../assets/svg/HowItWorks.webp";
import HowItWorkImg2 from "../../../assets/svg/HowItWorks2.webp";
import HowItWorkImg3 from "../../../assets/svg/HowItWorks3.webp";
import Container from "../../ui/Container";
const HowItWorks = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full  gap-x-6 mb-[144px]">
          <div className="w-full h-[634px] bg-red-500">
            <img
              className="h-full w-full object-cover"
              src={HowItWorkImg1}
              alt=""
            />
          </div>
          <div className=" w-full h-[612px] ">
            <div className=" w-full h-1/2 mb-6">
              <img
                src={HowItWorkImg2}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="flex max-md:flex-col w-full flex-grow h-1/2 gap-x-6">
              <div className=" h-full">
                <div className="w-full h-full p-4 bg-zinc-950 flex flex-col items-center justify-center gap-y-6">
                  <div className="w-[274px] text-center">
                    <span className="text-white text-2xl font-medium font-butler leading-9 tracking-tight">
                      How It{" "}
                    </span>
                    <span className="text-blue-600 text-2xl font-medium font-butler leading-9 tracking-tight">
                      Works?
                    </span>
                  </div>
                  <div className="w-[228px] text-center text-white text-base font-normal  leading-normal tracking-tight">
                    Call us - A villa specialist will share villas you will love
                    - Book & create memories
                  </div>
                  <div className="w-[147px] cursor-pointer h-12 px-6 py-4 bg-zinc-950/opacity-30 rounded border border-slate-200 flex-col justify-center items-center gap-2.5 inline-flex">
                    <div
                      className="text-white  text-base font-bold  leading-none"
                      onClick={() => navigate("/luxuryproperties")}
                    >
                      Book Now
                    </div>
                  </div>
                </div>
              </div>
              <div className=" h-full md:w-1/2 w-full">
                <img
                  src={HowItWorkImg3}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowItWorks;
