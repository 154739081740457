import React, { useState } from "react";
import ConfirmResetPassModal from "../../components/modals/auth/ConfirmResetPassModal";
import ConfirmResetPassModalContent from "./ConfirmResetPassModalContent";
const ForgotPasswordContent = ({
  setIsMailSent,
  setOpacity,
  isCloseForgotPass,
}) => {
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmPass, setConfirmPass] = useState(false);
  const isOpenConfirmPass = () => {
    setConfirmPass(true);
  };
  const isCloseConfirmPass = () => {
    setConfirmPass(false);
  };

  const sendResetPasswordLink = async () => {
    if (!email || email.length === 0) {
      setIsEmailEmpty(true);
    } else if (!validateEmail(email)) {
      setIsEmailEmpty(true);
    } else {
      const body = {
        email: email,
      };
      const data = await fetch(
        `https://api.ukiyostays.com/api/auth/forgot-password`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const responseJson = await data.json();
      isCloseForgotPass();
      setIsMailSent(true);
      if (window?.screen?.width >= 1024) {
        setOpacity(true);
      }
      localStorage.setItem("ForgotEmailLink", body.email);
      console.log(
        "Reponse data email send is : ",
        responseJson,
        "Body is : ",
        body
      );
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  return (
    <div className="px-4 py-6 font-Lato w-full h-[50vh]">
      <div>
        <div className="text-[#08090A] font-[700] text-[24px] max-lg:text-2xl max-lg:mb-2">
          Reset Password
        </div>
        <p className="w-[483px] text-[#08090A] font-[400] text-[20px] font-lato max-lg:text-base max-lg:w-full pr-6">
          Enter your email and we'll send you a link to reset your password
        </p>
        <div
          className={`mb-6 lg:mb-4 mt-6 bg-white ${
            isEmailEmpty ? "border-red-500" : ""
          }`}
        >
          <label
            htmlFor="email"
            className="block  text-black lg:text-[16px]   lg:font-[600] mb-2 justify-start"
          >
            Email address
          </label>
          <input
            type="email"
            id="email"
            placeholder="exampleperson@gmail.com"
            className={`border-2 p-2 outline-none w-full rounded-md placeholder:[#6D747A] ${
              isEmailEmpty ? "border-red-500" : "border-gray-300"
            } bg-white`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailEmpty(false);
            }}
          />
          {isEmailEmpty && (
            <p className=" font-semibold px-1  text-red-700 ">
              Please Enter The Valid Email
            </p>
          )}
        </div>
        <div>
          <button
            className="lg:w-[470px] w-full h-[48px] mt-5 bg-black text-white font-[700] lg:text-[16px] border rounded-[4px]"
            onClick={sendResetPasswordLink}
          >
            Send Link to Email
          </button>
        </div>
      </div>
      {confirmPass && (
        <ConfirmResetPassModal
          children={<ConfirmResetPassModalContent />}
          isCloseConfirmPass={isCloseConfirmPass}
        />
      )}
    </div>
  );
};

export default ForgotPasswordContent;
