import React, { useEffect, useState } from "react";
import location from "../../../assets/svg/location.webp";

const Map = ({ hotelData }) => {
  const [city, setCity] = useState("Location not available");
  const [subcity, setSubcity] = useState("");

  useEffect(() => {
    if (hotelData?.city) {
      setCity(hotelData.city);
    }
    if (hotelData?.subCity) {
      setSubcity(hotelData.subCity);
    }
  }, [hotelData]);

  const handleGetDirections = () => {
    const url = hotelData?.locationPin;

    // Validate the locationPin
    if (url) {
      // Open the locationPin URL directly in a new window
      window.open(url, "_blank");
    } else {
      alert("Location pin not available");
    }
  };

  const mapSrc = `https://www.google.com/maps?q=${city}+${subcity}&output=embed`;

  return (
    <>
      <div className=" w-full  lg:w-[400px] h-[500px] rounded-[10px] overflow-hidden">
        <h1 className="text-[36px] max-md:text-2xl xl:hidden font-[500] font-[Butler] px-5 lg:px-5 2xl:px-0 flex-col flex items-start justify-between max-md:py-6">
          Location
          <div className="w-full px-5 lg:px-5 2xl:px-0 border border-[#CED4DA]"></div>
        </h1>

        <p className="flex gap-2 items-center relative z-40 px-4 lg:px-5 text-xl my-4">
          <img
            className="w-[24px] h-[24px] cursor-pointer"
            src={location}
            alt="location-icon"
          />
          <span className="text-[#08090A] max-md:text-lg font-Lato font-[400]">
            {city}, {subcity}
          </span>
        </p>

        <div className="px-4 lg:px-5" style={{ position: "relative" }}>
          <iframe
            src={mapSrc}
            style={{
              border: "0",
              maxWidth: "100%",
              position: "relative",
            }}
            className="max-xl:w-full h-[450px] max-md:h-[243px] xl:w-[600px]"
            allowFullScreen=""
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

          <button
            className="w-[193px] h-14 px-6 py-4 bg-white rounded border absolute bottom-5 right-20 border-blue-600 justify-center items-center gap-2.5 flex"
            onClick={handleGetDirections}
          >
            <div className="text-blue-600 text-base font-Lato font-semibold leading-normal">
              Get Directions
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default Map;
