import { useEffect, useState } from "react";
import callIcon from "../../assets/svg/phone-call-black.webp";
import FaqsComp from "../../components/FaqPage/FAQComp";
import Container from "../../components/ui/Container";
import { useGetFaqFilterDataQuery } from "../../redux/features/faq/faqFilter";

const FAQpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // UseState variable for storing current filter choice

  const [filterKeyWord, setFilterKeyWord] = useState("general");

  // Calling faq filter api via rtk

  const {
    data: filterFaq,
    isLoading,
    isSuccess,
  } = useGetFaqFilterDataQuery(filterKeyWord);

  const faqFilterSwitcher = (keyWord) => {
    setFilterKeyWord(keyWord);
  };

  const handleCallUs = () => {
    window.location.href = "tel:+918976767650";
  };

  return (
    <div>
      {/* banner */}
      <div className="w-full h-[324px] bg-[#E3E3E3] flex justify-center items-center">
        <div className="text-center scale-y-[8px]">
          <h1 className="text-[#08090A] text-[56px] font-[400] font-butler">
            Frequently Asked Questions
          </h1>
          <p className="text-[16px] font-[600] text-[#08090A]">
            We are here to help
          </p>
        </div>
      </div>
      {/* LAYOUT--- */}
      <Container>
        <div className="flex flex-col md:flex-row items-start gap-x-[72px] mt-[56px] mb-[144px]">
          {/* RIGHT SECTION--- */}
          <div className="md:w-[916px]">
            <FaqsComp data={filterFaq ? filterFaq : null} />
          </div>
          {/* LEFT SECTION--- */}
          <div className="md:w-[306px] w-full mt-[64px]">
            <div className="border p-[16px] rounded-lg">
              <h1 className="text-[24px] font-[500] mb-1 ">Categories</h1>
              <hr />
              <ul className="flex flex-col gap-[16px] text-[20px] font-[500]  mt-[32px]">
                <li className=" cursor-pointer"
                  onClick={() => {
                    faqFilterSwitcher("safety and security");
                  }}
                >
                  Safety and Security
                </li>
                <li className=" cursor-pointer"
                  onClick={() => {
                    faqFilterSwitcher("amenities/services");
                  }}
                >
                  Amenities/Services
                </li>
                <li className=" cursor-pointer"
                  onClick={() => {
                    faqFilterSwitcher("general booking");
                  }}
                >
                  General Booking
                </li>
                <li className=" cursor-pointer"
                  onClick={() => {
                    faqFilterSwitcher("general");
                  }}
                >
                  General
                </li>
                <li className=" cursor-pointer"
                  onClick={() => {
                    faqFilterSwitcher("Listings");
                  }}
                >
                  Listings
                </li>
              </ul>
            </div>
            <div className="mx-4 mt-[56px]">
              <h1 className="text-[24px] font-[500]">
                Still have Questions? <br /> Call Now!
              </h1>
              <button
                onClick={handleCallUs}
                className={`w-full py-[14px] px-[24px] border border-[#08090A] rounded-[4px] mt-[24px] flex items-center gap-[10px] text-[16px]   justify-center font-[500]`}
              >
                <img loading="lazy" src={callIcon} alt="" /> 
                <p className="max-md:text-sm">Call Us on</p>
          <p className="font-medium max-md:text-sm">+91 8976767650</p>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FAQpage;
