import React, { useState } from "react";
import iconBottom from "../../assets/images/Faqs/bottom.webp";
import search from "../../assets/images/Faqs/search.webp";
import iconTop from "../../assets/images/Faqs/topIcon.webp";

const FaqsComp = (props) => {
  // Define the FAQ data array directly in the component
  const faqData = [
    {
      id: 1,
      question:
        "Can I book only one or two rooms, or do I need to book the entire Home?",
      answer:
        "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
    },
    {
      id: 2,
      question: "What are the check-in and check-out times?",
      answer: "Check-in time is 3 PM and check-out time is 11 AM.",
    },
    {
      id: 3,
      question: "Is breakfast included in the booking?",
      answer: "Yes, breakfast is included for all guests.",
    },
    {
      id: 4,
      question: "Can I bring my pet?",
      answer: "Pets are not allowed in the villa.",
    },
  ];

  // State to manage toggling of icons for each FAQ item
  const [isToggleIcons, setIsToggleIcons] = useState(
    new Array(faqData.length).fill(false)
  );
  const [searchKeyword, setSearchKeyword] = useState("");

  const toggleIcon = (index) => {
    const newToggleIcons = [...isToggleIcons];
    newToggleIcons[index] = !isToggleIcons[index];
    setIsToggleIcons(newToggleIcons);
  };

  const filteredFaqData = faqData.filter((item) =>
    item.question.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div className="xl:max-w-[1296px] max-md:mt-[300px] w-full items-center justify-center mx-auto px-5 lg:px-5 2xl:px-0">
      <div className="mt-[64px] flex flex-col items-center justify-center gap-y-9">
        <h1 className="text-[36px] max-md:text-2xl font-[500] font-[Butler] flex items-center justify-between">
          FAQs
        </h1>
        <div className="relative mb-10">
          <input
            type="text"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="w-[416px] max-md:w-full h-[48px] pl-16 pr-8 border border-gray-100 rounded-full bg-gray-100 text-neutral-700 text-lg font-medium leading-[27px] outline-none"
            placeholder="Search"
          />
          <img
            src={search}
            alt="search"
            className="absolute left-4 top-1/2 transform -translate-y-1/2"
          />
        </div>
      </div>
      {/* FAQ list */}
      <div className="w-full mt-[40px]">
        {filteredFaqData.length > 0 ? (
          filteredFaqData.map((item, index) => (
            <div key={item.id}>
              <div className="flex justify-between h-[40px] py-8 px-0 mb-4">
                <div className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
                  {item.question}
                </div>
                <div className="cursor-pointer">
                  <img
                    src={isToggleIcons[index] ? iconBottom : iconTop}
                    className="w-6 max-md:w-[17.61px]"
                    alt="toggle"
                    onClick={() => toggleIcon(index)}
                  />
                </div>
              </div>
              <div
                className={`w-[746px] max-md:w-full text-[14px] ${
                  isToggleIcons[index] ? "block" : "hidden"
                }`}
              >
                {item.answer}
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div className="text-zinc-950 mt-2 ml-3 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
            No results found
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqsComp;
