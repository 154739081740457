import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import isOpenImg from "../../../assets/svg/isOpen.webp";
import minus from "../../../assets/svg/Minus.webp";
import plus from "../../../assets/svg/plus.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import BookingOfferseModal from "../../modals/BookingOfferseModal";
import OfferseModalContent from "../../PropertyBookingCoupon/OfferseModalContent";
import ContactButton from "../ContactButton";
import BookingDatePicker from "./BookingDatePicker";
import { PickRooms } from "./BookingDropDown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalContent = ({
  price,
  isBookNowClicked,
  hotelId,
  isRoomEmpty,
  setIsRoomEmpty,
  mobileBooking,
  guests,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isRoomOpen, setIsRoomOpen] = useState(false);

  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [children, setChildren] = useState(0);
  const [isCheckInDateEmpty, setIsCheckInDateEmpty] = useState(false);
  const [isCheckOutDateEmpty, setIsCheckOutDateEmpty] = useState(false);
  const [isGuestEmpty, setIsGuestEmpty] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const token = useTokenFromLocalStorage();
  const [offers, setOffers] = useState(false);

  const [selectedCoupons, setSelectedCoupons] = useState();
  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
  ];
  useEffect(() => {
    const storedCheckInDate = sessionStorage.getItem("checkInDate");
    const storedCheckOutDate = sessionStorage.getItem("checkOutDate");
    const storedAdults = sessionStorage.getItem("adults");
    const storedChildren = sessionStorage.getItem("children");
    const storedRooms = sessionStorage.getItem("rooms");

    if (storedCheckInDate) setCheckInDate(new Date(storedCheckInDate));
    if (storedCheckOutDate) setCheckOutDate(new Date(storedCheckOutDate));
    if (storedAdults) setAdults(Number(storedAdults));
    if (storedChildren) setChildren(Number(storedChildren));
    if (storedRooms) setRooms(Number(storedRooms));
  }, []);

  useEffect(() => {
    if (checkInDate) sessionStorage.setItem("checkInDate", checkInDate);
    if (checkOutDate) sessionStorage.setItem("checkOutDate", checkOutDate);
    if (adults) sessionStorage.setItem("adults", adults);
    if (children) sessionStorage.setItem("children", children);
    if (rooms) sessionStorage.setItem("rooms", rooms);
  }, [checkInDate, checkOutDate, adults, children, rooms]);

  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth() + 1; // Month is zero-based
    let year = date?.getFullYear();
    let formattedDate = `${(day < 10 ? "0" : "") + day}-${
      (month < 10 ? "0" : "") + month
    }-${year}`;
    return formattedDate;
  };

  // const handleBookNow = () => {
  //   if (adults === 0) {
  //     setIsGuestEmpty("Please select at least 1 guest.");
  //     return;
  //   }
  //   if (!checkInDate) {
  //     setIsCheckInDateEmpty("Please select a check-in date.");
  //     return;
  //   }
  //   if (!checkOutDate) {
  //     setIsCheckOutDateEmpty("Please select a check-out date.");
  //     return;
  //   }
  //   if (rooms === 0) {
  //     setIsRoomEmpty("Please select at least 1 room.");
  //     return;
  //   } else 
  //   console.log("token : ",token)
  //   //navigateTo();
  //   setIsRoomEmpty("");
  //   setIsCheckInDateEmpty("");
  //   setIsCheckOutDateEmpty("");
  // };
  const handleBookNow = async () => {
    if (adults === 0) {
      setIsGuestEmpty("Please select at least 1 guest.");
      return;
    }
    if (!checkInDate) {
      setIsCheckInDateEmpty("Please select a check-in date.");
      return;
    }
    if (!checkOutDate) {
      setIsCheckOutDateEmpty("Please select a check-out date.");
      return;
    }
    if (rooms === 0) {
      setIsRoomEmpty("Please select at least 1 room.");
      return;
    } else {
      console.log("token : ", token);
  
      const requestData = {
        hotelId: hotelId, 
        checkIn: checkInDate,
        checkOut: checkOutDate,
        bookingType: 'entire' 
      };
  
      try {
        const response = await fetch('https://api.ukiyostays.com/api/bookings/checkBooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
          },
          body: JSON.stringify(requestData)
        });
  
        const data = await response.json();
  
        if (response.ok) {
          console.log("Booking check successful:", data);
          toast.success("You can make a booking! There is no preoccupancy here.");
          navigateTo(); 
        } else {
          console.error("Booking check failed:", data);
          toast.error("Sorry..The villa is already booked for the selected dates !");
        }
      } catch (error) {
        console.error("Error during API request:", error);
      }
    }
      setIsRoomEmpty("");
    setIsCheckInDateEmpty("");
    setIsCheckOutDateEmpty("");
  };
  
  
  
  
  const navigateTo = () => {
    const date1 = checkInDate;
    const date2 = checkOutDate;
    const difference = date2.getTime() - date1.getTime();
    const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    const payment = daysDifference * price;

    navigate("/bookingproperty", {
      state: {
        hotelId: hotelId,
        checkInDate: handleDateFormat(date1),
        checkOutDate: handleDateFormat(date2),
        checkIn: date1,
        checkOut: date2,
        payment: payment,
        adults: adults,
        rooms: rooms,
        children: children,
      },
    });
  };
  const checkInOut = (
    <>
      <div className="mb-4 w-full flex gap-2 ">
        <p>
          <label
            htmlFor="Check In"
            className="block text-[#08090A]  font-[600] flex justify-start"
          >
            Check In
          </label>
          <BookingDatePicker
            isCheckIn={true}
            selectedDate={checkInDate}
            setSelectedDate={setCheckInDate}
            setIsCheckInDateEmpty={setIsCheckInDateEmpty}
            isCheckInDateEmpty={isCheckInDateEmpty}
            minDate={new Date()} // Set minimum date to today for check-in
          />
        </p>
        <p>
          <label
            htmlFor=" Check out"
            className="block text-[#08090A]  font-[600] flex justify-start"
          >
            Check out
          </label>

          <BookingDatePicker
            isCheckOut={true}
            selectedDate={checkOutDate}
            setSelectedDate={setCheckOutDate}
            setIsCheckOutDateEmpty={setIsCheckOutDateEmpty}
            isCheckOutDateEmpty={isCheckOutDateEmpty}
            minDate={checkInDate} // Ensure che
          />
        </p>
      </div>
    </>
  );
  const isOpenOfferspModal = () => {
    setOffers(true);
  };
  const isCloseOffersModal = () => {
    setOffers(false);
  };
  useEffect(() => {
    // Additional logic if needed
  }, [checkInDate, checkOutDate]);

  return (
    <div className="py-4 px-0 max-lg:py-0 max-xl:static relative">
      <div>
        {/* <div className="max-xl:mt-6 mt-4 gap-3 mb-2 w-full max-xl:flex hidden">
          <div
            onClick={() => setShowDateRange(!showDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="flex relative w-full justify-center flex-col">
              <label
                htmlFor="Check In"
                className="block text-[#08090A] flex justify-start"
              >
                Check In
              </label>
              <div className="border w-full border-gray-300 rounded py-2.5 focus:outline-none text-start px-4 text-neutral-700 text-base leading-[30px]">
                {checkInDate ? format(checkInDate, "MM/dd/yyyy") : "dd/mm/yyyy"}
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(!showDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="flex relative w-full justify-center flex-col">
              <label
                htmlFor="Check Out"
                className="block text-[#08090A] font-[600] flex justify-start"
              >
                Check Out
              </label>
              <div className="border w-full border-gray-300 rounded py-2.5 focus:outline-none text-start px-4 text-neutral-700 text-base leading-[30px]">
                {checkOutDate
                  ? format(checkOutDate, "MM/dd/yyyy")
                  : "dd/mm/yyyy"}
              </div>
            </div>
          </div>
          {showDateRange && mobileBooking && (
            <div className="absolute max-lg:top-[330px] lg:top-28 max-lg:left-0 max-lg:right-0 max-md:w-[348px] mx-6 lg:w-[348px] max-md:mx-auto max-xl:left-28 z-40">
              <DateRange
                checkInDate={checkInDate}
                ShowDateRange={showDateRange}
                setCheckInDate={setCheckInDate}
                checkOutDate={checkOutDate}
                setCheckOutDate={setCheckOutDate}
                setIsCheckInDateEmpty={setIsCheckInDateEmpty}
                isCheckInDateEmpty={isCheckInDateEmpty}
                setShowDateRange={setShowDateRange}
              />
            </div>
          )}
        </div> */}
        <div className="">
          <div className="mb-4 w-full flex   md:hidden xl:block">
            {checkInOut}
          </div>
          <p className="text-[#039855] font-medium text-base mb-4 leading-4 text-start">
            Add dates for best price
          </p>

          <div className="mb-4 relative">
            <PickRooms
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isGuestEmpty={isGuestEmpty}
              setIsGuestEmpty={setIsGuestEmpty}
              roomsGuests={roomsGuests}
              isRoomOpen={isRoomOpen}
              guests={children + adults}
              setIsRoomOpen={setIsRoomOpen}
            />
            <div className="absolute top-7 right-5">
              {isOpen ? (
                <img
                  src={isOpenImg}
                  className="-mr-1 mt-2 ml-2 h-4 w-4 font-medium leading-8"
                  alt=""
                />
              ) : (
                <img
                  src={isOpenImg}
                  className="-mr-1 rotate-180 mt-2 ml-2 h-4 w-4 font-medium leading-8"
                  alt=""
                />
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="Guests"
              className="block text-[#08090A] font-[600] flex justify-start"
            >
              Rooms
            </label>
            <button
              type="button"
              onClick={() => {
                setIsRoomOpen(!isRoomOpen);
                setIsOpen(false);
              }}
              className="flex justify-between w-full h-[48px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
            >
              <span className="text-[#6D747A] font-medium leading-8">
                {rooms} rooms
              </span>
              <div className="flex items-center">
                {isRoomOpen ? (
                  <img
                    src={isOpenImg}
                    className="-mr-1 mt-2 ml-2 h-4 w-4 font-medium leading-8"
                    alt=""
                  />
                ) : (
                  <img
                    src={isOpenImg}
                    className="-mr-1 rotate-180 mt-2 ml-2 h-4 w-4 font-medium leading-8"
                    alt=""
                  />
                )}
              </div>
            </button>
            {isRoomEmpty && (
              <div className="text-red-500 text-sm mt-1">{isRoomEmpty}</div>
            )}
          </div>

          {isRoomOpen && (
            <div className="origin-top-right relative left-28 right-0 mt-5 w-60 bg-white p-[16px] rounded-md shadow-lg max-lg:left-0 max-lg:w-full ring-1 ring-black ring-opacity-5 z-50">
              <div>
                <div className="flex items-center justify-end max-lg:justify-between">
                  <div>
                    <h3 className="text-[#08090A] text-[16px] font-[500]">
                      Rooms
                    </h3>
                    <p className="text-[#6D747A] text-[12px]">
                      Selected Rooms (Min 1)
                    </p>
                  </div>
                  <div className="flex items-center gap-[16px]">
                    <div
                      onClick={() => {
                        setRooms((prevState) =>
                          prevState > 0 ? prevState - 1 : 0
                        );
                      }}
                      className="flex items-center justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
                    >
                      <img src={minus} alt="icon" />
                    </div>

                    <p className="text-[16px] text-[#1A1A1A]">{rooms}</p>
                    <div
                      onClick={() => {
                        setRooms((prevState) => prevState + 1);
                      }}
                      className="flex items-center justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
                    >
                      <img src={plus} alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#CED4DA] mt-[14px]"></div>

                <div className="flex items-center justify-end">
                  <button
                    onClick={() => setIsRoomOpen(false)}
                    className="text-[16px] uppercase text-[#08090A] cursor-pointer"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* <div
            className="flex justify-between items-center bg-[#ECF4FE] w-full h-[48px] mb-4 p-2 rounded-md   outline-dashed border-dashed mt-6 outline-[#0F6DDC] hover:cursor-pointer"
            onClick={isOpenOfferspModal}
          >
            <p className="text-[#6D747A] max-md:text-base font-[400]">
              Have a discount coupon?
            </p>
            <p className="text-[#0F6DDC] max-md:text-sm max-md:font-medium font-normal">
              VIEW OFFERS
            </p>
          </div> */}

          <button
            onClick={handleBookNow}
            type="submit"
            className="bg-[#08090A] mt-4 w-full h-[48px] text-white font-bold rounded-md"
          >
            Book Now
          </button>
        </div>
        {/* <ContactButton isBookNowClicked={isBookNowClicked}/> */}
      </div>
      {offers && (
        <div className="">
          <BookingOfferseModal
            children={
              <OfferseModalContent
                setSelectedCoupon={setSelectedCoupons}
                token={token}
                setOffers={setOffers}
              />
            }
            isCloseOffersModal={isCloseOffersModal}
          />
        </div>
      )}
    </div>
  );
};

export default ModalContent;
