import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OurLatestOfferImg from "../../../assets/svg/OurLatestOffer.webp";
import rightIcon from "../../../assets/svg/rightIcon.webp";
const OurLatestOffers = () => {
  const [offers, setOffers] = useState([]);
  const navigate = useNavigate();
  const fetching = async () => {
    const res = await fetch(`https://api.ukiyostays.com/api/offers`);
    const data = await res.json();
    setOffers(data);
  };
  useEffect(() => {
    fetching();
  }, []);
  return (
    <div>
      <div className="text-zinc-950 max-md:text-2xl text-4xl font-normal font-butler leading-[54px] text-center mb-10 tracking-tight">
        Our Latest Offers
      </div>
      <div className="w-full max-md:grid-cols-1 max-lg:grid-cols-2 max-lg:gap-4 grid grid-cols-4 max-xl:gap-4 lg:h-[360px] gap-x-6 mb-[144px]">
        {offers?.map((offer, i) => (
          <div key={i} className=" relative">
            <img
              src={OurLatestOfferImg}
              className="w-full  h-full object-cover rounded-lg"
              alt=""
            />
            <div className="w-full max-xl:px-3 max-lg:px-0 h-full absolute top-0 bottom-0 left-0 right-0 bg-zinc-950/20 flex items-center justify-center rounded-lg">
              <div className="w-[242px] max-lg:h-[284px] max-lg:w-[277px] flex h-[248px] bg-white rounded-lg justify-center items-center">
                <div className="w-[194px] h-[152px] flex-col justify-start items-center gap-6 inline-flex">
                  <div className="self-stretch h-24 flex-col justify-center items-center gap-3 flex">
                    <div className="flex-col justify-center items-center flex">
                      <div className="text-zinc-950 text-2xl font-normal font-butler leading-9 tracking-tight">
                        {offer?.offerName}
                      </div>
                    </div>

                    <div className="self-stretch justify-start items-center gap-4 inline-flex">
                      <div className="grow shrink basis-0 h-12 justify-start items-center gap-1 flex">
                        <div className="grow shrink basis-0 text-center text-neutral-700 text-base font-normal leading-normal tracking-tight">
                          {offer?.offerDescription}
                        </div>
                      </div>
                    </div>
                    <button className="font-butler my-2">
                      {offer?.offerPrice}₹
                    </button>
                  </div>
                  <div className="pl-1 pr-3 py-1 bg-zinc-950 rounded-3xl border justify-end items-center gap-2 flex">
                    <div className="w-6 h-6 relative  rotate-180">
                      <div className="w-6 h-6 left-0 flex items-center justify-center bg-white rounded-full">
                        <img width="14" height="14" src={rightIcon} alt="" />
                      </div>
                    </div>
                    <div
                      role="button"
                      className="text-right text-white text-base font-medium leading-none tracking-tight"
                      onClick={() => navigate("/typeofstays")}
                    >
                      Book Now
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurLatestOffers;
