import React from "react";
import cal from "../../../assets/svg/calendarSvg.webp";

const HeadingInfo = (props) => {
  // Function to convert ISO date into desired format
  const dateFormatter = (date) => {
    if (!date) return "Date not available"; // Return a fallback string if date is undefined

    let curYear = date.substring(0, 4);
    let curMonth = date.substring(5, 7);
    let curDate = date.substring(8, 10);
    let conMonth = "";
    switch (curMonth) {
      case "01":
        conMonth = "January";
        break;
      case "02":
        conMonth = "February";
        break;
      case "03":
        conMonth = "March";
        break;
      case "04":
        conMonth = "April";
        break;
      case "05":
        conMonth = "May";
        break;
      case "06":
        conMonth = "June";
        break;
      case "07":
        conMonth = "July";
        break;
      case "08":
        conMonth = "August";
        break;
      case "09":
        conMonth = "September";
        break;
      case "10":
        conMonth = "October";
        break;
      case "11":
        conMonth = "November";
        break;
      case "12":
        conMonth = "December";
        break;
      default:
        conMonth = "January";
    }
    return `${conMonth} ${curDate}, ${curYear}`;
  };

  return (
    <>
      <p className="text-base font-[500] font-Lato text-[#434141] leading-6 tracking-[0.16px] mb-2">
        By Rachita Sharma
      </p>
      <h2 className="font-butler text-2xl md:text-3xl  lg:text-4xl  font-[500] leading-[54px] tracking-[0.36px] mb-3">
        {/* The Finest Villas for Weddings, Birthdays, and Anniversaries in India  */}
        {props?.title}
      </h2>
      <div className="flex gap-6 border-b-[1px] border-b-[#CED4DA] pb-6 flex-col lg:flex-row">
        {props?.highlights?.map((highlight, index) => (
          <div key={index} className="text-[#08090A] px-3 py-[2px] border-[1px] border-[#CED4DA] leading-6 font-normal font-Lato text-sm lg:text-base cursor-pointer rounded-[4px] flex justify-center items-center order-2 lg:order-1">
            {/* <p>Wedding Paradise</p> */}
            <p>{highlight}</p>
          </div>
        ))}
        {props?.tags?.map((tag, index) => (
          <div key={index} className="text-[#08090A] px-3 py-[2px] border-[1px] border-[#CED4DA] leading-6 font-normal font-Lato text-sm lg:text-base tracking-[0.01em] cursor-pointer rounded-[4px] flex justify-center items-center order-3 lg:order-2">
            {/* <p>Luxury Villas</p> */}
            <p>{tag}</p>
          </div>
        ))}

        <div className="flex justify-center items-center gap-2 order-1 lg:order-3">
          <img src={cal} alt="cal" data-twe-toggle="tooltip" title="date" />
          <p className="text-[#08090A] font-[500] font-Lato text-sm lg:text-base leading-6 tracking-[0.16px]">
            {props.date
              ? dateFormatter(props.date.substring(0, 10))
              : "Nov11 - Nov14"}
          </p>
        </div>
      </div>
    </>
  );
};

export default HeadingInfo;
