import { useRef, useState, useEffect } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const DropdownWithScroll = ({ villaData, handleSearch1 }) => {
  const containerRef = useRef(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [expandedCities, setExpandedCities] = useState({}); // State to manage each city's expansion

  const handleScrollLeft = () => {
    if (containerRef.current) {
      const newScrollLeft = containerRef.current.scrollLeft - 230; 
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      checkScrollPosition(newScrollLeft);
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      const newScrollLeft = containerRef.current.scrollLeft + 230; 
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      checkScrollPosition(newScrollLeft);
    }
  };

  const checkScrollPosition = (scrollLeft = containerRef.current?.scrollLeft) => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsLeftDisabled(scrollLeft <= 0);
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      checkScrollPosition();
    }
    const handleScroll = () => {
      checkScrollPosition();
    };
    containerRef.current.addEventListener("scroll", handleScroll);
    
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const toggleExpanded = (city) => {
    setExpandedCities((prev) => ({
      ...prev,
      [city]: !prev[city], 
    }));
  };

  return (
    <div className="relative flex items-center h-full">
      <button
        className={`absolute -left-3 z-20 p-2 ${isLeftDisabled ? "opacity-50 " : "cursor-pointer"}`}
        onClick={handleScrollLeft}
        disabled={isLeftDisabled}
      >
        {/* <IoIosArrowBack className="w-6 h-6" /> */}
      </button>

      <div
        ref={containerRef}
        className="flex flex-wrap w-[989px] h-[411px] py-5 px-4"
        style={{ scrollBehavior: "smooth",overflowY:"scroll" }}
      >
        {villaData?.data &&
          villaData?.data.map((item) => {
            // const isExpanded = expandedCities[item.city]; 
            const isExpanded = true ;
            return (
              <div key={item.city} className="flex flex-col mb-4 lg:min-w-[230px]">
                <h3 className="text-base font-normal font-Lato text-[#6D747A] h-6">{item.city}</h3>
                <div className="flex flex-col mt-2 font-Lato text-sm font-normal text-[#08090A] w-full h-auto align-baseline gap-6">
                  {item.hotels &&
                    item?.hotels?.slice(0, isExpanded ? item.hotels.length : 5).map((ele) => {
                      return (
                        <div
                          key={ele.name}
                          onClick={() => handleSearch1(ele.subCity)}
                          className="cursor-pointer flex align-middle gap-2 max-h-fit w-full my-1"
                        >
                          <IoLocationOutline className="w-[16px] h-[16px] inline-block" />
                          <p className="">{ele.name}</p>
                        </div>
                      );
                    })}

                  {/* {item.hotels && item.hotels.length > 5 && (
                    <button
                      onClick={() => toggleExpanded(item.city)}
                      className="text-blue-500 underline mt-2"
                    >
                      {isExpanded ? "See Less" : "See More"}
                    </button>
                  )} */}
                </div>
              </div>
            );
          })}
      </div>

      <button
        className={`absolute right-0 z-20 p-2 ${isRightDisabled ? "opacity-50 " : "cursor-pointer"}`}
        onClick={handleScrollRight}
        disabled={isRightDisabled}
      >
        {/* <IoIosArrowForward className="w-6 h-6" /> */}
      </button>
    </div>
  );
};

export default DropdownWithScroll;
