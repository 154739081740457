import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useGetMostTendingPropertyQuery } from "../../../redux/features/luxuryProperties/luxuryPropertiesAPI";
import Card from "../../PropertyCardReusable/Card";

const MostTrendingProperty = ({ hideHeading }) => {
  const { data } = useGetMostTendingPropertyQuery();
  const swiperRef = useRef(null);
  const [isGrid, setIsGrid] = useState(false); // State to toggle between grid and swiper

  return (
    <div className="px-6 lg:px-0">
      {/* Heading */}
      {!hideHeading && (
        <p className="md:text-[32px] text-2xl text-[#000000] font-butler font-[400]">
          <span className="hidden md:inline">Most Trending Luxury Properties</span>
          <span className="inline md:hidden">Ukiyo Recommended Properties</span>
        </p>
      )}

      {/* Toggle Button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setIsGrid(!isGrid)}
          className="px-4 py-2 text-blue-500 bg-white rounded-md cursor-pointer font-semibold text-xl"
        >
          View {isGrid ? "Less" : "All"}
        </button>
      </div>

      {/* Grid Layout */}
      {isGrid ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {data?.data?.availableHotels?.map((property) => (
            <div key={property?._id}>
              <Card propertyData={property} />
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          slidesPerView={1} // Default to one slide per view
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            640: { slidesPerView: 1 },  // Small screens
            768: { slidesPerView: 2 },  // Tablets
            1024: { slidesPerView: 3 }, // Small Desktops
            1280: { slidesPerView: 4 }, // Larger Desktops
            1536: { slidesPerView: 5 }, // Very large screens
          }}
          className="mt-8 w-full"
        >
          {data?.data?.availableHotels?.map((property) => (
            <SwiperSlide key={property?._id}>
              <div className="flex justify-center gap-8">
                <Card propertyData={property} />
              </div>
            </SwiperSlide>
          ))}

          {/* Navigation Buttons */}
          <div className="swiper-button-prev absolute left-0 top-1/2 transform -translate-y-1/2 text-black" style={{ zIndex: 10 }}></div>
          <div className="swiper-button-next absolute right-0 top-1/2 transform -translate-y-1/2 text-black" style={{ zIndex: 10 }}></div>
        </Swiper>
      )}
    </div>
  );
};

export default MostTrendingProperty;
