import React, { useState } from "react";
import Anchor from "../../../assets/svg/Anchor.webp";
import WhatsApp from "../../../assets/svg/WhatsApp.webp";
import WhatsAppModal from "../WhatsAppModal";

const ShareAndCopy = () => {
  const [showTooltip, setShowTooltip] = useState(""); 
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const urlToShare =
    "https://www.ukiyostays.com/ThingsToDoInfo/665b1c271294808654b8e179";
  const handleCopyLink = () => {
    navigator.clipboard.writeText(urlToShare);
    alert("Link copied to clipboard!"); 
  };

  return (
    <div className="mt-8 mb-[120px]">
      <div className="max-w-[1296px] h-[75px] flex-col justify-start items-start gap-8 inline-flex">
        <div className="justify-start items-center gap-5 inline-flex">
          <div className="text-neutral-700 text-2xl font-semibold leading-[43.20px] tracking-tight">
            Share:
          </div>
          <div className="justify-start items-center gap-5 flex">
            <div className="justify-start items-center gap-5 flex">
              {/* WhatsApp Icon */}
              <div
                role="button"
                onClick={() => setShowWhatsAppModal(true)}
                onMouseEnter={() => setShowTooltip("whatsapp")}
                onMouseLeave={() => setShowTooltip("")}
                className="w-[25px] h-7 relative"
              >
                <img src={WhatsApp} alt="WhatsApp" />
                {showTooltip === "whatsapp" && (
                  <div className="absolute bottom-full mb-2 left-1/2 
                  transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
                    Share via WhatsApp
                  </div>
                )}
              </div>
              {/* Copy Link Icon */}
              <div
                role="button"
                onClick={handleCopyLink}
                onMouseEnter={() => setShowTooltip("copy")}
                onMouseLeave={() => setShowTooltip("")}
                className="w-6 h-6 relative"
              >
                <img src={Anchor} alt="Anchor" />
                {showTooltip === "copy" && (
                  <div className="absolute bottom-full mb-2 left-1/2 
                  transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
                    Copy link
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WhatsApp Modal */}
      {showWhatsAppModal && (
        <WhatsAppModal setShowWhatsAppModal={setShowWhatsAppModal} />
      )}
    </div>
  );
};

export default ShareAndCopy;
