import React, { useState } from "react";
import { LuChevronsUpDown } from "react-icons/lu";

import { useOutletContext } from "react-router-dom";
import Pagination from "../../components/OwnerProfile/Reservation/Pagination";
import ReservationRow from "../../components/OwnerProfile/Reservation/RservationsRow";
import TabItem from "../../components/OwnerProfile/Shared/TabItem";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetReservationQuery } from "../../redux/features/ownerprofile/reservationAPI";

const Reservations = () => {
  const { selectedCity, date, selectedListing } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);

  const token = useTokenFromLocalStorage();
  const [activeTab, setActiveTab] = useState("All Reservations");

  const { data } = useGetReservationQuery({
    token,
    city: selectedCity,
    date,
    listingId: selectedListing,
    pageNo: currentPage,
  });

  console.log(data?.result);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const filteredReservations = data?.result?.filter((reservation) =>
    activeTab === "All Reservations"
      ? true
      : reservation?.bookingInfo?.status === activeTab
  );

  return (
    <>
      <div className="mt-[24px]">
        {/* Reservation Heading */}
        <div className="flex  items-center  justify-between">
          <p className="text-2xl lg:text-[32px] font-normal">Reservations</p>

          <p className="text-blue-700 text-[14px] lg:text-base font-semibold font-['Lato'] cursor-pointer">
            View Calendar
          </p>
        </div>

        <div className="h-[27px] flex-col justify-start items-start gap-[9px] flex mt-[38px]">
          {/* reservations tab */}
          <div className="flex  items-center gap-8 lg:gap-12 ">
            {["All Reservations", "Upcoming", "Completed", "Cancelled"]?.map(
              (tab) => (
                <TabItem
                  key={tab}
                  label={tab}
                  activeTab={activeTab}
                  onClick={handleTabClick}
                />
              )
            )}
          </div>
          <div className="w-[113px] h-[3px] bg-zinc-950 rounded-[202px]" />
        </div>

        {/* date and action list */}
        <div className="flex flex-col mt-[40px]">
          <div className="flex gap-6 lg:gap-12 justify-start items-start overflow-scroll hide_scrollbar">
            <p className="text-black text-base font-bold font-['Lato'] leading-none  flex items-center gap-[8px]">
              Date
              <LuChevronsUpDown className="font-bold" />
            </p>
            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              City
            </p>

            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              Villa Name
            </p>

            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              No. of guests
            </p>

            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              Phone
            </p>

            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              Status
            </p>

            <p className="text-black text-base font-bold font-['Lato'] leading-none ">
              Price
            </p>
            <p className="text-black text-base font-bold font-['Lato'] leading-none">
              Action
            </p>
          </div>
          <div className="flex flex-col ">
            {filteredReservations?.map((reservation, index) => (
              <div key={index}>
                <div className="h-[0px] border border-gray-200 mt-[28px]"></div>
                <ReservationRow {...reservation} />
              </div>
            ))}
          </div>

          {/* Paginations */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItems={data?.result?.length}
          />
        </div>
      </div>
    </>
  );
};

export default Reservations;
