import React, { useEffect, useState } from "react";
import ChatModalContent from "../FutureGetaways/ChatModal/ChatModalContent";
import ChatModal from "../modals/ChatModal";
import Container from "../ui/Container";
import LuxuryVillas from "./LuxuryVillas";
import LuxuryVillasCity from "./LuxuryVillasCity";
import { useLocation } from "react-router-dom";
import LuxuryVillasNew from "./LuxuryVillasNew";

const MainLuxuryVillas = ({
  searchData,
  token,
  city,
  checkIn,
  checkOut,
  SearchTags,
}) => {
  const [chatModal, setChatModal] = useState(false);
  const openChatModal = () => {
    setChatModal(true);
  };
  const CloseChatModal = () => {
    setChatModal(false);
  };
  const location = useLocation();
  console.log(
    "👉🏻 > file: MainLuxuryVillas.jsx:25 > location:",
    // location?.state?.SearchTag
    location?.state?.data
  );






  return (
    <>
      <Container>
        <LuxuryVillas
          searchData={searchData}
          token={token}
          city={city}
          //SearchTag={location?.state?.SearchTag}
          SearchTag={SearchTags}
          checkIn={checkIn}
          checkOut={checkOut}
        />
        <LuxuryVillasCity city={city} />
        {/* <LuxuryVillasNew /> */}
        {chatModal && (
          <div>
            <ChatModal CloseChatModal={CloseChatModal}>
              <ChatModalContent
                CloseChatModal={CloseChatModal}
                openChatModal={openChatModal}
              />
            </ChatModal>{" "}
          </div>
        )}
      </Container>
    </>
  );
};

export default MainLuxuryVillas;
