import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import Container from "../ui/Container";
import { BaseURL } from "../../redux/config";
import { useNavigate } from "react-router-dom";

const Collections = ({ token }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [collections, setCollections] = useState([]);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const navigateTo = (responseJson) => {
    navigate("/allproperties", {
      state: {
        searchData: responseJson,
        location: "",
        checkIn: "",
        checkOut: "",
      },
    });
  };
  const viewAllProperties = async (tag) => {
    let value = "";
    if (tag === "Luxury") {
      value = "tag=Luxury";
    } else if (tag === "new_homes") {
      value = "new_homes=true";
    }

    const data = await fetch(`${BaseURL}/hotels/search?${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responseJson = await data.json();
    const responseData = responseJson?.data;
    console.log("all searches are:: ", responseJson);

    navigateTo(responseJson);
  };

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch(
          "https://api.ukiyostays.com/api/collection",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        if (result?.data) {
          setCollections(result.data);
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, [token]);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <Container>
      <div className="mt-[130px] mx-5">
        <div className="flex px-2 lg:px-7  items-center justify-between align-middle">
          <p className="md:text-[32px] text-2xl text-[#000000] font-butler font-[400]">
            Collections
          </p>
          {collections?.length > 3 && (
            <div>
              <a href="#" className="text-[#0F6DDC] text-[18px] font-[700]">
                VIEW ALL
              </a>
            </div>
          )}
        </div>
        <div className="mt-[32px] px-3 md:px-3">
          <Swiper
            ref={swiperRef}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              handleSlideChange();
            }}
            breakpoints={{
              1440: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 3.5,
              },
              768: {
                slidesPerView: 3,
              },
              368: {
                slidesPerView: 2,
              },
            }}
            spaceBetween={10}
            className="flex justify-center"
            onSlideChange={handleSlideChange}
          >
            {collections.map((collection) =>
              collection.hotelId.map((hotel) => (
                <SwiperSlide
                  className="flex justify-center items-center w-full lg:p-4"
                  key={hotel._id}
                >
                  <div
                    className="relative flex justify-center items-center w-full cursor-pointer"
                    onClick={() => viewAllProperties(collection.tag)}
                  >
                    <img
                      loading="lazy"
                      src={hotel.imageUrls[0] || ""}
                      alt={hotel.name || "Collection Image"}
                    />
                    <p className="absolute -bottom-1 text-white lg:p-4 p-2 max-w-[149px] left-2 md:max-w-full md:text-[24px] text-[14px] font-[Butler] font-[400]">
                      {hotel.name}
                    </p>
                  </div>
                </SwiperSlide>
              ))
            )}
          </Swiper>
          {collections?.length >= 1 && (
            <div className="flex items-center justify-end mt-[32px] md:px-5 px-2 gap-[16px]">
              <button
                onClick={swipePrev}
                className="max-sm:w-6 max-lg:w-10"
                disabled={isBeginning}
              >
                <img
                  loading="lazy"
                  src={isBeginning ? scrollLeft : scrollLeftActive}
                  alt="scroll"
                />
              </button>
              <button
                className={`max-sm:w-6 max-lg:w-10 ${
                  isEnd ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={swipeNext}
                disabled={isEnd}
              >
                <img loading="lazy" src={scrollRight} alt="scroll" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Collections;
