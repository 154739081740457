import React from "react";
import AboutUsBanner from "../../../assets/svg/AboutUsBanner.webp";

const SingleBlogBanner = (props) => {
  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(
to bottom,
rgba(15,1,1, 0.2),
rgba(17,1,1, 0.3)
), url(${props?.cover})`,
      }}
      className=" h-[410px] lg:max-h-[568px] bg-cover bg-center flex justify-center items-center flex-col"
    >
      <h1 className="font-butler text-white font-bold text-[32px] leading-[38.4px] lg:text-[56px] lg:leading-[67.2px] tracking-[1%]">
        {/* Manali */}
        {props?.city}
      </h1>
      <div className="lg:w-[607px] text-center text-white text-sm lg:text-2xl font-bold font-Lato leading-5   lg:leading-9 tracking-tight">
        Where the beaches are endless and the good vibes never end.
      </div>
    </div>
  );
};

export default SingleBlogBanner;
