import React, { useState } from 'react'
import { auth, provider } from "./config";
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

const AppleLogin = () => {
    const [userEmail, setUserEmail] = useState("");
    const [userUid, setUserUid] = useState("");
    const handleAppleLogin = async () => {
        const providers = new OAuthProvider('apple.com');
        try {
          const { user } = await signInWithPopup(auth, providers);
          localStorage.setItem("loginemail", user.email);
          setUserUid(user.uid);
          setUserEmail(user.email);
        //   continueWithGoogle(user.email, user.uid);
          console.log(
            "USER LOGGED in:: ",
            user,
            "\n\n\n useEmail",
            user.email,
            "\n\n uid: ",
            user.uid
          );
        } catch (error) {
          console.error("Error signing in with Google:", error);
        }
      };
  return (
    <button className='w-[100%] py-1 border-[1px] border-black text-black' onClick={handleAppleLogin}>Apple Login</button>
  )
}

export default AppleLogin