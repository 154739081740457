import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RectangleSlider from "../../../../assets/images/Rectangle 8766.webp";
import rightArrow from "../../../../assets/svg/rightArrow.webp";
import "./slider.css";

import useTokenFromLocalStorage from "../../../../hooks/useGetToken";
import { useLogInMutation } from "../../../../redux/features/auth/authApi";
import Form from "../Form";
import { Link } from "react-router-dom";

const banner = [
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
  { slideImages: RectangleSlider },
];

const Slider = ({ isHomeScreen }) => {
  const token = useTokenFromLocalStorage();
  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLogInMutation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const innerSwiperRef = useRef(null);

  // Initialize navigation controls for the second swiper
  useEffect(() => {
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      const swiperInstance = innerSwiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);

  const [loader, setLoader] = useState(false);

  const handleClick = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  return (
    <div className="relative max-lg:mb-[56px] mb-[237px]">
      {/* Main Slider */}
      <div className="overflow-x-hidden max-lg:max-h-[442px] max-lg:mb-0 mb-[237px] h-[602px] relative">
        <div className="w-screen mx-auto h-full">
          <Swiper
            modules={[Pagination, Autoplay, A11y, Navigation]}
            slidesPerView={1}
            loop={false}
            autoplay={{ delay: 10 }}
            pagination={{ clickable: true }}
            speed={3000}
            className="h-full flex justify-center items-center"
          >
            {banner?.map((item, index) => (
              <SwiperSlide
                className="h-full flex justify-center items-center"
                key={index}
              >
                <div className="relative w-full h-full flex justify-center">
                  <img
                    src={item.slideImages}
                    className="w-full min-h-[442px] h-full object-cover lg:rounded-lg"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Smaller Carousel with Custom Navigation */}
          <div className="w-56 h-25 p-1 bg-black bg-opacity-70 rounded absolute z-40 right-4 bottom-4">
            <Swiper
              ref={innerSwiperRef}
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onSwiper={(swiper) => {
                // Ensure custom navigation is updated on swiper initialization
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              className="relative"
            >
              {banner?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="flex p-2 pl-4 justify-center flex-col">
                    <h4 className="text-base font-Lato text-white">
                      Save on Upgraded Rooms and Suites
                    </h4>
                    <Link to="/SpecialOffersPage">
                      <h4
                        className="text-sm font-Lato text-white underline mt-3 cursor-pointer flex items-center"
                        onClick={handleClick}
                      >
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          "View Offer"
                        )}
                      </h4>
                    </Link>
                    {/* <img
                      src={item.slideImages}
                      className="w-full h-full object-cover lg:rounded-lg"
                      alt={`Slide ${index + 1}`}
                    /> */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Custom Navigation Arrows for the smaller carousel */}
          <div className="bottom-[0rem] max-lg:bottom-10 max-md:right-0 right-64 z-40 absolute h-[110px] flex gap-x-6">
            <div className="max-lg:hidden flex-col flex h-full items-end gap-y-2">
              <div
                ref={prevRef}
                className="w-6 h-6 bg-white flex justify-center items-center cursor-pointer"
              >
                <img
                  className="rotate-180"
                  src={rightArrow}
                  alt="Previous Slide"
                />
              </div>
              <div
                ref={nextRef}
                className="w-6 h-6 bg-white flex justify-center items-center cursor-pointer"
              >
                <img src={rightArrow} alt="Next Slide" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Form />
    </div>
  );
};

export default Slider;
