import { createContext, useContext, useEffect, useState } from "react";
import { useAuthUserQuery } from "../redux/features/auth/authApi";
import { io } from "socket.io-client";
import useTokenFromLocalStorage from "../hooks/useGetToken";

const SocketContext = createContext();

export const useSocketContext = () => {
  return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const token = useTokenFromLocalStorage();
  const { data: authUser } = useAuthUserQuery(token);

  useEffect(() => {
    if (authUser && !socket) {
      const socket = io("https://api.ukiyostays.com", {
        query: {
          userId: authUser?.user._id,
        },
      });
      setSocket(socket);
      socket.on("getOnlineUsers", (users) => {
        setOnlineUsers(users);
      });
      return () => socket.close();
    } else {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    }
  }, [authUser]);

  return (
    <SocketContext.Provider value={{ socket, onlineUsers }}>
      {children}
    </SocketContext.Provider>

    // <>
    //   {children}
    // </>

  );
};
