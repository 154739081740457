import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import building from "../../assets/svg/building.webp";
import priceTag from "../../assets/svg/priceTag.webp";
import routeTick from "../../assets/svg/routeTick.webp";
const SideBar = () => {
  const pathName = useLocation()?.pathname;
  const location = useLocation();
  console.log(location);
  const [fromPropertyDetails, setFromPropertyDetails] = useState(false);
  const [fromPricingDetails, setPricingDetails] = useState(false);
  const [fromAddMoreOption, setAddMoreOption] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("UpdatePropertyDetails") &&
      !localStorage.getItem("UpdatePricingDetails")
    ) {
      setFromPropertyDetails(true);
    } else if (
      localStorage.getItem("UpdatePricingDetails") &&
      localStorage.getItem("UpdatePropertyDetails") &&
      !localStorage.getItem("UpdateAddMoreOption")
    ) {
      setFromPropertyDetails(true);
      setPricingDetails(true);
    } else if (
      localStorage.getItem("UpdatePricingDetails") &&
      localStorage.getItem("UpdatePropertyDetails") &&
      localStorage.getItem("UpdateAddMoreOption")
    ) {
      setFromPropertyDetails(true);
      setPricingDetails(true);
      setAddMoreOption(true);
    }
  }, [location]);
  return (
    <div>
      <div className="max-md:mt-16 md:w-[306px] flex h-full md:relative border-r max-md:mb-[100px]">
        <div className="md:w-[306px] h-[268.50px] left-0 top-0 absolute justify-start items-center gap-[100px] inline-flex">
          <div className="md:w-[307px] flex-col justify-start  gap-8 inline-flex">
            <div className="md:w-[307px]  justify-end items-center inline-flex">
              <div className=" md:w-[305px] justify-start items-center gap-[100px] inline-flex">
                <div className="flex-col justify-start items-start gap-8 inline-flex">
                  <div className="mt-16 md:w-[194px] h-7 text-black md:text-2xl font-semibold font-Lato leading-9 tracking-tight">
                    List your property
                  </div>
                </div>
              </div>
            </div>
            <div className="md:-mt-3 text-center md:w-[305px] relative flex justify-center items-center max-md:gap-8 lg:flex-col">
              <Link
                to="/EditYourVilla"
                className={`${
                  pathName === "/ListYourVilla" ? "bg-neutral-300" : ""
                } w-full py-3 flex flex-col lg:flex-row rounded text-black
lg:text-xl gap-x-4 items-center max-md:justify-center hover:bg-neutral-300 px-2
font-normal
font-Lato
tracking-tight`}
              >
                {fromPropertyDetails ? (
                  <>
                    <img
                      src={routeTick}
                      className="w-[40px] h-[40px] relative flex-col justify-start items-start flex"
                      alt=""
                    />
                  </>
                ) : (
                  <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                    <img
                      src={building}
                      className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                      alt=""
                    />
                  </div>
                )}
                Add property details
              </Link>
              <Link
                to="/EditYourVilla/PriceDetails"
                className={`${
                  pathName === "/ListYourVilla/PriceDetails"
                    ? "bg-neutral-300"
                    : ""
                } w-full py-3 flex flex-col lg:flex-row rounded text-black
                  lg:text-xl gap-x-4 items-center hover:bg-neutral-300 px-2 font-normal font-Lato tracking-tight`}
              >
                {fromPricingDetails ? (
                  <>
                    <img
                      src={routeTick}
                      className="w-[40px] h-[40px] relative flex-col justify-start items-start flex"
                      alt=""
                    />
                  </>
                ) : (
                  <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                    <img
                      src={priceTag}
                      className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                      alt=""
                    />
                  </div>
                )}
                Pricing details
              </Link>
              <Link
                to="/EditYourVilla/AddMoreOptions"
                className={`${
                  pathName === "/ListYourVilla/AddMoreOptions"
                    ? "bg-neutral-300"
                    : ""
                } w-full py-4 flex flex-col lg:flex-row rounded text-black
lg:text-xl gap-x-4 items-center hover:bg-neutral-300 px-2
font-normal
font-Lato
tracking-tight`}
              >
                {fromAddMoreOption ? (
                  <>
                    <img
                      src={routeTick}
                      className="w-[40px] h-[40px] relative flex-col justify-start items-start flex"
                      alt=""
                    />
                  </>
                ) : (
                  <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                    <FaPlus className="text-[22px]" />
                  </div>
                )}
                Add More Options
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
