import { apiSlice } from "../api/apliSlice";

export const couponsAPI = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    findPrivateCoupon: builder.query({
      query: ({ token, userId }) => ({
        // url: `/coupons/user/${userId}`,
        url: `/coupons/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
        },
      }),
    }),
  }),
});

export const { useFindPrivateCouponQuery } = couponsAPI;
