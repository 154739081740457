import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

const MealPlans = ({ setShowMealsPlans, setMealPlansData }) => {
  const MealPlansData = ["Breakfast", "Lunch", "Evening snacks", "Dinner"];
  const CuisinesData = [
    "North Indian",
    "South Indian",
    "Local",
    "Continental",
    "Chinese",
    "Italian",
  ];
  const [selectedMealPlans, setSelectedMealPlans] = useState([]);
  const [CuisinesSelectedData, setCuisinesSelectedData] = useState([]);
  const [UploadMenus, SetUploadMenus] = useState([]);
  const [UploadImages, SetUploadImages] = useState([]);
  const [BasePrice, setBasePrice] = useState(0);
  const [BasePriceVeg, setBasePriceVeg] = useState(0);
  const [showBasePriceNonVeg, setShowBasePriceNonVeg] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("mealPlan")) {
      const mealplan = JSON.parse(localStorage.getItem("mealPlan"));
      // console.log("useeffect meal plan->", mealplan)
      if (mealplan?.SelectedMealPlans) {
        setSelectedMealPlans(mealplan.SelectedMealPlans)
      }

      if (mealplan?.CuisinesSelectedData) {
        setCuisinesSelectedData(mealplan.CuisinesSelectedData)
      }

      if (mealplan?.BasePrice) {
        setBasePrice(mealplan.BasePrice)
        // console.log("hii", mealplan.BasePrice)
      }

      if (mealplan?.BasePriceVeg) {
        setBasePriceVeg(mealplan.BasePriceVeg)
        setShowBasePriceNonVeg(true);
      }

      if (mealplan?.UploadMenus) {
        SetUploadMenus(mealplan.UploadMenus)
      }

      if (mealplan?.UploadImages) {
        SetUploadImages(mealplan.UploadImages)
      }
    }
  }, [])

  return (
    <div className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black/30 z-50 example overflow-scroll flex justify-center items-center">
      <div className="w-[424px] mb-10 mt-16 h-full  bg-white rounded flex-col justify-start items-start  inline-flex">
        <div className="w-[376px] flex align-middle justify-end">
          <div className="min-w-[60%] mt-6 flex align-middle justify-between text-center text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
            <h3>Meal plans</h3>
            <IoCloseOutline onClick={() => setShowMealsPlans(false)} className=" text-[#6D747A] cursor-pointer w-8 h-8" />
          </div>
        </div>
        <section className="w-full px-6 h-[608px] overflow-y-scroll overflow-x-hidden">
          <section id="MealsPrepared" className="w-full">
            <h2 className="mt-10 mb-4  text-zinc-950 text-lg font-bold font-Lato leading-7">
              Meals prepared
            </h2>
            <div className="px-4 w-full">
              {MealPlansData?.map((x, i) => (
                <div
                  key={i}
                  className="text-zinc-950
text-base w-full
font-semibold cursor-pointer
font-Lato
leading-normal mb-6 flex justify-between items-center"
                >
                  {x}
                  <input
                    // onChange={(e) => {
                    //   if (e.target.checked) {
                    //     if (!selectedMealPlans.find((y) => y === x)) {
                    //       selectedMealPlans.push(x);
                    //     }
                    //   } else if (!e.target.checked) {
                    //     const find = selectedMealPlans.find((y) => y === x);
                    //     selectedMealPlans.splice(
                    //       selectedMealPlans.indexOf(find),
                    //       1
                    //     );
                    //   }
                    //   console.log(selectedMealPlans);
                    // }}
                    checked={selectedMealPlans?.includes(x)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // Add the meal plan to the array if not already present
                        if (!selectedMealPlans.includes(x)) {
                          setSelectedMealPlans((prev) => [...prev, x]);
                        }
                      } else {
                        // Remove the meal plan from the array if unchecked
                        setSelectedMealPlans((prev) => prev.filter((y) => y !== x));
                      }
                    }}
                    className="w-6 h-6"
                    type="checkbox"
                  />
                </div>
              ))}
            </div>
          </section>
          <section id="CuisineServed">
            <div className="w-[376px] text-zinc-950 text-lg font-bold font-Lato leading-7">
              Cuisines served
            </div>
            <div className="px-4 mt-6 w-full">
              {CuisinesData?.map((x, i) => (
                <div
                  key={i}
                  className="text-zinc-950
text-base w-full
font-semibold cursor-pointer
font-Lato
leading-normal mb-6 flex justify-between items-center"
                >
                  {x}
                  <input
                    // onChange={(e) => {
                    //   if (e.target.checked) {
                    //     if (!CuisinesSelectedData.find((y) => y === x)) {
                    //       CuisinesSelectedData.push(x);
                    //     }
                    //   } else if (!e.target.checked) {
                    //     const find = CuisinesSelectedData.find((y) => y === x);
                    //     CuisinesSelectedData.splice(
                    //       CuisinesSelectedData.indexOf(find),
                    //       1
                    //     );
                    //   }
                    //   console.log(CuisinesSelectedData);
                    // }}
                    checked={CuisinesSelectedData?.includes(x)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        // Add the meal plan to the array if not already present
                        if (!CuisinesSelectedData.includes(x)) {
                          setCuisinesSelectedData((prev) => [...prev, x]);
                        }
                      } else {
                        // Remove the meal plan from the array if unchecked
                        setCuisinesSelectedData((prev) => prev.filter((y) => y !== x));
                      }
                    }}
                    className="w-6 h-6"
                    type="checkbox"
                  />
                </div>
              ))}
            </div>
          </section>
          <section id="SetBasePriceVeg">
            <div className="w-[416px] h-6 flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Set a base price (Vegetarian)
              </div>
            </div>
            <div className="w-[376px] h-[95px] p-4 bg-white rounded border border-gray-300 mt-2.5 flex-col justify-start items-start inline-flex">
              <input
                placeholder="₹2,150"
                onChange={(e) => setBasePrice(e.target.value)}
                className="text-black text-2xl font-semibold font-Lato outline-none leading-9 tracking-tight"
                type="text"
                value={BasePrice}
              />
              <div className="text-zinc-500 text-xs font-normal font-['Lato'] leading-[18px]">
                per night; per guest
              </div>
            </div>
          </section>
          <section id="SetBasePriceNonVeg">
            <div className="w-full h-6  justify-between items-start mt-6 gap-2 flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Set a base price (Non-Vegetarian)
              </div>
              <div
                onClick={() => {
                  if (showBasePriceNonVeg === false) {
                    setShowBasePriceNonVeg(true);
                  } else if (showBasePriceNonVeg === true) {
                    setShowBasePriceNonVeg(false);
                    setBasePriceVeg(0);
                  }
                }}
                className={`${showBasePriceNonVeg ? "text-red-600" : "text-blue-600"
                  }  text-sm font-bold font-Lato cursor-pointer
            uppercase leading-[21px]`}
              >
                {showBasePriceNonVeg ? "Remove" : "Add"}
              </div>
            </div>
            {showBasePriceNonVeg && (
              <div className="w-[376px] h-[95px] p-4 bg-white rounded border border-gray-300 mt-2.5 flex-col justify-start items-start inline-flex">
                <input
                  placeholder="₹2,650"
                  onChange={(e) => setBasePriceVeg(e.target.value)}
                  className="text-black text-2xl font-semibold font-Lato outline-none leading-9 tracking-tight"
                  type="text"
                  defaultValue={BasePriceVeg}
                />
                <div className="text-zinc-500 text-xs font-normal font-['Lato'] leading-[18px]">
                  per night; per guest
                </div>
              </div>
            )}
          </section>
          <section id="AddMenuAndAddImages">
            <section id="AddMenu">
              <div className="w-[416px] mt-6 h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Upload Menus
                </div>
              </div>
              <div className="w-[416px] mt-4 h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div
                  className="text-blue-700
text-base
font-semibold items-center
font-Lato flex gap-x-3
leading-normal
tracking-tight"
                >
                  <FaPlus /> Add File
                </div>
              </div>
            </section>
            <section id="UploadImages">
              <div className="w-[416px] mt-6 h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Upload images
                </div>
              </div>
              <div className="w-[416px] mb-10 mt-4 h-6 flex-col justify-start items-start gap-2 inline-flex">
                <div
                  className="text-blue-700
text-base
font-semibold items-center
font-Lato flex gap-x-3
leading-normal
tracking-tight"
                >
                  <FaPlus /> Add images
                </div>
              </div>
            </section>
          </section>
        </section>
        <section
          id="SaveCancelButton"
          className="px-6 pb-6 flex flex-col gap-4
        "
        >
          <div
            onClick={() => {
              const data = {
                SelectedMealPlans: selectedMealPlans,
                CuisinesSelectedData: CuisinesSelectedData,
                BasePrice,
                BasePriceVeg,
                UploadMenus,
                UploadImages,
              }
              setMealPlansData(data);
              // console.log("mealPlan -> ", data)
              localStorage.setItem("mealPlan", JSON.stringify(data))
              setShowMealsPlans(false);
            }}
            role="button"
            id="#SaveButton"
            className="w-[376px] h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-white text-base font-bold font-['Lato'] leading-none">
              Save
            </div>
          </div>
          <div
            onClick={() => setShowMealsPlans(false)}
            id="#CancelButton"
            role="button"
            className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-zinc-950 text-base font-bold font-['Lato'] leading-none">
              Cancel
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MealPlans;
