import React, { useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import scroll_left from "../../../assets/images/PropertyIPageId8/shere.webp";
import home from "../../../assets/svg/home.webp";
import meal1 from "../../../assets/images/property-d-meal-image-1.webp";
import meal2 from "../../../assets/images/property-d-meal-image-2.webp";
import meal3 from "../../../assets/images/property-d-meal-image-3.webp";
import minus from "../../../assets/svg/Minus.webp";
import plus from "../../../assets/svg/plus.webp";
import scrollLeft from "../../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";
import shareIcon from "../../../assets/svg/shere1.webp";

const MealPlansModalContent = ({ handleShare, hotelMealPlans }) => {
  const swiperRef = useRef(null);

  // State for adult and child counts for different meal plans
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(1);
  const [adultCount1, setAdultCount1] = useState(1);
  const [childCount1, setChildCount1] = useState(1);

  // Base prices for different meal options
  const adultPrice = 1850;
  const childPrice = 1050;

  const homeImages = [meal1, meal2, meal3];

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Handlers for incrementing and decrementing counts
  const handleAdultMinus = () => {
    setAdultCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleAdultPlus = () => {
    setAdultCount((prevCount) => prevCount + 1);
  };

  const handleChildMinus = () => {
    setChildCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleChildPlus = () => {
    setChildCount((prevCount) => prevCount + 1);
  };

  const handleAdultMinus1 = () => {
    setAdultCount1((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleAdultPlus1 = () => {
    setAdultCount1((prevCount) => prevCount + 1);
  };

  const handleChildMinus1 = () => {
    setChildCount1((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleChildPlus1 = () => {
    setChildCount1((prevCount) => prevCount + 1);
  };

  // Calculate total price based on count
  const calculatePrice = (count, basePrice) => {
    return count * basePrice;
  };

  const mealForAdultData = [
    {
      text: "Adult",
      sideText: "(12+ yrs)",
      price: calculatePrice(adultCount, adultPrice),
      leftText: "/per person",
      handlePlus: handleAdultPlus,
      handleMinus: handleAdultMinus,
      count: adultCount,
    },
    {
      text: "Child",
      sideText: "(5-12 yrs)",
      price: calculatePrice(childCount, childPrice),
      leftText: "/per person",
      handlePlus: handleChildPlus,
      handleMinus: handleChildMinus,
      count: childCount,
    },
  ];

  const mealForChildData = [
    {
      text: "Adult",
      sideText: "(12+ yrs)",
      price: calculatePrice(adultCount1, adultPrice),
      leftText: "/per person",
      handlePlus: handleAdultPlus1,
      handleMinus: handleAdultMinus1,
      count: adultCount1,
    },
    {
      text: "Child",
      sideText: "(5-12 yrs)",
      price: calculatePrice(childCount1, childPrice),
      leftText: "/per person",
      handlePlus: handleChildPlus1,
      handleMinus: handleChildMinus1,
      count: childCount1,
    },
  ];

  return (
    <div className="p-4 sm:p-6 w-screen md:w-full bg-white rounded-lg shadow-md">
      {/* Header Section */}

      {/* Meal Options Description */}
      <div>
        <h2 className="text-base font-semibold text-[#08090A] mb-3">
          Meal options provided at the property
        </h2>
        <ul className="list-disc ml-4 space-y-2 text-sm text-[#08090A]">
          <li>
            A personal chef is available to prepare local specialties and a
            delicious spread of home-cooked meals all day, at an additional
            charge.
          </li>
          <li>Meals prepared: Breakfast, Lunch, Evening Snacks, Dinner</li>
          <li>
            Cuisines available: North Indian, South Indian, Continental, Local
          </li>
        </ul>
      </div>

      {/* Image Slider */}
      <div className="relative mt-4 mb-6 h-[200px] sm:h-[300px]">
        <Swiper
          ref={swiperRef}
          className="w-full h-full"
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {homeImages.map((url, i) => (
            <SwiperSlide key={i}>
              <img
                className="w-full h-full object-cover rounded-lg"
                src={url}
                alt={`Meal ${i + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className="absolute left-2 top-1/2 z-50 max-sm:w-6 max-lg:w-10"
          onClick={swipePrev}
        >
          <img src={scrollLeft} alt="Previous" />
        </button>
        <button
          className="absolute right-2 top-1/2 z-50 max-sm:w-6 max-lg:w-10"
          onClick={swipeNext}
        >
          <img src={scrollRight} alt="Next" />
        </button>
      </div>

      {/* Meal Options */}
      <div>
        <h3 className=" text-sm md:text-base  font-semibold text-[#08090A] mb-3">
          You can choose from veg & non-veg meals (including eggs)
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Veg Options */}
          <div>
            <div className="flex justify-start items-center  gap-2 mb-2">
              <img src={shareIcon} className="w-5 h-5" alt="" />
              <p className="text-sm font-medium text-[#08090A] mb-0">Veg</p>
            </div>

            {mealForAdultData.map((item, i) => (
              <div
                key={i}
                className="flex justify-between items-center pt-2 mb-3"
              >
                <div>
                  <p className="text-xs text-[#6D747A]">{item.text}</p>
                  <p className="text-sm font-medium text-[#08090A]">
                    ₹{item.price}
                    <span className="text-[#6D747A] ml-1">{item.leftText}</span>
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={item.handleMinus}
                    className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full"
                  >
                    <img src={minus} alt="Minus" />
                  </button>
                  <span className="text-sm font-medium">{item.count}</span>
                  <button
                    onClick={item.handlePlus}
                    className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full"
                  >
                    <img src={plus} alt="Plus" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Non-Veg Options */}
          <div>
            <div className="flex justify-start items-center  gap-2 mb-2">
              <img src={shareIcon} className="w-5 h-5" alt="" />
              <p className="text-sm font-medium text-[#08090A] mb-0">Non-Veg</p>
            </div>

            {mealForAdultData.map((item, i) => (
              <div
                key={i}
                className="flex justify-between items-center  pt-2 mb-3"
              >
                <div>
                  <p className="text-xs text-[#6D747A]">{item.text}</p>
                  <p className="text-sm font-medium text-[#08090A]">
                    ₹{item.price}
                    <span className="text-[#6D747A] ml-1">{item.leftText}</span>
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={item.handleMinus}
                    className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full"
                  >
                    <img src={minus} alt="Minus" />
                  </button>
                  <span className="text-sm font-medium">{item.count}</span>
                  <button
                    onClick={item.handlePlus}
                    className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full"
                  >
                    <img src={plus} alt="Plus" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealPlansModalContent;
