import React from "react";

const BlogSec = (props) => {
  return (
    <div className="mt-16">
      <h3 className="font-semibold text-3xl leading-[48px] tracking-[0.32px] mb-4">
        Palm Trove
      </h3>
      <p className="mb-4 leading-7 tracking-[0.16px] font-Lato font-normal text-base">
        Palm Trove is quite literally a trove of palms and makes for a tranquil
        retreat amidst lush greenery and scenic views. This sprawling villa with
        modern amenities is located a short distance from Awas Beach. Relax on
        the lawn, enjoy the trees, sip a cocktail, or lounge by the pool.
      </p>
      <div
        style={{
          backgroundImage: 'url("https://via.placeholder.com/856x540")',
        }}
        className="bg-cover bg-center bg-[#D9D9D9] w-full max-w-[856px] aspect-[856/540] mb-10"
      ></div>

      <h4 className="text-2xl mb-6 font-semibold font-Lato leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In Alibaug
      </h4>

      <ul className="list-disc pl-5 leading-6 tracking-[0.16px] text-[#434141]">
        <li>Expansive, L-shaped pool for swimming and sunbathing</li>
        <li>
          Serene, verdant lawn for outdoor relaxation, perfect for your pets to
          relax and revel in Games room for indoor entertainment{" "}
        </li>
        <li>Stylish, modern interiors with up-to-date amenities</li>
        <li>Distinctive exteriors with unique architectural elements</li>
        <li>Convenient location in close proximity to the beach</li>
      </ul>

      <h4 className="text-2xl mb-6 font-semibold pt-10 font-Lato leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In Alibaug
      </h4>

      <ul className="list-disc pl-5  leading-6 tracking-[0.16px] text-[#434141]">
        <li>Expansive, L-shaped pool for swimming and sunbathing</li>
        <li>
          Serene, verdant lawn for outdoor relaxation, perfect for your pets to
          relax and revel in Games room for indoor entertainment{" "}
        </li>
        <li>Stylish, modern interiors with up-to-date amenities</li>
        <li>Distinctive exteriors with unique architectural elements</li>
        <li>Convenient location in close proximity to the beach</li>
      </ul>

      <h3 className="font-semibold text-3xl leading-[48px] pt-10 tracking-[0.32px] mb-4">
        Palm Trove
      </h3>
      <p className="mb-4 leading-7 tracking-[0.16px] font-Lato font-normal text-base">
        Palm Trove is quite literally a trove of palms and makes for a tranquil
        retreat amidst lush greenery and scenic views. This sprawling villa with
        modern amenities is located a short distance from Awas Beach. Relax on
        the lawn, enjoy the trees, sip a cocktail, or lounge by the pool.
      </p>
      <div
        style={{
          backgroundImage: 'url("https://via.placeholder.com/856x540")',
        }}
        className="bg-cover bg-center bg-[#D9D9D9] w-full max-w-[856px] aspect-[856/540] mb-10"
      ></div>

      <h4 className="text-2xl mb-6 font-semibold pt-10 font-Lato leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In Alibaug
      </h4>

      <ul className="list-disc pl-5  leading-6 tracking-[0.16px] text-[#434141]">
        <li>Expansive, L-shaped pool for swimming and sunbathing</li>
        <li>
          Serene, verdant lawn for outdoor relaxation, perfect for your pets to
          relax and revel in Games room for indoor entertainment{" "}
        </li>
        <li>Stylish, modern interiors with up-to-date amenities</li>
        <li>Distinctive exteriors with unique architectural elements</li>
        <li>Convenient location in close proximity to the beach</li>
      </ul>

      <h4 className="text-2xl mb-6 font-semibold pt-10 font-Lato leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In Alibaug
      </h4>

      <ul className="list-disc pl-5  leading-6 tracking-[0.16px] text-[#434141]">
        <li>Expansive, L-shaped pool for swimming and sunbathing</li>
        <li>
          Serene, verdant lawn for outdoor relaxation, perfect for your pets to
          relax and revel in Games room for indoor entertainment{" "}
        </li>
        <li>Stylish, modern interiors with up-to-date amenities</li>
        <li>Distinctive exteriors with unique architectural elements</li>
        <li>Convenient location in close proximity to the beach</li>
      </ul>

      <h4 className="text-2xl mb-6 font-semibold pt-10 font-Lato leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In Alibaug
      </h4>

      <ul className="list-disc pl-5  leading-6 tracking-[0.16px] text-[#434141]">
        <li>Expansive, L-shaped pool for swimming and sunbathing</li>
        <li>
          Serene, verdant lawn for outdoor relaxation, perfect for your pets to
          relax and revel in Games room for indoor entertainment{" "}
        </li>
        <li>Stylish, modern interiors with up-to-date amenities</li>
        <li>Distinctive exteriors with unique architectural elements</li>
        <li>Convenient location in close proximity to the beach</li>
      </ul>
    </div>
  );
};

export default BlogSec;
