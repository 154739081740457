import React from "react";
import scroll_left from "../../../assets/images/PropertyIPageId8/shere.webp";

const AmenitiesContent = ({ amenitiesData }) => {
  return (
    <div className="p-5">
      <div className="text-lg font-bold mb-4">General Amenities</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-y-auto">
        {amenitiesData?.map((item, i) => (
          <div key={i} className="flex items-center gap-3">
            <img src={scroll_left} alt="icon" className="w-6 h-6" />
            <p className="text-sm sm:text-base">{item}</p>
          </div>
        ))}
      </div>

      {/* Additional sections */}
      <div className="mt-6">
        <h3 className="text-lg font-bold">Food and Drinks</h3>
        <div className="flex items-center gap-3 mt-4">
          <img src={scroll_left} alt="icon" className="w-6 h-6" />
          <p className="text-sm sm:text-base">
            Meals available at extra charges
          </p>
        </div>
        <div className="flex items-center gap-3 mt-4">
          <img src={scroll_left} alt="icon" className="w-6 h-6" />
          <p className="text-sm sm:text-base">
            Drinks available at extra charges
          </p>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-bold">Facilities</h3>
        <ul className="mt-4 space-y-2">
          {amenitiesData?.map((item, i) => (
            <li key={i} className="flex items-center gap-3">
              <span className="w-3 h-3 bg-black rounded-full"></span>
              <p className="text-sm sm:text-base">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AmenitiesContent;
