import React from "react";
import AboutEvents from "./AboutEvents";
import SidebarForm from "./SidebarForm";
import SubSec from "./SubSec";
import { secData } from "./dummyData";

const BaseSubSec = () => {
  return (
    <div>
      <AboutEvents />
      {/* Sub Sections and Sidebar */}
      <div className="grid grid-cols-1 sm:grid-cols-[2fr,1fr]  mt-[114px] max-w-full sm:max-w-[1298px] w-full mx-auto gap-3">
        <div className="flex flex-col gap-6 sm:gap-[72px]">
          {secData?.map((item) => (
            <SubSec key={item.id} data={item} />
          ))}
        </div>
        <SidebarForm />
      </div>
    </div>
  );
};

export default BaseSubSec;
