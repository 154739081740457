import { useEffect, useState } from "react";
import close from "../../assets/svg/close.webp";
import logo from "../../assets/svg/logo.webp";
import LoginWithPhone from "../../pages/auth/LoginWithPhone";
import Otp from "../../pages/auth/Otp";
import { useSendOTPMutation } from "../../redux/features/auth/authApi";
import OtpModal from "../modals/auth/OtpModal";
const PhoneLoginModal = ({
  setLoginPhone,
  setIsLoginModalOpen,
  setIsRegisterModalOpen,
  setOpacity,
}) => {
  const [otpShow, setOtpShow] = useState(false);
  // useEffect(() => {
  //   console.log(otpShow);
  // }, [otpShow]);
  const [
    loginPhoneInfo,
    { data, responseData, isLoading, isError, isSuccess, error },
  ] = useSendOTPMutation();
  const [phone, setPhone] = useState("+91");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

 const handleWithPhone = async (e) => {
  //if (!modalOtp) e.preventDefault();

  if (phone?.length < 10) {
    console.log("Please Enter a Valid Phone Number");
    setIsPhoneValid(false); 
    return;
  }

  console.log("Attempting to call API with phone number:", phone);

  try {
    const res = await loginPhoneInfo({ phone: phone });
    console.log("OTP sent response:", res);  
    if (res?.success) {
      console.log("otp sent successfully")
      setOtpShow(true);
    } else {
      console.log("OTP not sent. Response: ", res);
    }
  } catch (error) {
    console.error("Error logging in:", error);
  }
};

  const [modalOtp, setModalOtp] = useState(false);
  return (
    <div className="fixed flex justify-center items-center top-0  left-0 right-0 w-full h-screen overflow-scroll overflow-x-hidden bg-black/70 z-50">
      {otpShow === false && (
        <div
          className={` my-20 w-[532px] mx-auto  max-h-[670px] h-fit bg-white  rounded-lg absolute`}
        >
          <div className="flex justify-center relative">
            <img src={logo} className="w-[48px] my-6 h-[48px]" alt="" />
            <button
              onClick={() => {
                setIsRegisterModalOpen(false);
                setIsLoginModalOpen(false);
                setLoginPhone(false);
              }}
              className="w-[24px] h-[24px] absolute right-[24px] top-[32px]"
            >
              <img src={close} alt="" />
            </button>
          </div>
          <div className="w-full bg-gray-300 h-px"></div>
          <LoginWithPhone
            setLoginPhone={setLoginPhone}
            // SmallOtpShow={SmallOtpShow}
            setIsRegisterModalOpen={setIsRegisterModalOpen}
            setPhoneN={setPhone}
            setNewOtpShow={setOtpShow}
            NewOtpShow={otpShow}
            setOpacity={setOpacity}
            // setSmallPhoneLogin={setSmallPhoneLogin}
            setIsLoginModalOpen={setIsLoginModalOpen}
            onclick={() => {
              setIsLoginModalOpen(true);
              setLoginPhone(false);
            }}
          />
        </div>
      )}
      {otpShow === true && (
        <OtpModal onclick={() => setOtpShow(false)}>
          <Otp
            setModalOtp={setModalOtp}
            phone={phone}
            setNewOtpShow={setOtpShow}
            setIsRegisterModalOpen={setIsRegisterModalOpen}
            handleWithPhone={handleWithPhone}
          />
        </OtpModal>
      )}
    </div>
  );
};

export default PhoneLoginModal;
