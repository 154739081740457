import { useState } from "react";
import { Link } from "react-router-dom";
import heartIcon from "../../assets/svg/heart-red.webp";
import xIcon from "../../assets/svg/x.webp";
import WishListDeleteModal from "../../components/modals/WishListDeleteModal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const WishlistCard = ({ propertyData }) => {
  const [isDeleteWishlist, setIsDeleteWishlist] = useState(false);
  const [loading, setLoading] = useState(false); // Adjusted to boolean

  /** Handler Function for removing wishlist card */
  const handleRemoveWishlist = () => {
    setLoading(true); // Set loading state to true
    setTimeout(() => {
      setLoading(false); // Simulate completion
      setIsDeleteWishlist(true);
    }, 2000); // Simulate 2-second delay
  };

  const { imageUrls, _id } = propertyData;

  return (
    <>
      <div className="flex flex-col items-start mt-5">
        {/* Location above the card */}
        <p className="text-[18px] text-base leading-9 font-butler font-normal text-[#000] mb-[8px]">
          {propertyData?.city || "Unknown Location"}
        </p>

        {/* Card container */}
        <div className="2xl:w-[370px] md:w-[330px] xl:w-[419px] lg:w-[419px] 2xl:h-[419px] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-none rounded-br-none rounded-sm shadow-md mx-auto shadow-slate-200">
          {/* Card banner image */}
          <div className="relative cursor-default hover:cursor-pointer">
            <Link to={`/propertyDetails/${_id}`}>
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                autoplay
                slidesPerView={1}
                className="h-[256px] w-[370px] 2xl:w-[370px] md:w-[330px] xl:w-[350px] lg:w-[300px]"
              >
                {imageUrls?.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      loading="lazy"
                      className="w-full object-cover h-full"
                      src={
                        img ||
                        "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                      }
                      alt="property"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Link>

            {/* Remove button */}
            <button
              onClick={handleRemoveWishlist}
              className="w-[32px] z-10 h-[32px] absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] p-[5px] lg:pb-7 rounded-full"
              disabled={loading} // Disable button during loading
            >
              {loading ? (
                <div
                  className="animate-spin rounded-full border-2 border-t-transparent w-[20px] h-[20px]"
                  style={{
                    borderColor: "#ccc",
                    borderTopColor: "transparent",
                  }}
                ></div>
              ) : (
                <img
                  src={xIcon}
                  alt="Remove Wishlist"
                  data-twe-toggle="tooltip"
                  title="Remove Wishlist"
                />
              )}
            </button>

            <img
              className="absolute z-10 top-[16px] right-[16px] cursor-pointer"
              src={heartIcon}
              alt="icon"
              data-twe-toggle="tooltip"
              title="Favorite"
            />
          </div>

          {/* Card info */}
          <div className="px-[16px] py-[14px] rounded-[4px] h-[160px]">
            <div className="flex flex-col gap-[6px]">
              <p className="text-[18px] leading-[27px] tracking-[0.01em] font-butler font-normal text-[#000]">
                {propertyData?.name}
              </p>
              <span className="text-[#6D747A] font-Lato font-medium tracking-[0.01em] leading-[21px] text-[14px]">
                {propertyData?.address?.slice(0, 26)}, {propertyData?.city}
              </span>
              <span className="text-[#434141] font-medium font-Lato tracking-[0.01em] leading-[21px] text-[14px]">
                {propertyData?.maxGuests} Guests | {propertyData?.noOfBedrooms}{" "}
                Bedrooms | {propertyData?.noOfBathrooms} Bathrooms
              </span>
            </div>

            <div className="mt-[12px]">
              <p className="text-[#6D747A] cursor-text font-Lato font-medium tracking-[0.01em] leading-[21px] text-[14px]">
                Starting from
              </p>
              <p className="text-[#000000] cursor-text text-[18px] font-Lato leading-[27px] tracking-[0.01em] font-semibold mt-[2px]">
                ₹ {propertyData?.pricePerNight}
                <span className="text-[#434141] cursor-text font-Lato leading-[24px] font-normal tracking-[0.01em] text-[16px]">
                  / night + Taxes
                </span>
              </p>
            </div>
          </div>
          {/* Card info */}
        </div>
      </div>

      {isDeleteWishlist ? (
        <WishListDeleteModal
          id={propertyData?._id}
          setIsDeleteWishlist={setIsDeleteWishlist}
        />
      ) : null}
    </>
  );
};

export default WishlistCard;
