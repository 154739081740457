import React, { useState } from "react";
import Container from "../../ui/Container";
import SearchBar from "./SearchBar";
import Slider from "./Slider";
// import PropertySlider from "./Slider/PropertySlider";

const Banner = ({ isHomeScreen, city }) => {
  const [mobileSearchBar, setMobileSearchBar] = useState(false);
  return (
    <div>
      <div className="lg:relative">
        <Slider city={city} isHomeScreen={isHomeScreen} />
        {/* <PropertySlider /> */}
        <div
          className={`${
            window.location.pathname === "/allproperties"
              ? "max-xl:hidden block"
              : ""
          } w-full`}
        >
          <div className="xl:max-w-[1296px] w-full flex items-center justify-center mx-auto">
            <div
              className={`${
                window.screen.width === 1280 && "scale-95"
              } lg:absolute ${
                mobileSearchBar ? "z-50" : "z-40"
              } w-full bottom-0 top-[79%]  lg:z-40`}
            >
              <SearchBar
                mobileSearchBar={mobileSearchBar}
                setMobileSearchBar={setMobileSearchBar}
                setOpenSearchBar={null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
