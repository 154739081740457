import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import moreIcon from "../../assets/svg/more-vertical.webp";
import starIcon from "../../assets/svg/start-icon.webp";
import ReviewModal from "../../components/modals/ReviewModal";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { BaseURL } from "../../redux/config";
import {
  useCancelBookingMutation,
  useGetAllBookingsQuery,
} from "../../redux/features/userDashboard/userDashboardApi";
import ReviewModalContent from "./ReviewModalContent";
import CancellationModalContent from "./CancellationModalContent";
const MyBooking = () => {
  const token = useTokenFromLocalStorage();
  const [moreIconData, setMoreIconData] = useState(false);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [isReview, setIsReview] = useState(false);
  const [hotelId, setHotelId] = useState("");

  const [showRealMomentsModal, setShowRealMomentsModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setimage] = useState(null);
  

  const { data: bookings } = useGetAllBookingsQuery(token);

  const [cancelBooking] = useCancelBookingMutation();

  const upCommingBookings = bookings?.upcomingBookings;

  const completedBookings = bookings?.completedBookings;

  const [isCancelBooking, setIsCancelBooking] = useState(false); // State to control modal visibility
  const [bookingDetails, setBookingDetails] = useState({});

  useEffect(() => {
    fetch(BaseURL + `/auth/bookings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("bookings data",data);
        console.log("hotelId",data?.completedBookings[0]?.hotelId);
        setHotelId(data?.completedBookings[0]?.hotelId);
        setBookingDetails(data?.completedBookings)
      });
  }, []);
  // const handleWriteReview = (booking) => {
  //   setHotelId(booking?.hotel?._id);
  //   setIsReview(true);
  // };

  // Real moments
  const handleRealMomentSubmit = async () => {
    // console.log("hotel id is ",hotelId);
    console.log("image",image);
    if (!image || !firstName || !lastName) {
      alert("Please fill in all fields");
      return;
    }
  
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("image", image);
  
    try {
      const response = await fetch(`https://api.ukiyostays.com/api/realMoments/create?hotelId=${hotelId}`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      console.log("Result : ",result); 
      if (response.ok) {
        alert("Real moment submitted successfully!");
        setShowRealMomentsModal(false);
        setFirstName("");
        setLastName("");
        setimage(null);
      } else {
        alert("Failed to submit the real moment. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting real moment:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlemoreIcon = () => {
    setMoreIconData(!moreIconData);
  };

  // const handleCancelBooking = (token, id) => {
  // };
  const handleCancelBooking = async (token, id) => {
    setIsCancelBooking(true)
    // try {
    //   if (!token || !id) {
    //     throw new Error("Invalid token or booking ID");
    //   }
    //   console.log("Canceling booking with ID:", id);
    //   console.log("Using token:", token);
  
    //   const response = await cancelBooking({ token, id }).unwrap();
  
    //   console.log("Booking canceled successfully:", response);
    //   alert("Booking canceled successfully!");
    // } catch (error) {
    //   console.error("Error canceling booking:", error);
    //   alert(`Failed to cancel booking: ${error?.data?.message || error.message}`);
    // }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  //const navigate = useNavigate();
  return (
    <div className="  w-screen max-lg:px-5 md:w-[600px]">
      <div className="flex-auto flex items-center gap-x-4 max-md:text-lg font-butler text-[32px] font-normal tracking-wide my-4">
        {/* <button onClick={() => navigate(-1)} className="w-7 ">
          <img  src={leftArrow} alt="" />
        </button>{" "} */}
        My Bookings
      </div>
      <hr className="lg:hidden" />
      {/* tab menu */}
      <div className="flex items-center gap-[48px] mt-[24px]">
        <div>
          <button
            className={`text-[20px] text-center max-md:text-base font-[700] ${
              activeTab === "upcoming" ? "text-[#08090A]" : ""
            }`}
            onClick={() => handleTabClick("upcoming")}
          >
            Upcoming
          </button>
          <div
            className={`max-md:w-[80px] w-[95px] h-[3px] border-b-4 ${
              activeTab === "upcoming"
                ? "border-[#08090A]"
                : "border-transparent"
            } rounded-full mt-[6px]`}
          ></div>
        </div>
        <div>
          <button
            className={`text-[20px]  max-md:text-base font-Lato text-center font-[700] ${
              activeTab === "completed" ? "text-[#08090A]" : ""
            }`}
            onClick={() => handleTabClick("completed")}
          >
            Completed
          </button>

          <div
            className={`w-[95px] max-md:w-[80px] h-[3px] border-b-4 ${
              activeTab === "completed"
                ? "border-[#08090A]"
                : "border-transparent"
            } rounded-full mt-[6px]`}
          ></div>
        </div>
      </div>

      <div className="h-[1px] w-full bg-[#EAEFF2]"></div>

      {activeTab === "upcoming" ? (
        <div>
          {upCommingBookings?.length > 0 ? (
            upCommingBookings?.map((booking) => (
              <div
                key={booking?._id}
                className="flex flex-col w-[380px] md:w-[600px] max-w-[855px] max-md:w-[600px] mt-[32px] font-Lato"
              >
               {/* <p className="overflow-scroll">
                      Booking id is : "{booking?._id} <br />
                      Token is : {token} 
                      </p> */}
                <div className="flex z-10 flex-col p-6 max-md:py-2  w-full bg-white rounded border border-solid border-[color:var(--Card-Stroke,#EAEFF2)] max-md:px-2 max-md:max-w-full">
                  <div className="justify-between max-md:max-w-full">
                    <div className="flex gap-5  max-md:flex-col max-md:gap-0">
                      <div className="flex max-md:gap-x-2 flex-col w-[83%]  max-md:ml-0 max-md:w-full">
                        <div className="grow  max-md:max-w-full">
                          <div className="flex   max-md:gap-x-2">
                            <div className="flex max-sm:w-[100px] max-sm:h-[70px] gap-0 max-md:flex-col max-md:gap-0 ">
                              <img
                                alt="pic1"
                                src={booking?.hotel?.imageUrls[0]}
                                className="grow object-cover max-w-full  w-[111px] max-md:mt-0 rounded-md"
                              />
                            </div>

                            <div className="flex flex-col ml-5 w-[72%] max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col self-stretch my-auto text-base font-medium tracking-normal leading-6 text-neutral-700 max-md:mt-0">
                                <div className="flex gap-6 align-middle ">
                                  <h1 className="text-[24px] tracking-wider text-[black] max-md:flex font-[Butler] max-md:text-base w-fit font-[500]">
                                    {booking?.hotel?.name}{" "}
                                  </h1>
                                  <span className="flex flex-col cursor-pointer   max-md:ml-0 max-md:w-full max-md:hidden">
                                    <spa className="text-center px-2 py-1 font-Lato font-[400] text-[12px]  w-full  tracking-normal leading-5 whitespace-nowrap rounded border border-solid border-[color:var(--outline-button,#CED4DA)] text-zinc-950 ">
                                      {booking?.bookingStatus}
                                    </spa>
                                  </span>
                                </div>

                                <div className="flex gap-1 justify-between mt-2 whitespace-nowrap">
                                  <img
                                    alt="pic2"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccab394ecfe9cea1fccee2651bf0ac6f9205232ce28ac12686e51c25637dac79?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                    className="w-[25px] h-[26px] aspect-square max-md:w-4"
                                  />
                                  <p className="grow max-md:text-xs font-Lato">
                                    <span>{booking?.hotel?.address},</span> {""}
                                    <span>{booking?.hotel?.city}</span>
                                  </p>
                                </div>
                                <div className="flex gap-5 max-md:gap-2.5 max-md:mt-1 mt-6 whitespace-nowrap">
                                  {/* checkin checkout */}
                                  <div className="flex items-center gap-2 justify-between">
                                    <img
                                      alt="pic1"
                                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1cc22d7601495b2846aaa6956f32fe65e424b5a1044d2bcbfff35228dfb76b6?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                      className="w-6 h-6 max-md:w-4 aspect-square"
                                    />
                                    <div className="grow font-Lato max-md:text-xs">
                                      <span>
                                        {format(booking?.checkIn, "MMMdd")}
                                      </span>{" "}
                                      -{" "}
                                      <span>
                                        {format(booking?.checkOut, "MMMdd")}
                                      </span>
                                    </div>
                                  </div>
                                  {/* user count */}
                                  <div className="flex max-md:text-xs font-Lato gap-2 justify-between items-center">
                                    <img
                                      alt="pic1"
                                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dadc03b13637a6f5ce5dff000acc33c87d77de76bb2070bbe548de8e119130d?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                      className="w-6 h-6 max-md:w-4 aspect-square"
                                    />
                                    <span>
                                      {booking?.noOfAdults +
                                        booking?.noOfChildren +
                                        booking?.noOfRooms}
                                    </span>
                                  </div>
                                  {/* smaller device ratings */}
                                  <div className="max-md:flex items-center whitespace-nowrap hidden  text-sm max-md:text-[10px] text-zinc-950 ">
                                    <img
                                      alt="pic1"
                                      src={starIcon}
                                      className="max-md:w-4"
                                    />
                                    <span className="pl-1 font-Lato">
                                      {booking?.hotel?.rating} (
                                      {booking?.hotel?.reviews?.length})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex max-md:hidden flex-col font-Lato ml-5 max-md:ml-0">
                        <div className="flex items-center whitespace-nowrap text-sm text-zinc-950 ">
                          <img src={starIcon} alt="pic1" />
                          <span className="pl-1">
                            {booking?.hotel?.rating} (
                            {booking?.hotel?.reviews?.length} reviews)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-5 text-[#EBEDF0]" />
                  <div className="flex max-md:flex-col font-Lato max-md:items-start gap-5 justify-between w-full max-md:mt-3 mt-12 text-base max-md:flex-wrap  max-md:max-w-full">
                    <div className="flex-auto my-auto tracking-normal font-[400]  text-black w-full leading-[150%]">
                      Amount Paid:{" "}
                      <span className="font-semibold ">
                        ₹{booking?.totalAmount}
                      </span>
                    </div>
                    <div className="flex gap-x-4 justify-end w-full">
                      <button
                        onClick={() => handleCancelBooking(token, booking?._id)}
                        className="justify-center px-6 py-4 font-bold tracking-normal max-md:px-0 text-black whitespace-nowrap rounded border text-center border-[#08090A] leading-[100%] max-md:w-full cursor-pointer"
                      >
                        Cancel Booking
                      </button>
                     
                      {/* <p className="overflow-scroll">
                      Booking id is : "{booking?._id} <br />
                      Token is : {token} 
                      </p> */}
                      <Link
                        className="justify-center px-6 py-4 max-md:px-0 max-md:w-full font-bold tracking-normal text-white whitespace-nowrap max-md:text-center rounded bg-zinc-950 leading-[100%]  cursor-pointer"
                        to={`/propertyDetails/${booking?.hotel?._id}`}
                      >
                        View Details
                      </Link>
                    </div>
                    
                  </div>
                </div>
                {isCancelBooking && (
                      <CancellationModalContent
                        setIsCancelBooking={setIsCancelBooking} // Pass function to close modal
                        booking={upCommingBookings} // Pass booking data to the modal
                        token={token} // Pass token to the modal
                      />
                  )}
                <div className="justify-center text-center items-center px-16 py-2  pb-2 w-full text-[18px] max-md:text-sm max-md:pt-0 max-md:py-1.5 tracking-normal leading-6 text-[#000000] font-[500] whitespace-nowrap max-md:rounded-t-none bg-sky-100 rounded max-md:px-5 max-md:max-w-full">
                  Great choice, hope you enjoy
                </div>
              </div>
            ))
          ) : (
            <div>
              <div className="flex items-center font-Lato flex-col justify-center mt-[200px]">
                <h1 className="text-[#08090A] text-[20px] font-[600]">
                  No Upcoming booking yet
                </h1>
                <Link to="/">
                  <button className="w-[200px] bg-[#08090A] px-[24px] py-[16px] text-white rounded-[4px] mt-[24px]">
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        completedBookings?.map((booking) => (
          <div
            key={booking?._id}
            className="flex flex-col max-w-[855px] mt-[32px]"
          >
            <div className="flex z-10 flex-col p-6 max-md:py-2 w-full bg-white rounded border border-solid border-[color:var(--Card-Stroke,#EAEFF2)] max-md:px-2 max-md:max-w-full">
              <div className="justify-between max-md:max-w-full">
                <div className="flex gap-5  max-md:flex-col max-md:gap-0">
                  <div className="flex max-md:gap-x-2 flex-col w-[83%]  max-md:ml-0 max-md:w-full">
                    <div className="grow  max-md:max-w-full">
                      <div className="flex   max-md:gap-x-2">
                        <div className="flex max-sm:w-[100px] max-sm:h-[70px] gap-0 max-md:flex-col max-md:gap-0 ">
                          <img
                            alt="pic1"
                            src={booking?.hotel?.imageUrls[0]}
                            className="grow object-cover max-w-full  w-[111px] max-md:mt-0 rounded-md"
                          />
                        </div>

                        <div className="flex flex-col ml-5 w-[72%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col self-stretch my-auto text-base font-medium tracking-normal leading-6 text-neutral-700 max-md:mt-0">
                            <div className="flex gap-6">
                              <h1 className="text-[24px] tracking-wider text-[black] max-md:flex font-[Butler] max-md:text-base w-full font-[500]">
                                {booking?.hotel?.name}{" "}
                                <div className="relative  justify-end hidden max-md:flex flex-grow">
                                  <div
                                    className="cursor-pointer"
                                    onClick={handlemoreIcon}
                                  >
                                    <img src={moreIcon} alt="pic1" />
                                  </div>
                                  {moreIconData && (
                                    <div
                                      name=""
                                      id=""
                                      className="w-[163px] h-[48px] shadow-md  absolute top-8 right-0 appearance-none  text-center flex items-center bg-white z-50 justify-center text-lg text-black font-[600]"
                                    >
                                      Request Invoice
                                    </div>
                                  )}
                                </div>
                              </h1>
                              <span className="flex flex-col ml-8  max-md:ml-0 max-md:w-full max-md:hidden">
                                <spa className="text-center px-2 py-1 font-[400] text-[15px]  w-full  tracking-normal leading-5 whitespace-nowrap rounded border border-solid border-[color:var(--outline-button,#CED4DA)] text-zinc-950 ">
                                  {booking?.bookingStatus}
                                </spa>
                              </span>
                            </div>

                            <div className="flex gap-1 justify-between mt-2 whitespace-nowrap">
                              <img
                                alt="pic1"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccab394ecfe9cea1fccee2651bf0ac6f9205232ce28ac12686e51c25637dac79?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                className="w-6 aspect-square max-md:w-4"
                              />
                              <p className="grow max-md:text-xs">
                                <span>{booking?.hotel?.address},</span> {""}
                                <span>{booking?.hotel?.city}</span>
                              </p>
                            </div>
                            <div className="flex gap-5 max-md:gap-2.5 max-md:mt-1 mt-6 whitespace-nowrap">
                              {/* checkin checkout */}
                              <div className="flex items-center gap-2 justify-between">
                                <img
                                  alt="pic1"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1cc22d7601495b2846aaa6956f32fe65e424b5a1044d2bcbfff35228dfb76b6?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                  className="w-6 max-md:w-4 aspect-square"
                                />
                                <div className="grow max-md:text-xs">
                                  <span>
                                    {format(booking?.checkIn, "MMMdd")}
                                  </span>{" "}
                                  - {format(booking?.checkOut, "MMMdd")}
                                </div>
                              </div>
                              {/* user count */}
                              <div className="flex max-md:text-xs gap-2 justify-between items-center">
                                <img
                                  alt="pic1"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dadc03b13637a6f5ce5dff000acc33c87d77de76bb2070bbe548de8e119130d?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                  className="w-6 max-md:w-4 aspect-square"
                                />
                                <span>
                                  {booking?.noOfAdults +
                                    booking?.noOfChildren +
                                    booking?.noOfRooms}
                                </span>
                              </div>
                              {/* smaller device ratings */}
                              <div className="max-md:flex items-center whitespace-nowrap hidden  text-sm max-md:text-[10px] text-zinc-950 ">
                                <img
                                  src={starIcon}
                                  className="max-md:w-4"
                                  alt="pic1"
                                />
                                <span className="pl-1">
                                  {booking?.hotel?.rating} (
                                  {booking?.hotel?.reviews?.length})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex max-md:hidden flex-col ml-5 max-md:ml-0">
                    <div className="flex items-center whitespace-nowrap text-sm text-zinc-950 ">
                      <img src={starIcon} alt="pic1" />
                      <span className="pl-1">
                        {booking?.hotel?.rating} (
                        {booking?.hotel?.reviews?.length} reviews)
                      </span>
                    </div>
                  </div>

                  <div className="relative cursor-pointer max-md:hidden">
                    <div className="" onClick={handlemoreIcon}>
                      <img src={moreIcon} alt="pic1" />
                    </div>
                    {moreIconData && (
                      <div
                        name=""
                        id=""
                        className="w-[163px] flex items-center justify-center h-[48px] shadow-md z-50 bg-white absolute top-8 right-0 appearance-none  text-center text-lg text-black font-[600]"
                      >
                        Request Invoice
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-5 text-[#EBEDF0]" />
              <div className="flex max-md:flex-col max-md:items-start gap-5 justify-between w-full max-md:mt-3 mt-12 text-base max-md:flex-wrap  max-md:max-w-full">
                <div className="flex-auto my-auto tracking-normal font-[400]  text-black w-full leading-[150%]">
                  Amount Paid:{" "}
                  <span className="font-semibold ">
                    ₹{booking?.totalAmount}
                  </span>
                </div>
                <div className="flex gap-x-4 justify-end w-full">
                  <div
                    onClick={() => {
                      console.log("hotell idd: ", booking?.hotel._id);
                      setHotelId(booking?.hotel?._id);
                      setIsReview(true);
                    }}
                    className="justify-center px-6 py-4 max-md:px-0 max-md:w-full font-bold tracking-normal text-white whitespace-nowrap max-md:text-center rounded bg-zinc-950 leading-[100%]  cursor-pointer"
                  >
                    Write Review
                  </div>
                  <Link
                    className="justify-center px-6 py-4 font-bold tracking-normal max-md:px-0 text-black whitespace-nowrap rounded border text-center border-[#08090A] leading-[100%] max-md:w-full cursor-pointer"
                    to={`/propertyDetails/${booking?.hotel?._id}`}
                  >
                    View Details
                  </Link>
                  <div
                    onClick={() => setShowRealMomentsModal(true)}
                    className="justify-center px-6 py-4 font-bold tracking-normal max-md:px-0 text-white whitespace-nowrap rounded border text-center border-[#08090A] bg-zinc-950 leading-[100%] max-md:w-full cursor-pointer"
                  >
                    Real Moments
                  </div>
                 

                   {/* Modal for Real Moments */}
                    {showRealMomentsModal && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg p-6 w-96">
                          <h2 className="text-lg font-bold mb-4">Share a Real Moment</h2>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleRealMomentSubmit();
                            }}
                          >
                            <div className="mb-4">
                              <label className="block font-semibold mb-1">First Name</label>
                              <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="w-full border border-gray-300 rounded px-3 py-2"
                                placeholder="Enter First Name"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block font-semibold mb-1">Last Name</label>
                              <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="w-full border border-gray-300 rounded px-3 py-2"
                                placeholder="Enter Last Name"
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block font-semibold mb-1">Moment Image</label>
                              <input
                                type="file"
                                onChange={(e) => setimage(e.target.files[0])}
                                className="w-full border border-gray-300 rounded px-3 py-2"
                                accept="image/*"
                                required
                              />
                            </div>
                            <div className="flex gap-4 justify-end">
                              <button
                                type="button"
                                onClick={() => setShowRealMomentsModal(false)}
                                className="px-4 w-full py-3 bg-gray-300 rounded"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="px-4 w-full py-3 bg-zinc-950 text-white rounded"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="justify-center text-center items-center px-16 pt-4 pb-1 w-full text-[18px] max-md:text-sm max-md:pt-0 max-md:py-1.5 tracking-normal leading-6 text-[#000000] font-[500] whitespace-nowrap max-md:rounded-t-none bg-sky-100 rounded max-md:px-5 max-md:max-w-full">
              Great choice, hope you enjoy
            </div>
          </div>
        ))
      )}
      

      {isReview && (
        <ReviewModal
          children={
            <ReviewModalContent
              token={token}
              setIsReview={setIsReview}
              hotelId={hotelId}
            />
          }
          onclick={setIsReview}
        />
      )}
      
    </div>
    // <AllPayments className="w-100"/>
    
  );
};

export default MyBooking;
