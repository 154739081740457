import people4 from "../../../assets/svg/People1.webp";
import people3 from "../../../assets/svg/People2.webp";
import people2 from "../../../assets/svg/People3.webp";
import people1 from "../../../assets/svg/People4.webp";
import WhatPeopleThinkAboutUsCard from "./WhatPeopleThinkAboutUsCard";
const WhatPeopleThinkAboutUs = () => {
  const CardData = [
    {
      image: people1,
      name: "Dharmil Sheth",
      position: "Co-Founder of PharmEasy",
      comment:
        "Fantastic stay at Ukiyo - 485, seamless and welcoming experience, definitely staying again!",
    },
    {
      image: people2,
      name: "Rajkumar Rao",
      position: "Bollywood Actor",
      comment:
        "Highly recommend Ukiyo Stays for an exceptional stay at the stunning Ukiyo-209 with great hospitality and cleanliness.",
    },
    {
      image: people3,
      name: "Huma Qureshi",
      position: "Bollywood Actress",
      comment:
        "Great stay at Ukiyo-183 with exceptional staff, well-organized management, and beautiful amenities.",
    },
    {
      image: people4,
      name: "Allu Arjun",
      position: "Bollywood Actor",
      comment:
        "Great stay at Ukiyo-693 with amazing community, and a seamless booking experience with an always available sales team managing all requirements.",
    },
  ];
  return (
    <div className="mb-[146px] sm:mt-24 mt-40 mx-3 sm:mx-0">
      <div className="text-zinc-950 text-3xl sm:text-4xl font-normal font-butler mb-[56px] text-center leading-[54px] tracking-tight">
        What people say about us
      </div>
      <div className="grid sm:grid-cols-2 h-[380px] gap-x-6 gap-y-[56px] grid-cols-1">
        {CardData?.map((item, index) => (
          <WhatPeopleThinkAboutUsCard data={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default WhatPeopleThinkAboutUs;
