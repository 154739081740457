import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GetInTouch from "./getInTouch";
import PrivacyPolicy from "./privacyPolicy";
import TermsAndConditions from "./termsAndConditions";

const PrivacyPolicyPage = () => {
  const location = useLocation();
  const activeNum = location?.state?.active;
  console.log(location);
  const [activeTab, setActiveTab] = useState(activeNum || 1);

  useEffect(() => {
    if (location.pathname === "/termsAndConditions") {
      setActiveTab(1);
    } else if (location.pathname === "/privacyPolicy") {
      setActiveTab(2);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (activeNum !== undefined) {
      console.log("Setting activeTab from location.state.active:", activeNum);
      setActiveTab(activeNum);
    }
  }, [activeNum]);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <div className="w-full h-[224px] bg-[#E3E3E3] flex justify-center items-center">
        <div className="text-center scale-y-[8px]">
          {activeTab === 1 ? (
            <h1 className="text-[#08090A] text-4xl lg:text-[56px] font-butler">
              Terms and Conditions
            </h1>
          ) : (
            <h1 className="text-[#08090A] text-4xl lg:text-[56px] font-butler">
              Privacy Policy
            </h1>
          )}
          <p className="text-[16px] font-[600] text-[#08090A]">
            Last Modified: 1 March 2023
          </p>
        </div>
      </div>

      <section>
        <div className="flex gap-4 px-10 mt-5">
          <div className="relative mr-2 lg:mr-10">
            <button
              className={`focus:outline-none font-lato text-base lg:text-lg ${
                activeTab === 1 ? "font-medium text-black" : "text-[#434141]"
              }`}
              onClick={() => handleTabClick(1)}
            >
              Terms and Conditions
            </button>
            {activeTab === 1 && (
              <div className="absolute bg-black h-1 w-full rounded-lg transition-transform duration-300 ease-in-out transform"></div>
            )}
          </div>
          <div className="relative mr-10">
            <button
              className={`focus:outline-none font-lato text-lg ${
                activeTab === 2 ? "font-medium text-black" : "text-[#434141]"
              }`}
              onClick={() => handleTabClick(2)}
            >
              Privacy Policy
            </button>
            {activeTab === 2 && (
              <div className="absolute bg-black h-1 w-full rounded-lg transition-transform duration-300 ease-in-out transform"></div>
            )}
          </div>
        </div>
        <hr className="relative h-1 mt-1 w-full" />

        <article className="my-10 px-10 flex flex-col md:flex-row">
          <section className="flex w-full md:w-9/12 mb-8 md:mb-0">
            {activeTab === 1 ? <TermsAndConditions /> : <PrivacyPolicy />}
          </section>
          <section className="w-full md:w-3/12">
            <GetInTouch />
          </section>
        </article>
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
