import React from "react";
import { useNavigate } from "react-router-dom";
const BookingGuests = ({ adultsCount, chidrenCount }) => {
  const navigate = useNavigate();

  const navigateEditPage = () => {
    navigate(-1);
  };
  return (
    <div className="mt-10 mb-5 max-xl:px-6">
      {" "}
      <div className="flex justify-between items-center mb-5 max-xl:mb-2">
        <div
          className="text-zinc-950
text-2xl max-md:text-lg
font-normal
font-butler
leading-9
tracking-tight"
        >
          Guests
        </div>

        <p
          className="text-black cursor-pointer
text-lg
font-[500]
max-md:text-sm
underline-offset-2 uppercase
underline
leading-[27px]
tracking-tight"
          onClick={navigateEditPage}
        >
          Edit
        </p>
      </div>{" "}
      <hr className="hidden max-xl:block" />
      <p
        className="text-zinc-950
text-xl 
max-lg:text-base
font-medium
max-xl:mt-2
leading-[30px]
tracking-tight"
      >
        {adultsCount} Adults, {chidrenCount} Children
      </p>
      <hr className="mt-10 mb-12 bg-[#CED4DA] max-xl:hidden" />{" "}
    </div>
  );
};

export default BookingGuests;
