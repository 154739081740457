import React from "react";

const PrevNextBlog = (props) => {
  return (
    <div className="p-4 rounded-lg border-[1px] border-[#CED4DA]">
      <p className="mb-2 text-sm font-semibold font-Lato leading-5 tracking-[0.14px] text-[#6D747A]">
        {props.next ? "NEXT POST" : "PREVIOUS POST"}
      </p>
      <p className="font-butler text-lg font-normal leading-7 tracking-[0.18px]">
        Best Places to Visit in Monsoon 2023 – Luxury Villas Where Luxury and
        Beauty Combine
      </p>
    </div>
  );
};

export default PrevNextBlog;
