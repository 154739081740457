import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router-dom";
import arrowRight from "../../../assets/svg/arrowRight.webp";
import chevronup from "../../../assets/svg/chevronup.webp";
import cross from "../../../assets/svg/CrossGray.webp";
import callIcon from "../../../assets/svg/phone-call-black.webp";
import rightTick from "../../../assets/svg/rightTick.webp";
import { BaseURL } from "../../../redux/config";
import { useAuthUserQuery } from "../../../redux/features/auth/authApi";
import { InputField } from "../../inputField/InputField";
import ProfileDropDown from "../../Navbar/ProfileDropDown";
import BookingDatePicker from "./BookingDatePicker";
import { PickRooms } from "./BookingDropDown";
import ModalContent from "./ModalContent";
import QueryMadal from "../../modals/QueryModal";
import chatIcon from "../../../assets/svg/Chat Bubble.webp";

import PhoneInputSeparater from "../../PhoneInput/PhoneInput";
import "./phone.css";
import { FaMessage } from "react-icons/fa6";
const HotelBooking = ({ price, hotelId, token, tellme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [queryOpen, setQueryOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    residentialCity: "",
    phoneNumber: "",
    message: "",
  });
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCheckInDateEmpty, setIsCheckInDateEmpty] = useState(false);
  const [isCheckOutDateEmpty, setIsCheckOutDateEmpty] = useState(false);
  // const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [isResidentialValid, setIsResidentialValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [MessageInvalid, setMessageInvalid] = useState(false);
  const [queryCreated, setQueryCreated] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { data: authData } = useAuthUserQuery(token);

  // Distruturing auth user data
  const userPrefiled = authData?.user;
  console.log(userPrefiled);

  const hasSpecialCharacterBeforeAt = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex === -1) return false; // No @ symbol found
    const localPart = email.slice(0, atIndex);
    const specialCharRegex = /[^a-zA-Z0-9]/; // Regex for special characters
    return specialCharRegex.test(localPart);
  };
  const handleChange2 = (e) => {
    const { value } = e.target;
    setEmail(value);

    if (hasSpecialCharacterBeforeAt(value)) {
      setError('Email part before "@" should not contain special characters.');
    } else {
      setError("");
    }
  };
  const params = useParams().id;
  const handleChange = (e) => {
    handleChange2(e);
    if (e && !e.target) {
      console.log("event : ", e);
      setFormData({
        phoneNumber: e,
      });
    }
    if (e.target && e.target.name) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "residentialCity") {
      const validationError = validateField(e.target.name, e.target.value);
      if (e.target.value === "") {
        setIsResidentialValid(false);
        setErrorMessage("Please enter Residential City.");
      } else if (validationError) {
        setIsResidentialValid(false);
        setErrorMessage(validationError);
      } else {
        setIsResidentialValid(true);
        setErrorMessage("");
      }
    }
  };

  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
  ];
  const [BookingModalContent, setBookingModalContent] = useState(true);
  const [isBookNowClicked, setIsBookNowClicked] = useState(false);

  const BookingModalOpen = () => {
    setBookingModalContent(true);
    console.log("open");
  };
  const handleToggle = () => {
    console.log("Recommended");
    setIsOpen(!isOpen);
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
      case "residentialCity":
        if (/\d/.test(value)) {
          return "Numeric characters are not allowed.";
        }
        if (/[^a-zA-Z\s]/.test(value)) {
          return "Special characters and symbols are not allowed.";
        }
        return "";
      case "message":
        return /^[a-zA-Z\s]*$/.test(value);
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      case "phoneNumber":
        const phoneNumber = value.replace(/\D/g, "");
        return phoneNumber.length > 11;
      default:
        return true;
    }
  };
  const [shownameserror, setNameError] = useState(false);
  const [validateCheckinDate, setValidateCheckinDate] = useState(true);
  const [validateCheckOutDate, setvalidateCheckOutDate] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkInDate === null) {
      setValidateCheckinDate(false);
    }
    if (checkOutDate === null) {
      setvalidateCheckOutDate(false);
    }
    if (formData.message.length === 0) {
      console.log("hi");
      setMessageInvalid(true);
    }
    const validations = {
      firstName: validateField("firstName", formData.firstName),
      lastName: validateField("lastName", formData.lastName),
      email: validateField("email", formData.email),
      phoneNumber: validateField("phoneNumber", formData.phoneNumber),
      residentialCity: validateField(
        "residentialCity",
        formData.residentialCity
      ),
      message: validateField("message", formData.message),
    };
    Object.entries(validations).forEach(([fieldName, isValid]) => {
      switch (fieldName) {
        case "firstName":
          setIsFirstNameValid(isValid);
          break;
        case "lastName":
          setIsLastNameValid(isValid);
          break;
        case "email":
          setIsEmailValid(isValid);
          break;
        case "phoneNumber":
          setIsPhoneNumberValid(isValid);
          break;
        case "residentialCity":
          setIsResidentialValid(isValid);
          break;
        case "message":
          setIsMessageValid(isValid);
          break;
        default:
          break;
      }
    });
    const isFormValid = Object.values(validations).every((isValid) => isValid);

    if (!isFormValid) {
      // Form is invalid
      return;
    }
    handleEnquireNow();
  };
  const cityRegex = /^[A-Za-z\s]+$/;

  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth();
    let year = date?.getFullYear();
    let formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDate;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEnquireNow = async () => {
    if (
      formData.firstName.length > 0 &&
      formData.lastName.length > 0 &&
      formData.message.length > 0 &&
      formData.residentialCity.length > 0
    ) {
      setIsSubmitting(true); // Disable the button

      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        hotelId: hotelId,
        message: formData.message,
        phoneNumber: formData.phoneNumber,
        checkIn: handleDateFormat(checkInDate),
        checkOut: handleDateFormat(checkOutDate),
        guests: adults + children,
        city: formData.residentialCity,
      };
      console.log("body is: ", body);

      try {
        const data = await fetch(`${BaseURL}/enquiry/create`, {
          method: "POST",
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        const responseJson = await data.json();
        console.log("Response is: ", responseJson);

        // window.alert("Enquiry is created!");
        setQueryOpen(true)
      } catch (error) {
        console.error("Error creating enquiry:", error);
        window.alert("There was an error creating the enquiry.");
      } finally {
        setIsSubmitting(false); // Re-enable the button
      }
    } else {
      console.log(formData.firstName.length);
      if (formData.firstName.length <= 0) {
        setNameError(true);
      }
      window.alert("Fill out all details");
      // setQueryCreated(!queryCreated);
    }
  };

  const checkInOut = (
    <>
      <div className="mb-4 w-full flex gap-2">
        <p>
          <label
            htmlFor="Check In"
            className="text-[#08090A] font-[600] flex justify-start"
          >
            Check In
          </label>
          <BookingDatePicker
            isCheckIn={true}
            selectedDate={checkInDate}
            setSelectedDate={setCheckInDate}
            setIsCheckInDateEmpty={setIsCheckInDateEmpty}
            isCheckInDateEmpty={isCheckInDateEmpty}
            maxDate={checkOutDate} // Disable dates after the check-out date
          />
          {!validateCheckinDate ? (
            <p className="text-red-500">Please select check-in date</p>
          ) : null}
        </p>
        <p>
          <label
            htmlFor="Check out"
            className="block text-[#08090A] font-[600] flex justify-start"
          >
            Check out
          </label>
          <BookingDatePicker
            isCheckOut={true}
            selectedDate={checkOutDate}
            setSelectedDate={setCheckOutDate}
            isCheckOutDateEmpty={isCheckOutDateEmpty}
            setIsCheckOutDateEmpty={setIsCheckOutDateEmpty}
            minDate={checkInDate} // Disable dates before the check-in date
          />
          {!validateCheckOutDate ? (
            <p className="text-red-500">Please select check-out date</p>
          ) : null}
        </p>
      </div>
    </>
  );
  const [mobileBooking, setMobileBooking] = useState(false);
  const bookNowForm = (
    <>
      <div className="grid  grid-cols-2 gap-4 max-xl:w-full text-center border-2 border-[#CED4DA] rounded-[8px] p-4">
        <div className="col-span-2 ...">
          {isBookNowClicked ? (
            <div className="text-left">
              <span className="text-[#6D747A]">Starting from</span>
              <h4 className="text-[#08090A] font-[600] max-lg:text-3xl text-[24px]">
                ₹ {price}
                <span className="text-[#434141] font-[400] text-lg">
                  / night + Taxes
                </span>{" "}
              </h4>
            </div>
          ) : (
            <div className="text-left">
              <span className="text-[#6D747A]">Starting from</span>
              {/* <span className="line-through text-[#6D747A]">₹ 8,650</span> */}
              <div className="text-[#08090A] font-[600] text-[24px] max-lg:text-3xl">
                ₹ {price}{" "}
                <span className="text-[#434141] font-[400] text-lg">
                  / night + Taxes
                </span>{" "}
              </div>
            </div>
          )}

          <div className="flex justify-center max-lg:my-6 mb-4 mt-4 lg:px-1.5">
            <button
              className={`h-[56px] max-xl:w-full w-[194px] border border-1 rounded-md rounded-r-none font-[600] text-lg ${isBookNowClicked ? "bg-[#E3E3E3]" : "bg-white"
                }`}
              onClick={() => {
                setIsBookNowClicked(true);
                tellme(true);
              }}
            >
              Book Now
            </button>
            <button
              className={`h-[56px] max-xl:w-full w-[194px] border border-1 rounded-md rounded-l-none font-[600] text-lg ${!isBookNowClicked ? "bg-[#E3E3E3]" : "bg-white"
                }`}
              onClick={() => {
                setIsBookNowClicked(false);
                tellme(false);
              }}
            >
              Enquire Now
            </button>
          </div>
          {queryCreated ? (
            <div className="w-full bg-black flex items-center justify-center left-0 bg-opacity-70 h-full fixed top-0 z-50">
              <div className="w-[386px] relative bg-white rounded-2xl flex-col justify-start items-start gap-10 inline-flex">
                <div className="w-full relative justify-center items-center  flex">
                  <div
                    onClick={() => {
                      setQueryCreated(!queryCreated);
                    }}
                    role="button"
                    className="w-6 top-4 h-6 absolute right-4 flex-col justify-start items-start flex"
                  >
                    <img src={cross} alt="" />
                  </div>
                </div>
                <div className="w-full h-[248px] px-6 py-11 flex-col justify-start items-center gap-6 inline-flex">
                  <div className="w-20 h-20 pl-[17.37px] pr-[16.63px] pt-[17.37px] pb-[16.63px] bg-zinc-950 rounded-[482px] justify-center items-center inline-flex">
                    <div className="w-[46px] h-[46px] relative rounded-md flex-col justify-start items-start flex">
                      <img src={rightTick} width="47" height="47" alt="" />
                    </div>
                  </div>
                  <div className="self-stretch h-14 flex-col justify-start items-center gap-1 flex">
                    <div className="self-stretch text-center text-zinc-950 text-lg font-bold  leading-7">
                      Your details have been updated.
                    </div>
                    <div className="self-stretch text-center text-neutral-700 text-base font-normal  leading-normal">
                      Your changes have been saved.
                    </div>
                  </div>
                  <div className="h-[0px]" />
                </div>
              </div>
            </div>
          ) : (
            " "
          )}
          {!isBookNowClicked ? (
            // Your booking form
            <form onSubmit={handleSubmit}>
              <div className="mb-4 gap-2 flex  w-full  max-md:flex-col">
                <div
                  className={` w-full max-md:mr-0 ${isFirstNameValid ? "" : "border-red-500"
                    }`}
                >
                  <label
                    htmlFor="FirstName"
                    className="block text-[#08090A]  font-[600] flex justify-start"
                  >
                    First Name
                  </label>
                  <InputField
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={formData.firstName}
                    id="firstName"
                    className={`mt-1 p-2 w-[180px] border rounded-md flex-1 pl-32 ${isFirstNameValid ? "" : "border-red-500"
                      }`}
                  />
                  {console.log(shownameserror, "hs")}
                  {shownameserror === false &&
                    formData.firstName.length >= 0 ? null : (
                    <p className="text-red-500 text-sm">
                      Please enter valid FirstName.
                    </p>
                  )}
                </div>

                <div className={`  w-full max-md:mr-0`}>
                  <label
                    htmlFor="LastName"
                    className="block text-[#08090A]  font-[600] flex justify-start"
                  >
                    Last Name
                  </label>
                  <InputField
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={formData.lastName}
                    id="lastName"
                    className={` p-2 w-[180px] border rounded-md flex-1 pl-32 ${isLastNameValid ? "" : "border-red-500"
                      }`}
                  />
                  {shownameserror === false &&
                    formData.firstName.length >= 0 ? null : (
                    <p className="text-red-500 text-sm">
                      Please enter valid LastName.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex  gap-2">
                <div
                  className={`mb-4 w-full ${isPhoneNumberValid ? "" : "border-red-500"
                    }`}
                >
                  <label
                    // htmlFor="phoneNumber"
                    className=" text-[#08090A] font-medium font-bold flex justify-start"
                  >
                    Phone Number
                  </label>

                  <div className="flex w-full  items-center  relative">
                    {/* <PhoneInput
                      country={"in"} // Default country code (India in this case)
                      value={formData.phoneNumber}
                      placeholder="Enter your phone number"
                      onChange={(phone) => {
                        handleChange({
                          target: { name: "phoneNumber", value: phone },
                        });
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "48px",
                        outline: "none",
                      }}
                      buttonStyle={{
                        border: "none",
                        background: "transparent",
                      }}
                      containerClass="custom-phone-input-container"
                      dropdownClass="custom-dropdown" // Add custom class for the dropdown
                      // enableSearch={true} // Enable search in the dropdown
                      // localization={{}} // Add localization if needed
                    /> */}
                    <PhoneInputSeparater
                      VALUE={formData.phoneNumber}
                      NAME={"phoneNumber"}
                      setPhone={(phone) => {
                        handleChange({
                          target: { name: "phoneNumber", value: phone },
                        });
                      }}
                    />
                  </div>
                  {isPhoneNumberValid ? null : (
                    <p className="text-red-500 text-sm">
                      Please enter valid Phone Number.
                    </p>
                  )}
                </div>
                <div className="mb-4 w-full hidden md:flex xl:hidden ">
                  {checkInOut}
                </div>
              </div>
              <div className={`mb-4 ${isEmailValid ? "" : "border-red-500"}`}>
                <label
                  htmlFor="Email address"
                  className="block text-[#08090A]  font-[600] flex justify-start"
                >
                  Email address
                </label>
                <InputField
                  type="email"
                  placeholder="exampleperson@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  className={` p-2 w-[384px]  border rounded-md  ${isEmailValid ? "" : " border-red-500"
                    }`}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                {isEmailValid ? null : (
                  <p className="text-red-500 text-sm">
                    Please enter valid Email address.
                  </p>
                )}
              </div>
              <div className="mb-4 w-full flex   md:hidden xl:block">
                {checkInOut}
              </div>
              <div className="mb-4 relative">
                <PickRooms
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isGuestEmpty={isRoomEmpty}
                  setIsGuestEmpty={setIsRoomEmpty}
                  roomsGuests={roomsGuests}
                  guests={children + adults}
                />
                <div className="absolute top-7 right-5">
                  <img
                    className="-mr-1 mt-2 ml-2 h-4 w-4  font-medium leading-8"
                    src={chevronup}
                    alt=""
                  />
                </div>
              </div>

              <div
                className={`mb-4 ${isResidentialValid ? "" : "border-red-500"}`}
              >
                <label
                  htmlFor="Residential City"
                  className="block text-[#08090A] font-[600] flex justify-start"
                >
                  Residential City
                </label>
                <InputField
                  type="text"
                  placeholder="Enter your city"
                  name="residentialCity"
                  onChange={handleChange}
                  value={formData.residentialCity}
                  id="residentialCity"
                  className={`p-2 border rounded-md ${isResidentialValid ? "" : "border-red-500"
                    } `}
                />
                {formData.residentialCity.length > 0 && !cityRegex.test(formData.residentialCity) && (
                  <p className="text-red-500 text-sm">
                    Special characters and numbers are not allowed.
                  </p>
                )}
              </div>

              <div className={`mb-4 ${isMessageValid ? "" : "border-red-500"}`}>
                <label
                  htmlFor="Message"
                  className="block text-[#08090A]  font-[600] flex justify-start"
                >
                  Message
                </label>
                <InputField
                  type="textarea"
                  placeholder="Leave us a message..."
                  name="message"
                  onChange={handleChange}
                  value={formData.message}
                  id="message"
                  styles={`w-full  h-[144px] border border-[#CED4DA] rounded-md p-2 placeholder-[200] `}
                />
                {formData.message.length >= 0 ? null : (
                  <p className="text-red-500 text-sm">Please enter Message.</p>
                )}
              </div>

              <button
                type="submit"
                className={`bg-[#08090A] w-full text-white font-xl px-4 py-2 text-lg rounded-md ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={handleEnquireNow}
                disabled={isSubmitting} // Disable the button while submitting
              >
                {isSubmitting ? "Submitting..." : "SUBMIT"}
              </button>
              <div className="text-zinc-950 text-base font-semibold  my-5 leading-normal xl:hidden tracking-tight">
                Or{" "}
              </div>
              <button
                className={`w-full py-[14px] px-[24px] border border-[#08090A] rounded-[4px] xl:hidden flex items-center gap-[10px] text-[16px]   justify-center font-[500]`}
              >
                <img src={callIcon} alt="" /> Call Us on{" "}
                <span className="font-[600]">+91 8976767650</span>
              </button>
              <button
                className={` sm:hidden w-full  mt-3 py-[14px] px-[24px] border border-[#08090A] rounded-[4px] xl:hidden flex items-center gap-[10px] text-[16px]   justify-center font-[500]`}
              >
                <img src={chatIcon} alt="" />
                <span className="font-[600]">Connect with Host</span>
              </button>
            </form>
          ) : (
            // Your modal content
            <ModalContent
              price={price}
              hotelId={hotelId}
              isOpen={isOpen}
              mobileBooking={mobileBooking}
              setIsOpen={setIsOpen}
              isRoomEmpty={isRoomEmpty}
              setIsRoomEmpty={setIsRoomEmpty}
              roomsGuests={roomsGuests}
              guests={children + adults}
              isBookNowClicked={isBookNowClicked}
            />
          )}
        </div>
      </div>
    </>
  );
  return (
    <div>
      <div className="xl:max-w-[1296px] w-full  items-center justify-center max-lg:w-full max-xl:hidden px-4 lg:px-5 mx-auto max-md:mt-6">
        {bookNowForm}
      </div>

      {/* smaller device copun bar */}
      <div className="w-full z-40 bottom-0 h-[175px] px-6 pt-2 pb-3 bg-white shadow flex-col justify-start max-xl:flex items-start fixed gap-2 hidden">
        <div className="flex-col justify-start items-start gap-2 flex">
          <div className="justify-start items-start gap-1 inline-flex">
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="w-[205px] text-emerald-600 text-xs font-normal  leading-[18px] tracking-tight">
                Add Coupon code to get discounts
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch justify-end items-start gap-3 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-sm font-normal  leading-[21px] tracking-tight">
                10May-14May’23
              </div>
              <div className="w-[21px] h-[21px] relative" />
            </div>
            <div className="self-stretch text-zinc-500 text-sm font-normal leading-[21px] tracking-tight">
              2 Adults, 1 Child
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-end inline-flex">
            <div className="self-stretch text-right text-zinc-500 text-xs font-normal  leading-[18px] tracking-tight">
              Starting from
            </div>
            <div className="justify-center items-center gap-0.5 inline-flex">
              <div className="text-zinc-950 text-xl font-semibold  leading-[30px] tracking-tight">
                ₹{price}
              </div>
            </div>
            <div className="self-stretch text-right text-zinc-500 text-sm font-normal  leading-[21px] tracking-tight">
              (For 3 rooms)/night+taxes
            </div>
          </div>
        </div>
        <div
          role="button"
          onClick={() => setMobileBooking(!mobileBooking)}
          className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-base font-bold  leading-none">
            Request to book
          </div>
        </div>
      </div>
      <div
        className={` ${mobileBooking ? "left-0 opacity-100" : "left-[-100%] opacity-0"
          }  z-50 fixed overflow-y-scroll xl:hidden max-h-screen bg-white top-0 transform duration-500  min-h-screen w-full `}
      >
        <div className="text-zinc-950 text-lg font-semibold leading-[27px] justify-between mx-6 my-4 tracking-tight flex gap-4">
          <button
            onClick={() => {
              setMobileBooking(!mobileBooking);
            }}
          >
            <img src={arrowRight} width="24" height="25" alt="" />
          </button>{" "}
          <h1>
            <ProfileDropDown />
          </h1>
        </div>
        <hr className="border border-gray-300" />
        <div className="m-6">{bookNowForm}</div>
      </div>
      {queryOpen ? <><QueryMadal setQueryOpen={setQueryOpen} /></> : <></>}
      

    </div>

  );
};

export default HotelBooking;
