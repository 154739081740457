import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import momentImage1 from "../../assets/images/realMoment1.webp";
import momentImage2 from "../../assets/images/realMoment2.webp";
import momentImage3 from "../../assets/images/realMoment3.webp";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";

const RealMoments = ({ hotelId }) => {
  const swiperRef = useRef(null);
  const [momentImages, setMomentImages] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [fallback, setFallback] = useState(false); 

  useEffect(() => {
    const fetchRealMoments = async () => {
      try {
        console.log("Fetching moments for hotelId:", hotelId); 
        const response = await fetch("https://api.ukiyostays.com/api/realMoments");
        if (response.ok) {
          const result = await response.json();
          console.log("API response:", result);
          if (result.success && Array.isArray(result.data)) {
            // Filter the moments based on the received hotelId
            const filteredData = result.data.filter(
              (item) => item.hotelId === hotelId
            );
            console.log("Filtered data:", filteredData); 

            if (filteredData.length > 0) {
              const formattedData = filteredData.map((item) => ({
                image: `${item.image}`,
                text: `${item.firstName} ${item.lastName} | ${new Date(item.createdAt).toLocaleDateString()}`,
              }));
              setMomentImages(formattedData);
              setFallback(false); 
            } else {
              setMomentImages([
                {
                  image: momentImage1,
                  text: "Aparna Sunetra | 25/12/23",
                },
                {
                  image: momentImage2,
                  text: "Sudhir Baraskar | 02/04/24",
                },
                {
                  image: momentImage3,
                  text: "Vinay Sardesai | 15/10/21",
                },
               
              ]);
              setFallback(true); 
            }
          } else {
            console.error("Unexpected API response structure:", result);
            setFallback(true); 
          }
        } else {
          console.error("Failed to fetch real moments:", response.statusText);
          setFallback(true); 
        }
      } catch (error) {
        console.error("Error fetching real moments:", error);
        setFallback(true); 
      }
    };

    fetchRealMoments();
  }, [hotelId]); 

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="px-5 lg:px-5 2xl:px-0">
      <h1 className="max-sm:text-xl text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight">
        Real Moments
      </h1>
      <p className="text-zinc-950 text-base font-normal max-sm:text-sm leading-7 tracking-tight mt-4">
        Nestled in the quaint village of Morjim, Ashore is a luxurious villa
        overlooking the beach. An ultimate escape from the daily grind, surf the
        waves of serenity while you pamper yourself in the spa treatment room.
      </p>

      {/* Swiper for smaller devices */}
      <div className="mt-[24px] max-md:block hidden max-xl:w-full w-[856px]">
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          pagination={{ clickable: true }}
          slidesPerView={3}  
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="max-lg:w-full"
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            630: {
              slidesPerView: 2,
            },
            368: {
              slidesPerView: 2,
            },
          }}
        >
          {momentImages?.map((item, index) => (
            <SwiperSlide
              className="flex py-2 justify-center items-center w-full"
              key={index}
            >
              <div className="p-2 shadow-md flex flex-col justify-center shadow-neutral-600/40 rounded-[8px]">
                <img src={item.image} alt="moment"  className="object-cover h-[280px] w-full"/>
                <p className="text-neutral-700 text-[10px] font-normal mt-1.5 leading-[18px]">
                  {item.text}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Navigation buttons */}
      <div className="max-md:flex hidden items-center justify-end mt-5 max-sm:gap-2 gap-[16px]">
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipePrev}>
          <img src={scrollLeft} alt="scroll" />
        </button>
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipeNext}>
          <img src={scrollRight} alt="scroll" />
        </button>
      </div>

      {/* Grid for larger devices */}
      <div className="mt-[24px] max-md:hidden grid grid-cols-3 gap-2">
        {momentImages.slice(0, showAll ? momentImages.length : 3).map((item, index) => (
          <div className="p-[16px] shadow-md rounded-[8px] w-full h-[330px]" key={index}>
            <img src={item.image} alt="moment" className="h-[280px] object-cover w-full"/>
            <p className="text-neutral-700 text-xs font-normal mt-1.5 leading-[18px]">
              {item.text}
            </p>
          </div>
        ))}
      </div>

      {/* View More / View Less Button */}
      {momentImages.length > 3 && (
        <button
          className="text-blue-500 mt-4"
          onClick={toggleShowAll}
        >
          {showAll ? "View Less" : "View More"}
        </button>
      )}

      {/* Fallback message if no moments for the given hotelId */}
      {fallback && momentImages.length === 0 && (
        <p className="text-red-500 mt-4">No real moments found for this hotel.</p>
      )}
    </div>
  );
};

export default RealMoments;
