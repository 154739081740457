import img from "../../assets/images/EventsAndExp/one.webp";
import img2 from "../../assets/images/EventsAndExp/two.webp";
import wed1 from "../../assets/images/EventsAndExp/wedding1.jpg";
import wed2 from "../../assets/images/EventsAndExp/wedding2.jpg";
import wed3 from "../../assets/images/EventsAndExp/wedding3.jpg";
import bday1 from "../../assets/images/EventsAndExp/Birthday1.jpg";
import bday2 from "../../assets/images/EventsAndExp/Birthday2.jpg";
import bday3 from "../../assets/images/EventsAndExp/Birthday3.jpg";
import ani1 from "../../assets/images/EventsAndExp/Anniversary1.jpg";
import ani2 from "../../assets/images/EventsAndExp/Anniversary2.jpg";
import ani3 from "../../assets/images/EventsAndExp/Anniversary3.jpg";
import corp1 from "../../assets/images/EventsAndExp/corporate1.jpg";
import corp2 from "../../assets/images/EventsAndExp/corporate2.jpg";
import corp3 from "../../assets/images/EventsAndExp/corporate3.jpg";
import corp4 from "../../assets/images/EventsAndExp/corporate4.jpg";
import baby1 from "../../assets/images/EventsAndExp/babyshower1.jpg";
import baby2 from "../../assets/images/EventsAndExp/babyshower2.jpg";
import baby3 from "../../assets/images/EventsAndExp/babyshower3.jpg";
import baby4 from "../../assets/images/EventsAndExp/babyshower4.jpg";

export const secData = [
  {
    name: "Intimate Weddings",
    txt: "Crafting intimate weddings is our specialty. Our homes and our dedicated team ensure every detail reflects the warmth and intimacy of your special day. Let us bring your dream wedding to life with personalized care and attention to detail.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
      { img: wed2, head: "Romantic Retreats" },
      { img: img, head: "Attention to detail" }
    ],
  },
  {
    name: "Birthdays",
    txt: "Make your birthday unforgettable by celebrating in one of our luxurious rental homes. Our properties provide the perfect backdrop for your special day. With amenities like private pools, scenic views, and spacious entertainment areas, you can gather with friends and family in style.",
    card: [
      { img: bday1, head: "Joyful Celebrations" },
      { img: bday3, head: "Magical Moments" },
      { img: bday2, head: "Birthday Bliss" },
      { img: img2, head: "Unforgetable Memories" },
    ],
  },
  {
    name: "Anniversaries",
    txt: "Celebrate your love story in style at our homes. Whether it's your first anniversary or a milestone occasion, our homes offer the perfect setting for romance and relaxation. With breathtaking views, intimate settings, and luxurious amenities, you can create unforgettable memories with your partner.",
    card: [
      { img: ani1, head: "Enchanting Evenings" },
      { img: ani2, head: "Everlasting Memories" },
      { img: ani3, head: "Timeless Love" },
      { img: ani1, head: "Elevated Bonding" },
    ],
  },
  {
    name: "Corporate retreats",
    txt: "Elevate your next corporate event at our spacious homes. From team-building retreats to executive meetings, our spaces offer a sophisticated and inspiring setting for business gatherings. With lavish meeting areas, high-speed internet, and modern amenities, you can host productive sessions while enjoying the comforts of a luxurious retreat.",
    card: [
      { img: corp1, head: "Team-Building Triumphs" },
      { img: corp2, head: "Productive Escapes" },
      { img: corp3, head: "Inspiring Spaces" },
      { img: corp4, head: "Boosting Environment" },
    ],
  },
  {
    name: "Baby showers",
    txt: "Welcome your bundle of joy in a charming and intimate setting. From quaint villas to elegant estates, our homes provide the perfect backdrop for your baby shower celebration. With beautiful interiors, scenic outdoor areas, and all the amenities you need, you can create cherished memories with your loved ones.",
    card: [
      { img: baby1, head: "Cherished Celebrations" },
      { img: baby2, head: "Sweet Beginings" },
      { img: baby3, head: "Bundle of Joy" },
      { img: baby4, head: "Feeling of Togetherness" },
    ],
  },
];
