import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import calendar from "../../assets/images/PropertyBooking/cal.webp";
import paymentsuccess from "../../assets/images/PropertyBooking/paymentImg.webp";
import ChatNav from "../Navbar/ChatNav";
import paySuccessImg from "../../assets/images/payment-successful.png"

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
 
  const [token, setToken] = useState("");
  const [payDetails, setPayDetails] = useState({});
  const payId = location?.state?.payId;
  console.log(location?.state?.payId)
  //not able to receive payid

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      // receiving token
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  const changeDateFormate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2); // Extracting last two digits of the year
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${day} ${month}'${year}`;
  };

  const getPaymentSuccessDetails = async () => {
    if (!token) {
      console.log("Token is not available");
      return;
    }

    try {
      const response = await fetch(
        `https://api.ukiyostays.com/api/bookings?bookingId=${payId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error("Error fetching data:", response.status);
        return;
      }

      const responseJson = await response.json();
      const responseData = responseJson?.data?.booking;

      if (!responseData) {
        console.error("No booking data found");
        return;
      }

      const bookingId = responseData?._id;
      const checkIn = responseData?.checkIn;
      const checkInDate = changeDateFormate(checkIn);
      const checkOut = responseData?.checkOut;
      const checkOutDate = changeDateFormate(checkOut);
      const adults = responseData?.noOfAdults;
      const children = responseData?.noOfChildren;
      const rooms = responseData?.noOfRooms;
      const totalAmount = responseData?.totalAmount;
      const hotelPhotos = responseData?.hotelDetails?.hotelPhoto;

      const childrenNo =
        children > 1 ? children + " Children" : children === 1 ? "1 Child" : "";
      const members = adults + " Adults " + childrenNo;

      const resObj = {
        bookingId: bookingId,
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        guests: members,
        totalAmount: totalAmount,
        hotelPhotos: hotelPhotos,
      };

      setPayDetails(resObj);
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const downloadReceipt = () => {
    const element = document.createElement("a");
    const receiptData = `Booking ID: ${payDetails.bookingId}\nCheck-In: ${payDetails.checkInDate}\nCheck-Out: ${payDetails.checkOutDate}\nGuests: ${payDetails.guests}\nTotal Amount Paid: ₹${payDetails.totalAmount}`;
    const file = new Blob([receiptData], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "receipt.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);

  useEffect(() => {
    if (token && payId) {
      getPaymentSuccessDetails();
    }
  }, [token, payId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="w-full h-full mb-20">
      <ChatNav />
      <div className="grid grid-cols-12 lg:mt-10 lg:px-4">
        <div className="col-span-12 lg:col-span-8">
          <div className="relative">
            <img
              src={paySuccessImg}
              alt="paymentsuccess"
              className=" h-[60vh] lg:h-[859px] border border-[#02020266] rounded-sm"
              style={{filter:"brightness(0.6)"}}
            />
            <div className="w-[93%] mb-6 absolute bottom-[30%] lg:bottom-20 text-center flex justify-center">
              <div className="text-center">
              <p className="text-center text-[#FFFFFF] text-[24px] lg:text-[36px] font-[Lato] font-semibold ">
                Thank You! Payment Successful!
              </p>
              <p className="text-center text-[#FFFFFF] text-[20px] lg:text-2xl font-medium font-[Lato]">
                Payment for Silvio - Villa E, <br /> North Goa
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className=" h-[859px] py-6  px-8 border border-[#02020266] rounded-sm">
            <h1 className="text-[#000000] text-[24px] tracking-wider font-semibold font-[Lato] leading-4 text-center">
              YOUR RESERVATION
            </h1>
            <div className="text-[#EAEFF2] border border-b mt-6  mb-8"></div>
            <div className="">
              <button className="w-full h-[40px] border border-[#F8F9FA] rounded-md  bg-[#F8F9FA]  text-[#08090A] font-[Lato] font-medium text-[16px]">
                Booking Id - {payDetails?.bookingId}
              </button>
              <div className="flex justify-between mt-4">
                <div className="text-[#434141] font-[Lato] text-lg font-medium">
                  <div className="flex items-center gap-2 mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p className="text-[16px] font-medium">Check in</p>
                  </div>
                  <div className="flex items-center gap-2  mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p className="text-[16px] font-medium">Check Out</p>
                  </div>
                  <div className="flex items-center gap-2  mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p className="text-[16px] font-medium">Guests</p>
                  </div>
                </div>
                <div className=" text-[#08090A] font-[Lato] text-lg font-medium">
                  <p className="mt-4">{payDetails.checkInDate}</p>
                  <p className="mt-4">{payDetails.checkOutDate}</p>
                  <p className="mt-4">{payDetails.guests}</p>
                </div>
              </div>
            </div>
            <div className="text-[#EAEFF2] border border-b mt-5  mb-8"></div>
            <div className="">
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                    Rental
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-[14px] font-[400]">
                    For 3 Guests, 2 Nights
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                  ₹ {payDetails.totalAmount}
                </p>
              </div>
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                    Meals
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-[14px] font-[400]">
                    For 3 Guests, 2 Nights
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                  ₹10,000
                </p>
              </div>
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                    Security Deposit
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-[14px] font-[400]">
                    Refundable
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-[16px] font-[400]">
                  ₹0
                </p>
              </div>
            </div>
            <div className="text-[#EAEFF2] border border-b mt-5  mb-8"></div>
            <div className="flex justify-between text-[#08090A] font-[Lato] text-xl font-medium">
              <p>Total Amount Paid </p>
              <p className="text-xl font-medium">₹{payDetails.totalAmount}</p>
            </div>

            <div className="text-[#EAEFF2] border border-b mt-5  mb-4"></div>
            <div className="flex gap-6 mb-4">
              <div className="w-[192px] h-[56px] border border-[#F8F9FA] rounded-md bg-[#08090A]">
                {/* <button
                  onClick={() => navigate("/dashboard")}
                  className="w-full h-full text-[#08090A] text-lg font-[Lato] font-medium leading-4"
                >
                  Dashboard
                </button> */}
                <button
                  onClick={downloadReceipt}
                  className="w-full h-full text-[#FFFFFF] text-[16px] font-[Lato] font-bold leading-4"
                >
                  Download Receipt
                </button>
              </div>
              <div className="w-[192px] h-[56px] border border-[#08090A] rounded-md  bg-[#F8F9FA]">
                {/* <button
                  onClick={() => navigate("/trips")}
                  className="w-full h-full text-[#FFFFFF] text-lg font-[Lato] font-medium leading-4"
                >
                  View Trips
                </button> */}
                 <button
                  onClick={() => navigate("/")}
                  className="w-full h-full text-[#08090A] text-[16px] font-[Lato] font-semibold leading-4"
                >
                  Back to Home
                </button>
              </div>
            </div>
            <div className="w-full h-[56px] border border-[#F8F9FA] rounded-md bg-[#08090A]">             
                <button
                  onClick={() => navigate("/userdashboard/myBooking")}
                  className="w-full h-full text-[#FFFFFF] text-[16px] font-[Lato] font-medium leading-4"
                >
                  View Add-ons
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;