import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ukiyoWhiteLogo from "../../assets/images/ukiyo-logo-white.webp";
import deviceIcon from "../../assets/svg/Mobile-phone.webp";
import award from "../../assets/svg/award.webp";
import check from "../../assets/svg/check.webp";
import facebook from "../../assets/svg/facebook.webp";
import gem from "../../assets/svg/gem.webp";
import instagram from "../../assets/svg/instagram.webp";
import linkedin from "../../assets/svg/linkedin.webp";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import phoneCall from "../../assets/svg/phone-call.webp";
import twitter from "../../assets/svg/twitter.webp";
import Container from "../ui/Container";

import { Autoplay } from "swiper/modules";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import LocationCard from "./LocationCard";
const Footer = () => {
  const [BanglowShow, setBanglowShow] = useState(false);
  const [CottageShow, setCottageShow] = useState(false);
  const [LuxuryShow, setLuxuryShow] = useState(false);
  const [ApartmentsShow, setApartmentsShow] = useState(false);
  const [Headings, setHeadings] = useState([
    "Villas",
    "Luxury Villas",
    "Mansions",
    "Bungalows",
    "Cabins",
    "Apartments",
    "Luxury Resorts",
    "Luxury Hotels",
    "Cottages",
    "Homestays",
    "Estates",
    "Farmstay",
    "Elite Villas",
    "Holiday Homes",
  ]);
  const [currentYear, setCurrentYear] = useState(new Date());
  // const footerHeading = async () => {
  //   const data = await fetch(`https://api.ukiyostays.com/api/hotelTypes/names`);

  //   const responseData = await data.json();
  //   console.log("🚀 ~ footerHeading ~ responseData:", responseData);

  //   setHeadings(responseData);
  //   return responseData;
  // };
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  // useEffect(() => {
  //   footerHeading();
  // }, []);
  return (
    <>
      <div className="bg-[#202026] mt-10 py-[56px] px-4">
        <Container>
          {/* footer 1st section start here 🏃‍♂️ */}
          <div className="flex justify-center lg:gap-[20px] xl:gap-[75px]  flex-col lg:flex-row">
            <div className="flex  xl:justify-normal flex-col xl:gap-10 w-full md:flex-row">
              <a href="/" className="w-full md:w-[40%] lg:w-fit  ">
                <img
                  width={89}
                  height={75}
                  src={ukiyoWhiteLogo}
                  alt="app-logo"
                />
                <p className="text-[#fff] text-[16px] font-[500]  text-sm leading-5 mt-[19px]">
                  Driven by Wanderlust,
                  <br className="md:block hidden" /> Rooted in Hospitality:{" "}
                  <br className="md:block hidden" /> Learn about our guiding
                  <br className="md:block hidden" /> vision and core values that{" "}
                  <br className="md:block hidden" /> inspire us
                </p>
              </a>
              <div className="mt-5 flex-grow  flex md:items-center items-start md:flex-row flex-col lg:gap-10  xl:gap-[56px]">
                <div className="flex gap-[10px]  justify-between items-center w-full">
                  <div className="">
                    <p className="xl:text-[20px] text-nowrap text-[#fff]  font-[600]">
                      Quick Links
                    </p>
                    <ul className="flex flex-col gap-[12px] mt-[16px]">
                      <li className="text-[18px] text-[#fff] text-nowrap">
                        <Link to="/AboutUs">About Us</Link>
                      </li>
                      <Link
                        to="/HowUkiyoWorks"
                        className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                      >
                        How Ukiyo Works
                      </Link>
                      <Link
                        to="/FAQ"
                        className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                      >
                        FAQs
                      </Link>
                      <Link
                        to="/Blogs"
                        className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                      >
                        Blogs
                      </Link>
                      <Link
                        to="/HomeSolution"
                        className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                      >
                        Home Solutions
                      </Link>
                      <Link
                        to="/SpecialOffersPage"
                        className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                      >
                        Special offers
                      </Link>
                    </ul>
                  </div>
                  <div className="flex-col gap-10 mt-3 flex md:flex-row justify-between">
                    <div>
                      <ul className="flex flex-col gap-[12px] ">
                        <Link
                          to="/allproperties"
                          className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                        >
                          Villas
                        </Link>

                        <Link
                          to="/luxuryproperties"
                          className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                        >
                          Elite Villas
                        </Link>
                        <Link
                          to="/luxuryproperties"
                          className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                        >
                          International Properties
                        </Link>
                        <Link
                          to="/ContactUs"
                          className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                        >
                          Contact Us
                        </Link>
                        <Link
                          to="/ListYourVilla"
                          className="lg:text-[18px] text-nowrap text-sm text-[#fff]"
                        >
                          List your Property
                        </Link>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <ul className="flex flex-col gap-[12px] ">
                      <li className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                        <a href="/">Book a Villa</a>
                      </li>
                      <li className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                        <a href="/">Travel Agent</a>
                      </li>
                      <Link to="/eventsAndExp">
                        <li className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                          <NavLink to={"/eventsAndExp"} href="#">
                            Experiences
                          </NavLink>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap lg:flex-col mt-5 lg:mt-0">
              <div>
                <p className="text-[20px] text-[#fff]  font-[600]">
                  Get in Touch
                </p>

                <p className="md:w-[360px] text-white text-base font-normal  leading-normal tracking-tight mt-6 mb-4">
                  Patrewali Chawl,Room No.480,Behram Nagar,Get No. 18 Bandra
                  East,Mumbai-400051
                </p>
                <a
                  className="text[16px] text-[#fff] mt-[16px] hidden lg:flex "
                  href="mailto:info@ukiyostays.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@ukiyostays.com
                </a>
              </div>
              <div className="hidden lg:block">
                <a
                  href="tel:+918976767650"
                  className="flex items-center gap-[56px] cursor-pointer mt-[40px]"
                >
                  <div className="flex items-center gap-[1px]  text-[#fff]">
                    <img src={deviceIcon} alt="device" />{" "}
                    <p className="text-white text-base text-nowrap xl:text-lg font-medium  leading-[27px] tracking-tight">
                      +91 8976767650
                    </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/+918976767650"
                    className="text-white text-nowrap xl:text-lg font-medium  leading-[27px] tracking-tight"
                  >
                    Contact On Whatsapp
                  </a>
                </a>
                <div className="flex items-center gap-[30px] md:gap-[40px] mt-[40px]">
                  <span className="text-[#fff] text-[16px] text-nowrap font-[600]">
                    Follow us on
                  </span>
                  <a href="https://www.facebook.com/UkiyoStays/">
                    <img
                      src={facebook}
                      alt="social-icon  "
                      className="w-5 h-5 cursor-pointer"
                    />
                  </a>
                  <a href="https://www.instagram.com/ukiyostays/">
                    <img
                      src={instagram}
                      alt="social-icon  "
                      className="w-5 h-5 cursor-pointer"
                    />
                  </a>
                  <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A75879401&keywords=ukiyo%20stays&origin=RICH_QUERY_SUGGESTION&position=0&searchId=a6adcc2f-c729-4716-9df7-90a7407e15cb&sid=~75&spellCorrectionEnabled=false">
                    <img
                      src={linkedin}
                      alt="social-icon "
                      className="w-5 h-5 cursor-pointer"
                    />
                  </a>
                  <a
                    href=" https://x.com/ukiyostays"
                    target="_blank"
                    rel="noopener noreferrer"
                    role="button"
                  >
                    <img
                      src={twitter}
                      alt="social-icon  "
                      className="w-5 h-5 cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* footer 1st section end here 🔚 */}
          {/* footer 2nd section start here 🏃‍♂️ */}
          <div className="flex flex-wrap justify-between xl:justify-start items-center gap-y-5  xl:gap-[80px] lg:mt-[73px]  mt-9">
            <div className="flex items-center gap-[8px]">
              <img src={phoneCall} alt="icon" />
              <p className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                24/7 Assistance
              </p>
            </div>
            <div className="flex items-center gap-[8px]">
              <img src={award} alt="icon" />
              <p className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                Best price Guarantee
              </p>
            </div>
            <div className="flex items-center gap-[8px]">
              <img src={check} alt="icon" />
              <p className="lg:text-[18px] text-nowrap text-sm text-[#fff]">
                Hand-picked Villas
              </p>
            </div>
            <div className="flex items-center md:w-fit  w-[158px] gap-[8px]">
              <img src={gem} alt="icon" />
              <p className="lg:text-[18px] text-nowrap text-sm flex items-center text-[#fff]">
                Quality Service
              </p>
            </div>
          </div>

          {/* lg: hidden */}

          <div className="lg:hidden h-8  my-5">
            <a
              className="text[16px] text-[#fff] mt-[16px] "
              href="mailto:info@ukiyostays.com"
              target="_blank"
              rel="noreferrer"
            >
              info@ukiyostays.com
            </a>
          </div>

          <div className=" lg:hidden">
            <a
              href="tel:+918976767650"
              className="flex items-center gap-[56px] cursor-pointer mt-[40px]"
            >
              <div className="flex items-center gap-[1px]  text-[#fff]">
                <img src={deviceIcon} alt="device" />{" "}
                <p className="text-white text-base text-nowrap xl:text-lg font-medium  leading-[27px] tracking-tight">
                  +91 8976767650
                </p>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+918976767650"
                className="text-white text-nowrap xl:text-lg font-medium  leading-[27px] tracking-tight"
              >
                Contact On Whatsapp
              </a>
            </a>
            <div className="flex items-center gap-[30px] md:gap-[40px] mt-[40px]">
              <span className="text-[#fff] text-[16px] text-nowrap font-[600]">
                Follow us on
              </span>
              <a href="https://www.facebook.com/UkiyoStays/">
                <img
                  src={facebook}
                  alt="social-icon  "
                  className="w-5 h-5 cursor-pointer"
                />
              </a>
              <a href="https://www.instagram.com/ukiyostays/">
                <img
                  src={instagram}
                  alt="social-icon  "
                  className="w-5 h-5 cursor-pointer"
                />
              </a>
              <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A75879401&keywords=ukiyo%20stays&origin=RICH_QUERY_SUGGESTION&position=0&searchId=a6adcc2f-c729-4716-9df7-90a7407e15cb&sid=~75&spellCorrectionEnabled=false">
                <img
                  src={linkedin}
                  alt="social-icon "
                  className="w-5 h-5 cursor-pointer"
                />
              </a>
              <a href="">
                <img
                  src={twitter}
                  href="https://x.com/ukiyostays"
                  alt="social-icon  "
                  className="w-5 h-5 cursor-pointer"
                />
              </a>
            </div>
          </div>

          {/* footer 2nd section end here 🔚 */}
          {/* divider start here */}
          <div className="flex justify-center mt-[56px]">
            <div className="xl:w-[1296px] h-[1px] bg-[#929292]"></div>
          </div>
          {/* divider end here */}
          {/* footer 3rd section start here 🏃‍♂️ */}
          <div className="flex justify-center xl:gap-[120px] lg:gap-6 flex-col lg:flex-row gap-5  w-full xl:mr-24 mt-[56px] ">
            {" "}
            <Swiper
              ref={swiperRef}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              modules={[Autoplay]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 2,
                },
                368: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={10}
              className="flex justify-center max-lg:w-full max-md:max-w-[370px] max-lg:max-w-3xl"
              onSlideChange={handleSlideChange}
            >
              {Headings?.map((heading, index) => (
                <SwiperSlide className="" key={index}>
                  {" "}
                  <div>
                    <p className="text-[#fff] max-sm:w-[] text-[24px] font-[600] flex justify-between items-center">
                      {heading}
                    </p>
                    <LocationCard heading={heading} />
                    {/* <hr className="mt-4 opacity-30 lg:hidden" /> */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flex items-center justify-start mt-[32px]  gap-[16px]">
            <button
              onClick={swipePrev}
              disabled={isBeginning}
              className={`${
                isBeginning && "bg-gray-500 opacity-20 rounded-full"
              }`}
            >
              <img alt="scroll" src={scrollLeftActive} />
            </button>
            <button
              onClick={swipeNext}
              disabled={isEnd}
              className={`${isEnd && "bg-gray-500 opacity-20 rounded-full"}`}
            >
              <img src={scrollRight} alt="scroll" />
            </button>
          </div>
          {/* footer 3rd section end here 🔚 */}
          {/* divider start here */}
          <div className="lg:flex hidden justify-center mt-[35px]">
            <div className="w-full h-[1px] bg-[#929292]"></div>
          </div>
          {/* divider end here */}
          {/* footer 4th section start here 🏃‍♂️ */}
          <div className="flex mt-8 items-center justify-between flex-wrap lg:flex-nowrap  lg:mt-[12px] w-full">
            <div className="flex items-start flex-wrap w-full  gap-2.5 lg:gap-x-[30px] 2xl:gap-[44px]">
              <Link
                to={{
                  pathname: "/TermsAndConditions",
                  state: { active: 1 },
                }}
                className="text-[14px] text-[#fff]"
              >
                Terms & Conditions
              </Link>
              <Link
                to={{
                  pathname: "/privacyPolicy",
                  state: { active: 2 },
                }}
                className="text-[14px] text-[#fff]"
              >
                Privacy Policy
              </Link>

              <Link
                to="/cancellation"
                className="text-[14px] text-nowrap text-[#fff] font-[500] cursor-pointer"
              >
                Cancellation Policy
              </Link>
              <Link
                to="/sitemap"
                className="text-[14px] text-nowrap text-[#fff] font-[500] cursor-pointer"
              >
                Sitemap
              </Link>
            </div>
            <div className="flex items-center  lg:items-end flex-grow justify-start lg:justify-end w-full  gap-[30px] mt-4 lg:mt-0 2xl:gap-[44px]">
              <p className="text-[14px] text-nowrap text-[#fff]   ">
                © {currentYear.getFullYear()} Ukiyo Stays. All Rights Reserved.
              </p>
            </div>
          </div>
          {/* footer 4th section end here 🔚 */}
        </Container>
      </div>
    </>
  );
};

export default Footer;
