import React, { useState } from "react";
import FormField from "./FormField";
import FormDropDown from "./FormDropDown";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useSendFormInputMutation } from "../../redux/features/eventsAndExp/eventsFormApi";
import { SlLocationPin } from "react-icons/sl";
import PhoneInputSeparater from "../PhoneInput/PhoneInput";
import { toast } from "react-toastify";

const SidebarForm = () => {
  const token = useTokenFromLocalStorage();
  const [sendFormData] = useSendFormInputMutation();

  // Variables to store form data
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dest, setDest] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [eventType, setEventType] = useState("");
  const [guests, setGuests] = useState("");

  // Options for the dropdowns
  const eventTypes = ["Wedding", "Birthday", "Corporate Retreats", "Anniversary", "Baby Shower"];
  const guestOptions = ["1 Guest", "2 Guests", "3 Guests", "4 Guests", "5 Guests", "More than 5"];

  // State to track form submission status
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to combine form data to desired format
  const data = {
    firstName: fName,
    lastName: lName,
    email: email,
    checkIn: checkIn,
    checkOut: checkOut,
    phoneNumber: Number(phone),
    guest: guests,
    destination: dest,
    typeOfEvent: eventType,
  };

  // Submit function
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      console.log(data); // Add form data submission logic here
      // Uncomment this when API integration is ready
      // await sendFormData({ data, token });

      // Simulate success (you can replace this with actual API response handling)
      setIsSubmitted(true);
      toast.success("Form Submitted Successfully!");
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <form className="border-[1px] bg-[#FFFFFF] border-[#CED4DA] shadow-sm z-0 h-fit py-9 px-10 flex flex-col gap-6 rounded-lg" style={{ overflowX: "hidden" }}>
      <h2 className="text-[#08090A] font-butler text-2xl leading-9 tracking-[0.24px] text-center">Contact Us</h2>
      
      <FormField name="First Name" type="text" holder="First Name" func={setFName} />
      <FormField name="Last Name" type="text" holder="Last Name" func={setLName} />
      
      <div className="flex flex-col gap-2">
        <label className="text-[#08090A] font-semibold leading-6 tracking-[0.16px]" htmlFor="Phone">
          Phone
        </label>
        <PhoneInputSeparater NAME="Phone" Type="tel" PlaceHolder="Enter phone number" setPhone={setPhone} />
      </div>
      
      <FormField name="Email address" type="email" holder="exampleperson@gmail.com" func={setEmail} />
      
      <div className="relative align-middle">
        <FormField name="Destination" type="text" holder="Choose your destination" func={setDest} />
        <SlLocationPin className="absolute z-10 right-2 bottom-4 w-5 h-5" />
      </div>
      
      <FormField name="Check In" type="date" holder="dd/mm/yyyy" func={setCheckIn} />
      <FormField name="Check Out" type="date" holder="dd/mm/yyyy" func={setCheckOut} />
      
      <FormDropDown name="Type of Event" holder="Choose your event" func={setEventType} options={eventTypes} />
      <FormDropDown name="Guests" holder="10-20 guests" func={setGuests} options={guestOptions} />
      
      <button
        onClick={submitHandler}
        className={`py-4 px-6 rounded font-bold leading-4 -tracking-[0.16px] ${isSubmitted ? "bg-green-500 text-white" : "bg-[#08090A] text-white"} mt-4`}
      >
        {isSubmitted ? "Submitted" : "SUBMIT"}
      </button>
    </form>
  );
};

export default SidebarForm;
