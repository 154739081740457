import SingleCard from "./SingleCard";

const CardSection = () => {
  const datas = [
    {
      title: "Do you want to book a villa?",
      desc: "Reach out to us at bookings@ukiyostays.com",
    },
    {
      title: "Do you have an existing booking?",
      desc: "Reach out to us at concierge@ukiyostays.com",
    },
    {
      title: "Do you want to host an event?",
      desc: "Reach out to us at info@ukiyostays.com",
    },
    {
      title:
        "Do you want to list your home or  want to propose a business alliance?",
      desc: "Reach out to us at info@ukiyostays.com",
    },
    {
      title: "Do you want to work with us?",
      desc: "Reach out to us at talent@ukiyostays.com",
    },
    {
      title: "Do you want to propose a business alliance?",
      desc: "Reach out to us at info@ukiyostays.com",
    },
  ];
  return (
    <div className="pt-10 px-5 sm:px-10 2xl:px-[72px]  grid md:grid-cols-2 lg:grid-cols-3 gap-6  grid-cols-1 mx-auto mt-32 md:mt-[20.5rem]">
      {datas?.map((data, index) => (
        <SingleCard key={index} title={data.title} desc={data.desc} />
      ))}
    </div>
  );
};

export default CardSection;
