import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import queryicon from "../../assets/icon/querysendicon.webp";
import phone_call from "../../assets/images/PropertyBooking/phone.webp";
import chevronup from "../../assets/svg/chevronup.webp";
import cross from "../../assets/svg/CrossGray.webp";
import { BaseURL } from "../../redux/config";
import { CitySearch } from "../Citydropdown/Citysearch";
import { InputField } from "../inputField/InputField";
import PhoneInputRequest from "../PhoneInput/PhoneNumber";
import QueryMadal from "../modals/QueryModal";

const HelpModalContent = ({ hotelId, isCloseHelpModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [queryCreated, setQueryCreated] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [queryOpen, setQueryOpen] = useState(false);
  const handleOpenQueryModal = () => {
    setQueryOpen(true);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    interest: "",
    residentialCity: "",
    message: "",
  });

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isResidentialValid, setIsResidentialValid] = useState(true);
  const [isInterestValid, setIsInterestValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      // Apply filtering only to the firstName and lastName fields
      let filteredValue = value;
      if (name === "firstName" || name === "lastName") {
        filteredValue = value.replace(/[^A-Za-z\s]/g, ""); // Allow only letters and spaces
      }

      setFormData({
        ...formData,
        [name]: filteredValue,
      });

      // Perform validation on filtered value
      if (name === "firstName") {
        validateFirstName(filteredValue);
      } else if (name === "lastName") {
        validateLastName(filteredValue);
      }
    } else {
      // This is the phone number input
      setFormData({
        ...formData,
        phoneNumber: e, // e is the phone number value
      });
      validatePhoneNumber(e); // Validate the phone number
    }
  };

  const validateFirstName = (value) => {
    if (!value.trim()) {
      setIsFirstNameValid(false);
      return false;
    }
    setIsFirstNameValid(true);
    return true;
  };

  const validateLastName = (value) => {
    if (!value.trim()) {
      setIsLastNameValid(false);
      return false;
    }
    setIsLastNameValid(true);
    return true;
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);
    setIsEmailValid(isValid);
    return isValid;
  };

  const validatePhoneNumber = (value) => {
    // Remove all non-digit characters from the input
    const phoneNumber = value.replace(/\D/g, "");

    // Check if the phone number contains at least 10 digits
    if (phoneNumber.length < 10) {
      setIsPhoneNumberValid(false);
      return false;
    }

    // If the phone number has at least 10 digits, consider it valid
    setIsPhoneNumberValid(true);
    return true;
  };

  const validateResidentialCity = (value) => {
    if (!value.trim()) {
      setIsResidentialValid(false);
      return false;
    }
    setIsResidentialValid(true);
    return true;
  };
  const validateInterestCity = (value) => {
    if (!value.trim()) {
      setIsInterestValid(false);
      return false;
    }
    setIsInterestValid(true);
    return true;
  };

  const validateMessage = (value) => {
    if (!value.trim()) {
      setIsMessageValid(false);
      return false;
    }
    setIsMessageValid(true);
    return true;
  };

  const handleSendRequest = async () => {
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      hotelId: hotelId,
      message: formData.message,
      phoneNumber: formData.phoneNumber,
      city: formData.residentialCity,
      interestedIn: formData.interest,
    };
    console.log("body iss: ", body);
    const data = await fetch(`${BaseURL}/enquiry/create`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const responseJson = await data.json();
    setQueryCreated(!queryCreated);
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidFirstName = validateFirstName(formData.firstName);
    const isValidLastName = validateLastName(formData.lastName);
    const isValidEmail = validateEmail(formData.email);
    const isValidPhoneNumber = validatePhoneNumber(formData.phoneNumber);
    const isValidInterest = validateInterestCity(formData.interest);

    const isValidResidentialCity = validateResidentialCity(
      formData.residentialCity
    );
    const isValidMessage = validateMessage(formData.message);

    if (
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isValidPhoneNumber &&
      isValidResidentialCity &&
      isValidInterest
    ) {
      console.log("Forrmm adaat os:: ", formData);
      // Form submission logic here
      handleSendRequest();
      // isCloseHelpModal(true);
    }

    // setIsPhoneNumberValid("");
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
    //setQueryOpen(!queryOpen);
  };
  const handleCallUs = () => {
    window.location.href = "tel:+918976767650";
  };
  return (
    <div>
      <div className="px-4 py-4">
        {showForm && (
          <form onSubmit={handleSubmit}>
            <div className="mb-4 gap-2 flex  w-full  ">
              <div
                className={` w-full max-md:mr-0 ${
                  isFirstNameValid ? "" : "border-red-500"
                }`}
              >
                <label
                  htmlFor="FirstName"
                  className="block text-[#08090A]  font-[600] flex justify-start"
                >
                  First name
                </label>
                <InputField
                  type="text"
                  placeholder="First name"
                  name="firstName"
                  onChange={handleChange}
                  value={formData.firstName}
                  id="firstName"
                  className={`mt-1 p-2 w-[180px] border rounded-md flex-1 pl-32 ${
                    isFirstNameValid ? "" : "border-red-500"
                  }`}
                />
                {isFirstNameValid ? null : (
                  <p className="text-red-500 text-sm">
                    Please enter FirstName.
                  </p>
                )}
              </div>

              <div className={`  w-full max-md:mr-0`}>
                <label
                  htmlFor="LastName"
                  className="block text-[#08090A]  font-[600] flex justify-start"
                >
                  Last name
                </label>
                <InputField
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  onChange={handleChange}
                  value={formData.lastName}
                  id="lastName"
                  className={` p-2 w-[180px] border rounded-md flex-1 pl-32 ${
                    isLastNameValid ? "" : "border-red-500"
                  }`}
                />
                {isLastNameValid ? null : (
                  <p className="text-red-500 text-sm">Please enter LastName.</p>
                )}
              </div>
            </div>
            <div className="flex  gap-2">
              <div
                className={`mb-4 w-full ${
                  isPhoneNumberValid ? "" : "border-red-500"
                }`}
              >
                <label
                  htmlFor="phoneNumber"
                  className="block text-[#08090A] font-medium font-bold flex justify-start"
                >
                  Phone Number
                </label>
                <div className="flex w-full  items-center  relative">
                  <PhoneInputRequest
                    PlaceHolder="Enter your phone number"
                    Type="tel"
                    VALUE={formData.phoneNumber}
                    DISABLED={false}
                    ID="phoneNumber"
                    NAME="phoneNumber"
                    updateInputVal={(newVal) =>
                      setFormData({ ...formData, ...newVal })
                    }
                    inputVal={formData}
                    setPhone={(newPhoneNumber) =>
                      setFormData({ ...formData, phoneNumber: newPhoneNumber })
                    }
                    dvalue={formData.phoneNumber}
                    setIsPhoneNumberValid={setIsPhoneNumberValid}
                  />
                  {/* <PhoneInput
                  phoneNumberError={isPhoneNumberValid}
                  setPhoneNumberError={setIsPhoneNumberValid}
                  id="phoneNumber"
                  country={"in"}
                  placeholder="Enter your phone number"
                  className={"phone-input-wrappe"}
                  formData={formData.phoneNumber}
                  onChange={handleChange}
                  inputStyle={{
                    width: "100%",
                    height: "48px",
                    outline: "none",
                  }}
                /> */}
                </div>
                {isPhoneNumberValid ? null : (
                  <p className="text-red-500 text-sm">
                    Please enter 10 digits Phone Number.
                  </p>
                )}
              </div>
              {/* <div className="mb-4 w-full hidden md:flex xl:hidden ">
                            {checkInOut}
                        </div> */}
            </div>
            <div className={`mb-4 ${isEmailValid ? "" : "border-red-500"}`}>
              <label
                htmlFor="Email address"
                className="block text-[#08090A]  font-[600] flex justify-start"
              >
                Email address
              </label>
              <InputField
                type="email"
                placeholder="exampleperson@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                id="email"
                className={` p-2 w-[384px]  border rounded-md  ${
                  isEmailValid ? "" : " border-red-500"
                }`}
              />
              {isEmailValid ? null : (
                <p className="text-red-500 text-sm">
                  Please enter valid Email address.
                </p>
              )}
            </div>

            <div className="mb-4 flex gap-4">
              <div
                className={`mb-4 w-[50%] relative ${
                  isResidentialValid ? "" : "border-red-500"
                }`}
              >
                <label
                  htmlFor="Residential City"
                  className=" text-[#08090A]  font-[600] flex justify-start"
                >
                  Residential City
                </label>
                <CitySearch formData={formData} handleChange={handleChange} />
                {/* <InputField
                type="text"
                placeholder="Rent"
                name="residentialCity"
                onChange={handleChange}
                value={formData.residentialCity}
                id="residentialCity"
                className={`mt-1 p-2 w-[384px] border rounded-md flex-1 pl-32 ${isResidentialValid ? "" : "border-red-500"
                  }`}
              /> */}
                {isResidentialValid ? null : (
                  <p className="text-red-500 text-sm">
                    Please enter Residential City.
                  </p>
                )}
                {/* <div className="absolute top-8 right-5">
                <img
                  
                  src={chevronup}
                  className="-mr-1 mt-2 ml-2 h-4 w-4  font-medium leading-8"
                  alt=""
                />
              </div> */}
              </div>
              <div
                onClick={handleToggle}
                className={`mb-4 relative ${
                  isInterestValid ? "" : "border-red-500"
                }`}
              >
                <label
                  htmlFor="interest"
                  className="block text-[#08090A] font-[600] flex justify-start"
                >
                  Interested In
                </label>
                <input
                  placeholder="Rent"
                  type="text"
                  name="interest"
                  readOnly
                  value={formData.interest}
                  id="interest"
                  className={`mt-1 p-2 w-[100%] h-12 border rounded-md flex-1  cursor-pointer ${
                    isInterestValid ? "" : "border-red-500"
                  }`}
                  onClick={handleToggle}
                />
                {isInterestValid ? null : (
                  <p className="text-red-500 text-sm">
                    Please select an option.
                  </p>
                )}
                <div className="absolute items-center top-8 right-5">
                  <img
                    src={chevronup}
                    className={`-mr-2 mt-2.5 cursor-pointer ml-2 h-5 w-5 font-medium leading-8 transition-transform duration-300 ${
                      isOpen ? "rotate-180" : "rotate-0"
                    }`}
                    alt=""
                  />
                </div>
                {isOpen && (
                  <InterestedDropdown
                    setIsOpen={setIsOpen}
                    setFormData={setFormData}
                  />
                )}
              </div>
            </div>

            <div className={`mb-4 ${isMessageValid ? "" : "border-red-500"}`}>
              <label
                htmlFor="Message"
                className="block text-[#08090A]  font-[600] flex justify-start"
              >
                Message
              </label>
              <InputField
                type="textarea"
                placeholder="Leave us a message..."
                name="message"
                onChange={handleChange}
                value={formData.message}
                id="message"
                styles={` w-full h-[144px] border rounded-md p-2`}
                //styles={` w-full h-[84px] border rounded-md p-2`}
              />
            </div>

            <button
              type="submit"
              className="bg-[#08090A] w-full text-white font-xl px-4 py-2 text-lg rounded-md"
            >
              Submit
            </button>
            {/* {queryOpen && <QueryMadal setQueryOpen={setQueryOpen} />} */}
          </form>
        )}
        {queryCreated ? (
          <div className="w-full bg-black flex items-center justify-center left-0 bg-opacity-70 h-full fixed top-0 z-50">
            <div className="w-[386px] h-[400px] relative bg-white rounded-2xl flex-col justify-start items-start gap-10 inline-flex">
              <div className="w-full relative justify-center items-center  flex">
                <div
                  onClick={() => {
                    setQueryCreated(!queryCreated);
                    isCloseHelpModal(false);
                  }}
                  role="button"
                  className="w-6 top-4 h-6 absolute right-4 flex-col justify-start items-start flex"
                >
                  <img src={cross} alt="" />
                </div>
              </div>
              <div className="w-full h-[248px] px-6 py-11 flex-col justify-start items-center gap-6 inline-flex">
                <div className="relative rounded-md flex-col justify-start items-start flex">
                  <img src={queryicon} width={80} height={80} alt="" />
                </div>

                <div className="self-stretch h-14 flex-col justify-start items-center gap-1 flex">
                  <div className="self-stretch text-center text-zinc-950 text-lg font-bold  leading-7">
                    Query Submitted Successfully!
                  </div>
                  <div className="self-stretch text-center text-neutral-700 text-base font-normal  leading-normal">
                    Thank you for contacting us. We will get in touch with you
                    soon
                  </div>
                </div>
                <div className="h-[0.5px]" />
                <button
                  onClick={() => {
                    setQueryCreated(!queryCreated);
                    setShowForm(true);
                  }}
                  className="bg-[#039855] px-7 py-2 rounded-lg text-white "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          " "
        )}

        <div className="mt-4 font-medium">
          <div className="flex items-center justify-center">
            <div className=" orLine border-t border-gray-400 my-4 "></div>
            <span className="mx-2 font-semibold">Or</span>
            <div className=" orLine border-t border-gray-400 my-4 "></div>
          </div>
        </div>
        <div
          onClick={handleCallUs}
          className="flex cursor-pointer text-lg my-4 border border-[#08090A] font-[600] rounded-md p-3 max-md:text-base justify-center  items-center gap-1 mb-6 text-[#505356]  "
        >
          <p className="max-md:w-4 max-md:h-4 ">
            <img
              src={phone_call}
              alt="phone_call"
              className="text-[#6D747A] font-[500] mr-2"
            />
          </p>
          <p className="max-md:text-sm">Call Us on</p>
          <p className="font-medium max-md:text-sm">+91 8976767650</p>
        </div>
      </div>
    </div>
  );
};

export default HelpModalContent;

const InterestedDropdown = ({ setIsOpen, setFormData }) => {
  const handleSelect = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      interest: option,
    }));
    setIsOpen(false);
  };

  return (
    <div className="absolute top-20 -right-2 w-[198px] h-[192px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
      <div>
        {["Rent", "Own", "Partner as a Homeowner", "Others"].map((option) => (
          <p
            key={option}
            onClick={() => handleSelect(option)}
            className="py-2 px-2 text-[#08090A] font-[400] hover:bg-[#F7F7F7] cursor-pointer"
          >
            {option}
          </p>
        ))}
      </div>
    </div>
  );
};
