import React from "react";
import FilterBtn from "./FilterBtn";

const TopFilters = ({ amenities, selectedFilters, onFilterChange }) => {
  return (
    <div>
      <p className="text-xl mb-4 leading-[30px] tracking-[0.2px]">Top Filters</p>
      <div className="flex cursor-pointer flex-wrap gap-3">
        <FilterBtn
          name={{ amenities }}
          selectedFilters={selectedFilters} 
          onFilterChange={onFilterChange} 
        />
      </div>
    </div>
  );
};

export default TopFilters;
