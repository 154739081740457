import React from "react";
import linkIco from "../../../assets/svg/Anchor.webp";
import whatsapp from "../../../assets/svg/WhatsApp.webp";
import BlogSec from "./BlogSec";
import HeadingInfo from "./HeadingInfo";
import PrevNextBlog from "./PrevNextBlog";

const BlogContent = ({ data }) => {
  return (
    <div className="container overflow-hidden">
      <HeadingInfo
        title={data?.title}
        tags={data?.tags}
        date={data?.createdAt}
        highlights={data?.highlights}
      />
      <p className="mt-8 leading-7 font-Lato text-base md:text-lg lg:text-xl font-normal tracking-[0.16px] text-gray-800 max-w-full md:max-w-3xl lg:max-w-4xl">
        {/* Immerse yourself in unparalleled luxury with our meticulously designed
        accommodations. Immerse yourself in unparalleled luxury with our
        meticulously designed accommodations. Immerse yourself in unparalleled
        luxury with our meticulously designed accommodations. Immerse yourself
        in unparalleled luxury with our meticulously designed accommodations.
        Immerse yourself in unparalleled luxury with our meticulously designed
        accommodations. */}
        {data?.overview}
      </p>

      <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-4 cursor-pointer mt-10">
        {/* Top Items */}
        <div className="col-span-1 md:col-span-2 lg:col-span-2 bg-[#E0E0E0] flex justify-center items-center h-[150px] md:h-[220px] relative">
          <div className="text-gray-700 text-lg font-semibold"><img src={data?.images[0]} alt="image1" /></div>
        </div>
        {
          data?.images.length > 1 && <div className="col-span-1 md:col-span-2 lg:col-span-2 bg-[#E0E0E0] flex justify-center items-center h-[150px] md:h-[220px] relative">
            <div className="text-gray-700 text-lg font-semibold"><img src={data?.images[1]} alt="image1" /></div>
          </div>
        }
        {data?.images.length > 2 && <div className="col-span-1 md:col-span-2 lg:col-span-2 bg-[#E0E0E0] flex justify-center items-center h-[150px] md:h-[220px] relative">
          <div className="text-gray-700 text-lg font-semibold"><img src={data?.images[2]} alt="image1" /></div>
        </div>
        }
        <div className="col-span-1 md:hidden lg:hidden bg-[#E0E0E0] flex justify-center items-center h-[150px] relative">
          <div className="text-gray-700 text-lg font-semibold">Item 4</div>
        </div>

        {/* Bottom Items */}
        {data?.images.length > 3 && <div className="col-span-2 md:col-span-3 lg:col-span-3 bg-[#E0E0E0] flex justify-center items-center h-[200px] md:h-[300px] relative">
          <div className="text-gray-700 text-lg font-semibold"><img src={data?.images[3]} alt="image1" /></div>
        </div>
        }
        {data?.images.length > 4 && <div className="hidden lg:flex lg:col-span-3 bg-[#E0E0E0] justify-center items-center h-[200px] md:h-[300px] relative">
          <div className="text-gray-700 text-lg font-semibold"><img src={data?.images[4]} alt="image1" /></div>
        </div>
        }
      </div>

      <BlogSec />

      {/* Share Icons */}
      <div className="flex gap-5 items-center pt-9 mt-9 border-t-[1px] border-t-[#CED4DA]">
        <p className="text-2xl font-semibold font-Lato leading-[43px]">
          Share:
        </p>
        <a href="#">
          <img
            src={whatsapp}
            alt="whatsapp"
            className="w-[24px] cursor-pointer"
            data-twe-toggle="tooltip"
            title="whatsapp"
          />
        </a>
        <a href="#">
          <img
            src={linkIco}
            alt="link icon"
            className="w-[24px] cursor-pointer"
            data-twe-toggle="tooltip"
            title="link"
          />
        </a>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-14">
        <PrevNextBlog next={false} />
        <PrevNextBlog next={true} />
      </div>
    </div>
  );
};

export default BlogContent;
