import { apiSlice } from "../api/apliSlice";

const authApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    /** Registration Endpoint */
    singUp: builder.mutation({
      query: (registerData) => ({
        url: `/auth/signup`,
        method: "POST",
        body: JSON.stringify(registerData),
        headers: {
          contentType: "application/json",
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),

    logIn: builder.mutation({
      query: (loginInfo) => ({
        url: `/auth/login`,
        method: "POST",
        body: loginInfo,
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
    deleteGov: builder.mutation({
      query: ( govtId, token) => ({
        
        url: `/api/auth/govt/${govtId}`,
        method: "DELETE",
        // body:  govtId ,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),

    sendOTP: builder.mutation({
      query: (loginPhoneInfo) => ({
        url: `/auth/sendOTP`,
        method: "POST",
        body: loginPhoneInfo,
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
    verifyOtp: builder.mutation({
      query: (loginPhonOtp) => ({
        url: `auth/verifyOTP`,
        method: "POST",
        body: loginPhonOtp,
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),

    logOut: builder.query({
      query: () => ({
        url: `/auth/logout`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),

  authUser: builder.query({
  query: (token) => {
    if (!token) return null; 
    return {
      url: `/auth`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      fetchOptions: {
        mode: "cors",
        credentials: "include",
      },
    };
  },

}),


    updateUserData: builder.mutation({
      query: ({ token, userData }) => ({
        url: `/auth/profile/update`,
        method: "PATCH",
        body: userData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const {
  useSingUpMutation,
  useLogInMutation,
  useSendOTPMutation,
  useAuthUserQuery,
  useLogOutQuery,
  useVerifyOtpMutation,
  useUpdateUserDataMutation,
  useDeleteGovMutation,
  
} = authApi;
