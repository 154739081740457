import React from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Layout/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Container from "../../components/ui/Container";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";

const UserProfile = () => {
  const token = useTokenFromLocalStorage();
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Container>
        <Navbar />
        <div className=" flex md:w-full mx-auto gap-x-[42px] max-md:mt-4 max-lg:mt-10 mt-[32px] justify-center mb-[46px]">
          <aside className="hidden lg:flex sticky top-[72px]  h-max">
            <Sidebar
              userName={`${profileDetails?.firstName || ""} ${
                profileDetails?.lastName || ""
              }`}
              firstName={profileDetails?.firstName}
              lastName={profileDetails?.lastName}
              isLinked={true}
            />
          </aside>

          <Outlet />
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default UserProfile;
