import React, { useState } from "react";
import Send from "../../assets/svg/sendDeg.webp";
import { useSendGuestTextMutation } from "../../redux/features/chatbot/sendText";

const SendText = (props) => {
  // console.log("SendText : ",props)
  const [text, setText] = useState("");
  const [sendMessage, { data, isLoading, isSuccess, isError, error }] =
    useSendGuestTextMutation();
    // console.log("SendText : ",text)

  // console.log(props?.chatId);

  const sendHandler = (e) => {
    e.preventDefault();
    if (text.length >= 1) {
      const textData = {
        message: text,
      };
      // console.log(text);
      sendMessage({
        textData: textData,
        token: props?.token,
        chatId: props?.chatId,
      });
      setText("");

      props.update({
        message: text,
        sender: "user",
        timestamp: new Date(),
        _id: Math.floor(Math.random() * 1000),
      });
    }
  };

  if (isSuccess) {
    props.func(data?.chats);
    console.log(localStorage.getItem("guestChatId"));
  }

  return (
    <div className="pt-5 border-t-[1px] flex items-center justify-between border-t-slate-300">
      <input
        onChange={(e) => {
          setText(e.target.value);
          console.log("setText value : ",e.target.value);
        }}
        type="text"
        value={text}
        placeholder="Write a message"
        className="text-[#737376] text-[15px] leading-[20px] outline-none"
      />
      <button onClick={sendHandler}>
        <img src={Send} alt="send" />
      </button>
    </div>
  );
};

export default SendText;
