import { useEffect } from "react";
import "./App.css";
import Layout from "./components/Layout";
import useTokenFromLocalStorage from "./hooks/useGetToken";
import { useAuthUserQuery } from "./redux/features/auth/authApi";

function App() {
  const token = localStorage.getItem("token")
  console.log(token)
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;
  useEffect(() => {
    console.log(profileDetails);
    if (!window.location.pathname.includes("/ListYourVilla/")) {
      localStorage.removeItem("pricingDetails");
      localStorage.removeItem("PropertyDetails");
    }
    if (!profileDetails) {
      localStorage.removeItem("pricingDetails");
      localStorage.removeItem("PropertyDetails");
    }
  }, []);
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
