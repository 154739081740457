import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import MainModal from "./MainModal";
const MoreAvailiability = ({ setShowMoreAvailiability,setRestrictedcheckin,setRestrictedcheckOut,Restrictedcheckin,RestrictedcheckOut,handleUpdateAvailablity }) => {
  const [WeeklyDiscountValueDefault, setWeeklyDiscountValueDefault] =
    useState(true);
  const [restrictedCheckIn, setRestrictedCheckIn] = useState("");
  const [restrictedCheckOut, setRestrictedCheckOut] = useState("");




const handleSelectedCheckin = (id) => {
  setRestrictedcheckin((prevData) =>
    prevData.map((item) =>
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    )
  );
}


const handleSelectedCheckOut = (id) => {
  setRestrictedcheckOut((prevData) =>
    prevData.map((item) =>
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    )
  );
}



  return (
    <>
      <MainModal>
        <div>
          <div className="h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="h-[30px] relative w-full flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-full items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  More available options
                </div>
                <button
                  onClick={() => setShowMoreAvailiability(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div className="w-[375px] min-h-[215px] flex-col justify-start items-start gap-4 inline-flex">
              <div className="justify-start items-center gap-[164px] inline-flex">
                <div className="text-black text-xl font-normal font-['Lato'] leading-[30px] tracking-tight">
                  Restricted check-in
                </div>
              </div>
              <div className="w-[100%] h-[123px] flex-col justify-start items-start gap-3 flex">
                <div className=" gap-3 flex flex-wrap">
                {Restrictedcheckin?.map((item,index)=>(
                  <div onClick={()=>handleSelectedCheckin(item.id)} key={index} className={`px-6 py-2 ${item.isSelected?"bg-black text-white":"text-[#434141] bg-white"} cursor-pointer rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex`}>
                    <div className="text-sm font-normal font-['Lato']">
                      {item.day}
                    </div>
                  </div>
                ))

                }
                  
                  {/* <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Monday
                    </div>
                  </div>
                  <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Tuesday
                    </div>
                  </div> */}
                </div>
                {/* <div className="justify-start items-start gap-3 inline-flex">
                  <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Wednesday
                    </div>
                  </div>
                  <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Thursday
                    </div>
                  </div>
                  <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Friday
                    </div>
                  </div>
                </div> */}
                {/* <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 inline-flex">
                  <div className="text-[#434141] text-sm font-normal font-['Lato']">
                    Saturday
                  </div>
                </div> */}
              </div>
              <div className="flex-col mt-4 justify-start items-start  gap-4 inline-flex">
                <div className="justify-start items-center gap-[164px] inline-flex">
                  <div className="text-black text-xl font-normal font-['Lato'] leading-[30px] tracking-tight">
                    Restricted check out
                  </div>
                </div>
                <div className="w-[375px] h-[123px] flex-col justify-start items-start gap-3 inline-flex">
                  <div className="flex gap-3 flex-wrap">
                  {RestrictedcheckOut.map((item,index)=>(
                    <div onClick={()=>handleSelectedCheckOut(item.id)} key={index} className={`px-6 py-2 ${item.isSelected?"bg-black text-white":"text-[#434141] bg-white"} cursor-pointer rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex`}>
                      <div className="text-sm font-normal font-['Lato']">
                        {item.day}
                      </div>
                    </div>
                  ))

                  }
                    
                    {/* <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                      <div className="text-[#434141] text-sm font-normal font-['Lato']">
                        Monday
                      </div>
                    </div>
                    <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                      <div className="text-[#434141] text-sm font-normal font-['Lato']">
                        Tuesday
                      </div>
                    </div> */}
                  </div>
                  <div className="justify-start items-start gap-3 inline-flex">
                    {/* <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                      <div className="text-[#434141] text-sm font-normal font-['Lato']">
                        Wednesday
                      </div>
                    </div>
                    <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                      <div className="text-[#434141] text-sm font-normal font-['Lato']">
                        Thursday
                      </div>
                    </div>
                    <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 flex">
                      <div className="text-[#434141] text-sm font-normal font-['Lato']">
                        Friday
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="px-6 py-2 bg-white rounded-[100px] border border-[#ced4da] justify-center items-center gap-2.5 inline-flex">
                    <div className="text-[#434141] text-sm font-normal font-['Lato']">
                      Saturday
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-[376px]">
              <span className="text-[#6c747a] text-base font-normal font-['Lato'] leading-normal">
                Please refer to our privacy policy for more information.
              </span>
              <span className="text-[#6c747a] text-sm font-normal font-['Lato'] leading-[21px]">
                {" "}
              </span>
              <span className="text-[#0f6cdc] text-base font-medium font-['Lato'] leading-normal">
                Privacy
              </span>
              <span className="text-[#0c69d6] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                {" "}
                Policy
              </span>
              <span className="text-[#08090a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                .
              </span>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => {  handleUpdateAvailablity();setShowMoreAvailiability(false)}}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Save
                </div>
              </button>
              <button
                onClick={() => {
                
                  setShowMoreAvailiability(false)
                  }}
                className="w-full h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default MoreAvailiability;
