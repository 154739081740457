import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import chatWhite from "../../assets/svg/chatWhite.webp";
import OurServicesCard from "../../components/AboutUs/OurServices";
import WhyBookAVilla from "../../components/AboutUs/WhyBookAVilla";
import Banner from "../../components/Banner";
import ChatModalContent from "../../components/FutureGetaways/ChatModal/ChatModalContent";
import FutureGetawaysStepner from "../../components/FutureGetaways/FutureGetawaysStepner";
import Collections from "../../components/HomeCollections/Collections";
import Destination from "../../components/HomeDestination/Destination";
import Example from "../../components/HomeExample/Example";
import GuestReview from "../../components/HomeGuestReview/GuestReview";
import RecentlyView from "../../components/HomeRecentlyView/RecentlyView";
import RecommendedProperties from "../../components/HomeRecommendedProperties/RecommendedProperties";
import AboutUs from "../../components/Landinghomepage/AboutUs";
import OurLatestOffers from "../../components/Landinghomepage/OurLatestOffers";
import WhyUs from "../../components/Landinghomepage/WhyUs";
import ConfirmResetPassModal from "../../components/modals/auth/ConfirmResetPassModal";
import SuccessResetModal from "../../components/modals/auth/SuccessResetModal";
import ChatModal from "../../components/modals/ChatModal";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { BaseURL } from "../../redux/config";
import { useGetAllPropertyQuery } from "../../redux/features/home/homeApi";
import ConfirmResetPassModalContent from "../auth/ConfirmResetPassModalContent";
import SuccessResetModalContent from "../auth/SuccessResetModalContent";
import ChatSupportPopup from "../ChatSupportPopup/ChatSupportPopup";
const HomePage = () => {
  /** Define state */
  const token = useTokenFromLocalStorage()
  const [query, setQuery] = useSearchParams();
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [openResetSuccess, setOpenResetSuccess] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const queryToken = query?.get("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** RTK-query Hooks for Get All Property Data  */
  const { data } = useGetAllPropertyQuery();

  /** Handler for opening chat modal */
  const openChatModal = () => {
    if (!token) return;
    setChatModal(true);
  };

  /** Handler for closing chat modal */
  const CloseChatModal = () => {
    setChatModal(false);
  };

  /** Async Handler Function for verifying token */
  const verfyToken = async () => {
    const response = await fetch(
      `${BaseURL}/auth/verifyToken?token=${resetToken}`
    );
    const responseJson = await response.json();
    const responseData = responseJson?.data;
    setEmail(responseData);
  };

  useEffect(() => {
    setResetToken(query.get("token"));
    if (queryToken) {
      verfyToken();
      setPasswordResetSuccess(true);
    }
  }, [query, queryToken, email]);

  /** After verifying token reset password */
  const isCloseConfirmPass = () => {
    setPasswordResetSuccess(false);
  };
  const isCloseSuccess = () => {
    setOpenResetSuccess(false);
  };


  console.error("===============================?", data)
  // console.log(token)
  return (
    <>
      {/* <ChatModalRemaster /> */}
      <Banner isHomeScreen={true} />
      {/* Recently View Section */}
      {token && <RecentlyView token={token} />}
      <div
        className={`${token
          ? "mt-[50px] lg:mt-[120px]  xl:mt-[132px] md:mt-[130px]"
          : "mt-[50px] lg:mt-[250px] xl:mt-[132px] md:mt-[130px]"
          }`}
      >
        {/* Destination Section */}
        <Destination propertiesData={data?.data?.hotels} token={token} />
        {/* Chat Support JSX Condition */}
      </div>

      {/* Collections Section */}
      <Collections token={token} />
      {/* Guest Review Section */}
      <GuestReview />
      {/* Recommended Properties Section */}
      <RecommendedProperties propertiesData={data?.data?.hotels} />
      {/* Example - Why Ukiyo Section */}
      <Example />
      {/* About Us -> Rahat code */}
      {/* <AboutUs /> */}
      {/* Why Book a villa -> Rahat code */}
      {/* <WhyBookAVilla sectionTitle={"Why Book a Villa"} /> */}
      {/* why us -> Rahat code */}
      {/* <WhyUs /> */}
      <div className="px-10 max-md:px-6">
        {/* Our Latest Offers  */}
        {/* <OurLatestOffers /> */}
        {/* our services -> Rahat code */}
        {/* <OurServicesCard /> */}
      </div>
      {/* Plan your future getaways Section */}
      <FutureGetawaysStepner />
      {/* Chat Support and Auth Related JSX condition */}
      {passwordResetSuccess && (
        <ConfirmResetPassModal
          children={
            <ConfirmResetPassModalContent
              email={email}
              setPasswordResetSuccess={setPasswordResetSuccess}
              setOpenResetSuccess={setOpenResetSuccess}
            />
          }
          isCloseConfirmPass={isCloseConfirmPass}
        />
      )}
      {openResetSuccess && (
        <SuccessResetModal
          children={<SuccessResetModalContent />}
          isCloseSuccess={isCloseSuccess}
        />
      )}
      <ChatSupportPopup />
    </>
  );
};

export default HomePage;
