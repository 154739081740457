import listYourVilla from "../../../assets/svg/listYourVilla.webp";
import { Link } from "react-router-dom";
const ListYourHome = () => {
  return (
    <div className="sm:px-10 2xl:px-[72px]  px-5 object-contain mt-[200px] md:mt-20">
      <div className="w-full py-4 md:py-0 sm:grid sm:grid-cols-2 h-[440px] bg-gray-50 object-contain rounded-lg sm:border border-gray-300 flex flex-col-reverse sm:mt-0 mt-20 overflow-hidden">
        <div className="sm:mt-[85px] sm:ms-[48px] mt-[20px]">
          <div className="w-full">
            <span className="text-zinc-950 text-4xl font-medium font-[butler] leading-[54px] tracking-tight">
              List your
            </span>
            <span className="text-blue-600 text-4xl font-medium font-[butler] leading-[54px] tracking-tight">
              {" "}Home
            </span>
          </div>
          <div className="mt-4 sm:mb-[56px] mb-5 text-zinc-950 text-base font-normal leading-normal tracking-tight pe-4 me-4">
            Get 10-14% ROI on your holiday home! Maximise the success of your holiday <br/>home with love from Ukiyo Stays!
          </div>
          <Link to="/ListYourVilla">
            <div className="w-[188px] cursor-pointer h-14 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="text-white text-base font-bold leading-none">
                Learn More
              </div>
            </div>
          </Link>
        </div>
        <img
          className="w-full h-full object-cover rounded-lg sm:rounded-sm"
          src={listYourVilla}
          alt="List your villa"
        />
      </div>
    </div>

  );
};

export default ListYourHome;
