import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import leftArrow from "../../assets/svg/leftArrow.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetAllWishlistQuery } from "../../redux/features/userDashboard/userDashboardApi";
import WishlistCard from "./WishlistCard";

const Wishlist = () => {
  const token = useTokenFromLocalStorage();
  const [Places, setPlaces] = useState(false);
  const [sortedWishlists, setSortedWishlists] = useState([]);
  const [sortCriterion, setSortCriterion] = useState("Places"); // Default sort criterion
  const dropdownRef = useRef(null);
  const { data: wishlists, isLoading, isError } = useGetAllWishlistQuery(token);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on mount
  }, []);

  useEffect(() => {
    if (wishlists?.data) {
      handleSort(sortCriterion); // Sort wishlist whenever data or sort criterion changes
    }
  }, [wishlists, sortCriterion]);

  const handleSort = (criterion) => {
    const sorted = [...(wishlists?.data || [])].sort((a, b) => {
      if (criterion === "Places") {
        return a?.propertyId?.location?.localeCompare(b?.propertyId?.location);
      }
      if (criterion === "Most Popular") {
        return b?.propertyId?.popularity - a?.propertyId?.popularity;
      }
      if (criterion === "Price: High to Low") {
        return (
          (b?.propertyId?.pricePerNight || 0) -
          (a?.propertyId?.pricePerNight || 0)
        );
      }
      if (criterion === "Price: Low to High") {
        return (
          (a?.propertyId?.pricePerNight || 0) -
          (b?.propertyId?.pricePerNight || 0)
        );
      }
      return 0;
    });
    setSortedWishlists(sorted);
  };

  const handleToggle = () => setPlaces(!Places);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPlaces(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state while the data is being fetched
  }

  if (isError) {
    return <div>Error loading wishlist</div>; // Error handling state
  }

  return (
    <>
      {wishlists?.data?.length > 0 ? (
        <div className="max-lg:px-5">
          <div className="grid grid-cols-2 px-5 gap-20 md:gap-40">
            <div className="flex-auto flex items-center gap-x-4 text-lg font-butler text-[24px] md:text-[32px] leading-[32px] md:leading-[48px] tracking-wide">
              <button onClick={() => navigate(-1)} className="w-7">
                <img src={leftArrow} alt="Back" />
              </button>
              Wishlist
            </div>
            <div
              className="relative flex items-center md:w-full lg:w-[300px] gap-1 px-4 py-2 mt-4 md:mt-0 text-sm md:text-base font-normal font-Lato tracking-normal rounded border border-solid border-[color:var(--outline-button,#CED4DA)]"
              onClick={handleToggle}
              ref={dropdownRef}
            >
              <div className="grow text-[14px] font-Lato font-semibold">
                Sort By{" "}
                <span className="hidden md:inline">: {sortCriterion}</span>
              </div>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ffedd13c1ca93058f821007ecea9c9fce272c692c0ee419b6257aef26d5c12b6?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                className={`w-6 aspect-square transition-transform ${
                  Places ? "rotate-180" : "rotate-0"
                }`}
                alt="Dropdown Icon"
              />
              {Places && (
                <div
                  className="absolute top-10 right-0 z-50 mt-2 w-full md:w-56 rounded-md shadow-lg bg-white"
                  role="menu"
                >
                  <div className="py-1" role="none">
                    {[
                      "Most Popular",
                      "Price: High to Low",
                      "Price: Low to High",
                      "Places",
                    ].map((option) => (
                      <button
                        key={option}
                        onClick={() => setSortCriterion(option)}
                        className={`block px-4 py-2 text-sm md:text-lg font-normal w-full text-left ${
                          sortCriterion === option
                            ? "bg-gray-200 font-bold"
                            : "text-gray-700"
                        }`}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Wishlist Items */}
          <div className="flex max-md:justify-center px-5 flex-wrap gap-x-[28px] gap-y-[37px] mt-[32px]">
            <div className="flex flex-wrap justify-start gap-4 px-5 mt-8">
              {sortedWishlists.map((property) => (
                <WishlistCard
                  key={property?.id}
                  propertyData={property?.propertyId}
                  token={token}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex-auto mx-4 mb-4 lg:mb-8 flex items-center gap-x-4 max-md:text-lg font-butler text-[32px] tracking-wide">
            <button onClick={() => navigate(-1)} className="w-7 lg:hidden">
              <img src={leftArrow} alt="Back" />
            </button>
            Wishlist
          </div>
          <div className="flex max-lg:mt-8 flex-col px-5 font-semibold leading-[150%] max-w-[492px] text-zinc-950">
            <div className="text-2xl tracking-wide">
              Create your first wishlist
            </div>
            <div className="mt-[16px] text-[18px] tracking-normal leading-8 text-[#6D747A]">
              As you search, click the heart icon to save your favourite places
              and Experiences to a wishlist.
            </div>
            <Link to="/">
              <button className="w-[200px] py-4 mt-[40px] text-base font-bold text-white bg-zinc-950 rounded">
                Explore Now
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Wishlist;
