import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import thingstodoblogcover from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverSmall from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverMedium from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverLarge from "../../../../assets/svg/ThingsTodo1.webp";
import cavronRight from "../../../../assets/svg/chevron-right.webp";

const LocationCard = ({ data, id, index }) => {
  const navigate = useNavigate();
  const shortMonth = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short' });
  useEffect(() => {
    // console.log("Data:", data);
  }, [data]);

  // Array of data for multiple cards
  // const cards = [
  //   {
  //     id: 1,
  //     title: "Maharashtra",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  //   {
  //     id: 2,
  //     title: "Himachal",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  //   {
  //     id: 3,
  //     title: "Bali",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  //   {
  //     id: 4,
  //     title: "Goa",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  //   {
  //     id: 5,
  //     title: "Thailand",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  //   {
  //     id: 6,
  //     title: "Uttarakhand",
  //     description:
  //       "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  //   },
  // ];

  // const card = {
  //   id: 1,
  //   title: "Maharashtra",
  //   description:
  //     "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodationsImmerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
  // }

  return (<>
    {/* {data?.map((card) => ( */}
    <div
      key={data?._id}
      className="location-data? max-w-full"
      style={{
        gridRowEnd: index === 1 ? "span 2" : "span 1", // Example to make one card taller
      }}
    >
      <div
        className={`${index === 1 ? "h-[800px]" : "h-[348px]"
          } max-w-[628px] w-full rounded-tl-lg rounded-tr-lg relative `}
      >
        <img
          src={data?.coverPhoto?.url || thingstodoblogcover}
          srcSet={`${data?.coverPhoto || thingstodoblogcoverSmall} 480w, ${data?.coverPhoto || thingstodoblogcoverMedium} 1024w, ${data?.coverPhoto || thingstodoblogcoverLarge} 1600w`}
          sizes="(max-width: 600px) 480px, (max-width: 1200px) 1024px, 1600px"
          className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg"
          alt="Blog Cover"
        />
      </div>
      <div className="w-full border border-neutral-700/10 min-h-[358px]  px-6 py-8 bg-white flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch min-h-[235px] flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 font-Lato text-neutral-700 text-base font-medium leading-normal">
              {/* LOCATION */}
              {data?.city}
            </div>
          </div>
          <div className="self-stretch min-h-[203px] flex-col justify-start items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                {data?.title}
              </div>
            </div>
            <div className="self-stretch text-neutral-700 text-base font-Lato font-medium leading-7">
              {data?.overview}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-start items-center">
          <button
            className="flex justify-center items-center gap-2 bg-black text-base leading-4 tracking-tighter text-center font-lato font-bold text-white px-6 w-[162px] h-[48px] rounded-md hover:bg-neutral-700 transition-all"
            onClick={() => navigate(`/LocationBlog/${id}`)}
            title="View all posts"
          >
            <span className="flex items-center">View All</span>
            <img
              src={cavronRight}
              alt="icon"
              className="invert brightness-0 inline-block w-4 h-4"
            />
          </button>
        </div>
      </div>
    </div>
    {/* ))} */}
  </>
  );
};

export default LocationCard;
