import React from "react";
import SingleBlogBanner from "../../components/Blogs/SingleBlog/SingleBlogBanner";
import MustReads from "../../components/Blogs/SingleBlog/MustReads";
import BlogContent from "../../components/Blogs/SingleBlog/BlogContent";
import RelatedBlogs from "../../components/Blogs/SingleBlog/RelatedBlogs";
import { useParams } from "react-router-dom";
import { useGetSingleThingDataQuery } from "../../redux/features/thingsToDo/singleThingApi";

const SingleBlog = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetSingleThingDataQuery(id);
  if (isSuccess) {
    console.log("d", data);
  }
  // Mock data for layout development
  // const data = {
  //   coverPhoto: "https://via.placeholder.com/1500x500",
  //   city: "Sample City",
  // };
  // console.log(data)

  return (
    <div>
      <SingleBlogBanner cover={data?.coverPhoto} city={data?.city} />
      <div className="grid grid-cols-1 md:grid-cols-[4fr,1fr] pt-[88px] px-4 md:px-[72px] gap-8 md:gap-[88px]">
        {data &&
          <>
            <BlogContent data={data} />
            <MustReads data={data} />
          </>
        }
      </div>
      <div className="px-4 md:px-[72px] mt-10 md:mt-[120px] pb-10 md:pb-[144px]">
        <RelatedBlogs />
      </div>
    </div>
  );
};

export default SingleBlog;
