import React, { useContext, useState, useEffect } from "react";
import OtpModal from "../../components/modals/auth/OtpModal";
import { useSendOTPMutation } from "../../redux/features/auth/authApi";
import Otp from "./Otp";
import SocialContent, { OtpContext } from "./SocialContent";
import "./LoginWithPhone.css"; // Import custom CSS for the PhoneInput
import { OpacityContext } from "../../components/Navbar/Navbar";
import PhoneInput from "react-phone-input-2";
import PhoneInputSeparater from "../../components/PhoneInput/PhoneInput";
import Login from "./Login";
import AppleLogin from "./AppleLogin";

const LoginWithPhone = ({
  onclick,
  setPhoneN,
  setNewOtpShow,
  NewOtpShow,
  SmallOtpShow,
  setLoginPhone,
  setIsRegisterModalOpen,
  setSmallOtpShow,
  setShowMobileSIgn,
  setSmallPhoneLogin,
  setIsLoginModalOpen,
  setOpacity,
}) => {
  const { setOtpModalShow, OtpModalShow } = useContext(OtpContext);
  const datas = useContext(OpacityContext);
  const [phone, setPhone] = useState("+91");
  const [modalOtp, setModalOtp] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [OtpVerified, setOtpVerified] = useState(false);
  const [isLoginEmail, setIsLoginEmail] = useState(false);
  const [loginPhoneInfo] = useSendOTPMutation();

  const handleWithPhone = async (e) => {
    if (!modalOtp) e.preventDefault();

    const phoneNumber = phone.replace(/^\+91/, ""); 
    if (phoneNumber.length < 10 || !/^[6-9]/.test(phoneNumber)) {
      setIsPhoneValid(false); 
      return;
    }

    try {
      const res = await loginPhoneInfo({ phone: phone });
      console.log("Logging in with:", phone);
      if (window.screen.width > 768) {
        setNewOtpShow(true);
      } else {
        if (isPhoneValid) {
          setShowMobileSIgn(false);
          setSmallOtpShow(true);
        }
      }
      setOtpModalShow(true);
      setModalOtp(true);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    if (OtpVerified) {
      setSmallPhoneLogin(false);
      setIsRegisterModalOpen(true);
      setSmallOtpShow(false);
    }
  }, [OtpVerified]);

  const [newPhoneLogin, setNewPhoneLogin] = useState(datas?.loginPhone);
  useEffect(() => {
    if (newPhoneLogin !== undefined && newPhoneLogin !== datas?.loginPhone) {
      onclick();
    }
  }, [newPhoneLogin]);

  return (
    <div
      className={`${OtpModalShow ? "" : "lg:p-6 px-6 py-6 bg-white font-Lato"
        } rounded-lg text-black`}
    >
      {!OtpModalShow && !SmallOtpShow && !NewOtpShow && !isLoginEmail && (
        <div>
          <h2 className="text-[24px] leading-4 font-bold text-black mb-2 flex justify-start font-Lato">
            Login or create an account
          </h2>
          <form onSubmit={handleWithPhone} className="mt-5">
            <div className="mb-4 bg-white">
              <label
                htmlFor="Phone"
                className=" text-[#08090A] text-[16px] font-[600] flex justify-start font-Lato"
              >
                Phone
              </label>
              <div
                className={`relative phone-input-wrapper flex-col align-middle justify-center border-2 rounded-md `}
              >
                <PhoneInputSeparater
                  PlaceHolder="Enter Phone number"
                  VALUE={phone}
                  setPhone={(newPhone) => {
                    setPhone(newPhone);
                    window.innerWidth > 768 && setPhoneN(newPhone);
                    setIsPhoneValid(
                      newPhone.replace(/^\+\d+/, "").length >= 10
                    );
                  }}
                />

                {/* <PhoneInput
                  country={"in"} // Default country code (India in this case)
                  value={phone}
                  placeholder="Enter your phone number"
                  onChange={(phone) => {
                    setPhone(phone);
                    const phoneNumber = phone.replace(/^\+91/, "");
                    const isValid =
                      phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber);
                    setIsPhoneValid(isValid);
                    window.innerWidth > 768 && setPhoneN(phone);
                  }}
                  inputStyle={{
                    border: "none",
                    width: "95%",
                    height: "48px",
                    outline: "none",
                  }}
                  buttonStyle={{
                    border: "none",
                    background: "transparent",
                  }}
                  containerClass="custom-phone-input-container"
                  dropdownClass="custom-dropdown" // Add custom class for the dropdown
                /> */}
              </div>

              {!isPhoneValid && (
                <p className="text-red-500 text-sm mt-1">
                  Please Enter a Valid Phone Number
                </p>
              )}
            </div>
            <button
              type="submit"
              className="bg-black text-white text-[16px] p-2 font-[400] rounded-md w-full h-[48px]"
              disabled={!isPhoneValid} // Disable button if phone number is invalid
            >
              Continue
            </button>
          </form>
          <p className="text-[16px] font-medium font-Lato mt-2">
            Don't have an Account?{"  "}
            <span
              onClick={() => {
                setIsRegisterModalOpen(true);
              }}
              className="text-[#0D69D7] cursor-pointer ml-2"
            >
              Sign up
            </span>
          </p>
          <div className="mt-4 font-medium">
            <div className="flex items-center justify-center">
              <div className="orLine border-t border-[#CED4DA] my-4"></div>
              <span className="mx-2 font-semibold">Or</span>
              <div className="orLine border-t border-[#CED4DA] my-4"></div>
            </div>
          </div>
          <SocialContent
            setLoginPhone={setLoginPhone}
            setShowMobileSIgn={setShowMobileSIgn}
            phone={phone}
            setNewPhoneLogin={setNewPhoneLogin}
            setIsLoginModalOpen={setIsLoginModalOpen}
          />
          {/* <AppleLogin/> */}
        </div>
      )}
      {SmallOtpShow && !isLoginEmail && (
        <Otp
          setOtpVerified={setOtpVerified}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setModalOtp={setModalOtp}
          phone={phone}
        />
      )}
      {NewOtpShow && !isLoginEmail && (
        <OtpModal onclick={() => setModalOtp(false)}>
          <Otp
            setModalOtp={setModalOtp}
            phone={phone}
            handleWithPhone={handleWithPhone}
          />
        </OtpModal>
      )}
      {isLoginEmail &&

        <Login
          setLoginPhone={setLoginPhone}
          isLoginEmail={isLoginEmail}
          setOpacity={setOpacity}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setShowMobileSIgn={setShowMobileSIgn}
          phone={phone}
          setNewPhoneLogin={setNewPhoneLogin}
          setIsLoginModalOpen={setIsLoginModalOpen}
        />}
    </div>
  );
};

export default LoginWithPhone;