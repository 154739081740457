import AboutUsBanner from "../../../assets/svg/AboutUsBanner.webp";
const Banner = () => {
  return (
    <div className="2xl:h-[568px] h-[500px] w-full flex col-span-1 bg-blue-500 relative">
      <img className="w-full h-full object-cover" src={AboutUsBanner} alt="" />
      <div className=" top-0 bg-gradient-to-b from-stone-950/30 to-stone-950/30 absolute w-full h-full flex justify-center items-center">
        <div className="w-[607px] h-[147px] flex-col justify-start items-center gap-2 inline-flex">
          <div className="text-center text-white text-3xl lg:text-[56px] font-bold font-butler leading-[67.20px] tracking-wide">
            Maharashtra{" "}
          </div>
          <div className="lg:w-[607px] text-center text-white text-sm lg:text-2xl font-bold font-Lato   leading-9 tracking-tight">
            Where the beaches are endless and the good vibes never end.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
