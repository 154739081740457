import { React, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import heartIconChange from "../../../assets/svg/heart-red.webp";
import heartIcon from "../../../assets/svg/heart.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../../redux/features/userDashboard/userDashboardApi";
import { Autoplay, Pagination } from "swiper/modules";
const Card = ({ propertyData }) => {
  const token = useTokenFromLocalStorage();

  const { name, address, subCity, city, pricePerNight, tags, imageUrls, _id } =
    propertyData;

  const [addWishlist, { isLoading, isSuccess }] = useAddWishlistMutation();
  const [loading, setLoading] = useState(false);

  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
  };

  return (
    <>
      <div className="rounded-sm pb-2">
        {/* Card container */}
        <div className="2xl:h-[419px] w-[306px] rounded-sm shadow-md shadow-slate-300 mx-auto">
          {/* Card banner image */}
          <div className="relative">
            <Link to={`/propertyDetails/${_id}`}>
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                autoplay
                slidesPerView={1}
                className="h-[256px] w-[306px] rounded-t-[4px] overflow-hidden"
              >
                {imageUrls?.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={
                        img ||
                        "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                      }
                      alt="property-image"
                      className="w-[306px] h-[256px] object-cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Link>

            {/* Tag Button */}
            <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[16px] text-[#434141] px-[8px] py-[4px] rounded-[4px] font-[600] z-50">
              {tags?.[0] || "Popular Home"}
            </button>

            {/* Wishlist Button */}
            <button
              onClick={() => handleAddWishlist(_id)}
              className="absolute top-[16px] right-[16px] z-50"
            >
              <img
                className="w-[24px] h-[24px]"
                src={isSuccess ? heartIconChange : heartIcon}
                alt="wishlist-icon"
              />
            </button>
          </div>

          {/* Card info */}
          <Link to={`/propertyDetails/${_id}`}>
            <div className="px-[16px] py-[14px]">
              {/* Property Name */}
              <p className="text-[18px] text-[#000]  font-[Butler] font-[600] leading-[24px] tracking-[0.02em]">
                {name}
              </p>

              {/* Address */}
              <span className="text-[#6D747A] text-[14px] font-normal leading-5 font-Lato">
                {`${address?.slice(0, 26)}, ${subCity}, ${city}`}
              </span>

              {/* Property Details */}
              <span className="block text-[#434141] text-[14px] font-[400] font-Lato leading-5 mt-[4px]">
                12 Guests • 5 Bedrooms • 5 Bathrooms
              </span>

              {/* Pricing */}
              <div className="mt-[12px]">
                <p className="text-[#6D747A] text-[14px] font-Lato font-[400] leading-5">
                  Starting from
                </p>
                <p className="text-black text-[18px] font-Lato font-[600] leading-7 mt-[2px]">
                  ₹ {pricePerNight || 10000}
                  <span className="text-[#434141] text-[14px] font-Roboto font-[400] leading-6">
                    {" "}
                    / night + Taxes
                  </span>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Card;
