import React, { useRef, useState } from "react";
import arr from "../../assets/images/EventsAndExp/arrow.webp";
import MostTrendingProperty from "../LuxuryProperties/MostTrending";

const ExplorePopular = () => {
  const mostTrendingRef = useRef(null);
  const [showAll, setShowAll] = useState(false); // State to toggle between Swiper and Grid

  const handleNext = () => {
    mostTrendingRef.current?.slideNext();
  };

  const handlePrev = () => {
    mostTrendingRef.current?.slidePrev();
  };

  const toggleView = () => {
    setShowAll((prev) => !prev); // Toggle between Swiper and Grid view
  };

  return (
    <div className="mt-[72px] max-w-[1298px] w-[90%] px-4">
      {/* Heading and button */}
      <div className="flex flex-col sm:flex-row mb-8 justify-between items-start sm:items-center">
        <h2 className="font-butler max-md:text-center text-2xl sm:text-[32px] leading-[36px] sm:leading-[48px] tracking-[0.32px] mb-4 sm:mb-0">
          Explore popular experiences
        </h2>
      
      </div>

      {/* Cards container */}
      <div>
        {showAll ? (
          <MostTrendingProperty hideHeading={true} isGrid={true} />
        ) : (
          <MostTrendingProperty ref={mostTrendingRef} hideHeading={true} />
        )}
      </div>

      {/* Forward/Backward buttons for Swiper */}
      {!showAll && (
        <div className="flex justify-end mt-6 sm:mt-10 gap-0 lg:gap-4 mb-[72px]">
          <button className="p-2" onClick={handlePrev}>
            <img
              src={arr}
              alt="arrow"
              className="h-6 w-6 sm:h-[48px] sm:w-[48px]"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
          <button className="p-2" onClick={handleNext}>
            <img
              src={arr}
              alt="arrow"
              className="h-6 w-6 sm:h-[48px] sm:w-[48px]"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default ExplorePopular;
