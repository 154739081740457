import React, { useState } from "react";
import arr from "../../assets/images/EventsAndExp/arrow.webp";
import arrFade from "../../assets/images/EventsAndExp/arrpwDisable.webp";
import Card from "./Card";

const SubSec = (props) => {
  const [showAll, setShowAll] = useState(false); // State to handle toggling
  const [currentIndex, setCurrentIndex] = useState(0); // State for swiper index
  const visibleCards = 3; // Number of visible cards in swiper

  const totalCards = props.data.card?.length || 0;
  const maxIndex = totalCards - visibleCards; // Maximum index for the swiper

  // Handle swiper navigation
  const handleForwardScroll = () => {
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBackwardScroll = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Slice the cards for swiper or display all for grid view
  const visibleCardsArray = showAll
    ? props.data.card.slice(0, 4) // Show first 4 cards when toggled
    : props.data.card?.slice(currentIndex, currentIndex + visibleCards); // Default swiper view

  // Toggle between swiper and grid view
  const handleToggleView = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="p-4">
      {/* Sub section heading */}
      <h3 className="text-[20px] font-butler lg:text-2xl text-[#000000] sm:text-[20px] leading-[36px] sm:leading-[42px] tracking-[0.28px] mb-4">
        {props.data.name}
      </h3>
      {/* Sub Section text */}
      <p className="text-[#08090A] text-base sm:text-lg leading-6 sm:leading-[28.8px] mb-8 tracking-[0.16px]">
        {props.data.txt}
      </p>

      {/* View All Button */}
      <div className="flex justify-between sm:justify-end mb-4">
        <button
          onClick={handleToggleView}
          className="text-[#0F6DDC] text-base sm:text-lg font-bold leading-6 sm:leading-[27px]"
        >
          {showAll ? "SHOW LESS" : "VIEW ALL"}
        </button>
      </div>

      {/* Display Cards */}
      {!showAll ? (
        <div className="relative">
          {/* Swiper View */}
          <div className="flex gap-5 overflow-hidden max-w-full sm:max-w-[858px]">
            {visibleCardsArray?.map((item) => (
              <Card key={item.id} data={item} />
            ))}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-end gap-0 lg:gap-4 mb-8 mt-4">
            {/* Backward button */}
            <button
              onClick={handleBackwardScroll}
              disabled={currentIndex === 0}
              className={`p-2 ${
                currentIndex === 0 ? "opacity-50" : " rotate-180 "
              }`}
            >
              <img
                src={currentIndex === 0 ? arrFade : arr}
                alt="arrow-backward"
                className="h-6 w-6 sm:h-6 sm:w-6 lg:h-12 lg:w-12"
              />
            </button>

            {/* Forward button */}
            <button
              onClick={handleForwardScroll}
              disabled={currentIndex >= maxIndex}
              className={`p-2 ${
                currentIndex >= maxIndex ? "opacity-50 -rotate-180" : "rotate-0 "
              }`}
            >
              <img
                src={currentIndex >= maxIndex ? arrFade : arr}
                alt="arrow-forward"
                className="h-6 w-6 sm:h-6 sm:w-6 lg:h-12 lg:w-12"
              />
            </button>
          </div>
        </div>
      ) : (
        // Grid View for showing 4 cards (2 per row)
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 max-w-full">
          {visibleCardsArray?.map((item) => (
            <Card key={item.id} data={item} />
          ))}
        </div>
      )}

      {/* Load More Button */}
      <div className="flex justify-center mt-8">
        <button
          onClick={handleToggleView}
          className="px-4 py-2 text-[#08090A] font-semibold leading-6 -tracking-[0.16px] rounded border border-[#08090A] min-w-[200px] sm:min-w-[294px]"
        >
          {showAll ? "SHOW LESS" : "LOAD MORE"}
        </button>
      </div>
    </div>
  );
};

export default SubSec;
