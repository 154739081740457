import React, { useState } from "react";
import chat from "../../assets/svg/chatWhite.webp";
import ChatModalRemaster from "../../components/FloatingModal/ChatModalRemaster";

const ChatSupportPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pause, setPause] = useState(true);

  const pauseHandler = () => {
    if (isOpen === false) {
      setPause(true);
    }
  };

  return (
    <div
      onClick={() => {
        setIsOpen(!isOpen);
        pauseHandler();
        console.log(pause);
      }}
      className={
        isOpen
          ? "bg-none z-40 fixed right-[24px] bottom-[24px] p-[15px] rounded-full"
          : "bg-[#08090A] z-40 cursor-pointer fixed right-[24px] bottom-[24px] p-[15px] rounded-full"
      }
    >
      <img
        src={chat}
        alt="chat"
        className={isOpen ? "hidden" : "h-[36px] w-[36px]"}
      />
      <div className="relative w-full">
        <div
          className={isOpen ? "fixed top-0 bg-black left-0 h-full w-auto" : "hidden"}
        ></div>
        <ChatModalRemaster
          pauseVal={pause}
          pauseValSetter={setPause}
          func={setIsOpen}
          open={isOpen}
        ></ChatModalRemaster>
      </div>
    </div>
  );
};

export default ChatSupportPopup;
