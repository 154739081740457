import { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import cameraIcon from "../../assets/svg/cameraIcon.webp";
import cameraIconTwo from "../../assets/svg/CameraIconTwo.webp";
import cross from "../../assets/svg/CrossGray.webp";
import leftArrow from "../../assets/svg/downTick.webp";
import rightTick from "../../assets/svg/rightTick.webp";
import uploadIcon from "../../assets/svg/upload.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { BaseURL } from "../../redux/config";
import { useUpdateUserDataMutation } from "../../redux/features/auth/authApi";
const GovernmentId = ({ capturedImage, setCapturedImage, profileData ,Disabled}) => {
  const [uploadGovernmentId, setUploadGovernmentId] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadImg, setUploadImg] = useState(true);
  const [showUploadImg, setshowUploadImg] = useState(null);
  const [updateUserData, { isSuccess }] = useUpdateUserDataMutation();
  const [SubmitImg, setSubmitImg] = useState(null);
  const [uploadImages, setUploadImages] = useState(null);
  const [uploadGovernMentId, setUploadGovernMentId] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [showCountryDropDOwn, setShowCountryDropDown] = useState(false);
  const [selectedImg, SetSelectedImg] = useState(null);

  const countryNames = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong SAR China",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao SAR China",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Samoa",
    "San Marino",
    "São Tomé and Príncipe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia & South Sandwich Islands",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Barthélemy",
    "St. Helena",
    "St. Kitts & Nevis",
    "St. Lucia",
    "St. Martin",
    "St. Pierre & Miquelon",
    "St. Vincent & Grenadines",
    "Sudan",
    "Suriname",
    "Svalbard & Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad & Tobago",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks & Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wallis & Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  const [showWebCam, setShowWebCam] = useState(null);
  const [webcamImg, setWebcamImg] = useState(false);
  const token = useTokenFromLocalStorage();
  const [IdType, setIdType] = useState("Driving License");
  const webcamRef = useRef(null);

  const handleFileUpload = (event) => {
    if (event.target.files.length > 0) {
      setUploadedFileName(event.target.files[0].name);
      console.log(event.target.files[0].name) // Get uploaded file name
    }
  };
  const handleSubmit = () => {
    if (!uploadedFileName) {
      alert("Please upload a file before submitting.");
    } else {
      setUploadGovernmentId(true); // Simulate setting the uploaded status
      alert(`Submitted: ${uploadedFileName}`);
    }
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = dataURLtoFile(imageSrc, "webcam_image.jpg");
    setCapturedImage(file);
    setSubmitImg(true);
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    if (capturedImage !== null) {
      console.log(capturedImage);
    }
  }, [capturedImage]);
  const subMitGovernmentImg = () => {
    const formData = new FormData();
    formData.append("image", selectedImg);
    fetch(`${BaseURL}/images/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("received data",data);
        var ImageName = (data.data);
        setUploadedFileName(data.data)
        const datas = {
          ...profileData,
          governmentIds: [
            {
              type: IdType,
              issuingCountry: selectedCountry,
              image: data.image,
              fileName: selectedImg.name,
            },
          ],
        };
        fetch(`${BaseURL}/auth/profile/update/`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datas),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.message === "User details updated successfully") {
              setUpdatedInfo(true);
              SetSelectedImg(null);
            }
          });
      });
  };
  const submitWebCamImg = () => {
    const formData = new FormData();
    formData.append("image", capturedImage);
    fetch(`${BaseURL}/images/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const datas = {
          ...profileData,
          profilePhoto: data.image,
        };
        fetch(`${BaseURL}/auth/profile/update/`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datas),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log(response);
            if (response?.message === "User details updated successfully") {
              setUpdatedInfo(true);
              setCapturedImage(null);
            }
          });
      });
  };

  const blob = new Blob([selectedImg]);
  const objectURL = URL.createObjectURL(blob);
  return (
    <div className="max-md:px-4">
      <h1
        className="text-zinc-950
                  text-base
                  font-semibold
                  mt-6
                  leading-normal
                  tracking-tight"
      >
        Government ID
      </h1>
      <div
        onClick={() => setUploadGovernMentId(true)}
        role="button"
        className="w-[174px] h-6 mt-3 justify-start items-center gap-3 inline-flex"
      >
        <div className="text-blue-700 text-base font-semibold  leading-normal flex gap-x-3 items-center tracking-tight">
          <FaPlus />
          {uploadedFileName || "Add government ID"}       
           </div>
      </div>
      {uploadGovernMentId && (
        <div className="w-full flex items-center font-Lato justify-center left-0 bg-opacity-70 h-full fixed bg-black top-0 z-50">
          <div className="w-[424px] h-[400px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div className="text-zinc-950 text-xl font-bold  leading-[30px] text-nowrap">
                Upload government ID
              </div>
              <div
                onClick={() => setUploadGovernMentId(false)}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="h-48 flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div className="w-[376px] text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                  We’ll need you to add an official government ID. This step
                  helps make sure you’re really you.
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative flex items-center">
                    <input
                      onClick={() => {
                        setUploadImg(true);
                        setWebcamImg(false);
                      }}
                      checked={uploadImg}
                      type="radio"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                    Upload an existing photo (Recommended)
                  </div>
                </div>
                <div className="flex-col justify-center items-start gap-2 flex">
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="w-6 h-6 relative flex items-center">
                      <input
                        onClick={() => {
                          setUploadImg(false);
                          setWebcamImg(true);
                        }}
                        type="radio"
                        checked={webcamImg}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                      Use webcam to take a picture
                    </div>
                  </div>
                </div>
                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-zinc-500 text-sm font-normal  leading-[21px]">
                    {" "}
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium  leading-normal">
                      Privacy
                    </span>
                    <span className="text-blue-700 text-base font-medium  leading-normal tracking-tight">
                      {" "}
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium  leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              role="button"
              onClick={() => {
                if (uploadImg) {
                  setshowUploadImg(true);
                } else if (webcamImg) {
                  setShowWebCam(true);
                }
              }}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center cursor-pointer items-center gap-2.5 flex"
            >
              <div className="text-white text-base font-bold  leading-none ">
                Continue
              </div>
            </div>
          </div>
        </div>
      )}
      {showWebCam && (
        <div className="w-full flex items-center justify-center left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[424px] h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div
                onClick={() => {
                  setShowWebCam(false);
                  setshowUploadImg(false);
                }}
                role="button"
                className="w-6 h-6 absolute left-0 flex-col justify-start top-3 rotate-90 items-start flex"
              >
                <img src={leftArrow} alt="" />
              </div>
              <div className="text-zinc-950 text-xl font-bold  leading-[30px] text-nowrap ">
                Take photo
              </div>
              <div
                onClick={() => {
                  setShowWebCam(false);
                  setWebcamImg(false);
                }}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="h-full flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div>
                  <div className="w-[376px] text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                    Make sure your photos aren’t blurry and the front of your
                    identity card clearly shows your face.
                  </div>
                </div>
                <div className="w-[377px] h-[199px] bg-white flex-col justify-center items-center gap-2 relative flex">
                  <div className="w-[376px] absolute h-[199px]  bg-neutral-700 rounded-lg border border-black/opacity-20" />
                  <div className="w-[318px] border-dashed h-[164px] bg-zinc-950 rounded-lg border-2 border-gray-300 z-40" />
                </div>
                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-zinc-500 text-sm font-normal  leading-[21px]">
                    {" "}
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium  leading-normal">
                      Privacy
                    </span>
                    <span className="text-blue-700 text-base font-medium  leading-normal tracking-tight">
                      {" "}
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium  leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              onClick={() => setSubmitImg(true)}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex cursor-pointer"
            >
              <div className="text-white text-base font-bold  leading-none flex gap-[19px] items-center">
                <img src={cameraIcon} width="15" height="12" alt="" />
                Take photo
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {showWebCam && (
        <div className="w-full flex items-center justify-center font-Lato left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[424px] h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center flex">
              <div
                onClick={() => {
                  setCapturedImage(null);
                  setShowWebCam(false);
                  setShowWebCam(false);
                }}
                role="button"
                className="w-6 h-6 absolute left-0 flex-col justify-start top-3 rotate-90 items-start flex"
              >
                <img src={leftArrow} alt="" />
              </div>
              <div className="text-zinc-950 text-xl font-bold leading-[30px] text-nowrap">
                Take photo
              </div>
              <div
                onClick={() => {
                  setCapturedImage(null);
                  setShowWebCam(false);
                  setWebcamImg(false);
                }}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="h-full flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div>
                  <div className="w-[376px] text-zinc-950 text-base font-normal leading-normal tracking-tight">
                    Make sure your photos aren’t blurry and the front of your
                    identity card clearly shows your face.
                  </div>
                </div>
                <div className="w-[377px] h-[199px] bg-white flex-col justify-center items-center gap-2 relative flex">
                  {!capturedImage ? (
                    <Webcam
                      audio={false}
                      height={199}
                      mirrored={true}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={377}
                      videoConstraints={{
                        width: 377,
                        height: 199,
                        facingMode: "user",
                      }}
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(capturedImage)}
                      alt="Captured"
                    />
                  )}
                </div>
                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium leading-normal">
                      Privacy{" "}
                    </span>
                    <span className="text-blue-700 text-base font-medium leading-normal tracking-tight">
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="self-stretch h-12  py-4  rounded  flex-col justify-center cursor-pointer items-center w-full gap-2.5 flex">
              {!capturedImage ? (
                <div
                  onClick={capture}
                  className="text-white  py-4 rounded text-center w-full bg-zinc-950 text-base flex items-center justify-center gap-x-2 font-bold leading-none"
                >
                  <img src={cameraIconTwo} width="15" height="12" alt="" />
                  Click Picture
                </div>
              ) : (
                <>
                  <div className="w-full  flex gap-x-[14px]">
                    <div className="w-full h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center cursor-pointer items-center gap-2.5 inline-flex">
                      <div
                        className="text-zinc-950 text-base font-bold leading-none"
                        onClick={() => {
                          setCapturedImage(null);
                        }}
                      >
                        Retake Photo
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        submitWebCamImg();
                        setSubmitImg(true);
                        setShowWebCam(false);
                        setUploadGovernMentId(false);
                      }}
                      className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col w-full justify-center items-center gap-2.5 flex"
                    >
                      <div className="text-white text-base font-bold  leading-none flex gap-[19px] items-center">
                        Submit photo
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {SubmitImg && (
        <div className="w-full flex items-center justify-center left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[424px] h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div
                onClick={() => {
                  setSubmitImg(false);
                }}
                role="button"
                className="w-6 h-6 absolute left-0 flex-col justify-start top-3 rotate-90 items-start flex"
              >
                <img  src={leftArrow} alt="" />
              </div>
              <div className="text-zinc-950 text-xl font-bold  leading-[30px] text-nowrap">
                Take photo
              </div>
              <div
                onClick={() => {
                  setShowWebCam(false);
                  setWebcamImg(false);
                  setSubmitImg(false);
                  setUploadGovernMentId(false);
                }}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img  src={cross} alt="" />
              </div>
            </div>
            <div className="h-full flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div>
                  <div className="w-[376px] text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                    Make sure your photos aren’t blurry and the front of your
                    identity card clearly shows your face.
                  </div>
                </div>
                <div className="w-[377px] h-[199px] bg-white flex-col justify-center items-center gap-2 relative flex">
                  <div className="w-[376px] absolute h-[199px]  bg-neutral-700 rounded-lg border border-black/opacity-20" />
                  <div className="w-[318px] border-dashed h-[164px] bg-zinc-950 rounded-lg border-2 border-gray-300 z-40" />
                </div>
                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-zinc-500 text-sm font-normal  leading-[21px]">
                    {" "}
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium  leading-normal">
                      Privacy
                    </span>
                    <span className="text-blue-700 text-base font-medium  leading-normal tracking-tight">
                      {" "}
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium  leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-x-[14px]">
              <div className="w-full h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center cursor-pointer items-center gap-2.5 inline-flex">
                <div
                  className="text-zinc-950 text-base font-bold leading-none"
                  onClick={() => setSubmitImg(false)}
                >
                  Retake Photo
                </div>
              </div>
              <div
                onClick={() => {
                  setSubmitImg(false);
                  setShowWebCam(false);
                  setWebcamImg(false);
                  setUpdatedInfo(true);
                  setUploadGovernMentId(false);
                }}
                className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col w-full justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold  leading-none flex gap-[19px] items-center">
                  Submit photo
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showUploadImg && (
        <div className="w-full flex items-center justify-center font-Lato left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[424px] h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div
                onClick={() => {
                  setshowUploadImg(false);
                }}
                role="button"
                className="w-6 h-6 absolute left-0 flex-col justify-start top-3 rotate-90 items-start flex"
              >
                <img src={leftArrow} alt="" />
              </div>
              <div className="text-zinc-950 text-xl font-bold  leading-[30px] text-nowrap">
                Choose ID type
              </div>
              <div
                onClick={() => {
                  setshowUploadImg(false);
                  setUploadGovernMentId(false);
                }}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="h-full flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div className="relative">
                  <div className="text-zinc-950 text-base font-semibold  leading-normal tracking-tight">
                    Issuing country/region
                  </div>
                  <div
                    onClick={() => setShowCountryDropDown(!showCountryDropDOwn)}
                    className="w-[376px] cursor-pointer mt-2 h-12 px-4 py-3 rounded border border-gray-300 justify-between items-center inline-flex"
                  >
                    <div className="text-zinc-500 text-base font-normal  leading-normal tracking-tight">
                      {selectedCountry}
                    </div>
                    <div className="w-6 h-6 relative flex items-center">
                      <img src={leftArrow} alt="" />
                    </div>
                  </div>
                  {showCountryDropDOwn && (
                    <div className="w-full z-50 bg-white h-[200px] overflow-y-scroll absolute">
                      {countryNames?.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedCountry(item);
                              setShowCountryDropDown(false);
                            }}
                            className="w-full"
                            key={index}
                          >
                            <div className="w-full hover:bg-zinc-200 cursor-pointer px-4 py-3 rounded border border-zinc-950/20 my-1 justify-between items-center inline-flex">
                              <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                                {item}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative flex items-center">
                    <input
                      onClick={() => setIdType("Driving License")}
                      checked={IdType === "Driving License"}
                      type="radio"
                      className="w-full h-full"
                      name="name"
                    />
                  </div>
                  <div className="text-zinc-950 text-base font-normal leading-normal tracking-tight">
                    Driver's License
                  </div>
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative flex items-center">
                    <input
                      onClick={() => setIdType("Passport")}
                      checked={IdType === "Passport"}
                      type="radio"
                      className="w-full h-full"
                      name="name"
                    />
                  </div>
                  <div className="text-zinc-950 text-base font-normal leading-normal tracking-tight">
                    Passport
                  </div>
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative flex items-center">
                    <input
                      onClick={() => setIdType("Identity card")}
                      checked={IdType === "Identity card"}
                      type="radio"
                      className="w-full h-full"
                      name="name"
                    />
                  </div>
                  <div className="text-zinc-950 text-base font-normal leading-normal tracking-tight">
                    Identity card
                  </div>
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative flex items-center">
                    <input
                      onClick={() => setIdType("Aadhaar")}
                      checked={IdType === "Aadhaar"}
                      type="radio"
                      className="w-full h-full"
                      name="name"
                    />
                  </div>
                  <div className="text-zinc-950 text-base font-normal leading-normal tracking-tight">
                    Aadhaar card
                  </div>
                </div>
                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-zinc-500 text-sm font-normal  leading-[21px]">
                    {" "}
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium  leading-normal">
                      Privacy
                    </span>
                    <span className="text-blue-700 text-base font-medium  leading-normal tracking-tight">
                      {" "}
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium  leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setUploadImages(true);
              }}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center cursor-pointer gap-2.5 flex"
            >
              <div className="text-white text-base font-bold  leading-none ">
                Continue
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadImages && (
        <div className="w-full flex items-center justify-center font-Lato left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[424px] h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div
                onClick={() => {
                  setUploadImages(false);
                  SetSelectedImg(null);
                }}
                role="button"
                className="w-6 h-6 absolute left-0 flex-col justify-start top-[10px] rotate-90 items-start flex"
              >
                <img src={leftArrow} alt="" />

              </div>
              <div className="text-zinc-950 text-xl font-bold  leading-[30px] text-nowrap">
                Upload images
              </div>
              <div
                onClick={() => {
                  setUploadImages(false);
                  setshowUploadImg(false);
                  setUploadGovernMentId(false);
                  SetSelectedImg(null);
                }}
                role="button"
                className="w-6 h-6 absolute right-0 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="h-full flex-col justify-start items-start gap-12 flex">
              <div className="flex-col justify-start items-start gap-4 flex">
                <div className="w-[376px] text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                  Make sure your photos aren’t blurry and the front of your
                  identity card clearly shows your face.
                </div>
                <div className="w-[377px] h-[199px] flex-col justify-start items-start gap-2 inline-flex">
                  {selectedImg !== null && (
                    <>
                      <img
                        src={objectURL}
                        className="w-full object-cover h-full rounded-lg max-h-[200px]"
                        alt=""
                      />
                      <input
                        // onChange={(e) => SetSelectedImg(e.target.files[0])}
                        onChange={handleFileUpload}
                        className="absolute opacity-0 w-full h-full rounded-lg max-h-[200px]"
                        type="file"
                      />
                    </>
                  )}
                  {selectedImg === null && (
                    <div className=" w-full pt-[23px] pb-[22px] bg-white rounded-lg border border-black/opacity-20 relative justify-center items-center inline-flex">
                      <div className="self-stretch flex-col justify-start relative w-full  items-center gap-2 inline-flex">
                        <img width="47" height="46" src={uploadIcon} alt="" />
                        <input
                          onChange={(e) => SetSelectedImg(e.target.files[0])}
                          className="absolute opacity-0 w-full h-full"
                          type="file"
                        />
                        <div className="text-zinc-950 text-base font-semibold text-nowrap leading-normal">
                          Drag and drop files here
                        </div>
                        <div className="text-zinc-500 text-[10px] font-normal ">
                          or
                        </div>
                        <div className="w-[137px] px-6 py-4 bg-zinc-950 rounded-[100px] flex-col justify-center items-center gap-2.5 flex">
                          <div className="text-white text-base font-bold text-nowrap leading-none">
                            Browse Files
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-[376px]">
                  <span className="text-zinc-500 text-base font-normal  leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-zinc-500 text-sm font-normal  leading-[21px]">
                    {" "}
                  </span>
                  <Link to="/privacyPolicy">
                    <span className="text-blue-600 text-base font-medium  leading-normal">
                      Privacy
                    </span>
                    <span className="text-blue-700 text-base font-medium  leading-normal tracking-tight">
                      {" "}
                      Policy
                    </span>
                    <span className="text-zinc-950 text-base font-medium  leading-normal tracking-tight">
                      .
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                subMitGovernmentImg();
                setUploadGovernMentId(false);
                setUploadImages(false);
                setshowUploadImg(false);
                setUploadGovernMentId(false);
              }}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex cursor-pointer"
            >
              <div className="text-white text-base font-bold  leading-none "   onClick={handleSubmit}>
                Submit
              </div>
            </div>
          </div>
        </div>
      )}
      {updatedInfo && (
        <div className="w-full bg-black flex items-center justify-center left-0 bg-opacity-70 h-full fixed top-0 z-50">
          <div className="w-[386px] relative bg-white rounded-2xl flex-col justify-start items-start gap-10 inline-flex">
            <div className="w-full relative justify-center items-center  flex">
              <div
                onClick={() => {
                  setUpdatedInfo(false);
                }}
                role="button"
                className="w-6 top-4 h-6 absolute right-4 flex-col justify-start items-start flex"
              >
                <img src={cross} alt="" />
              </div>
            </div>
            <div className="w-full h-[248px] px-6 py-11 flex-col justify-start items-center gap-6 inline-flex">
              <div className="w-20 h-20 pl-[17.37px] pr-[16.63px] pt-[17.37px] pb-[16.63px] bg-zinc-950 rounded-[482px] justify-center items-center inline-flex">
                <div className="w-[46px] h-[46px] relative rounded-md flex-col justify-start items-start flex">
                  <img src={rightTick} width="47" height="47" alt="" />
                </div>
              </div>
              <div className="self-stretch h-14 flex-col justify-start items-center gap-1 flex">
                <div className="self-stretch text-center text-zinc-950 text-lg font-bold  leading-7">
                  Your details have been updated.
                </div>
                <div className="self-stretch text-center text-neutral-700 text-base font-normal  leading-normal">
                  Your changes have been saved.
                </div>
              </div>
              <div className="h-[0px]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GovernmentId;
