import React, { useEffect, useState } from "react";
import MustReadsCard from "../SingleBlog/MustReadsCard";

const MustReads = ({ data }) => {
  const [allData, setAllData] = useState([]);

  const AllBlogFetching = async () => {
    try {
      const response = await fetch(`https://api.ukiyostays.com/api/blogs/`);
      const responseData = await response.json();

      // Filter blogs to exclude the current blog
      const allblogs = responseData.filter((blog) => blog?._id !== data?._id);
      // console.log("Filtered Blogs:", allblogs, data);

      setAllData(allblogs); // Set state
    } catch (error) {
      console.error("Error fetching all blogs:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  })

  useEffect(() => {
    if (data) {
      AllBlogFetching();
    }
  }, []); // Runs once on component mount

  return (
    <div className="hidden lg:block">
      <div className="flex flex-col gap-8">
        {allData.length > 0 && <MustReadsCard data={allData} />}
      </div>
    </div>
  );
};

export default MustReads;
