import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Slider from "react-slider";
import iconBottom from "../../assets/images/Faqs/bottom.webp";
import iconTop from "../../assets/images/Faqs/topIcon.webp";
import close from "../../assets/svg/close.webp";
import dropdown from "../../assets/svg/downArrow.svg";
import HeartImg from "../../assets/svg/HeartImg.webp";
import leftArrow from "../../assets/svg/leftArrow.webp";
import map from "../../assets/svg/location.webp";
import minus from "../../assets/svg/Minus.webp";
import plus from "../../assets/svg/plus.webp";
import star from "../../assets/svg/start1.webp";
import wifi from "../../assets/svg/wifi1.webp";
import wifiGreen from "../../assets/svg/wifigreen.webp";
import Container from "../ui/Container";
import "./LuxuryVillas.css";
import VillaCard from "./VillaCard";

const LuxuryVillas = ({searchData, token, SearchTag  }) => {
  console.log("search data in Luxuryvillas : ",searchData?.data?.availableHotels[0]?.city)
  const city = searchData?.data?.availableHotels[0]?.city;
  const [LuxuryVillasCard, setLuxuryVillasCard] = useState([]);
  const [result, setResult] = useState([]);

  const [show, setShow] = useState(5);
  const [roomCounting, setRoomCounting] = useState(1);
  const [Recommended, setRecommended] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(24999);
  const [rangeValue, setRangeValue] = useState([minValue, maxValue]);
  const [tempRangeValue, setTempRangeValue] = useState([minValue, maxValue]);

  const ref = useRef(null);
  const [showTopFilters, setshowTopFilters] = useState(false);
  const [showRoomCount, setshowRoomCount] = useState(false);
  const [showSortBy, setSortBy] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [typeofStay, setTypeofStay] = useState(false);
  const navigate = useNavigate();
  const [sort, setSort] = useState({ label: "Recommended", key: "" });
  const [topFilter, setTopFilter] = useState({
    // tag: [{ label: "", key: "", isSelected: false }],
    // amenities: [{ label: "", key: "", isSelected: false }],
    // bestRated: [{ label: "Best Rated", key: "best_rated", isSelected: false }],
    // newHomes: [{ label: "New Homes", key: "new_homes", isSelected: false }],
  });
  const [Tags, setTags] = useState([]);
  const [checkboxData, setCheckboxData] = useState([
    { label: "Villas", isSelected: false },
    { label: "Luxury Villas", isSelected: false },
    { label: "Homestays", isSelected: false },
    { label: "Bungalows", isSelected: false },
    { label: "Apartments", isSelected: false },
    { label: "Elite villas", isSelected: false },
  ]);
  // location
  const location = useLocation();
  // const city = location?.state?.location;
  const checkIn = location?.state?.checkIn;
  const CheckOut = location?.state?.checkOut;

  const sortData = [
    { label: "Price per night with taxes", key: "" },
    { label: "Most Popular", key: "" },
    { label: "Ukiyo Exclusive", key: "" },
    { label: "Recommended", key: "" },
    { label: "Price : Low to High", key: "price-low-to-high" },
    { label: "Price : High to Low", key: "price-high-to-low" },
    { label: " Newest First", key: "rating-high-to-low" },
    { label: "Is Featured", key: "true" },
  ];
  const handleToggle = () => {
    setRecommended(!Recommended);
  };

  // const responseMapper = (response) => {
  //   const responseData = response;
  //   const HotelData = responseData?.data;
  //   const hotelDetails = HotelData?.availableHotels;

  //   const availableHotels = hotelDetails?.map((hotel) => {
  //     const id = hotel?._id;
  //     const hotelName = hotel?.name;
  //     const address = `${hotel?.address ? hotel.address + " " : ""}${
  //       hotel?.city || ""
  //     }`;
  //     const amenities =
  //       hotel?.amenities?.length > 10
  //         ? "10 + amenities"
  //         : hotel?.amenities?.length + " amenities";
  //     const price = hotel?.pricePerNight;
  //     const rating = hotel?.rating;
  //     const reviews = hotel?.reviews?.length;
  //     const images = hotel?.imageUrls?.map((url) => {
  //       const image = url + "";
  //       const imageObj = { slideImages: image };
  //       return imageObj;
  //     });

  //     const hotelObj = {
  //       id: id,
  //       heading: hotelName,
  //       heartIcon: HeartImg,
  //       mapIcon: map,
  //       mapIconText: address,
  //       entireVilla:
  //         "Entire Villa      I  4-7 Guests  I  2 Bedroom  I  1 Pool ",
  //       amentiesSearchIcon: amenities,
  //       amentiesSearchIconText: "Pets Allowed",
  //       wifiGreen: wifiGreen,
  //       wifiIcon: wifi,
  //       wifiIconText1: "Pool",
  //       wifiIconText2: "Wifi",
  //       wifiIconText3: "Breakfast Included",
  //       textAmenities: amenities + " Amenities",
  //       starting: "starting from",
  //       Rs: "₹ " + price,
  //       taxes: "+ taxes & fees/Per night",
  //       startIcon: star,
  //       reviews: `${rating !== undefined ? rating + "" : "0 reviews"}${
  //         reviews !== undefined ? `(${reviews} reviews)` : ""
  //       }`,
  //       images: images,
  //       hotelOverview: hotel?.hotelOverview,
  //     };
  //     return hotelObj;
  //   });
  //   setResult(availableHotels);
  //   setLuxuryVillasCard(availableHotels);
  // };
  
  const responseMapper = (response) => {
    const responseData = response;
    const HotelData = responseData?.data;
    const hotelDetails = HotelData?.availableHotels;

    const availableHotels = hotelDetails?.map((hotel) => {
      const id = hotel?._id;
      const hotelName = hotel?.name;
      const address = `${hotel?.address ? hotel.address + " " : ""}${hotel?.city || ""}`;
      // const address = `${hotel?.address ? hotel.address + ", " : ""}${hotel?.subCity ? hotel.subCity + ", " : ""}${hotel?.city || ""}`;

      const amenities = hotel?.amenities?.length > 10
        ? "10 + amenities"
        : `${hotel?.amenities?.length} amenities`;
      const price = hotel?.pricePerNight;

      const rating = hotel?.rating || 0;
      const totalReviews = hotel?.ratingDetails.totalReviews || "no";

      const images = hotel?.imageUrls?.map((url) => {
        const image = url + "";
        const imageObj = { slideImages: image };
        return imageObj;
      });

      const hotelObj = {
        id: id,
        heading: hotelName,
        heartIcon: HeartImg,
        mapIcon: map,
        mapIconText: address,
        entireVilla: "Entire Villa      I  4-7 Guests  I  2 Bedroom  I  1 Pool ",
        amentiesSearchIcon: amenities,
        amentiesSearchIconText: "Pets Allowed",
        wifiGreen: wifiGreen,
        wifiIcon: wifi,
        wifiIconText1: "Pool",
        wifiIconText2: "Wifi",
        wifiIconText3: "Breakfast Included",
        textAmenities: `${amenities} Amenities`,
        starting: "starting from",
        Rs: `₹ ${price}`,
        taxes: "+ taxes /Per night",
        startIcon: star,
        reviews: `${rating} (${totalReviews} reviews)`, // Format using `rating` and `totalReviews`
        images: images,
        hotelOverview: hotel?.hotelOverview,
      };
      return hotelObj;
    });
    setResult(availableHotels);
    setLuxuryVillasCard(availableHotels);
  };


  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth();
    let year = date?.getFullYear();
    let formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDate;
  };

  const filterData = useCallback(async () => {
    const bestRated = topFilter?.bestRated?.[0]?.isSelected
      ? `&bestRated=${topFilter.bestRated[0].isSelected}`
      : "";
    const newHomes = topFilter?.newHomes?.[0]?.isSelected
      ? `&newHomes=${topFilter.newHomes[0].isSelected}`
      : "";
    const minPrice = rangeValue?.[0] > 0 ? `&minPrice=${rangeValue[0]}` : "";
    const maxPrice = rangeValue?.[1] > 0 ? `&maxPrice=${rangeValue[1]}` : "";
  
    const hotelType = checkboxData
      ?.filter((item) => item.isSelected)
      ?.map((item) => item.label)
      .join(",");
  
    const tag = topFilter?.tag
      ?.filter((item) => item.isSelected)
      ?.map((item) => item.label)
      .join(",");
  
    const amenities = topFilter?.amenities
      ?.filter((item) => item.isSelected)
      ?.map((item) => item.label)
      .join(",");
  
    const hotelTypeEP = hotelType?.length > 0 ? `&hotelType=${hotelType}` : "";
    const tagEP = tag?.length > 0 ? `&tags=${tag}` : "";
    const amenitiesEP = amenities?.length > 0 ? `&amenities=${amenities}` : "";
  
    const checkInType = checkIn?.length > 0 ? handleDateFormat(checkIn) : "";
    const CheckOutType = CheckOut?.length > 0 ? handleDateFormat(CheckOut) : "";
  
    // Fetch logic based on filters
    if (
      location?.state?.hotelType &&
      !SearchTag &&
      location?.state?.from === "/typeofstays"
    ) {
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?city=${city}&hotelType=${location.state.hotelType
        }&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort?.key
        }${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}&page=1&limit=1000000000`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const responseData = await data.json();
      // responseMapper(responseData);
      return responseData?.data;
    } else if (
      SearchTag === "Luxury" &&
      location?.state?.from === "/luxuryproperties"
    ) {
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?tags=Luxury&city=${city}&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort?.key
        }${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}&page=1&limit=100000000000000000000`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const responseData = await data.json();
      // responseMapper(responseData);
      return responseData?.data;
    } else if (!location?.state?.from) {
      const data = await fetch(
        `https://api.ukiyostays.com/api/hotels/search?city=${city}&checkIn=${checkInType}&checkOut=${CheckOutType}&noOfRooms=${roomCounting}&guests=&sortBy=${sort?.key
        }${minPrice}${maxPrice}${hotelTypeEP}${bestRated}${newHomes}${tagEP}${amenitiesEP}&page=1&limit=10000000000000000`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const responseData = await data.json();
      responseMapper(responseData);
      return responseData?.data;
    }
  }, [
    topFilter,
    rangeValue,
    checkboxData,
    checkIn,
    CheckOut,
    location,
    city,
    roomCounting,
    sort,
    token,
    SearchTag,
  ]);
  
  useEffect(() => {
    // Update tempRangeValue when minValue or maxValue changes.
    setTempRangeValue([minValue, maxValue]);
  }, [minValue, maxValue]);
  useEffect(() => {
     filterData();
  }, [filterData,city]);

  const getInitialData = async () => {
    const HotelData = await filterData();

    const maxPrice = HotelData?.maxPricePerNight;

    fetch(
      `https://api.ukiyostays.com/api/hotels/fetch-tags?city=${city ? city.slice(14) : "Goa"
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data?.data);
        setTags(data?.data);
        setTopFilter((prev) => {
           return { ...prev, tag: filterTags, amenities: filterAminities };
          // return {
          //   ...prev,
          //   tag: data?.data?.map((tag) => {
          //     return { label: tag, key: tag, isSelected: false };
          //   }),
          // };
        });
      });
    // console.log(Tags);
    const filterAminities = HotelData?.amenities?.map((item) => {
      return { label: item, key: item, isSelected: false };
    });
    const filterTags = Tags?.map((tag) => {
      return { label: tag, key: tag, isSelected: false };
    });
    const hotelTypes = HotelData?.hotelTypes?.map((item) => {
      return { label: item, key: item, isSelected: false };
    });

    setMinValue(0);
    setMaxValue(maxPrice);
    setRangeValue([0, maxPrice]);
    // setCheckboxData(hotelTypes);
    // setTopFilter((prev) => {
    //   return { ...prev, tag: filterTags, amenities: filterAminities };
    // });
  };
  useEffect(() => {
    getInitialData();
  }, []);
  useEffect(() => {
    responseMapper(searchData);
  }, [searchData]);

  const handleShowAll = () => {
    // alert("load more clicked")
    //console.log(LuxuryVillasCard?.length);
    setShow(LuxuryVillasCard?.length);
  };
  const [minTypeChange, setMinTypeChange] = useState(false);
  const [maxTypeChange, setMaxTypeChange] = useState(false);

  return (
    <>
      <Container >
        {/* top bar */}
        <div className="max-lg:w-[90%] w-[95%] mt-4 rounded-lg mx-auto xl:hidden min-h-14 border border-gray-300 flex items-center px-2 gap-x-2">
          <button onClick={() => navigate(-1)} className="w-7 ">
            <img loading="lazy" src={leftArrow} alt="" />
          </button>
          <div>
            <div className="text-zinc-950 text-sm font-semibold  leading-[21px]">
              {city}
            </div>
            <div className="min-w-[189px] text-zinc-950 text-xs font-normal flex leading-[18px]">
              <div className="flex gap-x-1">
                {new Date(checkIn).toLocaleDateString("en-US", {
                  // month: "short",
                  day: "2-digit",
                })}
                &nbsp;
                {new Date(checkIn).toLocaleDateString("en-US", {
                  month: "short",
                  // day: "2-digit",
                })}
              </div>{" "}
              -{" "}
              <div className="flex gap-x-1">
                {CheckOut !== "undefined" ? (
                  <>
                    {new Date(CheckOut).toLocaleDateString("en-US", {
                      // month: "short",
                      day: "2-digit",
                    })}
                    &nbsp;
                    {new Date(CheckOut).toLocaleDateString("en-US", {
                      month: "short",
                      // day: "2-digit",
                    })}
                  </>
                ) : (
                  <>Checkout not selected</>
                )}
              </div>
              , 02 Guests, 1 Room
            </div>
          </div>
        </div>
        {/* options for smaller than xl devices */}
        <div className="flex gap-2 z-30 my-6  ps-2 pe-2 xl:hidden   overflow-x-auto hide_scrollbar">
          <div className="flex gap-2 z-30 mt-4  ps-2 pe-2 xl:hidden lg:h-12  w-[768px] ">
            {/* sort by */}
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setShowPriceRange(false);
                setSortBy(!showSortBy);
              }}
              className=" h-10 px-2   bg-white rounded-lg border border-gray-300 w-[130px] flex flex-nowrap justify-between items-center gap-1 relative"
            >
              <h1>Sort By</h1>
              <img loading="lazy" src={dropdown} className="sm:hidden"></img>
            </button>
            {/* no of rooms */}
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(!showRoomCount);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className=" h-10 px-2   bg-white rounded-lg border border-gray-300 w-[140px] flex flex-nowrap justify-between items-center gap-1 relative"
            >
              <h1>No. of rooms </h1>
              <img loading="lazy" src={dropdown} className="sm:hidden"></img>
            </button>
            {/* top filters */}
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowTopFilters(!showTopFilters);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className=" h-10 px-2  cursor-pointer bg-white rounded-lg border border-gray-300 w-[130px] flex flex-nowrap justify-between items-center gap-1 relative"
            >
              <h1>Top filters</h1>
              <img loading="lazy" src={dropdown} className="sm:hidden"></img>
            </button>
            {/* types of stay */}
            <button
              onClick={() => {
                setTypeofStay(!typeofStay);
                setshowRoomCount(false);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className=" h-10 px-2   bg-white rounded-lg border border-gray-300 w-[150px] flex flex-nowrap justify-between items-center gap-1 relative"
            >
              <h1>Types of stays</h1>
              <img loading="lazy" src={dropdown} className="sm:hidden"></img>
            </button>
            {/* price range */}
            <button 
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(false);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(!showPriceRange);
              }}
              className=" h-10 px-2   bg-white rounded-lg border border-gray-300 w-[130px] flex flex-nowrap justify-between items-center gap-1 relative"
            >
              <h1 className="font-normal font-Lato">Price Range</h1>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sm:hidden"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5584 1.06564C12.7292 1.2365 12.7292 1.5135 12.5584 1.68436L7.30838 6.93436C7.13753 7.10521 6.86052 7.10521 6.68966 6.93436L1.43966 1.68436C1.26881 1.5135 1.26881 1.23649 1.43966 1.06564C1.61052 0.894786 1.88753 0.894786 2.05838 1.06564L6.99902 6.00628L11.9397 1.06564C12.1105 0.894786 12.3875 0.894786 12.5584 1.06564Z"
                  fill="#08090A"
                  stroke="#08090A"
                  stroke-width="1.0133"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <hr className="xl:hidden" />

        <div className="md:grid flex md:grid-cols-12 mb-10 lg:mt-10 mt-10 xl:mt-[148px] xl:mx-0 2xl:mx-0">
          <div className="col-span-3 hidden xl:block" >
{/* ===========================================Top Filters ============================================================= */}   

            <div>
              <h1 className="text-[#000000] text-3xl font-normal font-Lato">
                Top Filters
              </h1>
              <div className="mt-2 flex flex-wrap gap-2 " 
              style={{maxHeight:"330px",overflowY:"scroll"}}>
                {Object.keys(topFilter)?.map((item) => {
                  return topFilter[item]?.map((filter, index) => {
                    return (
                      <div
                        onClick={() => {
                          const changesVal = topFilter[item];
                          changesVal[index].isSelected = !filter.isSelected;
                          setTopFilter((prev) => {
                            return { ...prev, [item]: changesVal };
                          });
                          filterData();
                        }}
                        className={`flex cursor-pointer flex-wrap gap-3 mt-2 ${
                          filter.isSelected ? "bg-gray-400" : null
                        } `}
                      >
                        <div className="py-2 px-4 rounded-[2px] cursor-pointer text-[#434141] text-sm border-[1px] border-[#CED4DA] tracking-normal ">
                          {filter.label}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
            <div className="w-[300px] mt-5 h-1 border-b  border-gray-600"></div>
{/* ===========================================PriceRange ============================================================= */}   

<div className="mt-4">
    <h1 className="text-[#000000] text-3xl font-normal font-Lato">Price Range</h1>

    <div>
      <div className="flex gap-4 mt-4">
        <div className="w-[138px] h-[52px] p-[4px 12px] rounded-md border flex flex-col justify-center items-center border-gray-300 text-gray-400 text-center">
          min price
          <input
            onChange={(e) => {
              const newMinValue = Math.min(e.target.value, tempRangeValue[1]); // Ensure minValue <= maxValue
              setTempRangeValue([newMinValue, tempRangeValue[1]]);
            }}
            className="text-[#434141] w-[108px] font-[600] text-center outline-none"
            type="text"
            value={`₹${tempRangeValue[0]}`}
          />
        </div>

        <div className="w-[138px] h-[52px] p-[4px 12px] rounded-md border border-gray-300 text-gray-400 text-center">
          max price
          <input
            onChange={(e) => {
              const newMaxValue = Math.max(e.target.value, tempRangeValue[0]); // Ensure maxValue >= minValue
              setTempRangeValue([tempRangeValue[0], newMaxValue]);
            }}
            className="text-[#434141] w-[108px] font-[600] text-center outline-none"
            type="text"
            value={`₹${tempRangeValue[1]}`}
          />
        </div>
      </div>

      <div className="mt-8 flex items-center gap-0">
        <div className="w-[290px] absolute">
          <Slider
            className="slider"
            onChange={setTempRangeValue}
            value={tempRangeValue}
            min={minValue}
            max={maxValue}
          />
        </div>
      </div>
    </div>

    <button
      onClick={() => {
        setRangeValue(tempRangeValue); // Update rangeValue with selected range
        filterData(); // Trigger the filter
      }}
      className="w-[290px] h-[38px] p-[16px 24px] mt-8 rounded-[4px] border border-gray-600 text-[#08090A] font-[600] cursor-pointer"
    >
      Apply
    </button>
  </div>

            <div className="w-[300px] mt-6 h-1 border-b  border-gray-600"></div>
{/* ===========================================Rooms ============================================================= */}   

            <div className="mt-4">
              <h1 className="text-[#000000] text-3xl font-normal font-Lato">
                {" "}
                Rooms{" "}
              </h1>
              <div className="flex gap-32 mt-6">
                <span className="text-[#112211] font-normal font-Lato">
                  No. of Rooms
                </span>
                <div className="flex gap-2 ">
                  <p
                    onClick={(e) => {
                      setRoomCounting((prevState) =>
                        prevState > 0 ? prevState - 1 : 0
                      );
                      filterData();
                    }}
                    className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                  >
                    <img loading="lazy" src={minus} alt="icon" />
                  </p>

                  <span>{roomCounting}</span>

                  <p
                    onClick={(e) => {
                      setRoomCounting((prevState) => prevState + 1);
                      filterData();
                    }}
                    className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                  >
                    <img loading="lazy" className="" src={plus} alt="icon" />
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[300px] mt-5 h-1 border-b  border-gray-600"></div>
{/* ===========================================Type of stays ============================================================= */}   

            <div className="mt-4">
              <h1 className="text-[#000000] text-3xl font-normal font-Lato">
                {" "}
                Types of stays
              </h1>
              {!checkboxData && <p>Type of stays not available</p>}
              {checkboxData &&
                checkboxData?.map((item, i) => (
                  <>
                    <div className="mt-3  flex gap-1 items-center">
                      {item.label !== "" && item.label !== null && (
                        <>
                          <input
                            onChange={(e) => {
                              const changesVal = checkboxData;
                              checkboxData[i].isSelected = !item.isSelected;
                              setCheckboxData(changesVal);
                              filterData();
                            }}
                            value={item.isSelected}
                            type="checkbox"
                            className=" border border-gray-500 h-[20px] w-[20px] rounded-md"
                          />
                          <p className="text-xl font-[400] ">{item.label}</p>
                        </>
                      )}
                    </div>
                  </>
                ))}
            </div>

            <div className="w-[300px] mt-5 h-1 border-b  border-gray-600"></div>

          </div>

{/*====================================== result ======================================================== */}

          {/* <div className="col-span-1 hidden lg:block" >
            <div className="w-0 ml-16 mr-0 p-0 h-full border-1 border-l border-gray-700"></div>
          </div> */}

          <div className="xl:col-span-8 min-w-[342px]  md:w-full  md:mx-5 
          md:px-5 xl:me-0 md:col-span-12 lg:pe-6 lg:col-span-11 " style={{borderLeft:"gray solid 1px",paddingLeft:"40px"}}>
            {/* heading */}
            <div className="flex justify-between mt-4 mb-4 ml-0  " >
              <h1 className="text-black  text-4xl font-[400] font-[Butler]">
                Explore our luxury villas in {city},
                <span className="text-gray-500 text-lg md:text-2xl ml-2 font-[Butler] font-medium">
                  {searchData?.data?.availableHotels?.length} Properties
                </span>
              </h1>
              <div className="hidden xl:block">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      onClick={handleToggle}
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700  "
                    >
                      Sort By : {sort.label}
                      {Recommended ? (
                        <img
                          loading="lazy"
                          src={iconBottom}
                          className="w-[16px] h-[16px]"
                          alt=""
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={iconTop}
                          alt=""
                          className="w-[16px] h-[16px]"
                        />
                      )}
                    </button>
                  </div>
                  <div ref={ref}>
                    {Recommended && (
                      <div
                        className="origin-top-right absolute z-50  right-0 mt-2 w-56 rounded-md shadow-lg bg-white "
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <div className="py-1" role="none">
                          {sortData?.map((item) => {
                            return (
                              <button
                                onClick={() => {
                                  setSort({ ...item });
                                  filterData();
                                  handleToggle();
                                }}
                                className="block px-4 z-50 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              >
                                {item.label}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {result ? (
              //console.log("results :",result)
              result
                ?.slice(0, show)
                ?.map((item, i) => <VillaCard item={item} setResult={setResult}/>)
            ) : (
              <div className="flex items-center justify-center self-center">
                No Filterd data found!{" "}
              </div>
            )}

            {result?.length > 1 && (
              <div className="max-md:px-10 md:px-40 lg:px-0">
                <button
                  onClick={handleShowAll}
                  className="xl:max-w-[866px] w-full h-[58px] ml-0 mt-20 lg:text-xl font-[600] rounded-md border  text-[#08090A] border-gray-400 gap-10"
                >
                  SHOW MORE RESULTS
                </button>
              </div>
            )}
          </div>

        </div>

        {/* filter bars slides up from bottom */}
        {/* sort by */}
        <div
          className={` ${showSortBy ? "fixed" : "hidden"
            }  z-50 w-full bottom-0 overflow-y-scroll min-h-[432px] bg-white rounded-tl-lg rounded-tr-lg`}
        >
          <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex justify-between">
            <h1>Sort</h1>
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setShowPriceRange(false);
                setSortBy(!showSortBy);
              }}
            >
              <img loading="lazy" src={close} alt="" />
            </button>
          </div>
          <hr className="border border-gray-300" />
          <div className="overflow-y-scroll max-h-[60vh]">
            {sortData?.map((item) => {
              return (
                <button
                  onClick={() => {
                    setSort({ ...item });
                    filterData();
                   // console.log(sort);
                    handleToggle();
                  }}
                  className={`block ${sort.label === item.label
                      ? "text-gray-700 bg-gray-100"
                      : "text-black"
                    }  w-full px-4 py-4 text-sm  text-start hover:text-gray-900`}
                >
                  {item.label}
                </button>
              );
            })}
          </div>
          <div
            style={{ boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)" }}
            className="w-full shadow-neutral-500 h-[72px] px-6 py-3 bg-white shadow-2xl justify-start items-start gap-3 inline-flex"
          >
            <div
              onClick={() => {
                setSort({ label: "Recommended", key: "" });
                setSortBy(false);
              }}
              className="grow shrink basis-0 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Clear
              </div>
            </div>
            <div
              onClick={() => {
                setSortBy(false);
              }}
              className="grow shrink basis-0 h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 flex"
            >
              <div className="w-[88px] text-center text-white text-base font-bold  leading-none">
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* room count */}
        <div
          className={` ${showRoomCount ? "fixed" : "hidden"
            }  z-50 w-full bottom-0 overflow-y-scroll min-h-[216px] bg-white rounded-tl-lg rounded-tr-lg`}
        >
          <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex justify-between">
            <h1>Rooms</h1>
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(!showRoomCount);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
            >
              <img loading="lazy" src={close} alt="" />
            </button>
          </div>
          <hr className="border border-gray-300" />
          <div className="flex justify-between items-center mx-12 gap-2 bg-white my-8">
            <div className="text-neutral-900 text-base font-normal ">
              No. of Rooms
            </div>
            <div className="flex gap-2">
              <p
                onClick={(e) => {
                  setRoomCounting((prevState) =>
                    prevState > 0 ? prevState - 1 : 0
                  );
                  filterData();
                }}
                className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
              >
                <img loading="lazy" src={minus} alt="icon" />
              </p>

              <span>{roomCounting}</span>

              <p
                onClick={(e) => {
                  setRoomCounting((prevState) => prevState + 1);
                  filterData();
                }}
                className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
              >
                <img loading="lazy" className="" src={plus} alt="icon" />
              </p>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)" }}
            className="w-full shadow-neutral-500 h-[72px] px-6 py-3 bg-white shadow-2xl justify-start items-start gap-3 inline-flex"
          >
            <div
              onClick={() => {
                setRoomCounting(0);
                setshowRoomCount(false);
              }}
              className="grow shrink basis-0 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Clear
              </div>
            </div>
            <div
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(!showRoomCount);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className="grow shrink basis-0 h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 flex"
            >
              <div className="w-[88px] text-white text-base font-bold text-center leading-none">
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* price range */}
        <div
          className={` ${showPriceRange ? "fixed" : "hidden"
            }  z-50 w-full bottom-0 overflow-y-scroll min-h-[216px] bg-white rounded-tl-lg rounded-tr-lg`}
        >
          <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex justify-between">
            <h1>Price Range</h1>
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(false);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(!showPriceRange);
              }}
            >
              <img loading="lazy" src={close} alt="" />
            </button>
          </div>
          <hr className="border border-gray-300" />
          <div className="flex justify-between items-center mx-12 gap-2 bg-white my-8">
            <div className="w-full flex flex-col justify-center items-center  p-1">
              <div className="flex w-full flex-col gap-4 max-lg:mt-4">
                <div className="flex gap-x-2 justify-center">
                  <div className="max-lg:w-[138px] h-[52px] p-[4px 12px] w-full rounded-md border border-gray-300 text-gray-400 text-center">
                    min price
                    <h1 className="text-[#434141] font-[600]">
                      ₹ {rangeValue[0]}
                    </h1>
                  </div>
                  <div className="max-lg:w-[138px] h-[52px] p-[4px 12px] w-full  h-[52px] p-[4px 12px] rounded-md border border-gray-300 text-gray-400 text-center">
                    max price
                    <h1 className="text-black font-[600]">₹ {rangeValue[1]}</h1>
                  </div>
                </div>
              </div>

              <div className="mt-8 flex  gap-0">
                <div className="w-[100%]  absolute left-0">
                  <Slider
                    className="slider"
                    onChange={setRangeValue}
                    value={rangeValue}
                    min={minValue}
                    max={maxValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)" }}
            className="w-full shadow-neutral-500 h-[72px] px-6 py-3 bg-white shadow-2xl justify-start items-start gap-3 inline-flex"
          >
            <div
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(false);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(!showPriceRange);
                setRangeValue(minValue, maxValue);
              }}
              className="grow shrink basis-0 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Clear
              </div>
            </div>
            <div
              onClick={() => {
                setTypeofStay(false);
                setshowRoomCount(false);
                setshowTopFilters(false);
                setSortBy(false);
                setShowPriceRange(!showPriceRange);
              }}
              className="grow shrink basis-0 h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 flex"
            >
              <div className="w-[88px] text-white text-base font-bold text-center leading-none">
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* types of stay */}
        <div
          className={` ${typeofStay ? "fixed" : "hidden"
            }  z-50 w-full bottom-0 overflow-y-scroll min-h-[202px] bg-white rounded-tl-lg rounded-tr-lg`}
        >
          <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex justify-between">
            <h1>Types of Stays</h1>
            <button
              onClick={() => {
                setTypeofStay(!typeofStay);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
            >
              <img loading="lazy" src={close} alt="" />
            </button>
          </div>
          <hr className="border border-gray-300" />
          <div className="overflow-y-scroll max-h-[60vh] px-6">
            {checkboxData?.map((item, i) => (
              <>
                <div className="my-4  flex gap-2 items-center">
                  <input
                    onChange={(e) => {
                      const changesVal = checkboxData;
                      checkboxData[i].isSelected = !item.isSelected;
                      setCheckboxData(changesVal);
                      filterData();
                    }}
                    value={item.isSelected}
                    type="checkbox"
                    className="checkbox-group border border-gray-500 h-[20px] w-[20px] rounded-md"
                  />
                  {/* <p className="text-sm font-[400] ">{item}</p> */}
                </div>
              </>
            ))}
          </div>
          <div
            style={{ boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)" }}
            className="w-full shadow-neutral-500 max-h-[72px] bg-white h-[72px] px-6 py-3 mt-auto relative bottom-0 flex-grow shadow-2xl justify-start items-start gap-3 inline-flex"
          >
            <div
              onClick={() => {
                document
                  .querySelectorAll(".checkbox-group")
                  .forEach(function (checkbox) {
                    checkbox.checked = false;
                  });
                setTypeofStay(!typeofStay);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className="grow shrink basis-0 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Clear
              </div>
            </div>
            <div
              onClick={() => {
                setTypeofStay(!typeofStay);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className="grow shrink basis-0 h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 flex"
            >
              <div className="w-[88px] text-center text-white text-base font-bold  leading-none">
                Apply
              </div>
            </div>
          </div>
        </div>
        {/* top filters */}
        <div
          className={` ${showTopFilters ? "fixed" : "hidden"
            }  z-50 w-full bottom-0 overflow-y-scroll min-h-[202px] bg-white rounded-tl-lg rounded-tr-lg`}
        >
          <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex justify-between">
            <h1>Top Filter</h1>
            <button
              onClick={() => {
                setTypeofStay(false);
                setshowTopFilters(!showTopFilters);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
            >
              <img loading="lazy" src={close} alt="" />
            </button>
          </div>
          <hr className="border border-gray-300" />
          <div className="my-4 flex flex-wrap mx-6 gap-4 overflow-y-scroll max-h-[60vh]">
            {Object.keys(topFilter)?.map((item) => {
              return topFilter[item]?.map((filter, index) => {
                return (
                  <div
                    onClick={() => {
                      const changesVal = topFilter[item];
                      changesVal[index].isSelected = !filter.isSelected;
                      setTopFilter((prev) => {
                        return { ...prev, [item]: changesVal };
                      });
                      filterData();
                    }}
                    className={`flex px-3 border-neutral-700 border ${filter.isSelected ? "bg-red-400" : null
                      } `}
                  >
                    <div className="w-full h-[33px] p-[8px 16px]  rounded-[2px]  gap-[10px] flex items-center justify-start  text-gray-700   leading-[17px] tracking-normal ">
                      {filter.label}
                    </div>
                  </div>
                );
              });
            })}
          </div>
          <div
            style={{ boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)" }}
            className="w-full shadow-neutral-500 max-h-[72px] bg-white h-[72px] px-6 py-3 mt-auto relative bottom-0 flex-grow shadow-2xl justify-start items-start gap-3 inline-flex"
          >
            <div
              onClick={() => {
                setTopFilter(topFilter);
                setTypeofStay(false);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className="grow shrink basis-0 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Clear
              </div>
            </div>
            <div
              onClick={() => {
                setTypeofStay(false);
                setshowTopFilters(false);
                setshowRoomCount(false);
                setSortBy(false);
                setShowPriceRange(false);
              }}
              className="grow shrink basis-0 h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 flex"
            >
              <div className="w-[88px] text-center text-white text-base font-bold  leading-none">
                Apply
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LuxuryVillas;
