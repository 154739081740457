import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eyeClose from "../../assets/icon/eye-close.webp";
import eye from "../../assets/svg/eye.webp";
import { OpacityContext } from "../../components/Navbar/Navbar";
import ResetPasswordModal from "../../components/modals/auth/ResetPasswordModal";
import { useLogInMutation } from "../../redux/features/auth/authApi";
import ForgotPasswordContent from "./ForgotPasswordContent";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import SocialContent from "./SocialContent";

const Login = ({
  onClick,
  setIsLoginModalOpen,
  isLoginModalOpen,
  handleRegisterModalToggle,
  setGoogleValue,
  loginPhone,
  setLoginPhone,
  setSmallPhoneLogin,
  SmallPhoneLogin,
  GoogleValue,
  setShowMobileSIgn,
  setOpacity,
  setIsRegisterModalOpen,
  isLoginEmail,
  phone,
  setNewPhoneLogin,
}) => {
  const navigate = useNavigate();
  const datas = useContext(OpacityContext);
  // console.log(SmallPhoneLogin);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [IsForgotPass, setIsForgotPass] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLogInMutation();

  const setTokenInLocalStorage = (token) => {
    console.log("token is getting saved:: ", token);
    if (token !== undefined) {
      localStorage.setItem("token", token);
    }
  };

  const setChatIdInLocalStorage = (id) => {
    console.log("chatId is getting saved:: ", id);
    localStorage.setItem("chatId", id);
  };

  const setUserIdInLocalStorage = (id) => {
    console.log("UserId is getting saved:: ", id);
    localStorage.setItem("UserId", id);
  };

  useEffect(() => {
    if (isSuccess && data?.data && data?.data?.token) {
      console.log(data.data?.token);
      if (data.data?.token !== undefined) {
        localStorage.setItem("token", data.data?.token);
        setTokenInLocalStorage(data.data?.token);
      }
      setChatIdInLocalStorage(data.data?.chatId);
      setUserIdInLocalStorage(data.data?.userId);
      console.log("token saved :: ", data.data?.token);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if (isError && error) {
      setLoginError(error?.data?.message || "Login failed. Please try again.");
    }
  }, [isError, error]);
  if (isSuccess) {
    window.location.reload();
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setIsEmailEmpty(!email);
    setIsPasswordEmpty(!password);

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{4,}$/;

    if (!email || !password) {
      setIsEmailEmpty(true);

      return;
    }
    if (password.length < 4) {
      setIsPasswordEmpty(true);
    }

    logIn({ email: email, password: password });

    setEmail("");
    setPassword("");
  };

  const isOpenForgotPass = () => {
    setIsForgotPass(true);
  };

  const isCloseForgotPass = () => {
    setIsForgotPass(false);
  };

  return (
    <div className="">
      {!IsForgotPass && (
        <>
          <div
            className={`${datas.loginPhone ? "hidden" : "px-6 py-4  bg-white"
              }  rounded-md  text-black`}
          >
            <div className={`${datas.loginPhone ? "hidden" : "block"}`}>
              <h2 className="text-[24px] leading-4  font-bold text-black mb-[5px]  flex justify-start">
                Login or create an account
              </h2>
              <form onSubmit={handleLogin} className="mt-4">
                <div
                  className={`mb-[5px] bg-white ${isEmailValid ? "" : "border-red-500"
                    }`}
                >
                  <label
                    htmlFor="email"
                    className=" text-black font-semibold   flex justify-start text-[16px]"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email...."
                    className={`border-2 pr-10 py-1 px-2 outline-none w-full h-[48px] rounded-md placeholder:[#6D747A] ${isEmailValid ? "" : "border-red-500"
                      } bg-white`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  {isEmailEmpty && (
                    <p className=" fontt-Lato font-semibold text-red-500 text-[12px]">
                      Enter the valid email
                    </p>
                  )}
                </div>

                <div
                  className={`mb-2 bg-white relative ${isPasswordValid ? "" : "border-red-500"
                    }`}
                >
                  <label
                    htmlFor="password"
                    className=" text-black font-semibold  flex justify-start text-[16px]"
                  >
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    className={`border-2 pr-10 py-1 px-2 outline-none w-full h-[48px] rounded-md placeholder:[#6D747A] ${isPasswordValid ? "" : "border-red-500"
                      }`}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />

                  {isPasswordEmpty && (
                    <p className="fontt-Lato font-medium text-red-500 text-[12px]">
                      Enter the valid password with minimum length 6
                    </p>
                  )}

                  <div
                    className="absolute top-[36px] right-2 cursor-pointer "
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src={eye} alt="eye" width={24} height={24} />
                    ) : (
                      <img src={eyeClose} alt="eye" width={24} height={24} />
                    )}
                  </div>
                  <div
                    className="text-[#0F6DDC] w-fit float-right font-normal text-[14px] text-end cursor-pointer"
                    onClick={() => {
                      // setIsLoginModalOpen();
                      if (window.screen.width >= 1024) {
                        setOpacity(false);
                      }
                      isOpenForgotPass();
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>
                {loginError && (
                  <div className="text-red-500 text-[12px] mb-2">
                    {loginError}
                  </div>
                )}
                <button
                  type="submit"
                  className="bg-black text-white p-2 rounded-md w-full h-[48px] text-[16px] hover:bg-gray-900"
                >
                  Continue
                </button>
              </form>

              <div className="flex felx-row items-center mt-2 cursor-pointer">
                <p className="font-Lato font-medium text-gray-600 text-[14px]">
                  Don't Have an account?
                </p>
                <span
                  onClick={() => {
                    setIsRegisterModalOpen(true);
                    if (window.screen.width <= 768) {
                      setShowMobileSIgn(false);
                    }
                  }}
                  className=" font-Lato font-semibold ml-1 text-blue-700 text-[14px]"
                >
                  Sign Up
                </span>
              </div>

              <div className="mt-2 font-medium">
                <div className="flex items-center justify-center">
                  <div className=" orLine border-t border-[#CED4DA] my-2 "></div>
                  <span className="mx-2 font-semibold">Or</span>
                  <div className=" orLine border-t border-[#CED4DA] my-2 "></div>
                </div>
              </div>
            </div>

            <div className=" w-full  lg:flex flex-col items-center justify-center">
              <SocialContent
                handleRegisterModalToggle={handleRegisterModalToggle}
                setSmallPhoneLogin={setSmallPhoneLogin}
                loginPhone={loginPhone}
                setLoginPhone={setLoginPhone}
                setGoogleValue={setGoogleValue}
                isLoginModalOpen={isLoginModalOpen}
                GoogleValue={GoogleValue}
                setOpacity={setOpacity}
                setIsLoginModalOpen={setIsLoginModalOpen}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                isLoginEmail={isLoginEmail}
                setShowMobileSIgn={setShowMobileSIgn}
                phone={phone}
                setNewPhoneLogin={setNewPhoneLogin}
              />
            </div>
          </div>
        </>
      )}
      {/* {IsForgotPass && (
        <ForgotPasswordContent
          setOpacity={setOpacity}
          setIsMailSent={setIsMailSent}
          isCloseForgotPass={isCloseForgotPass}
          email={email}
          setEmail={setEmail}
        />
      )} */}
      {IsForgotPass && window.screen.width > 0 && (
        <ResetPasswordModal
          setOpacity={setOpacity}
          children={
            <ForgotPasswordContent
              setOpacity={setOpacity}
              setIsMailSent={setIsMailSent}
              isCloseForgotPass={isCloseForgotPass}
              setEmail={setEmail}
            />
          }
          isCloseForgotPass={isCloseForgotPass}
        />
      )}
      {isMailSent && (
        <ForgotPasswordSuccess setIsMailSent={setIsMailSent} email={email} />
      )}
    </div>
  );
};

export default Login;
