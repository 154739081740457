import React from "react";

const FilterBtn = (props) => {
  const btnvalues = props.name.amenities;

  const handleClick = (btnValue) => {
    if (props.onFilterChange) {
      props.onFilterChange(btnValue); // Notify parent
    }
  };

  return (
    <div className="gap-2 h-[300px] overflow-y-scroll">
      {btnvalues.map((btnValue, index) => (
        <button
          key={index}
          className={`py-2 px-3 m-1 rounded-[2px] cursor-pointer text-sm border-[1px] border-[#CED4DA] ${
            props.selectedFilters.includes(btnValue) 
              ? "bg-gray-300 text-[#434141]" 
              : "bg-white text-[#434141]" 
          }`}
          onClick={() => handleClick(btnValue)}
        >
          {btnValue}
        </button>
      ))}
    </div>
  );
};

export default FilterBtn;
