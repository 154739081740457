import React, { useEffect, useRef, useState } from "react";
import Send from "../../assets/svg/send.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useSendMessageMutation } from "../../redux/features/chatSupport/chatSupport";
import ChatBubble from "./ChatBubble";
import ChatHead from "./ChatHead";
import ChatSubDetails from "./ChatSubDetails";

const dateFormatter = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

const ChatPane = (props) => {
  const [ConvoList, setConvoList] = useState(props.data);
  const chatContainerRef = useRef(null);
  const token = useTokenFromLocalStorage();
  const [msg, setMsg] = useState("");
  const [sendMessage] = useSendMessageMutation();
  const sender = () => {
    const data = {
      recipientId: props.details.participant_details._id,
      message: msg,
    };
    sendMessage({ data, token });
    setMsg("");
  };

  useEffect(() => {
    setConvoList(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props?.messages && props.messages.length > 0) {
      setConvoList((prev) => {
        const newMessages = props.messages
          .filter((msg) => !prev.some((prevMsg) => prevMsg._id === msg._id))
          .map((msg) => ({
            ...msg,
            isSender: msg.sender !== props.details.participant_details._id,
          }));
        return [...prev, ...newMessages];
      });
    }
  }, [props.messages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [ConvoList]);

  return (
    <div>
      {/* <ChatHead data={props?.details} /> */}
      <ChatSubDetails data={props?.details} />
      {/* Text messages */}
      <div className="flex flex-col justify-between min-h-[600px]">
        <div className="p-4">
          {/* Date */}
          <div className="flex justify-center items-center">
            {props?.data ? (
              <p className="rounded bg-[#EAEFF2] font-Lato font-normal px-4 py-2 text-lg text-[#08090A] mt-3 mb-[10px]">
                {dateFormatter(props.details.lastSentTime.substring(0, 16))}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            className="flex flex-col gap-[46px] max-h-[500px] overflow-y-scroll"
            ref={chatContainerRef}
          >
            {ConvoList ? (
              ConvoList?.map((item, index) => (
                <ChatBubble key={index} sender={item.isSender} data={item} />
              ))
            ) : (
              <p className="text-center text-slate-400 mt-4 text-2xl">
                No texts, start a conversation.
              </p>
            )}
          </div>
        </div>
        {/* Text bar */}
        <div className="border-[#CED4DA]  flex justify-between items-center border-[1px] rounded-full mb-4 mx-5">
          <input
            type="text"
            onChange={(e) => {
              setMsg(e.target.value);
            }}
            value={msg}
            placeholder="Type your message..."
            className="text-sm font-[500] placeholder:text-[#434141] py-4 px-6 rounded-full outline-none"
          />
          <button onClick={sender} className="bg-black m-2 rounded-full p-2">
            <img src={Send} alt="Send" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatPane;
