import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../PropertyCardReusable/Card";

const ExclusiveHomeStays = () => {
  const [showAll, setShowAll] = useState(false);
  // const { data } = useGetTopRatedStaysQuery();
  const handleShowAll = () => {
    setShowAll(true);
  };
  const [data, setData] = useState([]);
  const location = useLocation();
  const topRatedHotelFetch = async () => {
    const data = await fetch(
      `https://api.ukiyostays.com/api/hotels/search?city=${location.state?.city}&hotelType=${location.state?.hotelType}&tags=Ukiyo Exclusive,Luxury`
    );
    const responseData = await data.json();
    setData(responseData?.data?.availableHotels);
    return responseData;
  };
  useEffect(() => {
    topRatedHotelFetch();
    console.log("imp data ", data);
  }, [location.state?.hotelType, location.state?.city]);
  return (
    <div className={`mt-[136px]`}>
      <p className="md:text-[32px] max-md:px-6 text-[20px] text-[#000000] font-butler font-[400]">
        Ukiyo Exclusive Luxury {location?.state?.hotelType}
        {location?.state?.city}
      </p>

      {/* Render Most Trending Property Card */}
      <div className={` ${!data?.length > 0 && "hidden"} mt-[32px] max-md:flex-col  max-md:flex max-md:items-center mb-[144px]`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-[20px] gap-y-[24px] lg:gap-y-[64px] lg:gap-x-3">
          {showAll
            ? data?.map((property) => (
              <Card key={property?.id} propertyData={property} />
            ))
            : data
              ?.slice(0, 12)
              ?.map((property) => (
                <Card key={property?.id} propertyData={property} />
              ))}
        </div>
        {!showAll && (
          <div className="mt-[56px] mb-5 flex items-center justify-center">
            <button
              onClick={handleShowAll}
              className="w-[342px] px-[24px] py-[16px] text-[#08090A] border border-[#08090A] font-[600] text-[16px] rounded-[4px]"
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExclusiveHomeStays;
