import React from "react";
import RightIcon from "../../assets/icon/checkIcon.webp";
import UserIcon from "../../assets/icon/user-2.webp";
import Calendar from "../../assets/images/PropertyBooking/cal.webp";
import tickGrn from "../../assets/svg/check-circle-2.webp";
import { dateRangeFormatter } from "./customFunc";

const ChatSubDetails = (props) => {
  return (
    <div className="flex flex-col md:flex-row p-2 md:p-4 border-b-[1px] border-b-[#CED4DA]">
      {/* Header with hotel name and address */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
        <p className="text-[#08090A] font-Lato font-normal h-[20px] text-lg">
          {props?.data?.booking?.hotelId?.name}&nbsp;|&nbsp;
          {props?.data?.booking?.hotelId?.address}
        </p>
      </div>

      {/* Details section: date range and number of rooms */}
      <div className="flex flex-col md:flex-row gap-0 md:gap-4 items-start sm:items-center w-full">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <img src={Calendar} alt="Date" />
            <p className="text-[#434141] font-[500] leading-6 tracking-[0.16px]">
              {props?.data?.booking?.checkIn
                ? dateRangeFormatter(props?.data?.booking?.checkIn)
                : ""}
              &nbsp; - &nbsp;
              {props?.data?.booking?.checkOut
                ? dateRangeFormatter(props?.data?.booking?.checkOut)
                : ""}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={UserIcon} alt="User" />
            <p className="text-[#434141] font-[500] leading-6 tracking-[0.16px]">
              {props?.data?.booking?.noOfRooms}
            </p>
          </div>
        </div>
        <div className="flex sm:flex-row items-center gap-2 mt-2 sm:mt-0 md:ml-auto">
          {props?.data?.booking?.bookingStatus === "confirmed" ? (
            <img src={tickGrn} alt="tick" className="h-[24px] w-[24px]" />
          ) : (
            <img src={RightIcon} alt="tick" className="h-[24px] w-[24px]" />
          )}
          {props?.data?.booking?.bookingStatus === "confirmed" ? (
            <p className="leading-6 text-[#039855] tracking-[0.16px]">Booked</p>
          ) : (
            <p className="leading-6 text-[#0F6DDC] font-Lato font-medium text-2xl tracking-[0.16px]">
              Enquiry
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatSubDetails;
