const OurServicesCard = ({ info }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${info?.img})`,
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        cursor:"pointer"
      }}
      className=" rounded-lg transform transition-transform duration-300 ease-in-out hover:scale-110"
    >
      <div className="bg-black/30 max-lg:h-[233px] h-full rounded-lg w-full max-h-[300px] 
      flex justify-center items-center text-white text-2xl max-lg:text-base font-bold">
        {info?.title}
      </div>
    </div>
  );
};

export default OurServicesCard;
