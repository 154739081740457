import React, { useState } from "react";
import chevronup from "../../assets/svg/chevronup.webp";

const HouseRules = ({ houseRules = [] }) => {
  const [showMore, setShowMore] = useState(false);
  const [showHouseRules, setShowHouseRules] = useState(false);

  const fallbackMessage = "No house rules available at the moment.";

  const alwaysVisibleRules = houseRules.slice(0, 2);
  const extraRules = houseRules.slice(2);

  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <div
      className="mt-[60px] px-5 lg:px-5 2xl:px-0"
      id="House Rules"
      style={{ scrollMarginTop: "80px" }}
    >
      <div onClick={() => setShowHouseRules(!showHouseRules)}>
        <h1 className="text-[36px] max-md:text-2xl font-[500] font-[Butler] flex items-center justify-between max-md:py-6">
          House Rules
          <img
            src={chevronup}
            className={`max-md:block hidden w-6 h-6 ${showHouseRules ? "rotate-180" : ""}`}
            alt="Toggle House Rules"
          />
        </h1>
        <div className="w-full border border-[#CED4DA]"></div>
      </div>

      {/* Main Info */}
      <div className={`mt-[40px] ${showHouseRules ? "block" : "max-md:hidden"}`}>
        {houseRules.length > 0 ? (
          <>
            <ul className="list-disc flex flex-col gap-[12px] ml-5">
              {alwaysVisibleRules.map((item, i) => (
                <li key={i}>{item}.</li>
              ))}

              {showMore &&
                extraRules.map((item, i) => (
                  <li key={i + 3}>{item}.</li> 
                ))}
            </ul>

            {extraRules.length > 0 && (
              <button
                onClick={toggleShowMore}
                className="w-[196px] h-12 px-6 py-4 rounded border border-zinc-950 mt-8 flex-col justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-zinc-950 text-base font-bold leading-none">
                  {showMore ? "Read Less" : "Read More"}
                </div>
              </button>
            )}
          </>
        ) : (
          <p>{fallbackMessage}</p>
        )}
      </div>
    </div>
  );
};

export default HouseRules;
