import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatNav from "../../components/Navbar/ChatNav";
import ChatCard from "../../components/ChatSupport/ChatCard";
import ChatPane from "../../components/ChatSupport/ChatPane";
import selectChat from "../../assets/svg/selectChat.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import {
  useGetAllConversationQuery,
  useGetConversationQuery,
} from "../../redux/features/chatSupport/chatSupport";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import { useSocketContext } from "../../context/SocketContext";
import EmptyChats from "../../components/EventsAndExperiences/EmptyChats";
import leftArrow from "../../assets/svg/leftArrow.webp";
import ChatHead from "../../components/ChatSupport/ChatHead"; // Import ChatHead

const NewChat = () => {
  const navigate = useNavigate();
  const token = useTokenFromLocalStorage();
  const { data: authUser } = useAuthUserQuery(token);
  const { socket } = useSocketContext();
  const [chat, setChat] = useState("");
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [showChatDetails, setShowChatDetails] = useState(false);

  const { data } = useGetAllConversationQuery(token);
  const { data: convData } = useGetConversationQuery({ chat, token });

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setIsMobileView(isMobile);
      if (!isMobile) setShowChatDetails(false); // Show both panes on desktop
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle new messages via socket
  useEffect(() => {
    socket?.on("newMessage", (newMessage) => {
      if (chat === newMessage.conversation_id) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    return () => socket?.off("newMessage");
  }, [socket, chat]);

  // Update active chat
  const chatUpdater = (data) => {
    setChat(data.conversation_id);
    setActiveChat(data);
    setMessages([]); // Reset messages
    if (isMobileView) setShowChatDetails(true); // Show chat details on mobile
  };

  return (
    <div>
      {/* Navbar */}
      <ChatNav />

      {/* Conditionally Render Header */}
      {!showChatDetails && (
        <div className="flex items-center py-6 px-[26px] border-b-[4px] border-b-[#f2f3f4]">
          <button onClick={() => navigate(-1)} className="w-7">
            <img src={leftArrow} alt="Back" />
          </button>
          <h2 className="text-[#08090A] max-w-screen-xl md:mx-auto text-2xl font-semibold leading-9">
            Inbox
          </h2>
        </div>
      )}

      {/* Main chat pane */}
      {data && Array.isArray(data) && data.length > 0 ? (
        <div className="max-w-screen-xl mx-auto">
          <div
            className={`grid ${
              isMobileView ? "grid-cols-1" : "grid-cols-[300px,1fr]" // Set the left panel width
            }`}
          >
            {/* Chat List */}
            {!showChatDetails && (
              <div className="py-4 px-6 border-r-[2px] border-r-[#f2f3f4]">
                {data.map((item, index) => (
                  <ChatCard
                    key={index}
                    active={item.conversation_id === chat}
                    data={item}
                    func={chatUpdater}
                  />
                ))}
              </div>
            )}
            {/* Chat Details */}
            {(showChatDetails || !isMobileView) && (
              <div className="flex flex-col w-full">
                {isMobileView && (
                  <div className="flex items-center gap-2 mb-4">
                    <button
                      onClick={() => setShowChatDetails(false)}
                      className="text-blue-600 md:hidden block"
                    >
                      <img src={leftArrow} alt="Back" />
                    </button>
                    {/* Render ChatHead only when it's mobile view */}
                    <ChatHead data={activeChat} />
                  </div>
                )}
                {activeChat ? (
                  <ChatPane
                    data={convData}
                    details={activeChat}
                    messages={messages}
                    ChatHeadProps={{
                      onBack: isMobileView
                        ? () => setShowChatDetails(false)
                        : null,
                    }}
                  />
                ) : (
                  <div className="w-[100%] h-[100%] flex items-center justify-center">
                    <img
                      src={selectChat}
                      alt="Select a chat"
                      className="max-w-sm"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyChats />
      )}
    </div>
  );
};

export default NewChat;
