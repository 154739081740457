import { useEffect, useRef, useState } from "react";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import scrollLeft from "../../../assets/svg/scroll-left.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";
import "../../LuxuryVillas/LuxuryVillas.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Card from "./Card";

const SimilarProperties = ({ similarHotelData }) => {
  console.log("Similar Properties Data:", similarHotelData);

  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [isGridView, setIsGridView] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef?.current?.isBeginning);
      setIsEnd(swiperRef?.current?.isEnd);
    }
  }, [similarHotelData]);

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef?.current?.slideNext();
      setIsBeginning(swiperRef?.current?.isBeginning);
      setIsEnd(swiperRef?.current?.isEnd);
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef?.current?.slidePrev();
      setIsBeginning(swiperRef?.current?.isBeginning);
      setIsEnd(swiperRef?.current?.isEnd);
    }
  };

  const toggleViewMode = () => {
    setIsGridView((prev) => !prev);
  };

  if (
    !Array.isArray(similarHotelData?.data) ||
    similarHotelData.data.length === 0
  ) {
    return <p>No similar properties available.</p>;
  }

  return (
    <div className="mb-[136px] px-5 lg:px-4">
      <div className="flex items-center justify-between mt-[90px]">
        <p className="text-[36px] max-md:text-2xl font-[500] font-[Butler]">
          View Similar Properties
        </p>
        {similarHotelData.data.length >= 4 && (
          <button
            className="uppercase text-[#0F6DDC] text-[18px] font-[700]"
            onClick={toggleViewMode}
          >
            {isGridView ? "View Less" : "View all"}
          </button>
        )}
      </div>

      {isGridView ? (
        // Grid View Layout
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-6 h-[160px]">
          {similarHotelData.data.map((property, index) => (
            <Card key={property?._id || index} propertyData={property} />
          ))}
        </div>
      ) : (
        // Swiper Layout
        <div className="ml-[11px] mt-[40px]">
          <Swiper
            ref={swiperRef}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay, A11y]}
            loop={false}
            breakpoints={{
              1280: { slidesPerView: 4 },
              1024: { slidesPerView: 3 },
              630: { slidesPerView: 2 },
              368: { slidesPerView: 1 },
            }}
            spaceBetween={24}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              setIsBeginning(swiper.isBeginning);
              setIsEnd(swiper.isEnd);
            }}
          >
            {similarHotelData.data.map((property, index) => (
              <SwiperSlide key={property?._id || index}>
                <Card propertyData={property} />
              </SwiperSlide>
            ))}
          </Swiper>

          {similarHotelData.data.length >= 4 && (
            <div className="flex items-center justify-end mt-10 gap-[16px]">
              <button
                className={`${
                  isBeginning ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={swipePrev}
                disabled={isBeginning}
              >
                <img src={scrollLeft} alt="Scroll Left" />
              </button>
              <button
                className={`${isEnd ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={swipeNext}
                disabled={isEnd}
              >
                <img src={scrollRight} alt="Scroll Right" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SimilarProperties;
