import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import quot from "../../assets/svg/quot.webp";
import StarO from "../../assets/svg/StarO.webp";
import foundersPic from "../../assets/images/Review.jpg";

const FoundersReview = ({ Id }) => {
  const [ReviewData, setReviewData] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); 
  const parms = useParams();

  // Fallback review
  const fallbackReview = {
    name: "Gorge Milston",
    ratings: 4.5,
    img: foundersPic,
    note: "Serenity Villa Retreat is a hidden gem in a lush tropical paradise. Its seamless blend of modern luxury and traditional charm creates an inviting ambiance. The villa's amenities, from the infinity pool to the luxurious bedrooms, ensure a stay of unparalleled comfort. The personalized service, anticipating your every need, transforms a mere vacation into an unforgettable experience. Whether you desire a gourmet meal prepared by a private chef, a relaxing massage by the poolside, or an adventure exploring the nearby pristine beaches and vibrant local culture, Serenity Villa Retreat caters to your every whim with meticulous attention to detail.",
  };

  const fetching = async () => {
    try {
      const res = await fetch(
        `https://api.ukiyostays.com/api/propertyListing/founderReview/${parms?.id}`
      );
      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }
      const data = await res.json();
      if (data?.message === "Review not found") {
        setReviewData(null);
        setHidden(false);
      } else {
        setHidden(false);
        setReviewData(data);
      }
    } catch (error) {
      console.error("Fetching error:", error.message);
      setReviewData(null); // Fallback to default review if fetching fails
    }
  };

  useEffect(() => {
    if (parms.id) {
      fetching();
    }
  }, [parms.id]);

  const displayData = ReviewData || fallbackReview; // Use fallback if no ReviewData

  const ratingAndName = (
    <div>
      <div className="w-full pb-2 font-Lato h-[18px] text-black text-base font-bold text-nowrap leading-none">
        {displayData?.name}
      </div>
      <div className="h-5 max-lg:mt-2 flex-col justify-start items-start gap-1 flex">
        <div className="justify-start items-start gap-1 inline-flex">
          {Array.from({ length: Math.floor(displayData?.ratings) }).map((_, index) => (
            <div key={index} className="w-5 h-5 relative">
              <img src={StarO} alt="star" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!hidden && (
        <div className="pb-10"> 
          <div className="w-full px-5 2xl:px-0 h-full lg:h-[342px] flex-col justify-start items-start max-xl:gap-2.5 gap-5 flex ">
            <div className="flex-col justify-start items-start flex">
              <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight mt-5 ">
                Founder’s Review
              </div>
            </div>
            <div className="h-[1px] -mt-2 w-full bg-zinc-200 mb-[21px]"></div>
            <div className="xl:max-w-[853px] justify-start items-center gap-10 max-xl:gap-5 w-full lg:flex-row max-lg:gap-2.5 flex-col flex">
              <div className="flex max-lg:w-full items-center gap-[14px]">
                <img
                  className="w-50 h-[300px] rounded-lg max-lg:w-[75px] max-lg:h-[73px] max-lg:rounded-full object-cover"
                  alt="founderPicture"
                  src={displayData?.img}
                />
                <div className="lg:hidden">{ratingAndName}</div>
              </div>
              <div className="flex-col justify-center items-start gap-2 inline-flex">
                <div className="max-lg:hidden ">{ratingAndName}</div>
                <div className="min-h-[152px] relative flex-col justify-start items-start gap-4 flex">
                  <img
                    className="absolute w-5 -left-2 -top-2 lg:hidden"
                    src={quot}
                    alt=""
                  />
                  <div className="min-h-[152px] flex-col justify-start items-start gap-3 flex">
                    <div className="max-w-[616px] text-zinc-950 text-base font-normal font-Lato leading-7 tracking-tight">
                      {isExpanded
                        ? displayData?.note 
                        : `${displayData?.note.substring(0, 350)}...`} 
                    </div>
                    <div
                      role="button"
                      className="text-black text-base font-normal underline leading-normal tracking-tight cursor-pointer"
                      onClick={() => setIsExpanded(!isExpanded)} 
                    >
                      {isExpanded ? "SHOW LESS" : "READ MORE"} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FoundersReview;
