import { useNavigate } from "react-router-dom";
import thingstodoblogcover from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverSmall from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverMedium from "../../../../assets/svg/ThingsTodo1.webp";
import thingstodoblogcoverLarge from "../../../../assets/svg/ThingsTodo1.webp";
import shareIcon from "../../../../assets/svg/share.webp";
import cavronRight from "../../../../assets/svg/chevron-right.webp";

const BlogCard = ({ id, index, data }) => {
  const navigate = useNavigate();
  // const customDate = new Date('2024-12-21'); // Replace with your custom date
  const shortMonth = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short' });
  // console.log(index, data)
  const handleShare = () => {
    const url = window.location.href;
    const title = data?.title || "Check out this blog";
    const text = data?.activity || "Check out this blog post";

    if (navigator.share) {
      navigator
        .share({ title, text, url })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard
        .writeText(url)
        .then(() => alert("URL copied to clipboard"))
        .catch((error) => console.log("Error copying URL", error));
    }
  };

  return (
    <div className="h-full mb-6 mx-1 px-4">
      {/* Card Image Section */}
      <div
        className={`${index === 1 ? "h-[800px]" : "h-[348px]"
          } max-w-[628px] w-full rounded-t-lg relative`}
      >
        <img
          src={data?.coverPhoto ?? thingstodoblogcover}
          srcSet={`${data?.coverPhoto ?? thingstodoblogcoverSmall} 480w, ${data?.coverPhoto ?? thingstodoblogcoverMedium} 1024w, ${data?.coverPhoto ?? thingstodoblogcoverLarge} 1600w`}
          sizes="(max-width: 600px) 480px, (max-width: 1200px) 1024px, 1600px"
          className="w-full h-full object-cover rounded-t-lg"
          alt="Blog Cover"
        />
        {/* Date Badge */}
        <div className="w-[61px] h-[67px] px-4 py-2 bg-slate-100 rounded-sm flex-col top-6 left-6 justify-center absolute items-center gap-0.5 inline-flex">
          <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
            {shortMonth}
          </div>
          <div className="w-[29px] text-black text-[28px] font-normal font-butler leading-7 tracking-tight">
            {data?.createdAt.substring(8, 10)}
          </div>
        </div>
      </div>

      {/* Card Content Section */}
      <div className=" w-full max-w-[628px] border border-neutral-700/10 min-h-[358px] px-6 py-8 bg-white flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch min-h-[235px] flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 font-Lato text-neutral-700 text-sm md:text-base font-medium leading-normal">
              {/* OUTDOORS */}{
                data?.tags?.map((item) => item + ",")
              }
            </div>
            <div className="w-6 h-6 cursor-pointer">
              <img
                src={shareIcon}
                width="24"
                height="24"
                alt="Share"
                data-twe-toggle="tooltip"
                title="Share this post"
              />
            </div>
          </div>
          <div className="self-stretch min-h-[203px] flex-col justify-start items-start gap-4 flex">
            {/* Title section with Share Icon */}
            <div className="flex justify-between items-start w-full relative">
              <div className="text-black text-xl md:text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                {/* Imagica Theme & Water Park */}
                {data?.title}
              </div>
            </div>

            {/* Content Section */}
            <div className="self-stretch text-neutral-700 text-base font-Lato font-medium leading-7">
              {/* Immerse yourself in unparalleled luxury with our meticulously
              designed accommodations. Immerse yourself in unparalleled luxury
              with our meticulously designed accommodations. Immerse yourself in
              unparalleled luxury with our meticulously designed accommodations. */}
              {data?.overview}
            </div>
          </div>
        </div>
        <div
          className="text-black mt-8 text-lg font-normal cursor-pointer underline leading-[27px] tracking-tight"
          onClick={() => navigate(`/singleBlog/${id}`)}
        >
          Read More
        </div>
      </div>
    </div>
  );
};

const BlogList = ({ id, index, data }) => {
  // const blogData = [
  //   { id: 1, title: "Imagica Theme Park", activity: "Outdoor fun" },
  //   { id: 2, title: "Marine World", activity: "Water activities" },
  //   { id: 3, title: "Safari Adventure", activity: "Wildlife exploration" },
  //   { id: 4, title: "Hilltop Hiking", activity: "Mountain adventure" },
  //   { id: 5, title: "Mountain Trekking", activity: "Trekking" },
  //   { id: 6, title: "Beach Resort", activity: "Relaxing" },
  // ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-6 grid-flow-row auto-rows-min">
      {/* {blogData.map((data, index) => ( */}
      <div key={data.id} className="flex flex-col">
        <BlogCard id={id} index={index} data={data} />
      </div>
      {/* ))} */}
    </div>
  );
};

export default BlogList;
