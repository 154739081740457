import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../components/Banner/Slider/slider.css";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import DeleteListing from "../Modal/DeleteListing";

import RestoreListing from "../Modal/RestoreListing";

const ListingPropertyCard = ({ propertyData }) => {
  const token = useTokenFromLocalStorage();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const navigate = useNavigate();
  /** Destrucring PropertyData Object */
  const { name, address, city, status, imageUrls, _id, isArchived } =
    propertyData;

  console.log(name);

  return (
    <>
      <div className="md:w-[306px] rounded-sm shadow-md shadow-slate-200  ">
        {/* card banner image */}
        <div className="relative">
          <Link to={`/propertyDetails/${_id}`}>
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              onSwiper={() => {}}
              slidesPerView={1}
              className="h-[256px]"
            >
              {imageUrls?.map((img) => (
                <SwiperSlide>
                  <img
                    className="w-full object-cover h-full"
                    src={img}
                    alt="property-image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] px-[8px] py-[4px] rounded-[4px] font-[600] z-30">
            {status}
          </button>
        </div>

        {/* card banner image */}
        {/* card info */}
        <Link to={`/propertyDetails/${_id}`}>
          <div className="px-[16px] py-[12px]">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000]">{name}</p>
              <span className="text-[#6D747A] text-[14px]">
                {address?.slice(0, 26)}
                {city}
              </span>
            </div>
          </div>
        </Link>
        {/* card info */}
        {/* Controll button */}
        <div className="flex items-center gap-10 px-3 pb-5 mt-[28px] w-full">
          {status === "Archived" ? (
            <button
              onClick={() => setIsRestore(!isRestore)}
              className="  bg-[#08090A] text-[#FFFFFF]  text-[16px] font-[700] py-[14px] px-[24px] rounded-[4px]"
            >
              Restore
            </button>
          ) : (
            <button className="  bg-[#08090A] text-[#FFFFFF]  text-[16px] font-[700] py-[14px] px-[24px] rounded-[4px]">
              Edit Listing
            </button>
          )}

          <button
            onClick={() => setIsDelete(!isDelete)}
            className="text-[#000] mx-auto text-[16px] font-[700] py-[14px] px-[24px] rounded-[4px]"
          >
            Delete
          </button>
        </div>
        {/* Render modal */}

        {isDelete && (
          <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <DeleteListing
              setIsDelete={setIsDelete}
              status={status}
              propertyId={_id}
              token={token}
            />
          </div>
        )}
        {isRestore && (
          <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <RestoreListing setIsRestore={setIsRestore} />
          </div>
        )}
      </div>
    </>
  );
};

export default ListingPropertyCard;