import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import checkImg from "../../../src/assets/svg/Check-in icon.png";
import calendar from "../../../src/assets/svg/calender.webp";
import users from "../../../src/assets/svg/users.webp";


const BookingDate = ({ checkInDate, checkOutDate, adultsCount, chidrenCount }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatDate = (dateString) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const date = new Date(dateString);
    const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
  };

  const navigateEditPage = () => {
    navigate(-1);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="mt-10 mb-10 max-xl:px-6">
      <div className="flex justify-between items-center mb-5">
        <div className="text-zinc-950 text-2xl max-md:text-lg font-normal font-butler leading-9 tracking-tight">
          Booking Dates
        </div>
        <p
          className="text-black cursor-pointer text-lg font-[500] max-md:text-sm underline-offset-2 uppercase underline leading-[27px] tracking-tight"
          onClick={navigateEditPage}
        >
          Edit
        </p>
      </div>
      <div className="flex max-md:gap-9 gap-[72px] items-center max-w-[437px] mb-3">
        <div>
          <p className="text-zinc-950 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
            Check-In
          </p>
          <h2 className="text-[Lato] max-md:text-base text-xl font-medium text-nowrap">
            {formatDate(checkInDate)}
          </h2>
        </div>
        <div className="border border-r-2 h-10 border-black font-medium text-xl"></div>
        <div>
          <p className="text-zinc-950 max-md:text-sm text-base font-semibold leading-normal tracking-tight">
            Check-Out
          </p>
          <h2 className="text-zinc-950 max-md:text-base text-nowrap text-xl font-semibold leading-[30px] tracking-tight">
            {formatDate(checkOutDate)}
          </h2>
        </div>
      </div>
      <button
        className="bg-[#08090A] text-white text-base mt-4 max-lg:hidden leading-none px-5 py-4 h-[48px] rounded-md"
        onClick={toggleModal}
      >
        Self Check in
      </button>
      <hr className="mt-10 mb-12 bg-[#CED4DA]" />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          
          <div className="bg-white p-7 rounded-md w-[350px] mt-20 z-5">
          <div className="cursor-pointer pb-3">
            <RxCross2 className="text-xl ms-auto"  onClick={toggleModal} />
          </div>
          <img src={checkImg} alt="check" className="h-[70px] w-[70px] text-center mx-auto block my-3" />
            <h3 className="text-xl font-semibold text-center">Checked In Successfully!</h3>
            <p className="text-gray-600 mb-2 text-center ">Thank you for checking in.</p>
            <p className="text-gray-800 mb-5 bg-gray-100 mx-auto text-center mt-3 p-2">Booking ID: 1234-5678-91011</p>
            <p className="mb-2 flex justify-between mt-10">
              <span className="flex"><img src={calendar} alt="calndr" className="h-[15px] w-[15px] mt-1 mx-2" />Check-In:</span>
               <span>{formatDate(checkInDate)}</span>
            </p>
            <p className="mb-2 flex justify-between">
              <span className="flex"><img src={calendar} alt="calndr" className="h-[15px] w-[15px] mt-1 mx-2" />Check-Out:</span> 
              <span>{formatDate(checkOutDate)}</span>
            </p>
            <p className=" flex justify-between">
              <span className="flex"><img src={users} alt="calndr" className="h-[15px] w-[15px] mt-1 mx-2" />Guests:</span> 
              <span>{adultsCount} Adults, {chidrenCount} Child</span>
            </p>
            <button
              className="bg-black text-white w-full px-4 py-2 rounded-md  text-center mt-10"
              onClick={toggleModal}
            >
              Continue to Homepage
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingDate;
