import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: true,
};

export const PricingOrAvailabilitySlice = createSlice({
  name: "PricingOrAvailability",
  initialState,
  reducers: {
    SetTrue: (state) => {
      state.value = true;
    },
    SetFalse: (state) => {
      state.value = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetTrue, SetFalse } = PricingOrAvailabilitySlice.actions;

export default PricingOrAvailabilitySlice.reducer;




// ====================================================
