import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../redux/config";
import Card from "../PropertyCardReusable/Card";
import Container from "../ui/Container";

const ROW_SIZE = 4; // Number of properties per row
const INITIAL_ROWS = 3; // Initial number of rows to display

const RecommendedProperties = ({ propertiesData, token }) => {
  const [visibleRows, setVisibleRows] = useState(INITIAL_ROWS);
  const [isAllVisible, setIsAllVisible] = useState(false); // Track whether all properties are visible
  const navigate = useNavigate();

  const handleShowMore = () => {
    if (isAllVisible) {
      // Collapse the properties list
      setVisibleRows(INITIAL_ROWS);
      setIsAllVisible(false);
    } else {
      // Expand the properties list
      setVisibleRows((prevRows) => prevRows + 2);
      if (visibleProperties.length >= propertiesData.length) {
        setIsAllVisible(true); // Set to true if all properties are visible
      }
    }
  };

  const navigateTo = (responseJson) => {
    navigate("/allproperties", {
      state: {
        searchData: responseJson,
        location: "",
        checkIn: "",
        checkOut: "",
      },
    });
  };

  const viewAllProperties = async () => {
    const data = await fetch(`${BaseURL}/hotels/search`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responseJson = await data.json();
    navigateTo(responseJson);
  };

  const visibleProperties =
    propertiesData?.slice(0, visibleRows * ROW_SIZE) || [];

  return (
    <Container>
      {propertiesData && (
        <div className="mt-[70px]">
          {/* Recommended Properties navbar start */}
          <div className="flex px-2 lg:px-7 mx-4 md:mx-5 md:mx-none items-center justify-between">
            <p className="md:text-[32px] w-full text-[24px] text-[#000000] font-butler font-[400]">
              Ukiyo Recommended Properties
            </p>
            <div onClick={viewAllProperties}>
              <a
                href="#"
                className="text-[#0F6DDC] hidden md:block text-[18px] font-[700]"
              >
                VIEW ALL
              </a>
            </div>
          </div>
          {/* Recommended Properties navbar end */}

          {/* Recommended Properties Show start */}
          <div className="mt-[32px]">
            <div className="flex lg:px-0 2xl:px-0 justify-center items-center flex-wrap gap-x-[10px] gap-y-[20px] lg:gap-y-[64px]">
              {visibleProperties?.map((property) => (
                <Card key={property?._id} propertyData={property} />
              ))}
            </div>

            {/* Show More / Show Less Button */}
            <div className="mt-[56px] mb-5 flex items-center justify-center">
              <button
                onClick={handleShowMore}
                className="w-[342px] px-[24px] py-[16px] text-[#08090A] border border-[#08090A] font-[600] text-[16px] rounded-[4px]"
              >
                {isAllVisible ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          {/* Recommended Properties Show end */}
        </div>
      )}
    </Container>
  );
};

export default RecommendedProperties;
