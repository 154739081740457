import React from "react";
import thingstodoblogcover from "../../../assets/svg/thingstodoblogcover.webp";

const RelatedCard = () => {
  return (
    <div className="rounded relative overflow-hidden border-[1px] border-[#EAEFF2]">
      <div
        style={{
          backgroundImage: `url(${thingstodoblogcover})`,
        }}
        className="max-h-[348px] h-[348px] bg-cover bg-center"
      ></div>
      <div className="p-6">
        <p className="mb-4 font-Lato font-[500] leading-6 text-[#434141]">
          LUXURY VILLAS | CELEBRATION{" "}
        </p>
        <p className="font-butler text-2xl font-normal leading-9 tracking-[0.24px]">
          Top Villas for House Parties: An Ultimate Celebration Guide
        </p>
      </div>
      {/* Date/Month */}
      <div className="absolute top-4 left-4 rounded-sm flex justify-center items-center flex-col bg-[#EFF5F8] py-2 px-4">
        <p className="text-[#6D747A] text-sm pb-[2px] leading-5 tracking-[0.14px]">
          APR
        </p>
        <p className="font-butler text-[28px] leading-7 tracking-[0.28px]">
          28
        </p>
      </div>
    </div>
  );
};

export default RelatedCard;
