import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";
import { IoIosArrowUp } from "react-icons/io";
import { secData } from "./dummyData";

const EventSearchBar = (props) => {
  const defaultCities = props?.city;
  console.log("cities : ",defaultCities)
  
  const [formData, setFormData] = useState({
    destination: "",
    checkIn: "",
    checkOut: "",
    eventType: "",
    guests: 0,
  });

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isEventDropdownVisible, setEventDropdownVisible] = useState(false);
  const [guestDropdownVisible, setGuestDropdownVisible] = useState(false);
  const [error, setError] = useState("");

  const checkInRef = useRef(null);
  const checkOutRef = useRef(null);

  // Handle changes to the form data
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  // Handle geolocation for "Near Me" option
  const handleNearMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevData) => ({
            ...prevData,
            destination: "Current Location",
          }));
          setDropdownVisible(false);
          checkInRef.current.focus(); // Move focus to the Check In field
        },
        () => {
          setError("Unable to retrieve your location.");
        }
      );
    } else {
      setError("Geolocation is not supported by your browser.");
    }
  };

  // Handle city selection
  const handleCityChange = (value) => {
    
        handleInputChange("destination", value);
        setDropdownVisible(false);
      
    
  };

  // Handle event type selection
  const handleEventSelect = (eventType) => {
    handleInputChange("eventType", eventType);
    setEventDropdownVisible(false);
  };

  // Handle guest count selection
  const handleGuestSelect = (guestCount) => {
    handleInputChange("guests", guestCount);
    setGuestDropdownVisible(false);
  };

  return (
    <form className="lg:min-w-[1250px] min-w-[400px] gap-[18px] rounded-2xl grid grid-cols-1 lg:grid-cols-4 py-8 px-[26px] lg:px-[88px] absolute -translate-x-[50%] left-[50%] lg:-bottom-[25%] -bottom-[45%] bg-white mx-auto shadow-md">
      {/* Destination field */}
      <div className="relative lg:col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Enter Destination/Villa
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              handleInputChange("destination", e.target.value);
              setDropdownVisible(true);
            }}
            value={formData.destination}
            type="text"
            placeholder="Where to?"
            className="rounded outline-none w-full"
            onFocus={() => setDropdownVisible(true)}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              handleInputChange("destination", "");
            }}
          >
            <SlLocationPin className="w-5 h-5" />
          </button>
        </div>
        {isDropdownVisible && (
          <div className="absolute z-20 w-full bg-white border-gray-300 rounded mt-1 max-h-40 overflow-y-auto">
            {defaultCities?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleCityChange(item.name)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>

      {/* Check-in field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check In
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkInRef}
            onChange={(e) => handleInputChange("checkIn", e.target.value)}
            value={formData.checkIn}
            type="date"
            className="rounded outline-none w-full"
          />
          <LuCalendarDays
            onClick={() => checkInRef.current?.showPicker()}
            className="m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>

      {/* Check-out field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check Out
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkOutRef}
            onChange={(e) => handleInputChange("checkOut", e.target.value)}
            value={formData.checkOut}
            type="date"
            className="rounded outline-none w-full"
          />
          <LuCalendarDays
            onClick={() => checkOutRef.current?.showPicker()}
            className=" cursor-pointer m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>

      {/* Event type field */}
      <div className="relative lg:col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Type of event
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            value={formData.eventType}
            type="text"
            placeholder="Choose your event"
            className="rounded outline-none w-full"
            readOnly
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setEventDropdownVisible(!isEventDropdownVisible);
            }}
          >
            {isEventDropdownVisible ? (
              <IoIosArrowUp className="w-5 h-5" />
            ) : (
              <IoIosArrowDown className="w-5 h-5" />
            )}
          </button>
        </div>
        {isEventDropdownVisible && (
          <div className="absolute z-20 w-full bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto">
            {secData.map((item) => (
              <div
                key={item.name}
                onClick={() => handleEventSelect(item.name)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Guest count field */}
      <div className="relative lg:col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Guests
        </p>
        <div
          className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2 cursor-pointer"
          onClick={() => setGuestDropdownVisible(!guestDropdownVisible)}
        >
          <input
            value={formData.guests !== 0 ? formData.guests : ""}
            type="text"
            placeholder="No. of guests"
            className="rounded outline-none w-full"
            readOnly
          />
          {guestDropdownVisible ? (
            <IoIosArrowUp className="w-5 h-5" />
          ) : (
            <IoIosArrowDown className="w-5 h-5" />
          )}
        </div>
        {guestDropdownVisible && (
          <div className="absolute z-20 w-full bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto">
            {[...Array(10).keys()].map((num) => (
              <div
                key={num + 1}
                onClick={() => handleGuestSelect(num + 1)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {num + 1} Guests
              </div>
            ))}
          </div>
        )}
        
      </div>
      <div className="lg:col-span-4 flex justify-center items-center">
        <button
          onClick={props?.func}
          className="text-[#ffff] bg-[#08090A] font-bold leading-[16px] -tracking-[0.16px] min-w-[362px] rounded border-[1px] border-[#08090A] py-3 px-6"
        >
          Search
        </button>
        </div>
    </form>
  );
};

export default EventSearchBar;
