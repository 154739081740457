import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../../components/TypeOfStays/Banner";
import ChatSupport from "../../components/TypeOfStays/ChatSupport";
import ExclusiveHomeStays from "../../components/TypeOfStays/ExclusiveHomeStays";
import LodgingLanscape from "../../components/TypeOfStays/LodgingLandscape";
import ThingsToDo from "../../components/TypeOfStays/ThingsToDo";
import TopeRatedStays from "../../components/TypeOfStays/TopRatedStays";
import Container from "../../components/ui/Container";
import ChatSupportPopup from "../ChatSupportPopup/ChatSupportPopup";
const TypeOfStays = () => {
  const location = useLocation();
  const city = location.state?.city;
  console.log(city);
  const hotelType = location.state?.hotelType || "luxury";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [city, hotelType]);
  return (
    <>
      <Container>
        {/* Banner Section -> rahat code 👇🏻 */}
        <Banner />
        <div className="flex flex-col md:px-6">
          {/* Top Rated Homestays in Manali -> nur code 👇🏻*/}
          <TopeRatedStays />

          {/* Lodging Landscape: Exploring Stay Options in Manali -> nur code 👇🏻*/}
          <LodgingLanscape />

          {/* Ukiyo Exclusive Luxury Homestays -> nur code 👇🏻*/}
          <ExclusiveHomeStays />

          {/* <div>fksdnfkhdfkjh</div> */}
          <ThingsToDo />
          {/* Chat Support  -> Animesh code 👇🏻*/}
          <ChatSupportPopup />
        </div>
      </Container>
    </>
  );
};

export default TypeOfStays;
