import React, { useState } from "react";
import rightArrow from "../../assets/svg/chevron-right.webp";

import AmenitiesModal from "../modals/AmenitiesModal";
import Button from "../ui/Button";
import AmenitiesContent from "./SimilarProperties/AmenitiesContent";

import {
  FaWifi,
  FaDumbbell,
  FaSpa,
  FaBusinessTime,
  FaChalkboardTeacher,
  FaUtensils,
  FaSwimmer,
  FaTree,
  FaUmbrellaBeach,
  FaCocktail,
  FaWater,
  FaHiking,
  FaMountain,
  FaTheaterMasks,
  FaPizzaSlice,
  FaHorse,
  FaGlassCheers,
  FaSeedling,
  FaCity,
  FaShoppingCart,
  FaConciergeBell,
  FaHeart,
  FaTv,
  FaSnowflake,
  FaMusic,
  FaParking,
  FaDog,
  FaShieldAlt,
  FaBath,
  FaWheelchair,
  FaTshirt,
  FaCouch,
  FaChair,
  FaFilm,
  FaGamepad,
  FaHome,
  FaSteam,
  FaFutbol,
  FaSlidersH,
  FaChild,
  FaFire,
  FaFireAlt,
  FaPrayingHands,
  FaPagelines,
  FaArrowsAlt,
  FaWind,
  FaEye,
  FaBuilding,
  FaStar,
  FaGlobe,
  FaAppleAlt,
  FaMapMarkerAlt,
  FaTractor,
  FaTableTennis,
  FaBasketballBall,
  FaPlug,
  FaCloudRain,
  FaFirstAid,
  FaBug,
  FaLeaf,
  FaWineBottle,
  FaClock,
  FaArchway,
  FaStoreAlt,
  FaWalking,
  FaHotTub,
} from "react-icons/fa";

const icons = {
  BBQ: FaFireAlt,
  WiFi: FaWifi,
  Swimming: FaSwimmer,
  Lawn: FaLeaf,
  Garden: FaLeaf,
  Gym: FaDumbbell,
  Spa: FaSpa,
  Business: FaBusinessTime,
  Education: FaChalkboardTeacher,
  Dining: FaUtensils,
  Beach: FaUmbrellaBeach,
  Drinks: FaCocktail,
  Pool: FaWater,
  Gazebo: FaTree,
  Breakfast: FaPizzaSlice,
  Towels: FaBath,
  Bar: FaWineBottle,
  AC: FaWind,
  Refrigerator: FaWind,
  Mountain: FaMountain,
  Jacuzzi: FaHotTub,
  Meals: FaPizzaSlice,
  Viewing: FaEye,
  Home: FaHome,
  Pet: FaDog,
};
const Amenities = ({ amenitiesData }) => {
  const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false);

  // Open modal
  const openAmenitiesModal = () => {
    setIsAmenitiesOpen(true);
  };

  // Close modal
  const closeAmenitiesModal = () => {
    setIsAmenitiesOpen(false);
  };

  return (
    <div
      className="mt-[120px] max-md:mt-12 px-4 lg:px-5 2xl:px-0"
      id="Amenities"
      style={{ scrollMarginTop: "100px" }}
    >
      {/* Heading */}
      <h1 className="text-[36px] max-sm:text-2xl font-[500] font-[Butler]">
        Amenities
      </h1>
      {/* Divider */}
      <div className="w-full border border-[#CED4DA]"></div>
      {/* Amenities list */}
      <div className="grid grid-cols-2 gap-y-[32px] mt-[32px]">
        {amenitiesData?.map((item, index) => (
          <div
            key={index + 1}
            className="flex items-center max-md:gap-3 gap-[24px]"
          >
            <div
              className="max-md:w-8 flex justify-center items-center 
            max-md:h-8 md:w-11 md:h-11 border-2 border-[#6D747A] text-[#6D747A] rounded-full"
            >
              {/* Dynamically render icons */}
              {icons[item]
                ? React.createElement(icons[item])
                : React.createElement(FaHome)}
            </div>
            <p className="text-[20px] max-md:text-base text-zinc-950">{item}</p>
          </div>
        ))}
      </div>
      {/* Button to view all amenities */}
      <div onClick={openAmenitiesModal}>
        <Button title="View All Amenities" icon={rightArrow} />
      </div>
      {/* Modal */}
      {isAmenitiesOpen && (
        <AmenitiesModal
          onCloseModale={closeAmenitiesModal}
          children={<AmenitiesContent amenitiesData={amenitiesData} />}
        />
      )}
    </div>
  );
};

export default Amenities;
