import { apiSlice } from "../api/apliSlice";

const reservationsApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    /** Get Reservation Endpoint */
    getReservation: builder.query({
      query: ({ token, city, date, listingId, pageNo }) => ({
        url: `/owner/bookings?hotelIds[0]=${listingId || "65f7eb46a2056fdd36a33678"
          }&city=${city}&date=${date}&pageNo=${pageNo}&recordsPerPage=9`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },

        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["reservations"],
    }),
    /** Cencel Booking / Reservations */
    cancelReservation: builder.mutation({
      query: ({ token, reservationId }) => ({
        url: `/bookings/cancel?bookingId=${reservationId}`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
        body: {
          refundNotificationMode: "email",
          cancellationReason: "by user"
        }
      }),
      invalidatesTags: ["reservations"],
    }),
  }),
});

export const { useGetReservationQuery, useCancelReservationMutation } =
  reservationsApi;
