import { onAuthStateChanged, signInWithPopup } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import socialGoogle from "../../assets/svg/google.webp";
import Phone from "../../assets/svg/phone.webp";
import { auth, provider } from "./config";
import { MdOutlineMail } from "react-icons/md";

import { OpacityContext } from "../../components/Navbar/Navbar";
import { BaseURL } from "../../redux/config";
import {
  useLogInMutation,
  useSendOTPMutation,
} from "../../redux/features/auth/authApi";
import LoginWithPhone from "./LoginWithPhone";

export const OtpContext = createContext({});

const SocialContent = ({
  handleRegisterModalToggle,
  setIsLoginModalOpen,
  isLoginModalOpen,
  phone,
  setLoginPhones,
  loginPhone,
  setLoginPhone,
  GoogleValue,
  setGoogleValue,
  setOpacity,
  setNewPhoneLogin,
  setShowMobileSIgn,
  setSmallPhoneLogin,
  isLoginEmail,
}) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userUid, setUserUid] = useState("");
  const [OtpModalShow, setOtpModalShow] = useState(false);
  const [functionChange, setFunctionChange] = useState(false);

  const [
    loginPhonOtp,
    { data, responseData, isLoading, isError, isSuccess, error },
  ] = useSendOTPMutation();
  
  const datas = useContext(OpacityContext);

  const setTokenInLocalStorage = (token) => {
    console.log("token is getting saved:: ", token);
    if (token !== undefined) {
      localStorage.setItem("token", token);
    }
  };

  useEffect(() => {
    if (isLoginEmail) {
      setFunctionChange(true);
      setNewPhoneLogin(false);
    }
  }, [isLoginEmail]);

  useEffect(() => {
    console.log(data);
    if (isSuccess && data?.data && data?.data?.token && data !== undefined) {
      setTokenInLocalStorage(data.data?.token);
      console.log("token saved :: ", data.data?.token);
    }
  }, [isSuccess, data]);

  if (isSuccess) {
    window.location.reload();
  }

  const continueWithGoogle = async (userEmail, userUid) => {
    const body = {
      email: userEmail,
      uid: userUid,
    };
    const response = await fetch(`${BaseURL}/auth/continue-with-google`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
    if (response.data.token !== undefined) {
      localStorage.setItem("token", response.data.token);
    }
    window.location.reload();
  };

  const [user, setUser] = useState({});
  const [logIn, { data: response, isError: isErr }] = useLogInMutation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleGoogleClick = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      localStorage.setItem("loginemail", user.email);
      setUserUid(user.uid);
      setUserEmail(user.email);
      continueWithGoogle(user.email, user.uid);
      console.log(
        "USER LOGGED in:: ",
        user,
        "\n\n\n useEmail",
        user.email,
        "\n\n uid: ",
        user.uid
      );
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleLoginPhonOpen = () => {
    if (window.screen.width > 768) {
      datas?.setLoginPhone(true);
    } else {
      setSmallPhoneLogin(true);
      datas?.setShowMobileSIgn(false);
    }
  };

  const handleChangeEmail = (e) => {
    if (window.screen.width < 768) {
      setShowMobileSIgn(true);
    }
    setNewPhoneLogin(false);
  };

  useEffect(() => {
    if (datas?.loginPhone !== undefined) {
      if (datas?.loginPhone === false) {
        setFunctionChange(true);
      } else {
        setFunctionChange(false);
      }
    }
  }, [datas.loginPhone]);

  const social = [
    {
      socialImg: socialGoogle,
      socialContent: "Continue with Google",
      handleClick: handleGoogleClick,
    },
    {
      socialImg: functionChange ? Phone : MdOutlineMail,
      socialContent: !functionChange
        ? "Continue with Email"
        : "Continue with Phone",
      handleClick: functionChange ? handleLoginPhonOpen : handleChangeEmail,
    },
  ];

  const datas2 = {
    setOtpModalShow,
    OtpModalShow,
  };

  const navigatePrivacy = (num) => {
    navigate("/privacyPolicy", {
      state: { active: num },
    });
  };

  return (
    <div>
      <OtpContext.Provider value={datas2}>
        {loginPhone && (
          <LoginWithPhone
            setLoginPhone={setLoginPhone}
            onClick={() => setLoginPhone(false)}
          />
        )}
        {!OtpModalShow &&
          social.map((item, index) => (
            <div
              key={index}
              className="border-2 bg-white border-gray-300 w-full h-12 rounded-md ps-7 md:ps-7 md:justify-center lg:justify-start mt-3 gap-x-9 lg:gap-x-0 flex items-center cursor-pointer"
              onClick={() => item.handleClick()}
            >
              <div className="w-[19px] flex justify-center">
                {typeof item.socialImg === "string" ? (
                  <img src={item.socialImg} alt="socialIcon" />
                ) : (
                  <item.socialImg className="text-2xl" />
                )}
              </div>
              <span
                className={`text-black font-medium flex justify-center items-center text-[16px] w-full ${
                  item.socialContent === "Continue with Google"
                    ? "mr-10"
                    : "mr-12"
                }`}
              >
                {item.socialContent}
              </span>
            </div>
          ))}
        {!OtpModalShow && !datas?.loginPhone && (
          <p className="font-normal text-black mt-4 text-center text-[14px]">
            By creating an account, you agree to our{" "}
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => navigatePrivacy(1)}
            >
              Terms of use
            </span>{" "}
            and{" "}
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => navigatePrivacy(2)}
            >
              Privacy policy
            </span>
          </p>
        )}
      </OtpContext.Provider>
    </div>
  );
};

export default SocialContent;
