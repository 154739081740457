import React from "react";
import RelatedCard from "./RelatedCard";
import MustRead from "./MustReadsCard";

const RelatedBlogs = () => {
  return (
    <div>
      <h2 className="font-butler text-[32px] leading-[48px] tracking-[0.32px] mb-10">
        Related Posts
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        <RelatedCard />
        <RelatedCard />
        <RelatedCard />
      </div>

      <div className=" md:hidden">
        <MustRead />
      </div>
    </div>
  );
};

export default RelatedBlogs;
