import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { debounce } from "../../Utils/Debouce";
import heartIconChange from "../../assets/svg/heart-red.webp";
import heartIcon from "../../assets/svg/heart.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../redux/features/userDashboard/userDashboardApi";

const Card = ({ propertyData }) => {
  const token = useTokenFromLocalStorage();
  const { address, city, pricePerNight, tags, imageUrls, _id } = propertyData;

  const [addWishlist, { isLoading, isSuccess, isError, error }] =
    useAddWishlistMutation();
  const [inWishlist, setInWishlist] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added to wishlist!");
      setInWishlist(true);
    }
    if (isError) {
      toast.error(error?.data?.message);
    }
  }, [isSuccess, isError, error]);

  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
  };

  const debouncedAddWishlist = debounce(handleAddWishlist, 500);

  const handleWishlistClick = () => {
    if (inWishlist) {
      toast.info("Removed from wishlist!");
      setInWishlist(false);
    } else {
      debouncedAddWishlist(_id || propertyData?.hotel?._id);
    }
  };

  // Filter valid image formats
  const validImageUrls = (imageUrls || []).filter((url) =>
    /\.(jpg|jpeg|png)$/i.test(url)
  );

  // Default images if none are valid
  const fallbackImages = [
    "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png",
    "https://img.vistarooms.com/gallery/thumbnail/kabins-e398a4.jpg",
  ];

  const imagesToDisplay =
    validImageUrls.length > 0 ? validImageUrls : fallbackImages;

  return (
    <>
      <ToastContainer />
      <div className="rounded-sm shadow-md shadow-slate-200 w-[342px] 2xl:w-[300px] lg:w-[290px]">
        <div className="relative">
          <Link to={`/propertyDetails/${_id}`}>
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              slidesPerView={1}
              spaceBetween={0}
              className="h-[256px] w-full"
            >
              {imagesToDisplay.map((img, index) => (
                <SwiperSlide key={index}>
                  <img
                    className="w-full object-cover h-full"
                    src={img}
                    alt={`property-image-${index}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-sm px-[8px] py-[4px] rounded-[4px] font-[600] z-30">
            {tags?.[0] || propertyData?.hotel?.tags?.[0] || "No tags"}
          </button>
          <button onClick={handleWishlistClick}>
            <img
              className="absolute top-[16px] right-[16px] cursor-pointer z-30"
              src={inWishlist ? heartIconChange : heartIcon}
              alt="icon"
            />
          </button>
        </div>

        <Link to={`/propertyDetails/${_id || propertyData?.hotel?._id}`}>
          <div className="px-[16px] py-[12px]">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000]">
                {(propertyData?.name !== undefined && propertyData?.name) ||
                  propertyData?.hotel?.name}
              </p>
              <span className="text-[#6D747A] text-[14px]">
                {address?.slice(0, 26) ||
                  propertyData?.hotel?.address?.slice(0, 26)}
                {""} {city || propertyData?.hotel?.city}
              </span>
              <span className="text-[#434141] text-[14px]">
                12 Guests I 5 Bedrooms I 5 Bathrooms
              </span>
            </div>

            <div className="mt-[12px]">
              <p className="text-[#6D747A] text-[14px]">Starting from</p>
              <p className="mt-[2px]">
                <span className="text-[#000000] text-[18px] font-[600]">
                  ₹{" "}
                  {pricePerNight?.toLocaleString("en-IN") ||
                    (10000).toLocaleString("en-IN")}
                </span>
                <span className="text-[#434141] text-[16px]">
                  {" "}
                  / night + Taxes
                </span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
