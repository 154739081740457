import React, { useState } from "react";
import ReactSlider from "react-slider";

const PriceRange = ({ onApply }) => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(2000000);

  const handleSliderChange = ([min, max]) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const handleApply = () => {
    if (onApply) {  // Ensure that onApply is a function
      onApply(minPrice, maxPrice);  // Call the function passed via props
    }
  };

  return (
    <div className="py-6 border-b-[1px] border-b-[#6D747A]">
      <p className="text-xl leading-[30px] mb-4 tracking-[0.2px]">Price Range</p>
      <div className="flex justify-between mb-6">
        <div className="px-3 min-w-[114px] py-1 rounded border-[1px] border-[#CED4DA]">
          <p className="text-[#6D747A] text-xs leading-[18px]">min price</p>
          <p className="text-[#434141] font-semibold leading-[24px] tracking-[0.16px]">
            ₹ {minPrice.toLocaleString()}
          </p>
        </div>
        <div className="px-3 min-w-[114px] py-1 rounded border-[1px] border-[#CED4DA]">
          <p className="text-[#6D747A] text-xs leading-[18px]">max price</p>
          <p className="text-[#434141] font-semibold leading-[24px] tracking-[0.16px]">
            ₹ {maxPrice.toLocaleString()}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center mb-[34px]">
        <ReactSlider
          className="w-full h-3 bg-gray-300 rounded-full items-center"
          thumbClassName="h-3 w-3 bg-white rounded-full cursor-pointer"
          trackClassName="bg-black h-3 rounded-full"
          min={0}
          max={200000}
          step={1000}
          value={[minPrice, maxPrice]}
          onChange={handleSliderChange}
          withTracks
        />
      </div>
      <div className="flex justify-center items-center">
        <button
          className="text-[#08090A] rounded font-semibold leading-[24px] -tracking-[0.16px] border-[1px] border-[#08090A] py-2 px-6 w-full"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PriceRange;
