import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import exquisite from "../../../assets/svg/Exquisite.webp";
import calenderHeart from "../../../assets/svg/calenderHeart.webp";
import swipeNextIcon from "../../../assets/svg/swipeNext.webp";
import swipePrevIcon from "../../../assets/svg/swipePrev.webp";

const Exquisite = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 3,
    },
  };

  return (
    <>
      <div className="w-full max-md:h-full md:h-[466px] my-[136px] relative">
        <div className="w-full hidden max-sm:block h-full ml-auto">
          <img src={exquisite} alt="" />
        </div>
        <div className="lg:w-[924px] max-lg:px-6 max-md:w-full h-[234px] max-md:relative bg-white absolute top-[106px] md:shadow-lg">
          <div className="lg:w-[840px] md:w-[500px] max-md:w-full h-[202px] mx-auto">
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              ref={swiperRef}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              onSlideChange={handleSlideChange}
              breakpoints={breakpoints}
              spaceBetween={24}
              className="h-[256px]"
            >
              <SwiperSlide>
                <div className="mt-px">
                  <img src={calenderHeart} alt="" />
                </div>
                <div className="w-[264px] max-lg:w-full h-[138px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                  <div className="text-white text-xl font-bold leading-[30px]">
                    Exquisite Elegance
                  </div>
                  <div className="self-stretch text-white text-base font-normal leading-normal">
                    Immerse yourself in unparalleled luxury with our meticulously designed{" "}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="mt-px">
                  <img src={calenderHeart} alt="" />
                </div>
                <div className="w-[264px] max-lg:w-full h-[138px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                  <div className="text-white text-xl font-bold leading-[30px]">
                    Exquisite Elegance
                  </div>
                  <div className="self-stretch text-white text-base font-normal leading-normal">
                    Immerse yourself in unparalleled luxury with our meticulously designed{" "}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="mt-px">
                  <img src={calenderHeart} alt="" />
                </div>
                <div className="w-[264px] max-lg:w-full h-[138px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                  <div className="text-white text-xl font-bold leading-[30px]">
                    Exquisite Elegance
                  </div>
                  <div className="self-stretch text-white text-base font-normal leading-normal">
                    Immerse yourself in unparalleled luxury with our meticulously designed{" "}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="mt-px">
                  <img src={calenderHeart} alt="" />
                </div>
                <div className="w-[264px] max-lg:w-full h-[138px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                  <div className="text-white text-xl font-bold leading-[30px]">
                    Exquisite Elegance
                  </div>
                  <div className="self-stretch text-white text-base font-normal leading-normal">
                    Immerse yourself in unparalleled luxury with our meticulously designed{" "}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="flex md:flex-row max-w-[1296px] justify-between max-md:w-full relative -top-[88px] max-lg:hidden md:mx-6 xl:mx-4 z-40 items-center gap-x-4">
            <button onClick={swipePrev} disabled={isBeginning} className={`${isBeginning ? "opacity-50 cursor-not-allowed" : ""}`}>
              <img src={swipePrevIcon} width="8" height="14" alt="" />
            </button>
            <button onClick={swipeNext} disabled={isEnd} className={`${isEnd ? "opacity-50 cursor-not-allowed" : ""}`}>
              <img src={swipeNextIcon} width="8" height="14" alt="" />
            </button>
          </div>
        </div>

        <div className="w-[526px] max-sm:hidden h-full ml-auto">
          <img src={exquisite} alt="" />
        </div>
      </div>

      <div className="flex md:flex-row md:hidden md:mx-6 xl:mx-4 justify-center gap-x-10 -mt-5 z-40 items-center">
        <button onClick={swipePrev} disabled={isBeginning} className={`${isBeginning ? "opacity-50 cursor-not-allowed" : ""}`}>
          <img src={swipePrevIcon} width="8" height="14" alt="" />
        </button>
        <button onClick={swipeNext} disabled={isEnd} className={`${isEnd ? "opacity-50 cursor-not-allowed" : ""}`}>
          <img src={swipeNextIcon} width="8" height="14" alt="" />
        </button>
      </div>
    </>
  );
};

export default Exquisite;
