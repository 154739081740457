import React from "react";
import { Link } from "react-router-dom";
import cardImg from "../../assets/FutureGetaways/Rectangle 8792.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetHotelsByCitiesQuery } from "../../redux/features/futureGetaways/futureGetawaysApi";

const LuxuryVillasCity = ({ city }) => {
  const token = useTokenFromLocalStorage();

  const { data: hotelsData } = useGetHotelsByCitiesQuery(city);

  const stateData = [
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
    {
      city: "Stella Maris",
      img: cardImg,
      address: "P.O. Goa University Bambolim, Goa,",
      StartingFrom: "Starting from",
      starting: "₹ 6,650",
      facility: "/ night + Taxes",
    },
  ];

  return (
    <>
      <div className="mt-40 mb-40 px-2 lg:px-5">
        <h1 className="text-2xl md:text-[32px] text-[#000000] font-[Butler] lg:mb-12">
          Luxury Villas near {city}
        </h1>
        <div className="grid mt-6  xl:grid-cols-4 lg:grid-cols-3  grid-cols-1 overflow-hidden md:grid-cols-2 md:w-full gap-y-10 gap-x-5 ">
          {Array.isArray(hotelsData) && hotelsData.length > 0 ? (
            hotelsData?.map((item, i) => (
              <div key={i}>
                <Link to={`/propertyDetails/${item?._id}`}>
                  <div className="flex gap-4 w-[305px] h-[98px] items-center">
                    <img
                      loading="lazy"
                      src={item?.imageUrls?.[0]}
                      alt={`Hotel in ${item?.city}`}
                      className="object-cover h-[98px] w-[86px] border rounded-md"
                    />
                    <div className="w-99">
                      <p className="text-[#000000] font-[butler] text-2xl leading-6">
                        {item?.city}
                      </p>
                      <p className="text-gray-400 font-normal text-[12px] leading-[18px]">
                        {item?.address}
                      </p>
                      <p className="text-gray-400 font-[lato] text-[14px] leading-[21px]">
                        Starting from
                      </p>
                      <p className="text-black text-[18px] font-[lato] font-semibold leading-[27px] tracking-tight">
                        ₹{item?.pricePerNight} /{" "}
                        <span className="text-[#434141] text-[16px] font-normal leading-[24px]">
                          night + Taxes
                        </span>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p>No hotels available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default LuxuryVillasCity;
