import React, { useState } from "react";
import { Link } from "react-router-dom";
import chevronup from "../../assets/svg/chevronup.webp";
import Button from "../ui/Button";

const CancellationPolicy = ({ cancallationPoliciy }) => {
  const [showHouseRules, setShowHouseRules] = useState(false);

  const splitversion = cancallationPoliciy?.split(".,") || [];

  if (!cancallationPoliciy) {
    return <p className="p-5">No cancellation policy provided.</p>;
  }

  return (
    <div
      className="max-md:mt-0 mt-[60px] px-5 lg:px-5 2xl:px-0"
      id="Cancellation Policy"
      style={{ scrollMarginTop: "100px" }}
    >
      {/* Section Heading */}
      <div onClick={() => setShowHouseRules(!showHouseRules)}>
        <h1 className="text-[36px] max-md:text-2xl font-[500] font-[Butler] flex items-center justify-between max-md:py-6 cursor-pointer">
          Cancellation Policy
          <img
            src={chevronup}
            className={`max-md:block hidden w-6 h-6 transform transition-transform duration-300 ${
              showHouseRules ? "rotate-180" : ""
            }`}
            alt="Toggle"
          />
        </h1>
        {/* Divider */}
        <div className="w-full border border-[#CED4DA] font-[400]"></div>
      </div>

      {/* Main Info */}
      <div
        className={`mt-[40px] transition-all duration-300 ${
          showHouseRules ? "block" : "max-md:hidden"
        }`}
      >
        <ul className="list-disc flex flex-col gap-[12px] ml-5">
          {splitversion.map((item, i) => (
            <li key={i}>{item.trim()}.</li> 
          ))}
        </ul>
        <Link to="/cancellation">
          <div className="w-[196px]">
            <Button title="Read More" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CancellationPolicy;
