import React, { useState } from "react";
import Fuse from "fuse.js"; // For fuzzy search
import iconBottom from "../../../assets/images/Faqs/bottom.webp";
import search from "../../../assets/images/Faqs/search.webp";
import iconTop from "../../../assets/images/Faqs/topIcon.webp";

const Faqs = ({
  faqData = [
    {
      question:
        "Can I book only one or two rooms, or do I need to book the entire Home?",
      answer:
        "You can either book one room or the entire home, depending upon the number of people travelling with you. This villa has 3 bedrooms that can be booked.",
    },
    {
      question: "What is the check-in and check-out time?",
      answer:
        "The check-in time is 2:00 PM and check-out time is 12:00 PM. Early check-in or late check-out is subject to availability.",
    },
    {
      question: "Is breakfast included in the booking?",
      answer:
        "Yes, breakfast is included in the price of your booking. You can choose from a variety of breakfast options during your stay.",
    },
    {
      question: "Is there parking available at the property?",
      answer:
        "Yes, we provide free parking for our guests. The parking area is secure and located close to the villa.",
    },
    {
      question: "Are pets allowed in the property?",
      answer:
        "Unfortunately, pets are not allowed inside the property for the comfort of all our guests.",
    },
  ],
}) => {
  const [openIndex, setOpenIndex] = useState(null); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [showHouseRules, setShowHouseRules] = useState(false); 

  const fuse = new Fuse(faqData, {
    keys: ["question", "answer"], 
    threshold: 0.3,
  });

  const filteredFaqData = searchKeyword
    ? fuse.search(searchKeyword).map((result) => result.item)
    : faqData;

  const toggleIcon = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      className="xl:max-w-[1296px] w-full items-center justify-center sm:mt-[144px] mx-auto px-5 lg:px-5 2xl:px-0 "
      id="FAQs"
    >
      <h1
        onClick={() => setShowHouseRules(!showHouseRules)}
        className="text-[36px] max-md:text-2xl font-[500] font-[butler] flex items-center justify-center max-md:py-6"
      >
        FAQs
      </h1>

      <div
        className={`mt-[40px] ${showHouseRules ? "block" : "max-md:hidden"}`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <input
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              className="w-[684px] max-md:w-full placeholder:text-[#434141] h-[48px] pl-16 pr-8 border border-gray-100 rounded-full bg-gray-100 text-neutral-700 text-lg font-medium leading-[27px] outline-none"
              placeholder="Search"
            />
            <img
              src={search}
              alt="search"
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
            />
          </div>
        </div>
      </div>

      <div className={`w-full ${showHouseRules ? "block" : ""}`}>
        {filteredFaqData.length === 0 && searchKeyword ? (
          <div className="text-center text-red-500 text-lg font-semibold">
            No results found.
          </div>
        ) : (
          filteredFaqData?.map((item, index) => (
            <div
              key={index}
              onClick={() => toggleIcon(index)}
              className="cursor-pointer"
            >
              <div className="flex justify-between h-[40px] py-8 px-0 mb-4">
                <div className="text-zinc-950 text-base font-semibold max-md:text-sm leading-normal tracking-tight">
                  {item.question}
                </div>
                <div>
                  <img
                    src={openIndex === index ? iconBottom : iconTop}
                    className="w-6 max-md:w-[17.61] cursor-pointer"
                    alt=""
                  />
                </div>
              </div>
              <div
                className={`transition-height duration-500 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="w-[746px] max-md:w-full p-4">{item.answer}</div>
              </div>
              <hr />
            </div>
          ))
        )}
        <div className="w-[746px] max-md:w-full h-px border-slate-200 transform -rotate-0"></div>
      </div>
    </div>
  );
};

export default Faqs;
