import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import calendarSvg from "../../../assets/svg/calendarSvg.webp";
import lens from "../../../assets/svg/lensBlack.webp";
import mobileSearch from "../../../assets/svg/mobileSearch.webp";
import { BaseURL } from "../../../redux/config";
import { useGetAllCitiesQuery } from "../../../redux/features/search/getAllCities";
import Container from "../../ui/Container";
import DateRange from "./DateRange";
import PickLocation from "./PickLocation";
import PickRooms from "./PickRooms";
const SearchBar = ({
  mobileSearchBar,
  setMobileSearchBar,
  setOpenSearchBar,
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [room, setRoom] = useState(0);
  const [location, setLocation] = useState("");
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [checkInDate2, setCheckInDate2] = useState(null);
  const [checkOutDate2, setCheckOutDate2] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);
  const [isCheckInDateEmpty, setIsCheckInDateEmpty] = useState(false);
  const [isCheckOutDateEmpty, setIsCheckOutDateEmpty] = useState(false);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);
  const CitiesData = useGetAllCitiesQuery(token);
  const [ShowDateRange, setShowDateRange] = useState(false);
  const dateRangeRef = useRef(null); // Reference to DateRange component
  const [noMatch, setNoMatch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Location
  const locationState = useLocation();
  const searchBar = locationState?.state?.searchData;
  const city = locationState?.state?.location;
  const checkIn = locationState?.state?.checkIn;
  const CheckOut = locationState?.state?.checkOut;
  const Children = locationState?.state?.children;
  const Adults = locationState?.state?.adults;
  const Room = locationState?.state?.room;
  const isProp = locationState?.state?.isProp;
  useEffect(() => {
    if (city) {
      setLocation(city);
    }

    if (checkIn) {
      setCheckInDate2(format(checkIn, "MMMM/dd/yyyy"));
    }

    if (CheckOut) {
      setCheckOutDate2(format(CheckOut, "MMMM/dd/yyyy"));
    }

    if (Children) {
      setChildren(Children);
    }

    if (Adults) {
      setAdults(Adults);
    }

    if (Room) {
      setRoom(Room);
    }
  }, [city, checkIn, CheckOut, locationState, Children, Adults, Room]);
  // Function to handle click on date selection

  const handleDateClick = () => {
    setShowDateRange(!ShowDateRange);
    setTimeout(() => {
      if (dateRangeRef.current) {
        window.scrollTo({
          top: 500, 
          behavior: 'smooth' 
        });
      }
    }, 100); // Adding a small delay to ensure the component is rendered
  };

  const toggleDropdown = () => {
    console.log("55555555555555555555555555");

    setIsOpen(!isOpen);
    setTimeout(() => {
      if (dateRangeRef.current) {
        // window.scrollTo(0, 460);
      }
    }, 300);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 550) {
        setShowDateRange(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Array of rooms and guests
  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
    {
      heading: "Rooms",
      subHeading: "",
      count: room,
      handler: setRoom,
    },
  ];

  // Format date to dd-mm-yyyy
  const handleDateFormat = (date) => {
    if (!date) return null;
    const formattedDate = `${
      date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
    }-${
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getFullYear()}`;
    return formattedDate;
  };

  // Fetch token from local storage on component mount
  useEffect(() => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  }, []);

  // Update location list when CitiesData or date range changes
  useEffect(() => {
    if (CitiesData && CitiesData.data) {
      const citiesObj = CitiesData.data.data;
      const cities = Object.keys(citiesObj);
      const list = cities?.map((city, idx) => ({
        id: idx,
        name: city,
      }));
      setLocationList(list);
    }
  }, [CitiesData, checkInDate, checkOutDate]);

  // Handle click outside to close date range selector
  useEffect(() => {
    const handleClickOutside = (event) => {
      // if (
      //   ShowDateRange &&
      //   dateRangeRef.current &&
      //   !dateRangeRef.current.contains(event.target)
      // ) {
      //   setShowDateRange(true);
      // }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ShowDateRange]);

  // Navigate to results page with search parameters
  const navigateTo = (searchProp) => {
    navigate("/allproperties", {
      state: {
        searchData: searchProp,
        location: location,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        room,
        adults,
        children,
        SearchTag: "",
      },
    });
  };

  // Handle search button click
  const handleSearch = async () => {
    if (setOpenSearchBar) {
      // setOpenSearchBar(false);
    }

    try {
      // Validate inputs
      if (location.trim() === "") {
        setIsLocationEmpty("Please Select a Location");
        return;
      }
      if (noMatch) {
        return;
      }

      // Perform API call
      const response = await fetch(
        `${BaseURL}/hotels/search?city=${location}&checkIn=${handleDateFormat(
          checkInDate
        )}&checkOut=${handleDateFormat(
          checkOutDate
        )}&noOfRooms=${room}&guests=${adults + children}&page=1&limit=1000000000000000000000000000000000`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      // console.log("Response data:", responseData?.data);

      // Navigate to results page with search data
      navigateTo(responseData);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const [showPickRooms, setShowPickRooms] = useState(false);
  const [showMobileDateRange, setShowMobileDateRange] = useState(false);
  useEffect(() => {
    console.log(checkOutDate, checkInDate);
    if (checkOutDate !== null && checkInDate !== null) {
      if (window.innerWidth < 760) {
        setShowMobileDateRange(false);
      }
      setShowDateRange(false);
      setShowPickRooms(true);
    }
  }, [checkOutDate, checkOutDate]);
  return (
    <>
      <Container>
        <div
          className={`my-3 max-lg:hidden block ${
            window.screen.width > 1000 && "py-10"
          } w-full  mx-auto ${ShowDateRange && "mb-20 "}`}
        >
          {ShowDateRange && (
            <div
              // onMouseLeave={() => setShowDateRange(false)}
              className="absolute  max-lg:top-[169px] lg:top-40 max-lg:right-0 max-lg:left-0 max-sm:w-[342px] flex justify-center left-0 right-0 max-xl:left-28 z-40 "
              ref={dateRangeRef} // Adding ref to this div
            >
              <DateRange
                checkInDate={checkInDate}
                checkInDate2={checkInDate2}
                ShowDateRange={ShowDateRange}
                setCheckInDate={setCheckInDate}
                setCheckInDate2={setCheckInDate2}
                checkOutDate={checkOutDate}
                checkOutDate2={checkOutDate2}
                setCheckOutDate={setCheckOutDate}
                setCheckOutDate2={setCheckOutDate2}
                setIsCheckInDateEmpty={setIsCheckInDateEmpty}
                isCheckInDateEmpty={isCheckInDateEmpty}
                setShowDateRange={setShowDateRange}
              />
            </div>
          )}
          <div className="flex w-full flex-col xl:flex-row justify-center gap-[16px] relative  bg-[#fff] p-10 rounded-md shadow-md shadow-slate-100">
            <div className="flex max-lg:flex-col gap-[16px] w-full">
              <div className="  w-full flex md:flex-row flex-col gap-y-[16px] gap-x-[16px]">
                <PickLocation
                  setShowDateRange={setShowDateRange}
                  locationList={locationList}
                  location={location}
                  setLocation={setLocation}
                  isLocationEmpty={isLocationEmpty}
                  setIsLocationEmpty={setIsLocationEmpty}
                  noMatch={noMatch}
                  setNoMatch={setNoMatch}
                />
                {/* <Pick
                  pickTitle="Check In"
                  isCheckIn={true}
                  selectedDate={checkInDate}
                  setSelectedDate={setCheckInDate}
                  setIsCheckInDateEmpty={setIsCheckInDateEmpty}
                  isCheckInDateEmpty={isCheckInDateEmpty}
                /> */}
                <div
                  onClick={handleDateClick} // Use the handleDateClick function
                  className="relative cursor-pointer w-full  "
                >
                  <span className="absolute top-1 bg-[#fff] left-[16px] font-Lato transform -translate-x-2 -translate-y-4 text-[#505356]  z-10 px-[4px] text-[14px]">
                    Check In
                  </span>
                  <div className=" flex relative  w-full justify-center ">
                    <div
                      type="text"
                      placeholder="Select Date"
                      placeholderText="Select Date"
                      className="border w-full placeholder:text-[#434141] border-neutral-700 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
                    >
                      {checkInDate2 !== null ? checkInDate2 : " Select Date"}
                    </div>{" "}
                    <div className="absolute z-20 right-3 top-3.5 ">
                      <img src={calendarSvg} className="w-6 h-6" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full  gap-y-[16px] flex md:flex-row flex-col gap-x-[16px]">
                <div
                  onClick={handleDateClick} // Use the handleDateClick function
                  className="relative cursor-pointer  min-w-[224px] w-full  "
                >
                  <span className="absolute top-1 bg-[#fff] left-[16px] font-Lato transform -translate-x-2 -translate-y-4 text-[#505356]  z-10 px-[4px] text-[14px]">
                    Check out
                  </span>
                  <div className=" flex relative  w-full justify-center ">
                    <div
                      type="text"
                      placeholder="Select Date"
                      placeholderText="Select Date"
                      className="border w-full placeholder:text-[#434141] border-neutral-700 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
                    >
                      {checkOutDate2 !== null ? checkOutDate2 : " Select Date"}
                    </div>{" "}
                    <div className="absolute z-20 right-3 top-3.5 ">
                      <img src={calendarSvg} className="w-6 h-6" alt="" />
                    </div>
                  </div>
                </div>
                {/* <Pick
                  pickTitle="Check out"
                  isCheckOut={checkInDate}
                  selectedDate={checkOutDate}
                  setSelectedDate={setCheckOutDate}
                  isCheckOutDateEmpty={isCheckOutDateEmpty}
                  setIsCheckOutDateEmpty={setIsCheckOutDateEmpty}
                /> */}
                <PickRooms
                  ShowDateRange={setShowDateRange}
                  roomsGuests={roomsGuests}
                  guests={children + adults}
                  room={room}
                  showPickRooms={showPickRooms}
                  isRoomEmpty={isRoomEmpty}
                  setIsRoomEmpty={setIsRoomEmpty}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  toggleDropdown={toggleDropdown}
                />
              </div>
            </div>

            <button
              className="w-[170px] h-[56px] bg-[#08090A] text-[#FFFFFF] mx-auto text-[16px] font-[700] py-[16px] px-[24px] rounded-[4px]"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        {/* mobile search bar */}
        <div className="hidden justify-center max-lg:flex">
          <div
            onClick={() => {
              setMobileSearchBar(!mobileSearchBar);
            }}
            className="max-md:w-[342px] mt-6 h-14 pl-6 pr-2 py-4 bg-neutral-100 rounded-[28px] md:w-[90%] md:mx-auto w-full border border-gray-300 justify-start items-center gap-4 inline-flex"
          >
            <div className="rounded-[20px] flex-col justify-center items-center gap-2 inline-flex">
              <div className="w-4 h-4 relative">
                <img src={lens} alt="" />
              </div>
            </div>
            <div className="justify-start items-center gap-4 flex">
              <div>
                <span className="text-zinc-950 text-base font-normal  leading-normal">
                  Search for a property
                </span>
                {/* <span className="text-zinc-950 text-base font-medium  leading-normal">
                  {" "}
                  Manali |
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div
        className={` ${
          mobileSearchBar ? "left-0 opacity-100" : "left-[-100%] opacity-0"
        }  z-50 fixed hidden max-lg:block overflow-y-scroll max-h-screen bg-white top-0 transform duration-500  min-h-screen w-full `}
      >
        <div className="text-zinc-950 text-lg font-semibold leading-[27px] mx-6 my-4 tracking-tight flex gap-4">
          <button
            onClick={() => {
              setMobileSearchBar(!mobileSearchBar);
            }}
          >
            <img src={mobileSearch} width="24" height="25" alt="" />
          </button>{" "}
          <h1>Search Location/Villa</h1>
        </div>
        <hr className="border border-gray-300" />
        <div className=" mx-6 py-6">
          <PickLocation
            locationList={locationList}
            location={location}
            setLocation={setLocation}
            isLocationEmpty={isLocationEmpty}
            setIsLocationEmpty={setIsLocationEmpty}
            noMatch={noMatch}
            setNoMatch={setNoMatch}
          />
          <div className="mt-6 flex gap-3">
            <div
              onClick={() => {
                setShowMobileDateRange(!showMobileDateRange);
                window.scrollTo(100, 100);
              }}
              className="relative cursor-pointer w-full  "
            >
              <span className="absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-medium z-10 px-[4px] text-[14px]">
                Check In
              </span>
              <div className=" flex relative  w-full justify-center ">
                <div
                  type="text"
                  placeholder="Select Date"
                  placeholderText="Select Date"
                  className="border w-full placeholder:text-[#434141] border-neutral-700 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
                >
                  {checkInDate2 !== null ? checkInDate2 : " Select Date"}
                </div>{" "}
                <div className="absolute z-20 right-3 top-3.5 ">
                  <img src={calendarSvg} className="w-6 h-6" alt="" />
                </div>
              </div>
            </div>
            <div
              onClick={() => setShowMobileDateRange(!showMobileDateRange)}
              className="relative cursor-pointer   w-full  "
            >
              <span className="absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-medium z-10 px-[4px] text-[14px]">
                Check out
              </span>
              <div className=" flex relative  w-full justify-center ">
                <div
                  type="text"
                  placeholder="Select Date"
                  placeholderText="Select Date"
                  className="border w-full placeholder:text-[#434141] border-neutral-700 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700
            text-base
            
            leading-[30px]"
                >
                  {checkOutDate2 !== null ? checkOutDate2 : " Select Date"}
                </div>{" "}
                <div className="absolute z-20 right-3 top-3.5 ">
                  <img src={calendarSvg} className="w-6 h-6" alt="" />
                </div>
              </div>
            </div>

            {showMobileDateRange && (
              <div
                // onMouseLeave={() => setShowDateRange(false)}
                className="absolute max-lg:top-[229px] lg:top-28 max-lg:right-0 max-lg:left-0   max-xl:left-28 z-50 mx-4 "
              >
                <DateRange
                  checkInDate={checkInDate}
                  checkInDate2={checkInDate2}
                  ShowDateRange={ShowDateRange}
                  setCheckInDate={setCheckInDate}
                  setCheckInDate2={setCheckInDate2}
                  checkOutDate={checkOutDate}
                  checkOutDate2={checkOutDate2}
                  setCheckOutDate={setCheckOutDate}
                  setCheckOutDate2={setCheckOutDate2}
                  setIsCheckInDateEmpty={setIsCheckInDateEmpty}
                  isCheckInDateEmpty={isCheckInDateEmpty}
                  setShowDateRange={setShowDateRange}
                  setShowMobileDateRange={setShowMobileDateRange}
                />
              </div>
            )}
          </div>
          <div className="mt-4">
            <PickRooms
              ShowDateRange={setShowDateRange}
              roomsGuests={roomsGuests}
              guests={children + adults}
              room={room}
              isRoomEmpty={isRoomEmpty}
              setIsRoomEmpty={setIsRoomEmpty}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              toggleDropdown={toggleDropdown}
            />
          </div>{" "}
          <button
            className="w-full mt-2 h-[56px] bg-[#08090A] text-[#FFFFFF] mx-auto text-[16px] font-[700] py-[16px] px-[24px] rounded-[4px]"
            onClick={() => {
              if (setOpenSearchBar) {
                setOpenSearchBar(false);
              }

              handleSearch();
              setMobileSearchBar(false);
            }}
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
