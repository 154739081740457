import React, { useEffect, useRef } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";

const MealPlansModal = ({ children, modal, onCloseModale, text }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCloseModale(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModale]);
  return (
    <div>
      <div
        className="fixed top-0 font-Lato right-0 left-0  z-[1000]  h-screen w-screen bg-black bg-opacity-50 flex justify-center items-center"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative p-4 w-[650px] h-[970px]  max-w-2xl max-h-full"
          ref={modalRef}
        >
          <div className="relative bg-[#FFFFFF] rounded-lg shadow h-full" style={{overflowY:"scroll",overflowX:"hidden"}}>
            {/* header */}
            <div className="  py-3 px-5 border-b rounded-t dark:border-gray-600 ">
              <div className="flex justify-between  ">
                <div className="text-xl text-[#08090A] font-[700]">
                  Meal Plans
                </div>
                <div onClick={onCloseModale}>
                  <img src={closelogo} alt="closelogo" className="w-8 h-8" />
                </div>
              </div>
            </div>

            {/*  body */}
            <div>{children}</div>

            {/*  footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealPlansModal;
