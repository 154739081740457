import { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import roomImage1 from "../../assets/images/property-d-side-3.webp";
import roomImage2 from "../../assets/images/propertyDetailsSlider2.webp";
import roomImage3 from "../../assets/images/propertyDetailsSlider1.webp";

const RoomDetails = ({ hotelRooms }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [roomsDetails, setRoomsDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seeMoreStates, setSeeMoreStates] = useState({});

  const parseHotelRooms = (rooms) => {
    const parsedRooms = [];
    let currentRoom = null;

    rooms.forEach((item) => {
      const parts = item.split("\r\n").map((p) => p.trim());

      parts.forEach((part) => {
        if (part.match(/^https?:\/\/.*\.(jpeg|jpg|png|webp)$/i)) {
          if (currentRoom) {
            parsedRooms.push(currentRoom);
          }
          currentRoom = {
            id: parsedRooms.length + 1,
            title: `Room ${parsedRooms.length + 1}`,
            description: "",
            coverImage: part,
          };
        } else if (currentRoom) {
          currentRoom.description +=
            (currentRoom.description ? " " : "") + part;
        }
      });
    });

    if (currentRoom) {
      parsedRooms.push(currentRoom);
    }

    return parsedRooms.length
      ? parsedRooms
      : [
          //FallBack
          {
            id: 1,
            title: " Room 1",
            description: "Ground Floor | King size Bed | Ensuite Bathroom *",
            coverImage: roomImage1,
          },
          {
            id: 2,
            title: "Living Room",
            description: "Luxurious Sofa with chairs | TV | Fish tank *",
            coverImage: roomImage2,
          },
          {
            id: 3,
            title: "Backyard",
            description: "Jungle View | Dining Table Set | BBQ | Bon Fire *",
            coverImage: roomImage3,
          },
          {
            id: 4,
            title: "Master Bed room",
            description: "Ground Floor | King size Bed | Ensuite Bathroom *",
            coverImage: roomImage1,
          },
          {
            id: 5,
            title: "Hall",
            description: "Luxurious Sofa with chairs | TV | Fish tank *",
            coverImage: roomImage2,
          },
          {
            id: 6,
            title: "Dining",
            description: "Jungle View | Dining Table Set | BBQ | Bon Fire *",
            coverImage: roomImage3,
          },
        ];
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  useEffect(() => {
    setLoading(true);

    const parsedRooms = parseHotelRooms(hotelRooms || []);
    setRoomsDetails(parsedRooms);

    const initialSeeMoreStates = parsedRooms.reduce((acc, room) => {
      acc[room.id] = false;
      return acc;
    }, {});

    setSeeMoreStates(initialSeeMoreStates);

    setLoading(false);
  }, [hotelRooms]);

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  }, [roomsDetails]);

  const toggleSeeMore = (roomId) => {
    setSeeMoreStates((prevState) => ({
      ...prevState,
      [roomId]: !prevState[roomId],
    }));
  };

  return (
    <div className="mt-[72px] px-4 lg:px-5 2xl:px-0 w-full">
      {/* Section heading */}
      <div className="flex w-full items-center justify-between">
        <div>
          <h1 className="text-[36px] max-sm:text-lg max-md:text-xl font-[500] font-[Butler]">
            Property Details
          </h1>
        </div>
        <div className="flex items-center justify-end max-sm:gap-2 gap-[16px]">
          <button
            className={`max-sm:w-6 max-lg:w-10 ${
              isBeginning ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={swipePrev}
            disabled={isBeginning}
          >
            <img src={scrollLeft} alt="scroll" />
          </button>
          <button
            className={`max-sm:w-6 max-lg:w-10 ${
              isEnd ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={swipeNext}
            disabled={isEnd}
          >
            <img src={scrollRight} alt="scroll" />
          </button>
        </div>
      </div>

      {/* Slider */}
      <div className="mt-[24px] max-lg:w-full 2xl:w-[856px]">
        {loading ? (
          <p>Loading room details...</p>
        ) : (
          <Swiper
            ref={swiperRef}
            spaceBetween={10}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              setIsBeginning(swiper.isBeginning);
              setIsEnd(swiper.isEnd);
            }}
            className="max-lg:w-full"
            breakpoints={{
              1280: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              630: {
                slidesPerView: 2,
              },
              368: {
                slidesPerView: 1,
              },
            }}
          >
            {roomsDetails.map((room) => {
              const words = room.description.split(" ");
              const hasMore = words.length > 15;
              const shortDescription = words.slice(0, 15).join(" ");

              return (
                <SwiperSlide
                  className="flex justify-center items-center w-full"
                  key={room.id}
                >
                  <div className="flex justify-center items-center w-full">
                    <div>
                      <img
                        className="h-[194px] object-cover rounded-[4px] cursor-pointer w-full"
                        src={
                          room.coverImage ||
                          "https://i.ibb.co/d28My0p/property-d-side-3.png"
                        }
                        alt="room"
                      />
                      <div className="p-[12px]">
                        <p className="text-[18px] font-Lato font-[600]">
                          {room.title}
                        </p>
                        <p className="mt-[8px] font-[400] font-Lato leading-5 text-[14px] text-[#505356]">
                          {seeMoreStates[room.id] || !hasMore
                            ? room.description
                            : `${shortDescription}...`}
                          {hasMore && (
                            <button
                              onClick={() => toggleSeeMore(room.id)}
                              className="text-blue-500 ml-1"
                            >
                              {seeMoreStates[room.id] ? "See Less" : "See More"}
                            </button>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default RoomDetails;
