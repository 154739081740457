import DemoMustRead from "../../../assets/svg/DemoMustRead.webp";
const MustRead = ({ data }) => {
  const dateFormatter = (date) => {
    if (!date) return "Date not available"; // Return a fallback string if date is undefined

    let curYear = date.substring(0, 4);
    let curMonth = date.substring(5, 7);
    let curDate = date.substring(8, 10);
    let conMonth = "";
    switch (curMonth) {
      case "01":
        conMonth = "January";
        break;
      case "02":
        conMonth = "February";
        break;
      case "03":
        conMonth = "March";
        break;
      case "04":
        conMonth = "April";
        break;
      case "05":
        conMonth = "May";
        break;
      case "06":
        conMonth = "June";
        break;
      case "07":
        conMonth = "July";
        break;
      case "08":
        conMonth = "August";
        break;
      case "09":
        conMonth = "September";
        break;
      case "10":
        conMonth = "October";
        break;
      case "11":
        conMonth = "November";
        break;
      case "12":
        conMonth = "December";
        break;
      default:
        conMonth = "January";
    }
    return `${conMonth} ${curDate}, ${curYear}`;
  };
  // const data = [0, 1, 2, 3, 4];
  return (
    <div className="col-span-2 w-full ">
      <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight ">
        Must Reads
      </div>
      {data?.map((item, index) => (
        <div key={index} className="w-[352px] h-[150px] justify-start items-start mt-8 gap-4 inline-flex">
          <img
            className="w-[134px] h-[150px] rounded-sm object-cover"
            alt="DemoMustRead"
            // src={DemoMustRead}
            src={item?.coverPhoto}
          />
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-base lg:text-lg font-normal font-butler leading-[27px] tracking-tight">
              {/* Ditch Maldives: Ultimate Unexplored Beaches India for you */}
              {item?.title}
            </div>
            <div className="text-zinc-500 text-sm font-normal leading-[21px] tracking-tight">
              {/* Beach I Things To Do */}
              {item?.overview?.substring(0, 20)}...
            </div>
            <div className="text-zinc-500 text-sm font-normal leading-[21px] tracking-tight">
              {/* 15 March, 2023 */}
              {item?.createdAt && dateFormatter(item?.createdAt.substring(0, 10))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MustRead;

// import React from "react";
// import AboutUsBanner from "../../../assets/svg/AboutUsBanner.webp";

// const MustReadsCard = ({ blog }) => {
//   const months = ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"]

//   const day = blog?.createdAt.substring(8, 10);
//   const month = months[parseInt(blog?.createdAt.substring(5, 7)) - 1]
//   const year = blog?.createdAt.substring(0, 4)
//   // console.log(day, month, year)

//   return (
//     <div className="flex gap-4">
//       <div
//         style={{
//           backgroundImage: `url(${blog?.coverPhoto})`,
//         }}
//         className="min-w-[134px] w-[134px] h-[150px] bg-cover bg-center rounded-[2px]"
//       ></div>
//       <div>
//         <h4 className="font-butler text-lg leading-7 tracking-[0.18px] mb-2">
//           {blog?.title}
//         </h4>
//         <p className="text-[#6D747A] text-sm leading-5 tracking-[0.14px]">
//           {/* Beach | Things To Do */}
//           {blog?.activity || "Beach | Things To Do"}
//         </p>
//         <p className="text-[#6D747A] text-sm leading-5 tracking-[0.14px]">
//           {day} {month} | {year}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default MustReadsCard;
