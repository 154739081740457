import React from "react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RectangleSlider from "../../../assets/images/Rectangle 8766.webp";
import "./slider.css";

import { useNavigate } from "react-router-dom";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { useLogInMutation } from "../../../redux/features/auth/authApi";
import { useEffect } from "react";
import { useState } from "react";

const banner = [
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
];

const Slider = ({ isHomeScreen, city }) => {
  const [BannerData,setBannerData]=useState()
  const handleFetchBanners = async ()=>{
    try{
      const response = await fetch("https://api.ukiyostays.com/api/banner")
      const res = await response.json()
      console.log(res)
      let filteredData;

      if (isHomeScreen) {
        filteredData = res?.data?.filter(banner => !banner.city || banner.city === "");
      } else {
        filteredData = res?.data?.filter(banner => banner.city === city);
        if (filteredData.length === 0) {
          filteredData = res?.data?.filter(banner => !banner.city || banner.city === "");
        }
      }
      
      setBannerData(filteredData);
      
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    handleFetchBanners()
  },[])
  const token = useTokenFromLocalStorage();
  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLogInMutation();
  const navigate = useNavigate();
  return (
    <div className="overflow-x-hidden">
      <div className="w-screen mx-auto overflow-hidden overflow-x-hidden no-scrollbar">
        <div className="overflow-x-hidden no-scrollbar">
          <Swiper
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 5000,
            }}
            navigation
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            {BannerData?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="relative w-full">
                  <img
                    src={item?.bannerImage===""?RectangleSlider:item?.bannerImage}
                    className="w-full min-h-[500px]  max-h-[500px] object-cover"
                    alt={`Slide ${index + 1}`}
                  />
                  <div className="absolute top-0 left-0 bottom-0 right-0 w-[100%] h-[100%] z-0 bg-red-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} />
                  {isHomeScreen ? (
                    <div className="absolute bottom-6 left-0 right-0 mb-[147px] lg:mb-44 text-white leading-[38.40px] lg:leading-3 flex flex-col items-center ">
                    <h1 className="min-w-[258px] lg:w-[826px] lg:min-h-[67px] font-[Butler] font-700 text-[#FFFFFF] text-[32px] mb-[24px] lg:mb-0 text-center lg:text-[56px]">
                        {item?.description}
                      </h1>
                      <a href={item?.ctaLinkUrl || `${window.location.hostname}/aboutUs`}>
                      <button
                        type="button"
                        className="w-[147px] h-[48px] rounded-md border border-solid bg-[#08090A4D]"
                       
                      >
                        {item?.cta || "Learn More"}
                      </button>
                      </a>
                    </div>
                  ) : (
                    <div className="absolute bottom-4 left-0 right-0 mb-[147px] lg:mb-40 text-white mt-[300px] flex flex-col items-center">
                      <h1 className="min-w-[258px] lg:w-[826px] lg:min-h-[67px] font-[Butler] font-700 text-[#FFFFFF] text-[32px] mb-[24px] lg:mb-0 text-center lg:text-[56px]">
                        {city ? city : "Goa"}
                      </h1>
                      <div className="w-full font-bold tracking-wider text-center max-md:leading-6 leading-9 max-md:text-base text-nowrap flex flex-col justify-center text-lg">
                        <h1>
                        {item?.description}
                        </h1>
                        {/* <h1 className="max-lg:hidden block">never end.</h1> */}
                        <br/>
                        <a href={item?.ctaLinkUrl || `/aboutUs`}>
                      <button
                        type="button"
                        className="w-[147px] h-[48px] rounded-md border border-solid bg-[#08090A4D]"
                       
                      >
                        {item?.cta || "Learn More"}
                      </button>
                      </a>
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Slider;
