import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import experiencesImg1 from "../../assets/images/experience.webp"; // directly imported
import Button from "../ui/Button";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

const Experiences = () => {
  const [images] = useState([experiencesImg1, experiencesImg1, experiencesImg1]);

  return (
    <div className="mt-[72px] px-5 lg:px-5 2xl:px-0">
      <h1 className="text-[36px] max-sm:text-xl font-[500] font-[Butler]">
        Experiences
      </h1>
      <div className="relative">
        {/* main section */}
        <div className="mt-[40px] max-sm:mt-4 max-sm:w-full w-[586px]">
          <Swiper modules={[Pagination]} pagination={{ clickable: true }} slidesPerView={1}>
            {images?.map((image, index) => (
              <SwiperSlide key={index}>
                <LazyLoadImage
                  effect="blur"
                  className="w-[586px]"
                  src={image}  // Directly using the imported image path
                  alt="experiences-image"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* absolute section */}
        <div className="w-[378px] max-sm:p-4 p-[24px] max-sm:block shadow-md md:absolute max-sm:w-full top-16 right-0 z-30 bg-white">
          <h1 className="text-[18px] font-[700]">Mark Life's Special Moments</h1>
          <p className="text-[16px] text-[#08090A] mt-[16px] mb-6">
            From corporate retreats to birthday parties, <br /> weddings and
            jubilees to baby showers, <br /> Ukiyo’s luxury villas can host them
            all.
          </p>
          <Link to="/ContactUs" className="rounded border-2 border-[#08090A] px-6 py-2 font-semibold">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
