import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import building from "../../../assets/svg/building.webp";
import priceTag from "../../../assets/svg/priceTag.webp";
import routeTick from "../../../assets/svg/routeTick.webp";

const SideBar = () => {
  const pathName = useLocation()?.pathname;
  const location = useLocation();
  const [fromPropertyDetails, setFromPropertyDetails] = useState(false);
  const [fromPricingDetails, setPricingDetails] = useState(false);
  const [fromAddMoreOption, setAddMoreOption] = useState(false);

  // Loading states
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [isLoadingPricing, setIsLoadingPricing] = useState(false);
  const [isLoadingAddMore, setIsLoadingAddMore] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("PropertyDetails") &&
      !localStorage.getItem("pricingDetails")
    ) {
      setFromPropertyDetails(true);
    } else if (
      localStorage.getItem("pricingDetails") &&
      localStorage.getItem("PropertyDetails") &&
      !localStorage.getItem("AddMoreOption")
    ) {
      setFromPropertyDetails(true);
      setPricingDetails(true);
    } else if (
      localStorage.getItem("pricingDetails") &&
      localStorage.getItem("PropertyDetails") &&
      localStorage.getItem("AddMoreOption")
    ) {
      setFromPropertyDetails(true);
      setPricingDetails(true);
      setAddMoreOption(true);
    }
  }, [location]);

  // Simulate loading action
  const handleButtonClick = (type) => {
    if (type === "property") {
      setIsLoadingProperty(true);
    } else if (type === "pricing") {
      setIsLoadingPricing(true);
    } else if (type === "addMore") {
      setIsLoadingAddMore(true);
    }

    // Simulate API call
    setTimeout(() => {
      if (type === "property") setIsLoadingProperty(false);
      if (type === "pricing") setIsLoadingPricing(false);
      if (type === "addMore") setIsLoadingAddMore(false);
    }, 2000); // Simulates a 2-second loading period
  };

  return (
    <div>
      <div className="max-md:mt-16 md:w-[306px] flex h-full md:relative border-r max-md:mb-[100px]">
        <div className="md:w-[306px] h-[268.50px] left-0 top-0 absolute justify-start items-center gap-[100px] inline-flex">
          <div className="md:w-[307px] flex-col justify-start  gap-8 inline-flex">
            <div className="md:w-[307px]  justify-end items-center inline-flex">
              <div className=" md:w-[305px] justify-start items-center gap-[100px] inline-flex">
                <div className="flex-col justify-start items-start gap-8 inline-flex">
                  <div className="mt-16 md:w-[194px] h-7 text-black md:text-2xl font-semibold font-Lato leading-9 tracking-tight">
                    List your property
                  </div>
                </div>
              </div>
            </div>
            <div className="md:-mt-3 max-md:border-b-2 text-center md:w-[305px] relative flex justify-center items-center mx-auto max-md:gap-8 lg:flex-col">
              <Link
                to="/ListYourVilla"
                className={`${
                  pathName === "/ListYourVilla" ? "bg-neutral-300" : ""
                } w-full py-3 flex flex-col lg:flex-row rounded text-black
lg:text-xl gap-x-4 items-center max-md:justify-center hover:bg-neutral-300 px-2
font-normal
font-Lato
tracking-tight`}
                onClick={() => handleButtonClick("property")}
              >
                <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                  <img
                    src={building}
                    className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                    alt=""
                    data-twe-toggle="tooltip"
                    title="Add property details"
                  />
                </div>
                {isLoadingProperty ? (
                  <span className="loader inline-block w-5 h-5 border-4 border-t-4 border-t-neutral-600 rounded-full animate-spin"></span>
                ) : (
                  "Add property details"
                )}
              </Link>

              <Link
                to="/ListYourVilla/PriceDetails"
                className={`${
                  pathName === "/ListYourVilla/PriceDetails"
                    ? "bg-neutral-300"
                    : ""
                } w-full py-3 flex flex-col lg:flex-row rounded text-black
                  lg:text-xl gap-x-4 items-center hover:bg-neutral-300 px-2 font-normal font-Lato tracking-tight`}
                onClick={() => handleButtonClick("pricing")}
              >
                <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                  <img
                    src={priceTag}
                    className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                    alt=""
                    data-twe-toggle="tooltip"
                    title="Pricing Details"
                  />
                </div>
                {isLoadingPricing ? (
                  <span className="loader inline-block w-5 h-5 border-4 border-t-4 border-t-neutral-600 rounded-full animate-spin"></span>
                ) : (
                  "Pricing details"
                )}
              </Link>

              <Link
                to="/ListYourVilla/AddMoreOptions"
                className={`${
                  pathName === "/ListYourVilla/AddMoreOptions"
                    ? "bg-neutral-300"
                    : ""
                } w-full py-4 flex flex-col lg:flex-row rounded text-black
lg:text-xl gap-x-4 items-center hover:bg-neutral-300 px-2
font-normal
font-Lato
tracking-tight`}
                onClick={() => handleButtonClick("addMore")}
                data-twe-toggle="tooltip"
                title="Add More Options"
              >
                <div className="w-10 h-10 p-[9px] bg-neutral-200 rounded-[50px] justify-center items-center inline-flex">
                  <FaPlus className="text-[22px]" />
                </div>
                {isLoadingAddMore ? (
                  <span className="loader inline-block w-5 h-5 border-4 border-t-4 border-t-neutral-600 rounded-full animate-spin"></span>
                ) : (
                  "Add More Options"
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
