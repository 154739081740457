import React,{ useState } from "react";
import img from "../../assets/images/EventsAndExp/two.webp";
import locPin from "../../assets/svg/locationPin.webp";
import paw from "../../assets/svg/paw-print.webp";
import star from "../../assets/svg/star.webp";
import heartIcon from "../../assets/icon/heart.webp";
const SearchCard = (props) => {
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(!showAll);
  };
  const imageUrls = props.data.imageUrls;
  const validImageUrls = imageUrls.filter(url => /\.(jpg|jpeg|png)$/i.test(url));
  const randomImageUrl = validImageUrls[Math.floor(Math.random() * validImageUrls.length)];
//console.log("SearchCard prpo : ",props.data)
  return (
    <div className="grid grid-cols-[1fr,2fr] rounded-lg overflow-hidden shadow-md relative">
      {/* Image */}
        <span className="absolute top-0 right-0 m-3">
          <img src={heartIcon} alt="Heart Icon" className="h-6 w-6" />
        </span>

      <div
        style={{
          backgroundImage: `url(${randomImageUrl}) `,
        }}
        className="min-w-[332px] min-h-[324px] bg-cover bg-center"
      >
        <p className="text-[#434141] text-sm font-semibold leading-[21px] tracking-[0.14px] mt-[15px] ml-[20px] bg-white w-fit px-2 py-1 rounded">
          Popular Venue
        </p>
      </div>
      {/* Text content */}
      <div className="p-6">
        {/* Card heading */}
        <h4 className="font-butler text-2xl leading-[36px] tracking-[0.24px] mb-1 flex justify-between">
          {props.data?.name || 'Hotel Indigo Goa Downtown' }
        </h4>
        {/* Location */}
        <div className="flex items-center mb-1">
          <img className="h-6 w-6" src={locPin} alt="location pin" />
          <p className="text-[#434141] font-[500] leading-[24px] tracking-[0.16px]">
            {props.data?.address || props.data?.city}, {props.data?.state}
          </p>
        </div>
        {/* Sub details */}
        <p className="text-[#434141] leading-[24px] tracking-[0.16px] mb-[7px] ms-2">
          {`${props.data.noOfBedrooms + props.data.noOfRooms} Event Rooms I ${props.data?.SquareFeet || "9381"} sq.ft Event space `}
        </p>
        {/* Lower Grid */}
        <div className="border-t-[#CED4DA] border-t-[1px] ">
          {/* Left section */}
          <div className="">
            {/* Features */}
            <div className="py-[13px]">
              {/* Feature 1 */}
             <div className="grid grid-cols-2 gap-2 py-3">
                {props?.data?.amenities?.slice(0, showAll ? props.data.amenities.length : 3).map((item, index) => (
                  <div key={index} className="flex gap-2 ">
                    <img src={paw} alt="paw" className="h-6 w-6" />
                    
                    <p className="text-gray-500 leading-[21px]">
                      {item}
                    </p>
                  </div>
                ))}
                {props?.data?.amenities?.length > 4 && (
                  <button onClick={handleToggle} className="text-gray-400 text-start">
                    {showAll ? 'View Less Amenities' : '10+ Amenities'}
                  </button>
                )}
                
             </div>
             
              <p className="flex text-gray-500">
                      <span className="pe-4 pt-2">
                        <span className="font-semibold">Check-in Time :</span> {props.data.checkInTime}</span> 
                      <span className="pe-4 pt-2">
                        <span className="font-semibold">Check-out Time :</span> {props.data.checkOutTime}</span> </p>
                      <p className="flex text-gray-500">
                        <span className="font-semibold">Status : </span> 
                        <span>&nbsp; {props.data.status}</span>
                      </p>
                      <p className="flex text-gray-500">
                        <span className="font-semibold"> Number of rooms & Bedrooms : </span>
                        <span> {props.data.noOfBedrooms + props.data.noOfRooms}</span>
                      </p>
                      
            </div>
            {/* Rating */}
            <div className="flex items-center gap-3 mb-5 w-auto absolute bottom-0">
              <div className="flex items-center">
                <img src={star} alt="star" />
                <p className="text-[#08090A] text-sm leading-[18px]">
                  {props.data?.rating}{" "}
                  {`(${props.data?.reviews.length} reviews)`}
                </p>
              </div>
              <p className="text-[#08090A] text-xs leading-[18px] tracking-[0.12px] border-[#CED4DA] border-[1px] rounded py-1 px-3">
                Exceptional
              </p>
            </div>
          </div>
          {/* Right section */}
          <div className="flex flex-col justify-end items-end">
            <p className="text-[#434141] leading-[24px]">starting from</p>
            <p className="text-xl font-bold leading-[30px]">₹ 6,650</p>
            <p className="text-[#434141] leading-[24px] mb-2">
              + taxes /Per night
            </p>
            <button className="py-4 px-6 bg-[#08090A] text-white rounded font-bold leading-[16px] -tracking-[0.16px]">
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
    

  );
};

export default SearchCard;
