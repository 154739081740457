import CustomerCentric from "../../../assets/svg/CustomerCentric.webp";
import { useGetStatsDataQuery } from "../../../redux/features/aboutUs/aboutApi";
import { Link } from "react-router-dom";

const MostCentric = () => {
  // AboutStats api using rtk
  const { data, isLoading, isSuccess } = useGetStatsDataQuery();

  return (
    <>
      <div className="flex flex-col sm:hidden mx-3">
        <div className="flex flex-col gap-3 py-5 px-3 ">
          <div className="text-zinc-950 text-[24px] lg:text-[36px] font-medium font-butler  ">
            The most customer-centric Villa company
          </div>
          <div
            className="self-stretch text-neutral-700 text-base font-medium 
           leading-normal"
          >
            If you've found your way here, chances are you're a fan of fantastic
            stays. Well, welcome to Ukiyo Stays—where vacation magic happens!
            Founded by the dynamic quartet of Pinnac Yeddy, Shubham Pille,
            Taisin Qureshi, and Suhel Shaikh, we're not just a company; we're
            travel specialists. Think of us as your travel soulmates, making
            wanderlust dreams come true!
          </div>
        </div>
        <div className="mb-4 mx-3 px-4 py-4 rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 flex cursor-pointer w-36">
          <div
            className="text-zinc-950 text-base font-semibold 
           leading-normal"
          >
            <Link to='/HowUkiyoWorks' >Discover More</Link>
          </div>
        </div>
        <div className="relative mx-3">
          <img
            className="w-full h-fit absolute left-0 top-0 border-2 border-black"
            alt="CustomerCentric"
            src={CustomerCentric}
          />
          <div className=" left-0 top-0 absolute border-2 border-black">
            <div className="w-64 h-4 left-0 top-[180px] absolute bg-white" />
            <div className="w-3.5 h-[420px] left-[256px] top-0 absolute bg-white" />
            <div className="w-[5.5rem] h-4 left-[270px] top-[332px] absolute bg-white" />
            <div className="w-64 h-5 left-0 top-[446px] absolute bg-white" />
          </div>
        </div>
        <div className="flex flex-col gap-3  relative mt-[30rem] mx-3 shadow-2xl">
          <div className="px-12 py-5 border-b border-zinc-950 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.luxury + "+" : "00"}
            </div>
            <div
              className="text-center text-nowrap text-neutral-700 text-base font-normal 
         leading-normal"
            >
              Verified Luxury Villas
            </div>
          </div>
          <div className="px-12 py-5 border-b border-zinc-950 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.destination + "+" : "00"}
            </div>
            <div
              className="text-center text-neutral-700 text-base font-normal 
         leading-normal text-nowrap"
            >
              Destinations in India & Abroad
            </div>
          </div>
          <div className="px-12 py-5 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.guestHosted + "+" : "00"}
            </div>
            <div
              className="text-center text-neutral-700 text-base font-normal 
         leading-normal text-nowrap"
            >
              Guests Hosted
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1440px] h-[609px] relative mb-[169px] sm:block hidden">
        <div className="flex 2xl:gap-x-[88px] gap-x-[64px]">
          <div className="max-2xl:ms-[80px] ms-[148px] top-0  bg-white justify-start items-start gap-2 inline-flex">
            <div className="w-[526px] h-[584px] relative">
              <img
                className="w-[526px] h-[584px] left-0 top-0 absolute"
                alt="CustomerCentric"
                src={CustomerCentric}
              />
              <div className="w-[526px] h-[584px] left-0 top-0 absolute">
                <div className="w-64 h-4 left-0 top-[180px] absolute bg-white" />
                <div className="w-3.5 h-[584px] left-[256px] top-0 absolute bg-white" />
                <div className="w-64 h-4 left-[270px] top-[332px] absolute bg-white" />
                <div className="w-64 h-5 left-0 top-[446px] absolute bg-white" />
              </div>
            </div>
          </div>
          <div className="  flex-col justify-start items-start gap-12 inline-flex max-w-[511px]">
            <div className="w-[503.64px] text-zinc-950 text-4xl font-medium font-butler leading-[54px] tracking-tight">
              The most customer-centric Villa company
            </div>
            <div className="h-[248px] flex-col justify-start items-start gap-12 flex">
              <div className="self-stretch h-36 flex-col justify-start items-start gap-6 flex">
                <div
                  className="self-stretch text-neutral-700 text-base font-medium 
           leading-normal"
                >
                  If you've found your way here, chances are you're a fan of
                  fantastic stays. Well, welcome to Ukiyo Stays—where vacation
                  magic happens! Founded by the dynamic quartet of Pinnac Yeddy,
                  Shubham Pille, Taisin Qureshi, and Suhel Shaikh, we're not
                  just a company; we're travel specialists. Think of us as your
                  travel soulmates, making wanderlust dreams come true!
                </div>
              </div>
              <div className="px-6 py-4 rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 flex cursor-pointer">
                <Link to="/HowUkiyoWorks"
                  className="text-zinc-950 text-base font-semibold 
           leading-normal"
                >
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="2xl:left-[498px] max-h-[111px] left-[408px] top-[498px] absolute bg-white shadow max-w-[755px] justify-start items-start inline-flex">
          <div className="px-12 py-5 border-r border-zinc-950 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.luxury + "+" : "00"}
            </div>
            <div
              className="text-center text-nowrap text-neutral-700 text-base font-normal 
         leading-normal"
            >
              Verified Luxury Villas
            </div>
          </div>
          <div className="px-12 py-5 border-r border-zinc-950 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.destination + "+" : "00"}
            </div>
            <div
              className="text-center text-neutral-700 text-base font-normal 
         leading-normal text-nowrap"
            >
              Destinations in India & Abroad
            </div>
          </div>
          <div className="px-12 py-5 flex-col justify-start items-center gap-1 inline-flex">
            <div className="text-zinc-950 text-4xl font-medium font-butler">
              {isSuccess ? data.guestHosted + "+" : "00"}
            </div>
            <div
              className="text-center text-neutral-700 text-base font-normal 
         leading-normal text-nowrap"
            >
              Guests Hosted
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MostCentric;
