import React, { useEffect } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";
import { BsFillSendCheckFill } from "react-icons/bs";

const QueryMadal = ({ children, setQueryOpen }) => {

    // Scroll to the top when the modal is opened
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const handleClose = () => {
        setQueryOpen(false);
    };

    return (
        <div
            id="authentication-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="absolute inset-0 z-[1000] overflow-hidden h-screen max-h-full w-[100%] bg-black bg-opacity-50 flex justify-center items-center "
        >
            <div className="relative w-[424px] h-[650px] top-[160px] max-w-xl max-h-xl">
                {/* Modal content */}
                <div className="bg-white w-full flex flex-col align-middle justify-center gap-4   rounded-lg shadow ">
                    {/* Modal header */}
                    <div className="">
                        <div className="font-semibold text-gray-900 px-3 pt-5 flex justify-end">
                            <div onClick={handleClose}>
                                <img
                                    src={closelogo}
                                    alt="closelogo"
                                    className="w-7 h-7 cursor-pointer float-right "
                                />
                            </div>
                        </div>
                    </div>
                    {/* Modal body */}
                    <div className="w-20 h-20 m-auto flex align-middle justify-center bg-green-200 rounded-full items-center">
                        <BsFillSendCheckFill className="w-9 h-9 text-green-700" />
                    </div>
                    <div className="w-[338px]  m-auto flex-col align-middle justify-center">
                        <h3 className="text-center text-[#08090A] text-lg font-bold font-Lato">
                            Query Submitted Successfully!
                        </h3>
                        <p className="text-center text-[#434141] text-base font-normal font-Lato">
                            Thank you for contacting us. We will get in touch with you soon
                        </p>
                    </div>
                    <div className="w-full h-20 flex items-center justify-center border-t border-gray-200">
                    <button onClick={handleClose} 
                    className="w-[208px] h-11 text-[#ffff] cursor-pointer  rounded bg-[#039855] ">
                        Continue
                    </button>
                    </div>
                    {/* Modal Footer */}
                </div>
            </div>
        </div>
    );
};

export default QueryMadal;
