import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseURL } from "../../../../redux/config";
import { useGetAllCitiesQuery } from "../../../../redux/features/search/getAllCities";
import DateRange from "../../../Banner/SearchBar/DateRange";
import PickLocation from "../../../Banner/SearchBar/PickLocation";
import PickRooms from "../../../Banner/SearchBar/PickRooms";
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import eye from "../../../../assets/svg/eye.webp"

const Form = ({ titleDescription }) => {
  const navigateTo = useNavigate();
  const [token, setToken] = useState("");
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");
  const locationState = useLocation();
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const CitiesData = useGetAllCitiesQuery(token);
  const [room, setRoom] = useState(0);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [noMatch, setNoMatch] = useState(false); 
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState); 
  };
  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
    {
      heading: "Rooms",
      subHeading: "",
      count: room,
      handler: setRoom,
    },
  ];

  const dateRangeRef = useRef(null); // Ref to handle clicks outside

  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setTimeout(() => {
        setShowDateRange(false);
      }, 500);
    }
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    if (CitiesData && CitiesData?.data) {
      const citiesObj = CitiesData?.data?.data;
      const cities = Object.keys(citiesObj);
      const list = cities?.map((city, idx) => {
        const cityObj = {
          id: idx,
          name: city,
        };
        return cityObj;
      });
      setLocationList(list);
    }
  }, [CitiesData]);

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);

  // Handle location match and no match
  const checkLocationMatch = () => {
    const match = locationList.some((loc) => loc.name.toLowerCase() === location.toLowerCase());
    setNoMatch(!match); // Show error if no match
    return match;
  };

  // const handleSearch = async () => {
  //   try {
  //     if (location.trim() === "") {
  //       setIsLocationEmpty(true);
  //       return;
  //     }

  //     if (!checkLocationMatch()) {
  //       setIsLocationEmpty(true); // Error if location not found
  //       return;
  //     }

  //     const response = await fetch(
  //       `${BaseURL}/hotels/search?city=${location}&hotelType=${
  //         locationState?.state?.hotelType
  //       }&checkIn=${format(checkInDate, "dd-MM-yyyy")}&checkOut=${format(
  //         checkOutDate,
  //         "dd-MM-yyyy"
  //       )}&noOfRooms=${room}&guests=${adults + children}&page=1&limit=1000000000000000000000`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const responseData = await response.json();
  //     console.log("response data is : ", responseData);
  //     navigateTo(responseData);
  //   } catch (error) {
  //     console.error("There was a problem with your fetch operation:", error);
  //   }
  // };

  // Close DateRange picker when clicking outside
  const handleSearch = async () => {
    console.log("Location:", location);
    console.log("Check-in Date:", checkInDate);
    console.log("Check-out Date:", checkOutDate);
    console.log("Rooms:", room);
    console.log("Guests:", adults + children);

    try {
      if (location.trim() === "") {
        setIsLocationEmpty(true);
        return;
      }

      if (!checkLocationMatch()) {
        setIsLocationEmpty(true); 
        return;
      }
  
      const apiUrl =
       `https://api.ukiyostays.com/api/hotels/search?city=${location}&checkIn=${checkInDate?format(checkInDate,"yyyy-MM-dd") : ""}&checkOut=${checkOutDate ? format(checkOutDate, "yyyy-MM-dd") : ""}&noOfRooms=${room}&guests=${adults + children}&sortBy=&maxPrice=22086&page=1&limit=10000000000000000`;
  
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();  
      console.log("Response data in form:", responseData);
      navigateTo("/allproperties", { state: { data : responseData } });
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };
  
  const handleOutsideClick = (e) => {
    if (dateRangeRef.current && !dateRangeRef.current.contains(e.target)) {
      setShowDateRange(false);
    }
  };

  useEffect(() => {
    if (showDateRange) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDateRange]);

  return (
    <div>
      <div className="w-full">
        <div className="w-full flex-col justify-start items-start gap-6 inline-flex"></div>
        <div className="text-zinc-950 text-base leading-normal font-medium tracking-tight mb-2">Destination</div>
        <PickLocation
          setShowDateRange={setShowDateRange}
          locationList={locationList}
          location={location}
          setLocation={setLocation}
          isLocationEmpty={isLocationEmpty}
          setIsLocationEmpty={setIsLocationEmpty}
          noMatch={noMatch}
          setNoMatch={setNoMatch}
        />
        {isLocationEmpty && (
          <div className="text-red-500 text-sm mt-2">
            Please enter a valid location or select from the list.
          </div>
        )}
        {noMatch && (
          <div className="text-red-500 text-sm mt-2">
            Location not found. Please select a valid location.
          </div>
        )}
        <div className="mt-6 flex gap-3">
          <div
            onClick={() => setShowDateRange(!showDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="text-zinc-950 text-base font-medium leading-normal tracking-tight mb-2">
              Check In
            </div>
            <div className="flex relative w-full justify-center">
              <div className="flex items-center relative border w-full border-[#6D747A] rounded py-3 focus:outline-none font-medium px-6 text-[#6D747A] text-base leading-[30px]">
              <img src={eye}  className=" absolute left-[5px] top-[20px] text-gray-500" />
                <span className="mt-1 ml-2">{checkInDate !== null
                  ? format(checkInDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}</span>
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(!showDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="text-zinc-950 text-base font-medium leading-normal tracking-tight mb-2">
             
              Check Out
            </div>
            <div className="flex relative w-full justify-center">
              <div className="flex items-center relative border w-full border-[#6D747A] rounded py-3 focus:outline-none font-medium px-6 text-[#6D747A] text-base leading-[30px]">
            <img src={eye}  className=" absolute left-[5px] top-[20px] text-grey-500" />
                <span className="mt-1 ml-2">
                {checkOutDate !== null
                  ? format(checkOutDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}
                </span>
              </div>
            </div>
          </div>
          {showDateRange && (
            <div
              ref={dateRangeRef}
              className="absolute max-lg:top-[229px] lg:top-[300px] right-0 left-2 w-full z-40 scale-90"
            >
              <DateRange
                checkInDate={checkInDate}
                ShowDateRange={showDateRange}
                setCheckInDate={setCheckInDate}
                checkOutDate={checkOutDate}
                setCheckOutDate={setCheckOutDate}
                setShowDateRange={setShowDateRange}
              />
            </div>
          )}
        </div>
        <div className="text-zinc-950 text-base font-medium leading-normal tracking-tight mt-6 mb-2">
          Guests
        </div>
        <div className="w-full">
          <PickRooms
            ShowDateRange={setShowDateRange}
            roomsGuests={roomsGuests}
            guests={children + adults}
            room={room}
            isRoomEmpty={isRoomEmpty}
            setIsRoomEmpty={setIsRoomEmpty}
            handleDropdownToggle={setIsDropdownOpen}
          />
        </div>
        <button
          className="w-full h-[56px] bg-[#08090A] text-[#FFFFFF] mx-auto text-[16px] font-[700] my-4 py-[16px] px-[24px] rounded-[4px] flex justify-center items-center"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Form;
