import { useState } from "react";
import { NavLink } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import cavronRight from "../../assets/svg/chevron-right.webp";
import rewardIcon from "../../assets/svg/gift.webp";
import wishlistIcon from "../../assets/svg/heart-black-border.webp";
import luggageIcon from "../../assets/svg/luggage.webp";
import cancellationIcon from "../../assets/svg/no schedule.webp";
import userIcon from "../../assets/svg/user-icon.webp";
import { app } from "../../pages/auth/config";
import lockIcon from "../../assets/svg/lock.jpg";
import UserPlusIcon from "../../assets/svg/user-plus.png";

const authApp = getAuth(app);

const Sidebar = ({ userName, firstName, lastName, isLinked }) => {
  const [loading, setLoading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const [sideBarMenu] = useState([
    {
      id: 1,
      name: "My Profile",
      level: "profileDetails",
      icon: userIcon,
      title: "My Profile",
    },
    {
      id: 2,
      name: "My Booking",
      level: "myBooking",
      icon: luggageIcon,
      title: "My Booking",
    },
    {
      id: 3,
      name: "Wishlist",
      level: "wishlist",
      icon: wishlistIcon,
      title: "My Wishlist",
    },
    {
      id: 4,
      name: "Rewards",
      level: "rewards",
      icon: rewardIcon,
      title: "My Rewards",
    },
    {
      id: 5,
      name: "Cancellations",
      level: "cancellations",
      icon: cancellationIcon,
      title: "My Cancellations",
    },
    {
      id: 6,
      name: "Invite Friends & Family",
      level: "Invite",
      icon: UserPlusIcon,
      title: "Invite Friends & Family",
      action: () => setIsModalOpen(true), // Open modal on click
    },
    {
      id: 7,
      name: "Security",
      level: "Security",
      icon: lockIcon,
      title: "Security",
    },
  ]);

  const handleItemClick = (item) => {
    setLoading(item.id); // Set loading state for the clicked item
    if (item.action) {
      item.action(); // Trigger the action if defined
    } else {
      setTimeout(() => {
        setLoading(null); // Simulate loading completion
      }, 2000);
    }
  };

  // Handle logout functionality
  const handleLogOut = () => {
    localStorage.removeItem("token");
    signOut(authApp)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  // Function to copy the site link
  const handleCopyLink = () => {
    const link = "https://www.ukiyostays.com/"; 
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  // Function to open WhatsApp with a pre-filled message
  const handleWhatsAppShare = () => {
    const link = "https://yoursite.com"; // Replace with your site URL
    const message = `Check out this amazing site: ${link}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, "_blank");
  };

  return (
    <>
      <div className="flex flex-col text-base tracking-normal text-zinc-950 w-[288px]">
        <div className="flex flex-col p-6 w-full bg-white rounded-lg border border-solid border-[color:var(--Card-stroke,#EAEFF2)]">
          {/* Profile heading */}
          <div className="flex items-center justify-center">
            <div
              className="flex items-center justify-center bg-[#E3E3E3] w-[113px] h-[113px] rounded-full transition-transform duration-300 group-hover:scale-105 cursor-pointer"
              aria-label="View Profile"
            >
              <span className="text-[32px] font-[500] text-[#08090A] font-[Butler]">
                {firstName?.slice(0, 1)}
              </span>
              <span className="text-[32px] font-[500] text-[#08090A] font-[Butler]">
                {lastName?.slice(0, 1)}
              </span>
            </div>
          </div>

          <p className="self-center mt-[32px] text-xl font-medium font-[Butler] tracking-normal leading-5 text-black whitespace-nowrap">
            {userName || "Username"}
          </p>
          {/* Profile heading end */}

          {/* User dashboard route tab */}
          <div className="flex flex-col font-Lato justify-center py-[12px] mt-[32px] rounded gap-y-[16px] w-[247px]">
            {sideBarMenu?.map((item) => (
              <NavLink
                key={item.id}
                style={({ isActive }) => ({
                  background: isActive ? "#E3E3E3" : "none",
                })}
                to={item.level !== "Invite" ? `/userdashboard/${item.level}` : "#"}
                className="rounded"
              >
                <div
                  onClick={() => handleItemClick(item)}
                  className="flex gap-5 items-center px-[8px] py-[12px] justify-between rounded-tl-[4px] w-[247px] h-[48px] cursor-pointer"
                >
                  <div className="flex gap-[12px] items-center">
                    <img
                      src={item.icon}
                      className="w-6 h-6 object-contain"
                      alt="icon"
                      data-twe-toggle="tooltip"
                      title={item.title}
                    />
                    <h1 className="text-[16px] text-[#08090A] font-Lato font-normal leading-6 tracking-[0.01em]">
                      {loading === item.id ? (
                        // Loader animation
                        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-blue-500"></div>
                      ) : (
                        item.name
                      )}
                    </h1>
                  </div>
                  <img
                    minWidth={24}
                    minHeight={24}
                    src={cavronRight}
                    alt="icon"
                    data-twe-toggle="tooltip"
                    title={item.title}
                  />
                </div>
              </NavLink>
            ))}
          </div>
        </div>

        {/* Logout Button */}
        <div className="w-full h-[56px] mt-5 border border-[#F8F9FA] rounded-md bg-[#08090A]">
          <button
            onClick={handleLogOut}
            className="w-full h-full text-[#FFFFFF] text-lg font-[Lato] font-medium leading-4"
          >
            Log Out
          </button>
        </div>
      </div>

      {/* Invite Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2147483647] fixed top-0 left-0">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h2 className="text-xl font-bold mb-4">Invite Friends & Family</h2>
            <p className="mb-4">
              Share this link with your friends and family to invite them!
            </p>
            <div className="flex gap-4">
              <button
                onClick={handleCopyLink}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Copy Link
              </button>
              <button
                onClick={handleWhatsAppShare}
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
              >
                Share on WhatsApp
              </button>
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 text-red-500"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
