import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const FormDropDown = ({ name, holder, func, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(holder);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    func(value); 
  };

  return (
    <div className="flex flex-col gap-2 relative">
      <p className="text-[#08090A] font-semibold leading-6 tracking-[0.16px]">
        {name}
      </p>
      <div
        className="text-[#6D747A] h-12 p-3 flex justify-between items-center rounded border-[1px] border-[#CED4DA] leading-6 tracking-[0.16px] cursor-pointer"
        onClick={toggleDropdown}
      >
        <span>{selectedOption}</span>
        <IoIosArrowDown
          className={`text-xl text-black transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {isOpen && (
        <ul className="absolute w-full bg-white border border-[#CED4DA] rounded mt-20 z-10">
          {options.map((option, index) => (
            <li
              key={index}
              className="p-3 hover:bg-gray-100 cursor-pointer "
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormDropDown;
