import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import SideBar from "../../components/ListYourVilla/SideBar/SideBar";
import Navbar from "../../components/Navbar/Navbar";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";

const ListYourVillaLayout = () => {
  const token = useTokenFromLocalStorage();
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col overflow-hidden relative  ">
      <Navbar />
      <div className="pt-16 mx-auto min-h-screen px-[30px] md:px-[70px] flex flex-col lg:flex-row  w-full gap-6">
        <SideBar />
        <div className="">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ListYourVillaLayout;
