import { useNavigate } from "react-router-dom";
import cavronRight from "../../../../assets/svg/chevron-right.webp";
import { useGetThingsCardDataQuery } from "../../../../redux/features/thingsToDo/thingsCardApi";
import thingstodoblogcover from "../../../../assets/svg/thingstodoblogcover.webp";
import thingstodoblogcoverMedium from "../../../../assets/svg/thingstodoblogcover.webp";
import thingstodoblogcoverSmall from "../../../../assets/svg/thingstodoblogcover.webp";
import thingstodoblogcoverLarge from "../../../../assets/svg/thingstodoblogcover.webp";
import shareIcon from "../../../../assets/svg/share.webp";

const BlogCard = ({ id }) => {
  const { data } = useGetThingsCardDataQuery();
  const navigate = useNavigate();

  return (
    <div className="h-full mb-6 mx-1 columns-1 w-full">
      {/* Card Image Section */}
      <div
        className={`${
          id === 4 ? "h-[800px]" : "h-[348px]"
        } max-w-[628px] w-full rounded-tl-lg rounded-tr-lg relative`}
      >
        <img
          src={thingstodoblogcover}
          srcSet={`${thingstodoblogcoverSmall} 480w, ${thingstodoblogcoverMedium} 1024w, ${thingstodoblogcoverLarge} 1600w`}
          sizes="(max-width: 600px) 480px, (max-width: 1200px) 1024px, 1600px"
          className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg"
          alt="Blog Cover"
        />
        {/* Date Badge */}
        <div className="w-[61px] h-[67px] px-4 py-2 bg-slate-100 rounded-sm flex-col top-6 left-6 justify-center absolute items-center gap-0.5 inline-flex">
          <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
            APR
          </div>
          <div className="w-[29px] text-black text-[28px] font-normal font-butler leading-7 tracking-tight">
            28
          </div>
        </div>
      </div>

      {/* Card Content Section */}
      <div className="max-w-[628px] border border-neutral-700/10 min-h-[358px] px-6 py-8 bg-white flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch min-h-[235px] flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 font-Lato text-neutral-700 text-sm md:text-base font-medium leading-normal">
              OUTDOORS
            </div>
            <div className="w-6 h-6 cursor-pointer">
              <img
                src={shareIcon}
                width="24"
                height="24"
                alt="Share"
                data-twe-toggle="tooltip"
                title="Share this post"
              />
            </div>
          </div>
          <div className="self-stretch min-h-[203px] flex-col justify-start items-start gap-4 flex">
            {/* Title section with Share Icon */}
            <div className="flex justify-between items-start w-full relative">
              <div className="text-black text-xl md:text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                Top Villas for House Parties: An Ultimate Celebration Guide
              </div>
            </div>

            {/* Content Section */}
            <div className="self-stretch text-neutral-700 text-base font-Lato font-medium leading-7">
              Immerse yourself in unparalleled luxury with our meticulously
              designed accommodations. Immerse yourself in unparalleled luxury
              with our meticulously designed accommodations. Immerse yourself in
              unparalleled luxury with our meticulously designed accommodations.
            </div>
          </div>
        </div>
        <div
          className="text-black mt-8 text-lg font-normal cursor-pointer underline leading-[27px] tracking-tight"
          onClick={() => navigate(`/singleBlog/${id}`)}
        >
          Read More
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
