import React from "react";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "./LuxuryVillasSlider.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const LuxuryVillasSlider = ({ data }) => {
  return (
    <div className="overflow-x-hidden h-full">
      <div className="mx-auto overflow-hidden h-full overflow-x-hidden no-scrollbar">
        <div className="overflow-x-hidden h-full no-scrollbar">
          <Swiper
            modules={[Pagination, Autoplay, A11y]}
            slidesPerView={1}
            loop={false}
            pagination={{ clickable: true }}
            speed={3000}
            className="h-full"
          >
            {data?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="relative cursor-default h-full">
                  <img
                    src={item.slideImages}
                    className="lg:w-[332px] w-full h-[348px] object-cover  cursor-default"
                    alt={`Slide ${index + 1}`}
                  />
                  <p className="absolute border rounded-md top-3 left-3  bg-white py-1 px-3 text-[#434141]  font-medium text-lg">
                    Popular Home
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default LuxuryVillasSlider;
