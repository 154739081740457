import React, { useEffect, useRef } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useDeleteWishlistMutation } from "../../redux/features/userDashboard/userDashboardApi";

const WishListDeleteModal = ({ id, setIsDeleteWishlist }) => {
  const token = useTokenFromLocalStorage();
  const [deleteWishlist] = useDeleteWishlistMutation();

  const closeModal = (token, id) => {
    console.log(token, id);
    deleteWishlist({ token, propertyId: id });
    console.log("wihsliast item deleted");
    setIsDeleteWishlist(false);
  };
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsDeleteWishlist(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  const cancelModal = () => {
    setIsDeleteWishlist(false);
  };

  return (
    <div className="fixed  top-0 right-0 left-0 z-50   h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center ">
      <div
        className="bg-white w-[290px] h-[270px] border border-[#dcdada] rounded-md "
        ref={modalRef}
      >
        <div
          className="flex items-end justify-end py-3 px-1 text-[#6D747A]"
          onClick={closeModal}
        >
          {/* Header */}
          <img src={closelogo} alt="close" className="w-[22px] h-[22px] " />
        </div>

        <div className=" flex items-center justify-center px-5 mb-6 translate-x-1 ">
          {/* BODY */}
          <div className="text-center">
            <h1 className="text-[#101828] font-[700] text-[18px]">
              Delete this from wishlist?
            </h1>
            <p className="text-[#434141] text-center text-sm font-[400] leading-1">
              Are you sure you want to remove this Property from your wishlist.
            </p>
          </div>
        </div>
        <hr />
        <div className="flex items-center justify-center gap-4  mt-6">
          {/* FOOTER */}
          <button
            onClick={cancelModal}
            className="w-[115px] h-14 rounded-[4px] border font-bold border-black text-black bg-[#FFFFFF] text-[16px] "
          >
            Cancel
          </button>
          <button
            className="w-[115px] h-14 rounded-[4px] border boredr-[#08090A] text-white bg-black text-[16px] "
            onClick={() => closeModal(token, id)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default WishListDeleteModal;
