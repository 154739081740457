import { addDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLocation } from "react-router-dom";
import "./DateRange.css";

const DateRange = ({
  setShowDateRange,
  checkOutDate,
  setCheckOutDate,
  setCheckInDate,
  checkInDate,
  checkOutDate2,
  setCheckOutDate2,
  setCheckInDate2,
  checkInDate2,
  ShowDateRange,
  setShowMobileDateRange,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [monthShow, setMonthShow] = useState(1);
  const dateRangeRef = useRef(null);
  
  const location = useLocation();

  const updateWindowWidth = () => {
    if (window.innerWidth < 760) {
      setMonthShow(1);
    } else {
      setMonthShow(2);
    }
  };

  const closeDate = () => {
    if (window.innerWidth < 760) {
      setShowMobileDateRange(false);
    } else {
      setShowDateRange(false);
    }
  };

  // Function to format date based on screen width
  const formatDate = (date) => {
    return window.innerWidth < 444 ? format(date, "MMM/dd/yy") : format(date, "MMMM/dd/yyyy");
  };

  useEffect(() => {
    if (location.pathname === "/luxuryproperties") {
      setMonthShow(1);
    } else {
      updateWindowWidth();
    }
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setState([
        {
          startDate: new Date(checkInDate),
          endDate: new Date(checkOutDate),
          key: "selection",
        },
      ]);
    }
  }, [ShowDateRange, checkInDate, checkOutDate]);

  const handleFlexibleClick = () => {
    const startDate = new Date();
    const endDate = addDays(startDate, 3);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(formatDate(startDate)); 
    setCheckOutDate2(formatDate(endDate)); 
  };

  const handleTomorrowClick = () => {
    const startDate = addDays(new Date(), 1);
    const endDate = addDays(startDate, 1);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(formatDate(startDate)); 
    setCheckOutDate2(formatDate(endDate)); 
  };

  const handleTonightClick = () => {
    const startDate = new Date();
    const endDate = addDays(startDate, 1);
    setState([{ startDate, endDate, key: "selection" }]);
    setCheckInDate(startDate);
    setCheckOutDate(endDate);
    setCheckInDate2(formatDate(startDate));
    setCheckOutDate2(formatDate(endDate)); 
  };

  const handleWeekendClick = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
    const nextFriday = addDays(today, daysUntilFriday);
    const endDate = addDays(nextFriday, 2);

    setState([{ startDate: nextFriday, endDate, key: "selection" }]);
    setCheckInDate(nextFriday);
    setCheckOutDate(endDate);
    setCheckInDate2(formatDate(nextFriday)); 
    setCheckOutDate2(formatDate(endDate)); 
  };

  // Close Date Range Picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowDateRange(false);
        console.log("out side clicked")
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowDateRange]);

  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      if (
        format(checkInDate, "MMMM/dd/yyyy") ===
        format(checkOutDate, "MMMM/dd/yyyy")
      ) {
        // Reset dates but don't show alert
        setCheckOutDate(null);
        setCheckInDate(null);
        if (location.pathname !== "/typeofstays") {
          setCheckOutDate2(null);
          setCheckInDate2(null);
        }
      }
    }
  }, [
    checkInDate,
    checkOutDate,
    location.pathname,
    setCheckInDate,
    setCheckOutDate,
    setCheckInDate2,
    setCheckOutDate2,
  ]);

  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    
      }}
      className={`${
        location.pathname === "/" ? " absolute z-50 -top-[600px]" : "pb-4"}
      ${
        location.pathname === "/allproperties" ? " absolute z-50 -top-[600px]" : "pb-4"}
        ${
        location.pathname === "/luxuryproperties" ? "max-w-[400px] p-3" : ""
      } flex justify-center top-2 items-center flex-col lg:px-8 px-4 rounded-3xl max-md:rounded-lg bg-white max-lg:w-full w-[784px] z-[99999]`}
        >
      <DateRangePicker  
        ranges={state}
        onChange={(item) => {
          console.log("HI");
          if (
            location.pathname === "/" ||
            location.pathname === "/ClientDairies" ||
            location.pathname === "/allproperties"
          ) {
            setCheckInDate2(formatDate(item.selection.startDate)); // Updated here
          }

          if (item.selection.startDate !== item.selection.endDate) {
            setCheckOutDate(item.selection.endDate);
            if (
              location.pathname === "/" ||
              location.pathname === "/ClientDairies" ||
              location.pathname === "/allproperties"
            ) {
              setCheckOutDate2(formatDate(item.selection.endDate)); // Updated here
            }
          } else {
            setCheckOutDate(null);
          }

          setCheckInDate(item.selection.startDate);
          setState([item.selection]);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={monthShow}
        minDate={new Date()}
        direction="horizontal"
      />
      <div
        className={`${
          (location.pathname.includes("propertyDetails") ||
            location.pathname === "/luxuryproperties") &&
          "hidden"
        } min-h-[54px] flex-col justify-center items-start gap-4 max-md:px-3 pb-6 mt-6 inline-flex w-full`}
      >
        <div className="max-lg:w-full w-[704px] h-[0px] border border-gray-300"></div>
        <div className="flex max-md:flex-col max-md:gap-5 justify-between w-full">
          <div className="justify-start items-center gap-3 flex max-md:mx-4 overflow-scroll example">
            <button
              className="px-5 py-3 rounded-[100px] border border-gray-300"
              onClick={handleFlexibleClick}
            >
              I am flexible
            </button>
            <button
              className="px-5 py-3 rounded-[100px] border border-gray-300"
              onClick={handleTomorrowClick}
            >
              Tomorrow
            </button>
            <button
              className="px-5 py-3 rounded-[100px] border border-gray-300"
              onClick={handleTonightClick}
            >
              Tonight
            </button>
            <button
              className="px-5 py-3 rounded-[100px] border border-gray-300"
              onClick={handleWeekendClick}
            >
              This weekend
            </button>
          </div>
          <div
            onClick={closeDate}
            className="cursor-pointer"
          >
            CLOSE
          </div>
        </div>
      </div>
    </div>
  );
};


export default DateRange;
