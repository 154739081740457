import React from "react";
import blogsCoverImage from "../../../assets/svg/thingstodoblogcover.webp";
import { useGetThingsCardDataQuery } from "../../../redux/features/thingsToDo/thingsCardApi";
import Container from "../../ui/Container";
import BlogCard from "../BlogsCard";

const Blogs = () => {
  // Things to do API using RTK
  const { data, isLoading, isSuccess, isError } = useGetThingsCardDataQuery();

  return (
    <Container>
      {/* Loading or Error Handling */}
      {isLoading && <p>Loading...</p>}
      {isError && <p>Error fetching data</p>}

      {/* Headings */}
      <div className="mt-[88px] flex flex-col items-center justify-center">
        <p className="text-[16px] font-[500] text-[#434141] ">
          Things to do in Goa
        </p>
        <h1 className="mt-[8px] text-[36px] font-[500] font-butler">
          Make the most of your time
        </h1>
        <p className="mt-[16px] text-neutral-700 font-[500] text-center">
          Immerse yourself in unparalleled luxury with our meticulously designed
          accommodations. Immerse yourself in <br /> unparalleled luxury with our
          meticulously designed accommodations.
        </p>
      </div>

      {/* Cards - Dynamic Data from API */}
      <div className="mt-[120px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[8px] md:gap-[24px]">
        {isSuccess && data?.length > 0 ? (
          data.map((item, index) => (
            <BlogCard
              key={item._id} // Unique key for each item
              imageSrc={item.coverPhoto}
              title={item.title}
              category={item.activity}
              description={item.overview} // Assuming overview is the description
              flexRowReverse={index % 2 === 0} // Boolean value to switch flex direction
              column={false} // Assuming false for non-column layout
              overview={item.overview}
              routeId={item._id}
            />
          ))
        ) : (
          <p>No data available or failed to load</p>
        )}
      </div>

      {/* Static Cards with Row and Column Layout */}
      <div className="mt-[120px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-[8px] md:gap-[24px]">
        {/* Static Cards (you can replace these with dynamic data if necessary) */}
        {[...Array(3)].map((_, index) => (
          <BlogCard
            key={index}
            imageSrc={blogsCoverImage}
            title="Winter Hiking"
            category="OUTDOORS"
            description="Imagica Theme & Water Park"
            overview="Immerse yourself in unparalleled luxury with our meticulously designed accommodations."
            column={true}
          />
        ))}
      </div>
    </Container>
  );
};

export default Blogs;
